/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {loginRequest,logout as logout_request} from '../core/_requests'
import {useAuth} from '../core/Auth'
import axios from 'axios';
import { ResponseModel } from '../../../models'
import { Constants, KTInputError, SetPageMainTitle, StorageHelper } from '../../../services'
import { ActiveLoginModal } from './ActiveLoginModal'
import * as CryptoJSx from 'crypto-js';
import { OTPVerify } from '../core/_models'
import {decode as base64_decode, encode as base64_encode} from 'base-64';
const loginSchema = Yup.object().shape({
  username: Yup.string()
    .max(64, 'Maximum 64')
    .required('Username cannot be blank'),
  password: Yup.string()
    .max(64, 'Maximum 64')
    .required('Password cannot be blank'),
})

const initialValues = {
  username: '',
  password: '',
  isUserActive : false
}

export function LoginPage() {
  const [loading, setLoading] = useState(false)
  const [passExpired, SetPasswordExpire] = useState(false)
  const {login,logout} = useAuth()
  const navigate = useNavigate();

  const { token } = useParams();
  
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        setStatus(undefined);
        initialValues.isUserActive = false;
        var pass = CryptoJSx.SHA256(values.password).toString();
        const {data: auth} = await loginRequest(values.username, pass)
        if(auth.isOTPRequired === true)
        {
          var _verifyOtp = new OTPVerify();
          _verifyOtp.otpTokenUUID = auth.tokenUUID;
          _verifyOtp.sessionToken = auth.session;
          _verifyOtp.username = values.username;
          var jsonString = JSON.stringify(_verifyOtp);
          sessionStorage.setItem(base64_encode('_2FATOP'),base64_encode(jsonString)); 
          navigate('/auth/Otp');
        } else {
          login(auth.jwtToken.token);
        }
      } catch (error) {
        try{
          setSubmitting(false)
          setLoading(false)
          logout()
          if (axios.isAxiosError(error)) {
            const result: ResponseModel = JSON.parse(JSON.stringify(error.response?.data)) as ResponseModel
            setStatus(result.error);
            if(Constants.LOGIN_ACTIVE === result.error){
              initialValues.isUserActive = Constants.LOGIN_ACTIVE === result.error;
            }
            if(Constants.LOGIN_1ST_TIME === result.error){
              sessionStorage.setItem(base64_encode('_USERNAME'),base64_encode(values.username));   
              navigate('/auth/ResetPassword/token/0/valid/true');
            }
            if(Constants.LOGIN_PASS_EXPIRED === result.error){              
              SetPasswordExpire(true);
              sessionStorage.setItem(base64_encode('_token'),base64_encode(result.token)); 
            }                    
          }else{
            setStatus('The login detail is incorrect')
          }
        }
        catch(_error){}
        console.error(error)
      }
    },
  })

  const confirmActiveLoginModal = (username: string) => {
    logout_request(username).then(function (response) {formik.submitForm();
    });
  }

  useEffect(()=>{
    if(token && token !== null){
      sessionStorage.setItem(base64_encode('_token'),base64_encode(token)); 
      navigate("/");
    }

    SetPageMainTitle('Login');
    var _data = sessionStorage.getItem(base64_encode('_LOGOUT_REDIRECT'));
    if(_data !== null)
    {
      var type = base64_decode(_data);
      sessionStorage.removeItem(base64_encode('_LOGOUT_REDIRECT'));
      if(type === Constants.LOGOUT_ACTIVE_SESSION) {
        formik.setStatus(Constants.MESSAGE_LOGOUT_ACTIVE_SESSION);
      }
      if(type === Constants.LOGOUT_SESSION_TIMEOUT) {
        formik.setStatus(Constants.MESSAGE_LOGOUT_SESSION_TIMEOUT);
      }
    }
    StorageHelper.DeleteLocalStorageData();
    return ()=>{ 
    }
    // eslint-disable-next-line
   },[])
  

  return ( <>
  { !passExpired && 
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {initialValues.isUserActive ? (<ActiveLoginModal data={formik.values.username} handleConfirm={confirmActiveLoginModal}/>):<></>}
      {/* begin::Heading */}
      <div className='mb-8'>
        <h2 className='text-light mb-3 fw-normal'>LOGIN TO YOUR ACCOUNT</h2>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-5 alert alert-danger'> 
        <span className='btn btn-icon btn-active-light-primary btn-close fs-8 position-absolute top-0 end-0' 
            style={{height:'1.7rem',width:'1.7rem'}} aria-label="Close" onClick={()=>{ formik.setStatus(undefined)}} ></span>
          <div className='alert-text font-weight-bold mt-1'>{formik.status}</div>
        </div>
      ) : (
        <></>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-5'>
        <div className="input-group mb-1">
        <span className="input-group-text"><i className="fa fa-user fs-4 text-gray-400"></i></span>
        <input maxLength={32}
          placeholder='Username'
          {...formik.getFieldProps('username')}
          className={clsx(
            'form-control form-control-solid form-control-sm',
            {
              'is-invalid': formik.touched.username && formik.errors.username,
            }
          )}
          type='text'
          name='username'
          autoComplete='off'
        />
        </div>
        <KTInputError active={formik.touched.username} error={formik.errors.username} textClass='text-light'/>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-5'>
      <div className="input-group mb-1">
        <span className="input-group-text"><i className="fa fa-lock fs-4 text-gray-400"></i></span>
        <input placeholder='Password' type='password' autoComplete='off' maxLength={32}
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-solid form-control-sm',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            }
          )}
        />
        </div>
        <KTInputError active={formik.touched.password} error={formik.errors.password} textClass='text-light' />
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center fv-row mb-5'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-sm btn-secondary border border-secondary w-100 mt-2 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>LOGIN</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      <div className='d-flex justify-content-start mt-6'>
            <Link to='/auth/forgot-password' className='link-light fs-6 fw-bold py-1 px-2 text-hover-secondary bg-hover-light'>
              FORGOT YOUR PASSWORD ?
            </Link>
      </div>
    </form>
  }

  {passExpired && (
      <div className='fs-5'>
          <div className='text-light py-5'>
            Your password has expired, please click
            <span className='text-warning text-hover-primary m-1 hoverable' onClick={()=>{
                sessionStorage.setItem(base64_encode('_USERNAME'),base64_encode(formik.values.username));   
                navigate('/auth/ResetPassword/token/1/valid/true');
            }}><u>here</u></span> 
            to reset.
          </div>
        </div>
    )}
  </>
  )
}
