import { Auditable } from "../../../models/global/Auditable"
import * as Yup from 'yup';
import { string } from 'yup';
import { DatatableInput } from "../../../models/global/DataTable";


export class SearchForm {

  dataTablesInput : DatatableInput;
  searchValue : string = ""

  constructor(dataTablesInput : DatatableInput,searchValue:string)
  {
      this.dataTablesInput = dataTablesInput
      this.searchValue = searchValue
  }
}



export interface DoctorRatingWeightageResponse {
  DoctorRatingWeightageForm: DoctorRatingWeightage[];
}

export class DoctorRatingWeightage extends Auditable{
  id?: number;
  monthYear: string="";
  internalWeightage: string="";
  externalWeightage: string="";
  searchType?: any;
  searchValue?: any;
  month: string="";
  year: string="";
  flag: boolean=false;
  dataTablesInput?: any;
  rowId: number=0;
  canDelete: boolean=false;
  canEdit: boolean=false;

}

export const DoctorRatingWeightageSchema = Yup.object({
  monthYear: string().required('Please Select Month'),
  internalWeightage: string().required('Please Select Month'),
  externalWeightage: string().required('Please Select Month')
})
