import { Auditable } from "../../models/global/Auditable"
import { MenuList } from "../../models";


export class ApplicationUser extends Auditable  {
  applicationUserId: number = 0
  effectivedate: string = ""
  emailId: string = ""
  hashedpassword: string = ""
  id: number = 0
  isActive: string = ""
  isAuthenticated: boolean = false
  isMailSent: boolean = false
  lastLoginRecord: string = ""
  medixUser: boolean = false
  menuList: Array<MenuList> = []
  profileId: number = 0
  profilemaster: number = 0
  profilemasterName: string = ""
  remark: string = ""
  terminationdate: string = ""
  username: string = ""
  usertype: number = 0
  usertypeName:string = ""
}


export class UserTypeMaster{
  id: number = 0
  name: string = ""
}

export class ProfileMaster{
  id: number = 0
  name: string = ""
  parentusertypeid: number = 0
}
