import { useEffect, useState } from 'react';
import { RatingType, UploadInternalRatings, UploadInternalRatingsSchema} from './_models';
import { save,getById, FetchRatingTypeList, DownloadRatingSampleFile } from './_requests';
import { KTCard, KTCardBody,KTCardFooter,KTCardHead } from '../../../../_theme/helpers';
import { useFormik } from 'formik';
import { Constants, KTInputLabel, StatusResponseModal } from '../../../services';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { StatusResponse } from '../../../models/global/StatusResponce';
import { KTInputError, KTSelectBox } from '../../../services/components/InputTemplates';
import { KeyValueOption } from '../../../models';
import clsx from 'clsx';



export function UploadInternalRatingsAddEdit () {

  const [isLoading, setLoading] = useState(true);
  const [statusResp, setStatusResp] = useState(new StatusResponse());
  const [isAlertModal, setAlertModal] = useState(false);
  const [UploadInternalRatingsData, setUploadInternalRatings] = useState(new UploadInternalRatings());
  const [ratingTypeList, setRatingTypeList] = useState(Array<RatingType>());
  const [file, setFile] = useState<Blob>();
  const FileDownload = require('js-file-download');
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(()=>{
    fetchUploadInternalRatingsList();
    getAllRatingType();
  return ()=>{ 
  }
  // eslint-disable-next-line
 },[])

 const getAllRatingType = () => {
  FetchRatingTypeList().then(function (response) {
    setRatingTypeList(response.data);
    setLoading(false);
  })

}



 const fetchUploadInternalRatingsList = () => {
  if(id !== undefined && id && isLoading){
    getById(id).then(function (response) {
      setUploadInternalRatings(response.data);
      formik.resetForm({
        values:response.data
      })
      setLoading(false);
    })
  }else{
    setLoading(false);
  }
}

 const formik = useFormik({
    initialValues: UploadInternalRatingsData,
    validationSchema: UploadInternalRatingsSchema,
    enableReinitialize:true,
    onSubmit: async (values, {setStatus, setSubmitting}) => {

      setLoading(true)
      setSubmitting(true)
      try {

      const formData = new FormData();
      if(file)
        formData.append('file', file);
      formData.append('ratingtypeId', values.ratingtypeId.toString());
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };

        var saveResp = await save(formData, config);
        setStatusResp(saveResp.data);
        setAlertModal(true);
        setLoading(false)
        setSubmitting(false)
      } catch (error) {
        try{
        }
        catch(_error){
            setStatus(Constants.MESSAGE_ERROR);
        }
        console.error(error)
      }
    },
  })

  const downloadFile = (fileName:string) => {
    setLoading(true)
    DownloadRatingSampleFile(fileName).then(function (response) {
          FileDownload(response.data, fileName,'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8');
          setLoading(false)
    })
  }
  
  const closeModal = () => {
    setAlertModal(false);
    if(statusResp.code===1)
    navigate("/RatingSettings/UploadInternalRatings");
  }

  function handleChange(event:any) {
    formik.setFieldValue('filename',undefined);
    setFile(undefined);
    if(event.target.files[0]){
      var img = event.target.files[0];
      if((img.size/1024/1024)>1)
      {
        //setAlertMessage("Selcted file exceeded maximum limit of 1MB.");
        //setAlertModal(true);
      }else{
        formik.setFieldValue('filename',event.target.files[0].name);      
        setFile(event.target.files[0]);
      }
    }
  }
  


      return (
         <>
         {isAlertModal ? (<StatusResponseModal message={''} data={statusResp}  handleClose = {closeModal}/>):<></>}
          
          <KTCard>
            <KTCardHead title={((id == undefined || parseInt(id) === 0)?'Add':'Edit')+' Upload Internal Ratings'}>
            </KTCardHead>
            <KTCardBody>
                <form className='form w-100 my-5' onSubmit={formik.handleSubmit} noValidate id='kt_country_add_edit_form' >
                    

                <div className="row">
                <div className='col-sm-6 d-md-flex my-2'>
                  <span className='text-primary fw-bolder text-hover-secondary hoverable' onClick={() => downloadFile('ClinicUploadFormat.csv')}>
                    Download Sample CSV file for Clinic Ratings
                  </span>
                </div>
                <div className='col-sm-6 d-md-flex my-2'>
                  <span className='text-primary fw-bolder text-hover-secondary hoverable' onClick={() => downloadFile('DoctorUploadFormat.csv')}>
                    Download Sample CSV file for Doctor Ratings
                  </span>
                  </div>
                </div>
                
                <div className="row">
                      <div className='col-sm-6 d-md-flex my-2'>
                        <KTInputLabel required={true} title="CSV File" className='col-md-3' />
                        <div className="col-md-8">
                          
                        <input type="file" onChange={handleChange} 
                         className={clsx('form-control form-control-sm ' ,{ ' is-invalid': formik.touched.ratingtypeId && formik.errors.ratingtypeId})} />
                        <KTInputError active={formik.touched.filename} error={formik.errors.filename} />
                        </div>
                      </div>
                      <div className='col-sm-6 d-md-flex my-2'>
                        <KTInputLabel required={true} title="Upload Type" className='col-md-3' />
                        <div className="col-md-8">
                        <KTSelectBox placeholder='Select Upload Type'  formikProps={formik.getFieldProps('ratingtypeId')}  
                       
                        optionsList={ratingTypeList?.map((x) => {return new KeyValueOption(x.ratingtypeId,x.ratingtypeName);})}
                                  active={formik.touched.ratingtypeId} error={formik.errors.ratingtypeId}
                                  onChangeHandler={(val) => {
                                    formik.setFieldValue('ratingtypeId',parseInt(val));
                                  }} 
                                />
                        </div>
                      </div>
                    </div>

                    
                    <KTCardFooter className='d-flex justify-content-end mt-5'>
                      {/* begin::Action */}
                        <div className="btn-group" role="group" >                                
                          
                          <button type='submit' id='save_submit' className='btn btn-sm btn-secondary' disabled={formik.isSubmitting || !formik.isValid}>
                            {!isLoading && <span className='indicator-label'>SAVE</span>}
                            {isLoading && (
                              <span className='indicator-progress' style={{display: 'block'}}>
                                Please wait...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                            </button>
                            <Link className='btn btn-sm btn-secondary ms-3' to='/RatingSettings/UploadInternalRatings'>CLOSE</Link>
                        </div>
                        {/* end::Action */}
                    </KTCardFooter>
                </form>
            </KTCardBody>

          </KTCard>
          </>
      )
    
}


