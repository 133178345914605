import axios from 'axios'
import { env } from '../../../App.env';
import { CodeExist } from '../../../models';
import { Status, StatusResponse } from '../../../models/global/StatusResponce';
import { InpatientAttendanceTable, InpatientAttendanceTableResponse } from './_models';


const API_URL = env.base_api_url
const API_CP = `${API_URL}/Api/Settings/InpatientattendanceMaster`


export function searchInpatientAttendanceTable() {
  return axios.get<InpatientAttendanceTableResponse>(`${API_CP}/getInpatientattendancefeetableDetails`)
}

export function getById(id: String) {
  return axios.get<InpatientAttendanceTable>(`${API_CP}/id/${id}`)
}

export function save(req: InpatientAttendanceTable) {
  return axios.post<StatusResponse>(`${API_CP}/save`,req)
}

export function deleteById(id: number) {
  return axios.get<Status>(`${API_CP}/delete/id/${id}`)
}

export function IsCodeExist(name? : string,id : number = 0) {
  return axios.get<CodeExist>(`${API_CP}/fetchExistsInpatientattendanceMaster?Inpatientattendance=${name}&attendanceId=${id}`)
}

