import axios from 'axios'
import { env } from '../../../App.env';
import { Status, StatusResponse } from '../../../models/global/StatusResponce';
import { RatingType, SearchForm, UploadInternalRatings } from './_models';


const API_URL = env.base_api_url
const API_CP = `${API_URL}/Api/RatingSetting/UploadRating`

export function FetchRatingTypeList() {
  return axios.get<RatingType[]>(`${API_URL}/Api/RatingSetting/UploadRating/getAllRatingType`)
}

export function searchUploadInternalRatings(searchForm:SearchForm) {
  return axios.post<UploadInternalRatings[]>(`${API_CP}/search`,searchForm)
}

export function getById(id: String) {
  return axios.get<UploadInternalRatings>(`${API_CP}/id/${id}`)
}

export function save(formData:FormData, config:any) {
  return axios.post<StatusResponse>(`${API_URL}/Api/RatingSetting/UploadRating/saveDocuments`,formData, config)
}

export function deleteById(id: number) {
  return axios.get<Status>(`${API_CP}/delete/${id}`)
}

export function DownloadRatingSampleFile(fileName:string){
  return axios.get<any>(`${API_URL}/Api/RatingSetting/UploadRating/downloadRatingSample/fileName/${fileName}`)
  
}

