import React, { useEffect } from 'react';
import { SurveyConfig} from './_models';
import { deleteById, searchSurveyConfig } from './_requests';
import { KTCard, KTCardBody,KTCardHead } from '../../../../_theme/helpers';

import { AlertModal, ConfirmModal, Constants, Pagination, Loading, UserType, EmptyDatatable, DateToString, StringToDate } from '../../../services';
import { Link } from 'react-router-dom';
import { Status } from '../../../models/global/StatusResponce';
import { useAuth } from '../../auth';
import DataTable from 'react-data-table-component';
import {SurveyConfigTableColumns } from './_dataRowColumns';
import { KTDatePickerBox } from '../../../services/components/InputTemplates';
import { FormikProvider, useFormik } from 'formik';
import { SearchFormDate } from '../../../models/global/Common';

export function SurveyConfigList () {

  const {currentUser} = useAuth();
  const [isLoading, setLoading] = React.useState(true);
  const [dataList, setData] = React.useState(Array<SurveyConfig>);
  const [statusResp, setStatusResp] = React.useState(new Status(0,""));
  const [isConfirmModal, setConfirmModal] = React.useState(false);
  const [isAlertModal, setAlertModal] = React.useState(false);
  const [countryId, setSurveyConfigId] = React.useState(0);
  const [PageNo, setPageNo] = React.useState(1);
  const [searchFormDate] = React.useState(new SearchFormDate());
  

  useEffect(()=>{
    fetchSurveyConfig(searchFormDate,false);
  return ()=>{ 
  }
  // eslint-disable-next-line
 },[])

 
 const formik = useFormik({
  initialValues:searchFormDate,
  enableReinitialize:true,
  onSubmit: async (values) => {
    setLoading(true)
    fetchSurveyConfig(values,true);
  },
})


const fetchSurveyConfig = (searchForm:SearchFormDate,isSearch:boolean)=>{
  searchSurveyConfig(searchForm,isSearch).then(function (response) {
    setData(response.data);
    setLoading(false);
  })
}

  const confirmModal = (val?: number) => {
    setSurveyConfigId(val?val:0);
    setConfirmModal(true);
  }
  const closeModal = () => {
    setConfirmModal(false);
  }

  const closeDeleteModal = () => {
    setAlertModal(false);
    setLoading(true);
    fetchSurveyConfig(searchFormDate,false);
  }
  
  const DeleteSurveyConfig = async (val: any) => {
    try{
      setConfirmModal(false);
      await deleteById(parseInt(val)).then(function (response) {
        setStatusResp(response.data);
        setAlertModal(true);
      });
    }catch(e){
      console.log(e);
    }
  }



      return (
         <>
         {isLoading && (<Loading/>)}
         {isAlertModal ? (<AlertModal message={statusResp.message.toString()} data={statusResp}  handleClose = {closeDeleteModal}/>):<></>}
         {isConfirmModal ? (<ConfirmModal message={Constants.MESSAGE_DELETE_REQUEST} data={countryId} handleConfirm={DeleteSurveyConfig} handleClose = {closeModal}/>):<></>}
          <KTCard>
            <KTCardHead title='SEARCH SURVEY CONFIG'>
              {
              currentUser?.usertypeName === UserType.ADMINUSER.CODE && (
              <div className="card-toolbar my-0">
                <Link className='btn btn-sm btn-secondary' to='/RatingSettings/SurveyConfig/Add'>
                  ADD SURVEY CONFIG
                </Link>
              </div>)
              }
            </KTCardHead>
            <KTCardBody>
                
            <FormikProvider value={formik}>
                <form className='form w-100 my-5' onSubmit={formik.handleSubmit}>
                
                <div className="row">

                      <div className="col-xl-2 col-md-3 col-sm-4 col-6 my-2">
                      <KTDatePickerBox placeholder='Enter From Date'  formikProps={formik.getFieldProps('fromdate')} 
                                            handleOnChange ={ 
                                              (date : Date) => {  formik.setFieldValue('fromdate',DateToString(date));formik.setFieldValue('todate','');
                                              }
                                            }
                                            />
                      </div>
                      <div className="col-xl-2 col-md-3 col-sm-4 col-6 my-2">
                      <KTDatePickerBox placeholder='Enter To Date' 
                                            formikProps={formik.getFieldProps('todate')}
                                            handleOnChange ={ 
                                              (date : Date) => {
                                                formik.setFieldValue('todate',DateToString(date));
                                              }
                                            }
                                            DateRange={{start: StringToDate(formik.values.fromdate)}}
                                            />
                      </div>
                      <div className="col-xl-2 col-md-3 col-sm-4 my-2">
                        <button type="submit" id="search_submit" className="btn btn-sm btn-brand text-light float-end">
                          <span className="indicator-label">SEARCH</span>
                        </button>
                      </div>
                    </div>  

                    <div className={Constants.DATATABLE_STYLE_REACT}>
                      <DataTable
                        columns={SurveyConfigTableColumns(PageNo,confirmModal)}
                        data={dataList}
                        pagination
                        onChangePage={(page: number) => {setPageNo(page);}}
                        paginationComponent={Pagination} 
                        noDataComponent={<EmptyDatatable columns={SurveyConfigTableColumns()}/>}                   
                      />
                    </div>
                    </form>
                    </FormikProvider>

                    
            </KTCardBody>
          </KTCard>
          </>
      )
}
