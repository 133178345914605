
export class ResetPasswordForm {
  password?:string
  sessionToken?:string
  resetPassToken?:string
  username?:string
}

export class OTPForm {
  constructor(length:number)
  {
      var t = [];
      for(var i = 0 ; i<length ; i++ )
        t.push(undefined);
      this.otp = t;

  }
  otp: Array<number|undefined>
} 

export class OTPVerify {
  otp?:string
  otpTokenUUID?:string
  sessionToken?:string
  username?:string
  password?:string
  resetPassToken?:string
} 