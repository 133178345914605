import { Auditable } from "../../../models/global/Auditable"
import * as Yup from 'yup';
import { string } from 'yup';
import { DatatableInput } from "../../../models/global/DataTable";


export interface SurveyQuestionsResponse {
  SurveyQuestionsForm: SurveyQuestions[];
}

export class  SearchFormQuestion {

  dataTablesInput : DatatableInput;
  landingFlag: number=0;
  question: string="";
  publishFromdate: string="";
  publishTodate: string="";
  status: string="";
  clinicqustype: number=0;
  doctorqustype: number=0;
  todate: string="";
  fromdate: string="";

  constructor(dataTablesInput : DatatableInput)
  {
      this.dataTablesInput = dataTablesInput
  }
  
}

export class SurveyQuestionsPublish extends Auditable{
  id?: number;
  fromdate: string="";
  todate: string="";
  displayorder: string="";
  surveyquestionId: Number=0;
  createdName: string="";
  status:string="";
  candisplay:string="";
  
}


export class SurveyQuestions extends Auditable{
  id?: number;
  questiontype: number=0;
  question: string="";
  weightage?: string="";
  questiontypename: string="";
  searchType?: string="";
  searchValue?: string="";
  applicationuserName: string="";
  publishFromdate: string="";
  publishTodate: string="";
  status: string="";
  displayOrder: number=0;
  landingFlag: number=0;
  clinicqustype: number=0;
  doctorqustype: number=0;
  ansRating?: string="";
  modifiedName?: string="";
  rownum: number=0;
  filterrecord: number=0;
  totalrecord: number=0;
  deleteflag: number=0;
  dataTablesInput?: string="";
  rowId: number=0;
}

export class QuestionType{
  id: number=0;
  name: string="";
}

export const SurveyQuestionsSchema = Yup.object({
  question: string().max(256, 'Maximum 256').required('Please enter Survey Questions Name'),
  questiontype: Yup.number().moreThan(0, 'Please Select Question Type.'),
  
})

export const PublishSurveyQuestionsSchema = Yup.object({
  fromdate: string().max(256, 'Maximum 256').required('Please enter from date.'),
  displayorder: string().max(256, 'Maximum 256').required('Please display order.')
})
