import React, { useEffect } from 'react';
import { PaginationComponentProps } from 'react-data-table-component/dist/src/DataTable/types';
import { Constants } from '../lib/Constants';
import { KTPaginationInfo } from './Common';


const Pagination: React.FC<PaginationComponentProps> = ({rowCount,currentPage,onChangePage}) => {

    const [lastPage, setSetLastPage] = React.useState(1);
    const [PageLinks, setPageLinks] = React.useState<number[]>([]);

    useEffect(()=>{
        var _lastpage = Math.ceil(rowCount / Constants.DATATABLE_PAGE_SIZE);
        setSetLastPage(_lastpage);
        var p : number[] = [];
        for (let i = 1; i <= _lastpage; i++) {
            p.push(i);
        }
        setPageLinks(p);

      return ()=>{ 
      }
     },[rowCount])

    const NextPage = () => {
        if(currentPage < lastPage){
            onChangePage(currentPage + 1,rowCount);
        }
    };
    const PreviousPage = () => {
        if(currentPage > 1)
        onChangePage(currentPage - 1,rowCount);
    };
    const PageLink = (Page:number) =>{
        if(Page !== currentPage)
        onChangePage(Page,rowCount);
    }
  return (
    <div className='mt-3 d-flex flex-column flex-md-row justify-content-md-between'>
        <div className="dataTables_info mb-3 d-flex justify-content-center">{KTPaginationInfo(currentPage,rowCount)}</div>
        <ul className="pagination">
            <li className={'page-item border me-1 ' + (currentPage !==1 ? ' border-hover-primary' : ' bg-light bg-hover-light')}>
                <button className={'page-link rounded-0 ' + (currentPage !==1 ? ' ' : ' bg-light text-hover-gray-500')} onClick={PreviousPage}><i className="fa fa-chevron-left fs-6"></i></button>
            </li>
            { 
                  PageLinks.map((page : number, i) => (
                    ( 
                    (lastPage < 6 || (page < 6 && currentPage < 4) || (page > lastPage-5 && currentPage > lastPage-3) ||
                        (lastPage > 5 && (currentPage > 3 || currentPage < lastPage-2) &&
                            (page === currentPage || page === currentPage -1 || page === currentPage + 1 || page === currentPage -2 || page === currentPage + 2)
                        )
                    ) 
                    && ( 
                    <li  key={page} className={"page-item border me-1 " + (currentPage === page ? 'bg-secondary border-hover-secondary border-secondary' : ' border-hover-primary')}>
                        <button onClick={() => {PageLink(page)}} className={"page-link rounded-0 " + (currentPage === page ? ' text-light bg-secondary' : ' ')}>{page}</button>
                    </li>
                    ))
                   
                ))   
            }
            <li className={'page-item border me-1 ' + (currentPage !== lastPage ? ' border-hover-primary' : ' bg-light bg-hover-light')}>
                <button className={'page-link rounded-0 ' + (currentPage !==lastPage ? ' ' : ' bg-light text-hover-gray-500')} onClick={NextPage}><i className="fa fa-chevron-right fs-6"></i></button>
            </li>
        </ul>
    </div>
  )
}

export {Pagination}




