import axios from 'axios'
import { env } from '../../../App.env';
import { DatatableResponse } from '../../../models/global/DataTable';
import { Status, StatusResponse } from '../../../models/global/StatusResponce';
import { clean } from '../../../services';
import {QuestionType, SearchFormQuestion, SurveyQuestions, SurveyQuestionsPublish } from './_models';


const API_URL = env.base_api_url
const API_CP = `${API_URL}/Api/Surveyquestions`

export function FetchquestiontypeList() {
  return axios.get<QuestionType[]>(`${API_URL}/Api/Questiontype/fetchquestiontypeList`)
}

export function searchSurveyQuestions(searchForm:SearchFormQuestion) {
  return axios.post<DatatableResponse<SurveyQuestions>>(`${API_CP}/fetchquestionlist`,clean(searchForm))
}

export function getById(id: String) {
  return axios.get<SurveyQuestions>(`${API_CP}/id/${id}`)
}

export function save(req: SurveyQuestions) {
  return axios.post<StatusResponse>(`${API_CP}/saveQuestions`,clean(req))
}

export function deleteById(id: number) {
  return axios.get<Status>(`${API_CP}/delete/${id}`)
}

export function FetchAllSurveypublishedquestions(id: String) {
  return axios.post<SurveyQuestionsPublish[]>(`${API_URL}/Api/PublishSurveyquestions/getAllSurveypublishedquestions/id/${id}`)
}

export function savePublishquestions(req: SurveyQuestionsPublish) {
  return axios.post<Status>(`${API_URL}/Api/PublishSurveyquestions/savePublishquestions`,clean(req))
}


export function getUnpublishedId(id: String) {
  return axios.post<SurveyQuestionsPublish[]>(`${API_URL}/Api/PublishSurveyquestions/getUnpublishedId/${id}`)
}

export function deactivatequestionbyId(id: Number) {
  return axios.post<Status>(`${API_URL}/Api/PublishSurveyquestions/deactivatequestionbyId/${id}`)
}





