import { Auditable } from "../../../models/global/Auditable"
import * as Yup from 'yup';
import { ApplicationUser } from "../_models";
import { DatatableInput } from "../../../models/global/DataTable";


export class AdminUser extends Auditable  {
  applicationUserId?: number
  applicationuserName?: string
  did?: string
  dob?: string
  email: string = ""
  encdecAdminuserId?: string
  encdecApplicationuserId?: string
  filterrecord?: number
  id?: number = 0
  isMailSent?: number
  mobile?: string
  name: string = ""
  nric: string = ""
  profiletype?: string
  rownum?: number
  status?: string
  totalrecord?: number
  usertype?: string
}

export class AdminUserForm   {
  adminUserForm : AdminUser = new AdminUser();
  applicationuserForm : ApplicationUser = new ApplicationUser();
}

export class AdminSearchForm {
  dataTablesInput : DatatableInput;
  p_name?: string
  p_profiletype?: string
  p_status?: string
  p_username?: string
  p_usertype?: string
  searchType?: string
  searchValue?: string = ''
  constructor(dataTablesInput : DatatableInput)
  {
      this.dataTablesInput = dataTablesInput
  }
}

export const AdminUserValidationSchema = Yup.object({
  adminUserForm : Yup.object({
    nric: Yup.string().required('Please Enter LAN ID').min(6,'Must be at least 6 characters').max(16),
    name: Yup.string().required('Please Enter Name'),
    email: Yup.string().email('Please Enter vaild Email').required('Please Enter vaild Email')
  }),

  applicationuserForm: Yup.object({
    effectivedate: Yup.string()
      .required('Please Enter Effective Date'), 
    usertype: Yup.number().min(1,'Please Select User Type')
      .required('Please Select User Type'),
    username: Yup.string()
      .required('Please Enter User Name'),
    profilemaster: Yup.number().min(1,'Please Select Profile Type')
      .required('Please Select Profile Type'),
  })
})