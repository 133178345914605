import React, { useEffect } from 'react';
import { InpatientAttendanceFeeForm, SearchFormAttendancefee} from './_models';
import { deleteById, searchInpatientAttendanceFee } from './_requests';
import { KTCard, KTCardBody,KTCardHead } from '../../../../_theme/helpers';

import { AlertModal, ConfirmModal, Constants, Pagination, Loading, UserType, EmptyDatatable, DateToString, StringToDate } from '../../../services';
import { Link } from 'react-router-dom';
import { Status } from '../../../models/global/StatusResponce';
import { useAuth } from '../../auth';
import DataTable from 'react-data-table-component';
import {InpatientAttendanceFeeTableColumns } from './_dataRowColumns';
import { FormikProvider, useFormik } from 'formik';
import { KTDatePickerBox, KTSelectBox } from '../../../services/components/InputTemplates';
import { KeyValueOption } from '../../../models';
import { InpatientAttendanceTable } from '../../settings/inpatient-attendance-table/_models';
import { searchInpatientAttendanceTable } from '../../settings/inpatient-attendance-table/_requests';

export function InpatientAttendanceFeeList () {

  const {currentUser} = useAuth();
  const [isLoading, setLoading] = React.useState(true);
  const [dataList, setData] = React.useState(Array<InpatientAttendanceFeeForm>());
  const [statusResp, setStatusResp] = React.useState(new Status(0,""));
  const [isConfirmModal, setConfirmModal] = React.useState(false);
  const [isAlertModal, setAlertModal] = React.useState(false);
  const [countryId, setInpatientAttendanceFeeId] = React.useState(0);
  const [PageNo, setPageNo] = React.useState(1);
  const [inpatientAttendanceTableList, setInpatientAttendanceTableList] = React.useState(Array<InpatientAttendanceTable>());

  

  useEffect(()=>{
    fetchInpatientAttendanceFee(new SearchFormAttendancefee(),false);
    fetchInpatientAttendanceTable();
  return ()=>{ 
  }
  // eslint-disable-next-line
 },[])

 
 const formik = useFormik({
  initialValues:new SearchFormAttendancefee(),
  enableReinitialize:true,
  onSubmit: async (values) => {
    setLoading(true)
    fetchInpatientAttendanceFee(values,true);
  },
})


const fetchInpatientAttendanceFee = (req:SearchFormAttendancefee,isSearch:boolean)=>{
  searchInpatientAttendanceFee(req,isSearch).then(function (response) {
    setData(response.data.inpatientattendancefeeForm);
    setLoading(false);
  })
}

const fetchInpatientAttendanceTable = ()=>{
  searchInpatientAttendanceTable().then(function (response) {
    setInpatientAttendanceTableList(response.data.inpatientattendancefeetableForms);
    setLoading(false);
  })
}

  const confirmModal = (val?: number) => {
    setInpatientAttendanceFeeId(val?val:0);
    setConfirmModal(true);
  }
  const closeModal = () => {
    setConfirmModal(false);
  }

  const closeDeleteModal = () => {
    setAlertModal(false);
    setLoading(true);
    fetchInpatientAttendanceFee(new SearchFormAttendancefee(),false);
  }

  
  const DeleteInpatientAttendanceFee = async (val: any) => {
    try{
      setConfirmModal(false);
      await deleteById(parseInt(val)).then(function (response) {
        setStatusResp(response.data);
        setAlertModal(true);
      });
    }catch(e){
      console.log(e);
    }
  }

 

      return (
         <>
         {isLoading && (<Loading/>)}
         {isAlertModal ? (<AlertModal message={statusResp.message.toString()} data={statusResp}  handleClose = {closeDeleteModal}/>):<></>}
         {isConfirmModal ? (<ConfirmModal message={Constants.MESSAGE_DELETE_REQUEST} data={countryId} handleConfirm={DeleteInpatientAttendanceFee} handleClose = {closeModal}/>):<></>}
          <KTCard>
            <KTCardHead title='SEARCH INPATIENT ATTENDANCE FEE'>
              {
              currentUser?.usertypeName === UserType.ADMINUSER.CODE && (
              <div className="card-toolbar my-0">
                <Link className='btn btn-sm btn-secondary' to='/ClinicalSettings/InpatientAttendanceFee/Add'>
                  ADD INPATIENT ATTENDANCE FEE
                </Link>
              </div>)
              }
            </KTCardHead>
            <KTCardBody>
                

            <FormikProvider value={formik}>
              <form className='form w-100 my-5' onSubmit={formik.handleSubmit}>              
                <div className="row">
                <div className="col-md-3 col-sm-6 my-2">
                <KTSelectBox placeholder='Select Schedule Name'  formikProps={formik.getFieldProps('scheduleId')}  
                        optionsList={inpatientAttendanceTableList?.map((x) => {return new KeyValueOption(x.id,x.name);})}
                                  onChangeHandler={(val) => {
                                    formik.setFieldValue('scheduleId',parseInt(val));
                                  }} 
                                />
                </div>
                <div className="col-md-2 col-sm-6 my-2">
                <KTSelectBox placeholder='Select Status'  formikProps={formik.getFieldProps('makercheckerstatus')}  
                optionsList={['Approved','Submitted','Rejected']?.map((x) => {return new KeyValueOption(x,x);})}
                      onChangeHandler={(val) => {
                        formik.setFieldValue('makercheckerstatus',val);
                        }
                      }
                  />          
                </div>
                  <div className="col-md-2 col-sm-4 my-2">
                  <KTDatePickerBox placeholder='Enter From Date'  formikProps={formik.getFieldProps('fromDate')} 
                                        handleOnChange ={ 
                                          (date : Date) => {  formik.setFieldValue('fromDate',DateToString(date));formik.setFieldValue('toDate','');
                                          }
                                        }
                                        />
                  </div>
                  <div className="col-md-2 col-sm-4 my-2">
                  <KTDatePickerBox placeholder='Enter To Date' 
                                        formikProps={formik.getFieldProps('toDate')}
                                        handleOnChange ={ 
                                          (date : Date) => {
                                            formik.setFieldValue('toDate',DateToString(date));
                                          }
                                        }
                                        DateRange={{start: StringToDate(formik.values.fromDate)}}
                                        />
                  </div>
                  <div className="col-md-3 col-sm-4 my-2">
                    <button type="submit" id="search_submit" className="btn btn-sm btn-brand text-light float-end">
                      <span className="indicator-label">SEARCH</span>
                    </button>
                  </div>
                </div>          
              </form>
              </FormikProvider>


                <div className={Constants.DATATABLE_STYLE_REACT}>
                  <DataTable
                    columns={InpatientAttendanceFeeTableColumns(PageNo,confirmModal)}
                    data={dataList}
                    pagination
                    onChangePage={(page: number) => {setPageNo(page);}}
                    paginationComponent={Pagination} 
                    noDataComponent={<EmptyDatatable columns={InpatientAttendanceFeeTableColumns()}/>}                   
                  />
                </div>
            </KTCardBody>
          </KTCard>
          </>
      )
}
