import React, { useEffect } from 'react';
import { HealthScreeningType} from './_models';
import { deleteById, searchHealthScreeningType } from './_requests';
import { KTCard, KTCardBody,KTCardHead } from '../../../../_theme/helpers';

import { AlertModal, ConfirmModal, Constants, Pagination, Loading, UserType, EmptyDatatable } from '../../../services';
import { Link } from 'react-router-dom';
import { Status } from '../../../models/global/StatusResponce';
import { useAuth } from '../../auth';
import DataTable from 'react-data-table-component';
import {HealthScreeningTypeTableColumns } from './_dataRowColumns';

export function HealthScreeningTypeList () {

  const {currentUser} = useAuth();
  const [isLoading, setLoading] = React.useState(true);
  const [dataList, setData] = React.useState(Array<HealthScreeningType>());
  const [filterDataList, setFilterData] = React.useState(Array<HealthScreeningType>());
  const [statusResp, setStatusResp] = React.useState(new Status(0,""));
  const [isConfirmModal, setConfirmModal] = React.useState(false);
  const [isAlertModal, setAlertModal] = React.useState(false);
  const [countryId, setHealthScreeningTypeId] = React.useState(0);
  const [search_text, setSearch_text] = React.useState('');
  const [PageNo, setPageNo] = React.useState(1);

  useEffect(()=>{
    fetchHealthScreeningType();
  return ()=>{ 
  }
 },[])


const fetchHealthScreeningType = ()=>{
  searchHealthScreeningType().then(function (response) {
    setData(response.data);
    setFilterData(response.data);
    setLoading(false);
  })
}

  const confirmModal = (val?: number) => {
    setHealthScreeningTypeId(val?val:0);
    setConfirmModal(true);
  }
  const closeModal = () => {
    setConfirmModal(false);
  }

  const closeDeleteModal = () => {
    setAlertModal(false);
    setLoading(true);
    fetchHealthScreeningType();
  }
  
  const DeleteHealthScreeningType = async (val: any) => {
    try{
      setConfirmModal(false);
      await deleteById(parseInt(val)).then(function (response) {
        setStatusResp(response.data);
        setAlertModal(true);
      });
    }catch(e){
      console.log(e);
    }
  }

  function FilterHealthScreeningType(event:any) {
    var newArray = dataList.filter(function (el) {
      return el.name.toUpperCase().includes(event.target.value.toUpperCase());
    });
    setFilterData(newArray);
    setSearch_text(event.target.value);
  }
  

      return (
         <>
         {isLoading && (<Loading/>)}
         {isAlertModal ? (<AlertModal message={statusResp.message.toString()} data={statusResp}  handleClose = {closeDeleteModal}/>):<></>}
         {isConfirmModal ? (<ConfirmModal message={Constants.MESSAGE_DELETE_REQUEST} data={countryId} handleConfirm={DeleteHealthScreeningType} handleClose = {closeModal}/>):<></>}
          <KTCard>
            <KTCardHead title='SEARCH HEALTH SCREENING TYPE'>
              {
              currentUser?.usertypeName === UserType.ADMINUSER.CODE && (
              <div className="card-toolbar my-0">
                <Link className='btn btn-sm btn-secondary' to='/ClinicalSettings/HealthScreeningType/Add'>
                  ADD HEALTH SCREENING TYPE
                </Link>
              </div>)
              }
            </KTCardHead>
            <KTCardBody>
                <form className='form w-100 my-5'>
                <div className="row">
                  <div className="d-md-flex my-2 flex-column flex-sm-row justify-content-sm-end">
                    <label className="form-label text-dark col-md-3 fs-5 d-none d-md-block text-end pt-2 pe-2"><span>Search</span></label>
                    <div className="col-sm-4 mw-250px">
                      <input placeholder="Search" type="text" autoComplete="off" maxLength={64} name="search_text" className="form-control form-control-sm" value={search_text} onChange={FilterHealthScreeningType}/>
                    </div>
                    </div>
                  </div>
                </form>
                <div className={Constants.DATATABLE_STYLE_REACT}>
                  <DataTable
                    columns={HealthScreeningTypeTableColumns(PageNo,confirmModal)}
                    data={filterDataList}
                    pagination
                    onChangePage={(page: number) => {setPageNo(page);}}
                    paginationComponent={Pagination} 
                    noDataComponent={<EmptyDatatable columns={HealthScreeningTypeTableColumns()}/>}                   
                  />
                </div>
            </KTCardBody>
          </KTCard>
          </>
      )
}
