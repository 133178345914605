import React, { useEffect, useState } from 'react';
import { SearchForm, UploadInternalRatings} from './_models';
import { searchUploadInternalRatings } from './_requests';
import { KTCard, KTCardBody,KTCardHead } from '../../../../_theme/helpers';

import { Constants, Pagination, Loading, UserType, EmptyDatatable, DateToString, StringToDate } from '../../../services';
import { Link } from 'react-router-dom';
import { useAuth } from '../../auth';
import DataTable from 'react-data-table-component';
import {UploadInternalRatingsTableColumns } from './_dataRowColumns';
import { FormikProvider, useFormik } from 'formik';
import { KTDatePickerBox, KTInputCheckBox } from '../../../services/components/InputTemplates';

export function UploadInternalRatingsList () {

  const {currentUser} = useAuth();
  const [isLoading, setLoading] = React.useState(true);
  const [dataList, setData] = React.useState(Array<UploadInternalRatings>);
  const [PageNo, setPageNo] = React.useState(1);
  const [searchForm] = useState(new SearchForm());

  useEffect(()=>{
    fetchUploadInternalRatings(searchForm);
  return ()=>{ 
  }
  // eslint-disable-next-line
 },[])

 const formik = useFormik({
  initialValues:searchForm,
  enableReinitialize:true,
  onSubmit: async (values, { setSubmitting}) => {
    setLoading(true)
    setSubmitting(true)
    fetchUploadInternalRatings(values);
  }, 
})



const fetchUploadInternalRatings = (searchForm:SearchForm)=>{
  searchUploadInternalRatings(searchForm).then(function (response) {
    setData(response.data);
    setLoading(false);
  })
}

  


  

      return (
         <>
         {isLoading && (<Loading/>)}
          <KTCard>
            <KTCardHead title='SEARCH UPLOAD INTERNAL RATINGS'>
              {
              currentUser?.usertypeName === UserType.ADMINUSER.CODE && (
              <div className="card-toolbar my-0">
                <Link className='btn btn-sm btn-secondary' to='/RatingSettings/UploadInternalRatings/Add'>
                  NEW UPLOAD
                </Link>
              </div>)
              }
            </KTCardHead>
            <KTCardBody>
                

                
            <FormikProvider value={formik}>
                <form className='form w-100 mb-4' onSubmit={formik.handleSubmit}>                
                <div className="row">
                      <div className="col-xl-1 col-md-1 col-sm-2 d-flex align-items-center">For period</div>
                      <div className="col-xl-2 col-md-2 col-sm-5 col-6 my-2">
                      <KTDatePickerBox placeholder='Enter From Date'  formikProps={formik.getFieldProps('formdate')} 
                                            handleOnChange ={ 
                                              (date : Date) => {  formik.setFieldValue('formdate',DateToString(date));formik.setFieldValue('todate','');
                                              }
                                            }
                                            />
                      </div>
                      <div className="col-xl-2 col-md-2 col-sm-5 col-6 my-2">
                      <KTDatePickerBox placeholder='Enter To Date' 
                                            formikProps={formik.getFieldProps('todate')}
                                            handleOnChange ={ 
                                              (date : Date) => {
                                                formik.setFieldValue('todate',DateToString(date));
                                              }
                                            }
                                            DateRange={{start: StringToDate(formik.values.formdate)}}
                                            />
                      </div>
                      <div className="col-xl-1 col-md-1 col-sm-2 d-flex align-items-center"> Upload Type</div>
                      <div className="col-xl-5 col-md-6 col-sm-9 my-2 d-flex">
                        <KTInputCheckBox formikProps={formik.getFieldProps('clinicinternalrating')}
                        label={'Clinic Internal Ratings'} 
                                handleOnChange ={ (val : boolean) => formik.setFieldValue('clinicinternalrating', val ? 1 : 0)}
                               />                               
                                            
                        <KTInputCheckBox formikProps={formik.getFieldProps('doctorinternalrating')} className='ms-3' 
                                  label={'Doctor Internal Ratings'} 
                                  handleOnChange ={ (val : boolean) => formik.setFieldValue('doctorinternalrating', val ? 1 : 0)}
                                />                               
                      </div>
                      <div className="col-xl-1 col-md-12 col-sm-1 my-2">
                        <button type="submit" id="search_submit" className="btn btn-sm btn-brand text-light float-end">
                          <span className="indicator-label">SEARCH</span>
                        </button>
                      </div>
                    </div>
                </form>
            </FormikProvider>
            <div className={Constants.DATATABLE_STYLE_REACT}>
                  <DataTable
                    columns={UploadInternalRatingsTableColumns(PageNo)}
                    data={dataList}
                    pagination
                    onChangePage={(page: number) => {setPageNo(page);}}
                    paginationComponent={Pagination} 
                    noDataComponent={<EmptyDatatable columns={UploadInternalRatingsTableColumns()}/>}                   
                  />
                </div>                
            </KTCardBody>
          </KTCard>
          </>
      )
}
