import { AppointmentSearchForm } from "../../modules/appointment/_models";
import { DoctorSearchForm } from "../../modules/doctor-module/doctor/_models";
import { MakerCheckerTaskSearchForm } from "../../modules/operations/maker-checker-task/_models";
import { AdminSearchForm } from "../../modules/users/admin/_models";
import { Constants } from "../../services";
import { DatatableInput } from "./DataTable";

export class KeyValueOption{
    constructor(key:number | string | boolean,value:string)
    {
        this.key = key
        this.value = value
    }
    key: number | string | boolean= 0;
    value: String = '';
}

export class SearchFormLite{
  value:string="";
}

export class SearchForm {
    dataTablesInput : DatatableInput;
    type : string = ""
    value : string = ""
    searchSubmitted: boolean = false
    constructor(dataTablesInput : DatatableInput, type:string, value:string)
    {
        this.dataTablesInput = dataTablesInput
        this.type = type
        this.value = value
    }
  }

  export class SearchFormDate{
    fromdate:string="";
    todate:string="";
  }

  export class CodeExist
  {
    isExists: boolean = false 
  }

export class LiveSearch {
    id:number=0;
    name:string="";
    code:string="";
    constructor(id:number,name:string,code:string)
    {
        this.id = id
        this.name = name
        this.code=code
        
    }
    
}

export class LocalData {
    ClinicSearch : SearchForm = new SearchForm(new DatatableInput(0,Constants.DATATABLE_PAGE_SIZE,0),"p_clinicName","");
    DoctorSearch : DoctorSearchForm = new DoctorSearchForm(new DatatableInput(0,Constants.DATATABLE_PAGE_SIZE,0),"p_DoctorName","")
    ClinicUserSearch : SearchForm = new SearchForm(new DatatableInput(1,Constants.DATATABLE_PAGE_SIZE,0),"","")
    AdminSearchForm : AdminSearchForm = new AdminSearchForm(new DatatableInput(1,Constants.DATATABLE_PAGE_SIZE,0))
    TaskSearchForm : MakerCheckerTaskSearchForm = new MakerCheckerTaskSearchForm(new DatatableInput(1,Constants.DATATABLE_PAGE_SIZE,0),"name","")
    AppointmentSearch : AppointmentSearch[] = [{type:'DEFAULT' ,AppointmentSearchForm :new AppointmentSearchForm(new DatatableInput(1,Constants.DATATABLE_PAGE_SIZE,0),"","")}]
    backURL : string = ''
}
export interface AppointmentSearch{
  type : string
  AppointmentSearchForm : AppointmentSearchForm
}

export function GetOptionName(optionList:any[]| undefined,id:number) {
    if(optionList && optionList?.length>0 && id!==0){
      return optionList.filter(el => el.id === id)[0].name;
    }
  }