
import { useEffect, useState } from 'react';
//Datatable Modules

import { KTCard, KTCardBody, KTCardHead, KTCardHeadToolBar } from '../../../../_theme/helpers';
import { AlertModal, ConfirmModal, Constants, EmptyDatatable, KTInputBox, Loading, Pagination, StorageHelper } from '../../../services';
import { ClinicUserListTableColumns } from './_dataRowColumns';
import DataTable from 'react-data-table-component';
import { ClinicUser } from './_models';
import { ResetPasswordToClinicUser, SearchClinicUsers, SendFirstTimeLoginDetailsToClinicUser } from './_requests';
import { KTSelectBox } from '../../../services/components/InputTemplates';
import { KeyValueOption, SearchForm } from '../../../models';
import { useFormik } from 'formik';
import { DatatableInput } from '../../../models/global/DataTable';
import { Status } from '../../../models/global/StatusResponce';
import { env } from '../../../App.env';
import { unLockApplicationUser } from '../../users/_requests';

export function ClinicUserSearch () {

  const [isLoading, setLoading] = useState(false);
  const [ClinicUserList, setItems] = useState<ClinicUser[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [searchForm, setSearchForm] = useState<SearchForm>(new SearchForm(new DatatableInput(0,Constants.DATATABLE_PAGE_SIZE,0),"",""));
  const [status, setStatus] = useState(new Status(0,''));
  const [isAlertModal, setAlertModal] = useState(false);  
  const [isConfirmModal, setConfirmModal] = useState(false);  
  const [action,SetAction] = useState({type : '',id : 0, username :''})

    useEffect(()=>{
      var oldSearch = StorageHelper.getClinicUsersSearchForm();
      setSearchForm(oldSearch);
      StorageHelper.setBackURL()
    return ()=>{ 
    }
    // eslint-disable-next-line
   },[])

   const fetchClinicUsers = (page: number, type:string, value:string) => {
    type = (type=="")?"name":type;
    setLoading(true);
    var sf = new SearchForm(new DatatableInput(1,Constants.DATATABLE_PAGE_SIZE,0),type,value)
    sf.dataTablesInput = new DatatableInput(page,  Constants.DATATABLE_PAGE_SIZE,  ((page-1) * Constants.DATATABLE_PAGE_SIZE));
    sf.searchSubmitted = true;
    setSearchForm(sf);
    SearchClinicUsers(sf).then(function (response) {
      setItems(response.data.data);
      setTotalRows(response.data.recordsTotal);
      setLoading(false);
    });
    StorageHelper.setClinicUsersSearchForm(sf);
  }

   const formik = useFormik({
    initialValues:searchForm,
    enableReinitialize:true,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setSubmitting(true)
      fetchClinicUsers(1,values.type,values.value);
    },
  })
  
  const searchTypeList = [
    {"code":"name","name":"Name"},
    {"code":"username","name":"User ID"},
    {"code":"nric","name":"NRIC"},
    {"code":"mobile","name":"Mobile"}
  ];

  const AlertConfirm = (_type:'EMAIL'|'RESET'|'UNLOCK',_id:number,_username:string) =>{
    SetAction({type:_type,id:_id,username:_username});
    if(_type === 'EMAIL')
      setStatus(new Status(0,'Are you sure you want to Send First Time Login Details ?'))
    if(_type === 'RESET')  
      setStatus(new Status(0,'Are you sure you want to Reset Password ?'))
    if(_type==='UNLOCK')
    {
      setStatus(new Status(0,'Are your sure you want Proceed to unlock '+_username+' ?'))
    }
    setConfirmModal(true);
  }
  const handleClose = () =>{
    setAlertModal(false);
    setConfirmModal(false);
    if(status.code === 1)
        formik.submitForm();
  }
  const handleConfirm = () =>{
    if(action.type === 'EMAIL')
    {
      setLoading(true);
      SendFirstTimeLoginDetailsToClinicUser({encdecid : ClinicUserList.find(x=>x.id=== action.id)?.encdecClinicuserId}).then(res => {
        setStatus(res.data);
        setAlertModal(true);
        setLoading(false);
      })
    }
    if(action.type === 'RESET')
    {
      setLoading(true);
      var p = {
        encdecid : ClinicUserList.find(x=>x.id=== action.id)?.encdecClinicuserId,
        //clientUrl : window.location.href,
        apiUrl : env.base_api_url
      };
      ResetPasswordToClinicUser(p).then(res => {
        setStatus(res.data);
        setAlertModal(true);
        setLoading(false)
      })
    }
    if(action.type === 'UNLOCK')
    {
      setLoading(true);
      unLockApplicationUser(ClinicUserList.find(x=>x.id=== action.id)?.encdecApplicationuserId).then(res => {
        setStatus(res.data);
        setAlertModal(true);
        setLoading(false)
      })
    }

  }

  return (
    <>
    {isLoading && (<Loading/>)}
    {isConfirmModal && <ConfirmModal message={status.message}  handleClose = {handleClose} handleConfirm={handleConfirm} />}
    {isAlertModal && <AlertModal message={status.message}  handleClose = {handleClose}/>}
    <KTCard>
      <KTCardHead title='CLINIC USER'>
            <KTCardHeadToolBar>
          </KTCardHeadToolBar>
      </KTCardHead>
      <KTCardBody className='py-4'>
        <div className="row">
              <form className='form w-100 my-5' onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-xl-2 col-md-3 col-sm-4 my-2">
                    <KTInputBox placeholder='Code'  formikProps={formik.getFieldProps('value')} maxLength={128} />
                  </div>
                  <div className="col-xl-2 col-md-3 col-sm-4 my-2">
                    <KTSelectBox defaultPlaceholder={false} formikProps={formik.getFieldProps('type')}  optionsList={searchTypeList.map((x) => {return new KeyValueOption(x.code,x.name)})}
                        onChangeHandler={(val) => {formik.setFieldValue('type',val);}
                        }
                        />
                  </div>
                  <div className="col-xl-2 col-md-3 col-sm-4 my-2">
                    <button type="submit" id="search_submit" className="btn btn-sm btn-brand text-light">
                      <span className="indicator-label">SEARCH</span>
                    </button>
                  </div>
                </div>
              </form>
            <div className={Constants.DATATABLE_STYLE_REACT}>
                  <DataTable
                    columns={ClinicUserListTableColumns(AlertConfirm)}
                    data={ClinicUserList}
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangePage={(page: number) => {setLoading(true); fetchClinicUsers(page,searchForm.type,searchForm.value);}}
                    pagination
                    paginationDefaultPage={searchForm.dataTablesInput.draw}
                    paginationComponent={Pagination}
                    noDataComponent={<EmptyDatatable columns={ClinicUserListTableColumns()} show={searchForm.searchSubmitted}/>}                   
                  /> 
            </div>
        </div>
      </KTCardBody>
    </KTCard>
    </>
  );
}


