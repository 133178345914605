import React from 'react'
import {Modal} from 'react-bootstrap'
import { Status, StatusResponse } from '../../models/global/StatusResponce'

type Props = {
  message?: string,
  data: StatusResponse,
  handleClose?: (param?: string) => void
  handleConfirm?: (param?: string) => any
}

const StatusResponseModal: React.FC<Props> = ({data,message,handleClose,handleConfirm}) => {

  const [show, setShow] = React.useState(true);

  const close = () => {
  setShow(false);
    if(handleClose)
    handleClose("");
  } 
  const confirm = () => {
    setShow(false);
      if(handleConfirm)
      handleConfirm();
    } 

  return (
    <Modal
      className='modal fade'
      id='kt_modal_alert'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='modal-md'
      aria-hidden='true'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'> 
          {data.code!==0 && <span className='indicator-label'>CONFIRMATION</span>}
          {data.code===0 && <span className='indicator-label'>ERROR</span>}
            </h5>
          <button
            className='btn btn-icon btn-sm btn-active-light-primary ms-2 btn-close' onClick={close}
            aria-label="Close"
          >
          </button>
        </div>
        <div className='modal-body fs-4'>
          
          {data?.statusList.map((status : Status, i) => (<>
              <span key={'key_'+i}>{status.message}</span><br/></>
          ))}
          {message !== undefined && message !== '' && <><span key={'00'}>{message}</span></>}
        </div>
        <div className='modal-footer py-4'>

          {handleConfirm !== undefined && (data.code>1)&& (
            <button type='button' className='btn btn-sm btn-brand text-light' onClick={confirm}>
              YES
            </button>
            )
          }
            <button type='button' className='btn btn-sm btn-secondary' onClick={close}>
              { handleConfirm !== undefined && (data.code>1) ? 'NO' : 'OK'}
            </button>

        </div>
      </div>
    </Modal>
  )
}

export {StatusResponseModal}
