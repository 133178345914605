import axios from 'axios'
import { env } from '../../../App.env';
import { CodeExist } from '../../../models';
import { Status, StatusResponse } from '../../../models/global/StatusResponce';
import { HealthScreeningDoctor, HealthScreeningFeeSchdule, HealthScreeningProfile } from '../../clinic-module/clinic/_models';
import { HealthScreeningFee } from './_models';


const API_URL = env.base_api_url
const API_CP = `${API_URL}/Api/HealthScreeningFee`

export function getHealthScreeningFees() {
  return axios.get<HealthScreeningFee[]>(`${API_URL}/Api/HealthScreeningFee/list`)
}

export function searchHealthScreeningFee() {
  return axios.get<HealthScreeningFee[]>(`${API_CP}/findAll`)
}

export function getById(id: String) {
  return axios.get<HealthScreeningFee>(`${API_CP}/findById/${id}`)
}

export function save(healthScreeningFee: HealthScreeningFee) {
  return axios.post<StatusResponse>(`${API_CP}/saveOrUpdate`,healthScreeningFee)
}

export function deleteById(id: number) {
  return axios.get<Status>(`${API_CP}/delete/${id}`)
}

export function IsCodeExist(name? : string,id : number = 0) {
  return axios.get<CodeExist>(`${API_CP}/fetchExistsHealthScreeningFee?code=${name}&id=${id}`)
}

export function searchHealthScreeningDoctor(clinicId : number ) {
  return axios.get<HealthScreeningDoctor[]>(`${API_URL}/Api/HealthScreeningDoctor/findAll/${clinicId}`)
}

export function findHSScheduleByClinicId(clinicId : number,benefitCode:string) {
  return axios.get<HealthScreeningFee[]>(`${API_URL}/Api/HealthScreeningFeeSchedule/findAllActiveByClinicId/${clinicId}/${benefitCode}`)
}

export function findOneActiveHsProfileByClinicId(clinicId : number,benefitCode:string) {
  return axios.get<HealthScreeningProfile>(`${API_URL}/Api/HealthSrceeningProfile/findOneActiveByClinicId/${clinicId}/${benefitCode}`)
}





