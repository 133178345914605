import { Link } from "react-router-dom";
import { ActionIcon, Constants } from "../../../services";
import { useAuth } from "../../auth";
import { InpatientAttendanceFeeForm } from "./_models";


export function InpatientAttendanceFeeTableColumns (pageNo:number = 0,confirm?:any) {
const {permissions} = useAuth();
return  (
    [
        {
            name: 'NO.',
            cell: (row:InpatientAttendanceFeeForm,index:any) => (1 + index + (pageNo - 1 ) * Constants.DATATABLE_PAGE_SIZE),
            width:'65px'
        },

        {
            name: 'SCHEDULE NAME',
            cell: (row:InpatientAttendanceFeeForm) => <>
            <div className='lh-md py-2 align-self-baseline'> 
                {row.inpatientattendancefeeForm.feeschedulename}
            </div>
            </>
           
        },

        {
            name: 'EFFECTIVE DATE',
            selector: (row:InpatientAttendanceFeeForm) => row.inpatientattendancefeeForm.fromdate,
        },

        {
            name: 'TERMINATION DATE',
            selector: (row:InpatientAttendanceFeeForm) => row.inpatientattendancefeeForm.todate,
        },

        {
            name: 'CREATED BY',
            cell: (row:InpatientAttendanceFeeForm) => <>
            <div className='lh-md py-2 align-self-baseline'> 
                {row.makercheckertasksForm.creator}
            </div>
            </>
           
        },

        {
            name: 'ASSIGNED TO',
            cell: (row:InpatientAttendanceFeeForm) => <>
            <div className='lh-md py-2 align-self-baseline'> 
                {row.makercheckertasksForm.assignTo}
            </div>
            </>
        },

        {
            name: 'APPROVED BY',
            cell: (row:InpatientAttendanceFeeForm) => <>
            <div className='lh-md py-2 align-self-baseline'> 
                {row.makercheckertasksForm.approvedBy}
            </div>
            </>
        },

        {
            name: 'STATUS',
            cell: (row:InpatientAttendanceFeeForm) => <>
            <div className='lh-md py-2 align-self-baseline'> 
                {row.makercheckertasksForm.status}
            </div>
            </>
        },
        
        
        {
            name: 'ACTIONS',
            maxWidth:'160px',
            cell: (row:InpatientAttendanceFeeForm) => <>

            { row.makercheckertasksForm.status === 'Submitted' && permissions?.canEditAttendanceFee!==null && 
            <>
                <Link to={'/ClinicalSettings/InpatientAttendanceFee/Edit/'+row.inpatientattendancefeeForm.id}>
                    <ActionIcon label='EDIT' />
                </Link>
            </>
            }
           
            { row.makercheckertasksForm.status !== 'Submitted' && 
            <>
                <Link to={'/ClinicalSettings/InpatientAttendanceFee/View/'+row.inpatientattendancefeeForm.id}>
                    <ActionIcon label='VIEW' />
                </Link>
            </>
            }

            { row.makercheckertasksForm.status !== 'Approved' && permissions?.canEditAttendanceFee!==null && 
            <>
               <span onClick={() => confirm(row.inpatientattendancefeeForm.id)}>
                  <ActionIcon label='DELETE'/>
              </span>
            </>
            }
                
              
              </>
        }
      ]
  )
}
