import axios from 'axios'
import { env } from '../../../App.env';
import { Status, StatusResponse } from '../../../models/global/StatusResponce';
import { clean } from '../../../services';
import { PublishRatings, PublishRatingsForm } from './_models';


const API_URL = env.base_api_url
const API_CP = `${API_URL}/Api/ClinicalSettings/PublishRatings`



export function searchPublishRatings() {
  return axios.get<PublishRatings[]>(`${API_CP}/getRatingNames`)
}

export function getById(id: String) {
  return axios.get<PublishRatings>(`${API_CP}/id/${id}`)
}

export function save(req: PublishRatings) {
  return axios.post<StatusResponse>(`${API_CP}/save`,req)
}

export function deleteById(id: number) {
  return axios.get<Status>(`${API_CP}/delete/${id}`)
}

export function getPublishRatingsById(id: String) {
  return axios.post<PublishRatingsForm[]>(`${API_CP}/getPublishRatingsByRatingsById/${id}`)
}

export function DeActivatePublishRatings(req: PublishRatingsForm) {
  return axios.post<Status>(`${API_CP}/deActivate`,clean(req))
}

export function ActivatePublishRatings(req: PublishRatingsForm) {
  return axios.post<Status>(`${API_CP}/save`,clean(req))
}
