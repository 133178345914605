import { useEffect, useState } from 'react';
import { KTAccordion, KTAccordionItem, KTCard, KTCardBody,KTCardFooter,KTCardHead, KTCardHeadToolBar } from '../../../../_theme/helpers';
import { useFormik } from 'formik';
import { decodeAes, FormActionButtons, KTInputLabel, Loading ,StorageHelper,UserType} from '../../../services';
import { useParams } from 'react-router-dom';
import { KTDatePickerBox, KTInputBox, KTInputRadioBox, KTSelectBox, KTTextAreaBox} from '../../../services/components/InputTemplates';
import { ClinicHeader } from '../clinic/_headerClinic';
import { KeyValueOption } from '../../../models';
import { ClinicUser, ClinicUserEscapeHtml, ClinicUserValidationSchema } from './_models';
import { getClinicUserByid } from './_requests';
import { GetClinicById } from '../clinic/_requests';
import { Clinic } from '../clinic/_models';
import { fetchProfileType } from '../../users/_requests';
import { ProfileMaster } from '../../users/_models';

export function ClinicUserView () {
  const [isLoading, setLoading] = useState(true);
  const [ClinicData, setClinic] = useState(new Clinic());
  
  const [ClinicUserData, setClinicUserData] = useState(new ClinicUser());
  const [ClinicUserProfiles, setClinicUserProfiles] = useState<Array<ProfileMaster>>([]);
  const { id} = useParams();
  
  useEffect(()=>{
    fetchFormData();
  return ()=>{ 
  }
  // eslint-disable-next-line
 },[])

  async function fetchFormData() {
    if(id!==undefined && id!==null){
      getClinicUserByid(parseInt(id)).then(response => {
        var ClinicUser = response.data.clinicuserForms;
        
        ClinicUser = ClinicUserEscapeHtml(ClinicUser,"decode");
        
        ClinicUser.nric = decodeAes(ClinicUser.nric); 
        setClinicUserData(ClinicUser);
        GetClinicById(ClinicUser.clinic).then(responseClinic => {
        setClinic(responseClinic.data.clinicForms);
          setLoading(false);
        });      
    });
    }
    fetchProfileType(UserType.CLINICUSER.CODE).then(response => {
      setClinicUserProfiles(response.data.profilemasterForms);
    });
  }

 const formik = useFormik({
    initialValues:ClinicUserData,
    validationSchema: ClinicUserValidationSchema,
    enableReinitialize:true,
    onSubmit: async () => {},
  })
  

      return (
         <>
         {isLoading && (<Loading/>)}
          <KTCard>
            <KTCardHead title='clinic User'>
                <KTCardHeadToolBar>
                </KTCardHeadToolBar>
            </KTCardHead>
            <KTCardBody>
            <ClinicHeader formView={true} name={ClinicData.name} code={ClinicData.hcinumber} uen={ClinicData.uen} type={ClinicData.clinictypeName} />
                <form className='form w-100 pt-6 pb-2' onSubmit={formik.handleSubmit} noValidate id='kt_clinic_user_add_edit_form' >
                <KTAccordion keys={['kt_1','kt_2']}>
                      <KTAccordionItem id={'kt_1'} title='Clinic User Details'>
                        <div className="row">
                          <div className='col-sm-6 d-md-flex my-2'>
                            <KTInputLabel title="NRIC" className='col-md-3' required={true} />
                              <div className="col-md-8">
                              <KTInputBox placeholder='NRIC' formikProps={formik.getFieldProps('nric')}
                                       readOnly={true}   
                                />
                              </div>
                          </div>
                          <div className='col-sm-6 d-md-flex my-2'>
                            <KTInputLabel title="Name" className='col-md-3' required={true} />
                              <div className="col-md-8">
                              <KTInputBox placeholder='Enter Name' formikProps={formik.getFieldProps('name')}   readOnly={true}   
                                />
                              </div>
                          </div>
                        </div>  
                        <div className="row">
                          <div className='col-sm-6 d-md-flex my-2'>
                            <KTInputLabel title="DOB" className='col-md-3'/>
                              <div className="col-md-8">
                              <KTDatePickerBox placeholder='Enter Date Of Birth'  formikProps={formik.getFieldProps('dob')}  readOnly={true} dateFormat={'dd/MM/yyyy'}
                                />
                              </div>
                          </div>
                          <div className='col-sm-6 d-md-flex my-2'>
                            <KTInputLabel title="DID" className='col-md-3' />
                              <div className="col-md-8">
                              <KTInputBox placeholder='Enter DID' formikProps={formik.getFieldProps('did')}   readOnly={true}
                                />
                              </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className='col-sm-6 d-md-flex my-2'>
                            <KTInputLabel title="Mobile" className='col-md-3' required={true} />
                              <div className="col-md-8">
                              <KTInputBox placeholder='Enter Mobile Number' formikProps={formik.getFieldProps('mobile')} readOnly={true}
                                />
                              </div>
                          </div>
                          <div className='col-sm-6 d-md-flex my-2'>
                            <KTInputLabel title="Email" className='col-md-3' required={true} />
                              <div className="col-md-8">
                              <KTInputBox placeholder='Enter Email Address' formikProps={formik.getFieldProps('email')}   readOnly={true}  
                                />
                              </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className='col-sm-6 d-md-flex my-2'>
                            <KTInputLabel title="Gender" className='col-md-3' />
                              <div className="col-md-8">
                              <KTInputRadioBox formikProps={formik.getFieldProps('gender')} 
                                  optionsList={[new KeyValueOption('Male','Male'), new KeyValueOption('Female','Female')]}  readOnly={true}
                                />
                              </div>
                          </div>
                          <div className='col-sm-6 d-md-flex my-2'>
                            <KTInputLabel title="Internal Remarks" className='col-md-3'/>
                              <div className="col-md-8">
                              <KTTextAreaBox formikProps={formik.getFieldProps('internalRemarks')} readOnly={true} />
                              </div>
                          </div>
                        </div>
                      </KTAccordionItem>
                      <KTAccordionItem id={'kt_2'} title='Clinic User Login Details'>
                      <div className="row">
                          <div className='col-sm-6 d-md-flex my-2'>
                            <KTInputLabel title="User Name" className='col-md-3' required={true} />
                              <div className="col-md-8">
                              <KTInputBox  formikProps={formik.getFieldProps('applicationuserForm.username')} readOnly={true}/>
                              </div>
                          </div>
                          <div className='col-sm-6 d-md-flex my-2'>
                            <KTInputLabel title="User Profile" className='col-md-3' required={true} />
                              <div className="col-md-8">
                              <KTSelectBox placeholder='-- Select --' formikProps={formik.getFieldProps('applicationuserForm.profilemaster')}  readOnly={true}
                              optionsList={ClinicUserProfiles.map((x) => {return new KeyValueOption(x.id,x.name);})}
                                />
                              </div>
                          </div>
                        </div>
                        <div className="row">
                            <div className='col-sm-6 d-md-flex my-2'>
                              <KTInputLabel required={true} title="Effective Date" className='col-md-3' />
                              <div className="col-md-8">
                                <KTDatePickerBox placeholder='Effective Date'  
                                formikProps={formik.getFieldProps('applicationuserForm.effectivedate')} readOnly={true}
                                  />
                              </div>
                            </div>
                            <div className='col-sm-6 d-md-flex my-2'>
                              <KTInputLabel title="Termination Date" className='col-md-3' />
                              <div className="col-md-8">
                                <KTDatePickerBox placeholder='Enter Termination Date' 
                                  formikProps={formik.getFieldProps('applicationuserForm.terminationdate')}   readOnly={true}
                                  />
                              </div>
                            </div>
                          </div>
                      </KTAccordionItem>                     
                    </KTAccordion>
                  <KTCardFooter className='d-flex justify-content-end mt-5'>
                      {/* begin::Action */}                           
                        <FormActionButtons isLoading={isLoading} id={id} backUrl={StorageHelper.getBackURL()} editUrl={'/Clinic/ClinicUser/Edit/'+ ClinicUserData.id+'/clinicId/'+ClinicUserData.clinic} />
                        {/* end::Action */}
                  </KTCardFooter>
                </form>
            </KTCardBody>
          </KTCard>
          </>
      )
}
