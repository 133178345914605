import React, {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_theme/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../modules/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_theme/assets/ts/_utils'
import { UserModule } from '../modules/users/UserModule'
import ClinicalSettingsModule from '../modules/clinical-settings/ClinicalSettingsModule'
import { ClinicUserSearchModule } from '../modules/clinic-module/clinic-user/ClinicUserModule'
import RatingSettingsModule from '../modules/rating-settings/RatingSettingsModule'

const PrivateRoutes = () => {
  const SettingsModule = lazy(() => import('../modules/settings/SettingsModule'))
  const ReportsPage = lazy(() => import('../modules/reports/ReportsPage'))
  const HospitalModule = lazy(() => import('../modules/hospital/HospitalModule'))
  const AppointmentModule = lazy(() => import('../modules/appointment/AppointmentModule'))
  const ClinicModule = lazy(() => import('../modules/clinic-module/ClinicModule'))
  const DoctorModule = lazy(() => import('../modules/doctor-module/DoctorModule'))
  const OperationModule = lazy(() => import('../modules/operations/OperationModule'))  
  const MemberSearchModule = lazy(() => import('../modules/member-search/MemberSearchModule'))
  const AdminUserModule = lazy(() => import('../modules/users/admin/AdminUserModule'))
  const ClinicProfileModule = lazy(() => import('../modules/clinic-profile/ClinicProfileModule'))
  const ClinicAssistantModule = lazy(() => import('../modules/clinic-assistant/ClinicAssistantModule'))
  const VoucherModule = lazy(() => import('../modules/voucher/VoucherModule'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/Dashboard' />} />
        {/* Pages */}
        <Route path='Dashboard' element={<DashboardWrapper />} />
        <Route path='User/*' element={<UserModule />} />
        {/* Lazy Modules */}
        <Route
        path='Clinic/*'
        element={
          <SuspensedView>
          <ClinicModule />
          </SuspensedView>
        }
        />
        <Route
        path='Doctor/*'
        element={
          <SuspensedView>
          <DoctorModule />
          </SuspensedView>
        }
        />
         <Route
        path='Hospital/*'
        element={
          <SuspensedView>
          <HospitalModule />
          </SuspensedView>
        }
      />         
      <Route
      path='CareTeam/*'
      element={
        <SuspensedView>
        <MemberSearchModule />
        </SuspensedView>
      }
    />
         <Route
        path='Appointments/*'
        element={
          <SuspensedView>
          <AppointmentModule />
          </SuspensedView>
        }
      />          <Route
      path='Operations/*'
      element={
        <SuspensedView>
        <OperationModule />
        </SuspensedView>
      }
    /> <Route
          path='AdminUser/*'
          element={
            <SuspensedView>
            <AdminUserModule />
            </SuspensedView>
          }
          />
      <Route
          path='ClinicUser/*'
          element={
            <SuspensedView>
            <ClinicUserSearchModule />
            </SuspensedView>
          }
          />    
        <Route
        path='Settings/*'
        element={
          <SuspensedView>
            <SettingsModule />
          </SuspensedView>
        }
      />

    <Route
        path='ClinicalSettings/*'
        element={
          <SuspensedView>
            <ClinicalSettingsModule />
          </SuspensedView>
        }
      />

     <Route
        path='RatingSettings/*'
        element={
          <SuspensedView>
            <RatingSettingsModule />
          </SuspensedView>
        }
      />

      <Route
        path='Voucher/*'
        element={
          <SuspensedView>
            <VoucherModule />
          </SuspensedView>
        }
      />

        <Route
        path='Reports/*'
        element={
          <SuspensedView>
            <ReportsPage />
          </SuspensedView>
        }
      />
      <Route
      path='ClinicProfile/*'
      element={
        <SuspensedView>
          <ClinicProfileModule />
        </SuspensedView>
      }
    />      <Route
    path='ClinicAssistant/*'
    element={
      <SuspensedView>
        <ClinicAssistantModule />
      </SuspensedView>
    }
  />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<{children?: React.ReactNode}> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
