export const Constants = {
    LOGIN_ACTIVE : 'Active Login',
    LOGIN_1ST_TIME : 'First Time Login',    
    LOGIN_PASS_EXPIRED : 'Password Validity Expired',    
    LOGIN_OTP_LENGTH : 6,
    SESSION_TIMEOUT_IN_MINS : 14,
    LOGOUT_SESSION_TIMEOUT : 'SESSION_TIMEOUT',
    LOGOUT_ACTIVE_SESSION : 'ACTIVE_SESSION',
    DATE_FORMAT : 'dd-MMM-yyyy',
    P_SPECIAL_CHAR  : '#.?!@$%^&*()-+',
    
    //Messages
    MESSAGE_DELETE_REQUEST :'Proceed to delete?',
    MESSAGE_CAHNGE_REQUEST :'Proceed to change ',
    MESSAGE_ERROR :'Something went wrong, please try again later.',
    MESSAGE_LOGOUT_SESSION_TIMEOUT : 'Your session has expired. Please log in again.',
    MESSAGE_LOGOUT_ACTIVE_SESSION : 'Your session has expired due to another active session.',
    MESSAGE_INVALID_NRIC : 'Invalid member, please ensure that you have entered the correct NRIC/FIN.',

    //Datatable configs
    DATATABLE_PAGE_SIZE : 10,
    DATATABLE_STYLE_REACT : 'table-responsive table-react table-striped table-hover gy-3 gx-3 my-2',
    DATATABLE_STYLE : 'table border gy-3 gx-3',
    AESKEY : "9eMzgSwmgtUc1JDMVLgG3yDY",
};

export const MakerChecker={
    TASK :{
        ClinicDoctorMap : 'Clinic Doctor Map',
        ClinicGroup : 'Clinic Group',
        DoctorClinicMap : 'Doctor Clinic Map',
        InpatientAttendanceFees: 'Inpatient Attendance Fees',
        InpatientSurgeonFee :'Inpatient Surgeon Fee',
        LOGAuthorizationAmount :'LOG Authorization Amount',
        LOGAuthorizationUser :'LOG Authorization User',
        SurgicalProcedure:'Surgical Procedure',
        UnbundlingAndBilateralPricing:'Unbundling and Bilateral Pricing'
    }
}