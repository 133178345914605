import clsx from 'clsx'
import React from 'react'
import {FC} from 'react'
import { Accordion, useAccordionButton } from 'react-bootstrap'


type Props = {
  className?: string
  keys?:string[]
  children?: React.ReactNode
}

const KTAccordion: FC<Props> = (props) => {
  const {className, children, keys} = props
  return (
    <Accordion alwaysOpen flush defaultActiveKey = {keys} className={clsx(
      className && className,
      {},
    )}> {children}</Accordion>
  )
}


type PropsItems = {
  className?: string
  children?: React.ReactNode
  title?:string
  id:string
  required?:boolean
}

const KTAccordionItem: FC<PropsItems> = (props) => {

  const [body, setBody] = React.useState('collapsed');
  const {className, children,title,id,required} = props
  var eventId = 'kt_acc_'+Math.floor(Math.random()*1000);
  const OnClickButton = useAccordionButton(id,() => {
    if(body === 'collapsed') setBody('');
    else setBody('collapsed');
  });

  return (
    <Accordion.Item eventKey={id ? id:eventId}  className={clsx(
      'border-0',
      className && className,
      {},
    )}>
        <h2 className='accordion-header border-bottom fs-6 fw-bolder py-6 d-flex justify-content-between'>
          <span className={'bg-white text-dark text-hover-primary ps-0 align-text-bottom ' + (body)}
            onClick={OnClickButton}>
            {required ? (<span className="required">{title}</span>) : <>{title}</>}
          </span>
          <span className={'accordion-button bg-white text-dark text-hover-primary w-15px p-0 shadow-none ' + (body)} style={{zoom : '1.1'}}
            onClick={OnClickButton}>
          </span>
        </h2>
        <Accordion.Body className='px-0 show'>
        {children}
        </Accordion.Body>
    </Accordion.Item>
  )
}

export {KTAccordion,KTAccordionItem}
