import React, { useEffect } from 'react';
import { PublishRatingsForm} from './_models';
import { getPublishRatingsById } from './_requests';
import { KTCard, KTCardBody,KTCardHead, KTCardHeadToolBar } from '../../../../_theme/helpers';

import { Constants, Pagination, Loading, EmptyDatatable } from '../../../services';
import DataTable from 'react-data-table-component';
import { Link, useParams } from 'react-router-dom';
import { PublishRatingsLogsTableColumns } from './_dataRowColumns';

export function PublishRatingsView () {

  const [isLoading, setLoading] = React.useState(true);
  const [dataList, setData] = React.useState(Array<PublishRatingsForm>());
  const [PageNo, setPageNo] = React.useState(1);
  const { id } = useParams();
  useEffect(()=>{
    fetchPublishRatings();
  return ()=>{ 
  }
  // eslint-disable-next-line
 },[])


const fetchPublishRatings = ()=>{
  getPublishRatingsById(String(id)).then(function (response) {
    setData(response.data);
    setLoading(false);
  })
}


      return (
         <>
         {isLoading && (<Loading/>)}
          <KTCard>
            <KTCardHead title='VIEW PUBLISH RATING'>

            <KTCardHeadToolBar>
              <Link className='btn btn-sm btn-secondary' to={'/RatingSettings/PublishRatings/Search'}>BACK</Link>
            </KTCardHeadToolBar>
                
             
            </KTCardHead>
            <KTCardBody>
                
                <div className={Constants.DATATABLE_STYLE_REACT}>
                  <DataTable
                    columns={PublishRatingsLogsTableColumns(PageNo)}
                    data={dataList}
                    pagination
                    onChangePage={(page: number) => {setPageNo(page);}}
                    paginationComponent={Pagination} 
                    noDataComponent={<EmptyDatatable columns={PublishRatingsLogsTableColumns()}/>}                   
                  />
                </div>
            </KTCardBody>
          </KTCard>
          </>
      )
}
