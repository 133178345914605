
export class PreAuthSearch {
  admissionDate: string = ''
  docName: string = ''
  insuredNRIC: string = ''
  mcr: string = ''
  paRefNo: string = ''
  }

export interface PreAuthDetails{
  paRefNo?:string
	insuredName?:string
	insuredNRIC?:string
	docName?:string
	mcr?:string
	admissionDate?:string
	decisionDate?:string
	paDecision?:string
	reqAmnt?:string
	approvedAmnt?:string
	policyNo?:string
}