import {useEffect, useRef, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import { Constants, KTInputError, Loading, SetPageMainTitle } from '../../../services'
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import { OTPForm, OTPVerify } from '../core/_models'
import { resendOtp, resendOtpForResetPassword, verifyOtp, verifyOtpForResetPassword } from '../core/_requests'
import { SystemPasswordPolicy } from '../../settings/system-policy/_models'
import { getSystemPasswordPolicy } from '../../settings/system-policy/_requests'
import { useAuth } from '../core/Auth'
import { Status } from '../../../models/global/StatusResponce'


export function OtpPage() {
  const [loading, setLoading] = useState(false)  
  const [resendingOtp, setReSendingOtp] = useState(false)    
  const [isForLogin, setForLogin] = useState(false)  
  const [isForResetPassword, setForResetPassword] = useState(false)   
  const [resStatus, setResStatus] = useState<Status>()   
  const [OTPVerifyRequest, setOTPVerifyRequest] = useState(new OTPVerify())  
  const [customError, setCustomError] = useState<string>()
  const [resendOtpFlag, SetResendOtpFlag] = useState(false)  
  const [PasswordPolicy,SetSystemPasswordPolicy] = useState(new SystemPasswordPolicy())
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const {login} = useAuth()
  
  useEffect(()=>{
    SetPageMainTitle('OTP');
    var _2u = sessionStorage.getItem(base64_encode('_2FATOP'));
    if(_2u !== null)
    {
      var _2uu = base64_decode(_2u);
      var _2uuO = JSON.parse(_2uu) as OTPVerify;
      sessionStorage.removeItem(base64_encode('_2FATOP'));
      setForLogin(true);
      setOTPVerifyRequest(_2uuO);
    }
    var _RP = sessionStorage.getItem(base64_encode('_RESETPASSWORD'));
    if(_RP !== null)
    {
      var _RPP = base64_decode(_RP);
      var _RPPO = JSON.parse(_RPP) as OTPVerify;
      sessionStorage.removeItem(base64_encode('_RESETPASSWORD'));
      setForResetPassword(true);
      setOTPVerifyRequest(_RPPO);
    }
    getSystemPasswordPolicy().then(function (response) {
      SetSystemPasswordPolicy(response.data);
  });
  return ()=>{ 
  }
 },[])


  const formik = useFormik({
    initialValues : new OTPForm(Constants.LOGIN_OTP_LENGTH),
    enableReinitialize:true,
    onSubmit: async (values,{setStatus,setSubmitting}) => {
      try{
        setStatus(undefined);
        setCustomError(undefined)
        var _otp = values.otp.join('');
        if(_otp.length !== Constants.LOGIN_OTP_LENGTH)
        {
          setSubmitting(false);
          setCustomError('OTP is Required.')
        }
        if(_otp.length === Constants.LOGIN_OTP_LENGTH){
          setLoading(true)
          OTPVerifyRequest.otp = _otp;
          if(isForLogin){
            verifyOtp(OTPVerifyRequest)
                .then( res => {
                  setLoading(false);
                  setStatus(res.data.message);                  
                  setResStatus(res.data);
                  setSubmitting(false);
                  if(res.data.code === 0)
                  {
                    login(res.data.message);
                    navigate('/Dashboard');
                  }
              })
          }
          if(isForResetPassword){
            verifyOtpForResetPassword(OTPVerifyRequest)
                .then( res => {
                  setLoading(false);
                  setSubmitting(false);
                  setResStatus(res.data);
                  setStatus(res.data.message);
              })
          }
        }
      }
      catch(error){
      console.error(error)
    }
    },
  })

  const ResendOtp = () => {
    formik.setStatus(undefined);
    setReSendingOtp(true)
    if(isForLogin)
      resendOtp({username : OTPVerifyRequest.username})
          .then( res => {
            setReSendingOtp(false);
            if(res.data.tokenUUID !== null || res.data.session !== null)
            { 
              formik.setStatus(res.data.message);
            }
            if(res.data.tokenUUID !== null && res.data.session !== null){
              OTPVerifyRequest.otpTokenUUID = res.data.tokenUUID;
              OTPVerifyRequest.sessionToken = res.data.session;
              setOTPVerifyRequest(OTPVerifyRequest);
            }
            SetResendOtpFlag(false);
            formik.resetForm({values:new OTPForm(Constants.LOGIN_OTP_LENGTH)})
        })
    if(isForResetPassword)
    resendOtpForResetPassword({resetPassToken : OTPVerifyRequest.resetPassToken})
          .then( res => {
            setReSendingOtp(false);
            if(res.data.tokenUUID !== null || res.data.session !== null)
            { 
              formik.setStatus(res.data.message);
            }
            if(res.data.tokenUUID !== null && res.data.session !== null){
              OTPVerifyRequest.otpTokenUUID = res.data.tokenUUID;
              OTPVerifyRequest.sessionToken = res.data.session;
              setOTPVerifyRequest(OTPVerifyRequest);
            }
            SetResendOtpFlag(false);
            formik.resetForm({values:new OTPForm(Constants.LOGIN_OTP_LENGTH)})
        })
  }

  const onKeyDown = (e:any,p:number) => {
    var ignoreKeys = ['Enter','Backspace','Shift','Tab','CapsLock','Control','Home','End','Delete','ArrowLeft','ArrowRight','ArrowUp','ArrowDown'];
    if(ignoreKeys.includes(e.key))
      return true;
    var RE_DIGIT_ONLY = /^\d+$/;
    var f = (RE_DIGIT_ONLY.test(e.key));
    if(!f)
      e.preventDefault();
    return f;
  } 
  const onKeyUp = (p:number) => {
    var v = formik.values.otp[p];
    if( v !== undefined && v.toString() !== '')
    {
      var _in = inputRef.current?.parentNode?.parentNode?.children[p+1]?.firstChild as HTMLImageElement;
      if(_in)
      _in.focus();
    }
  } 

  return (
    <>
    {resendingOtp && (<Loading/>)}
    { (resStatus === undefined || (resStatus.code === 1 && isForLogin) || (resStatus.code === 0 && isForResetPassword))&& 
      <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_login_otp_form'>
      <div className='mb-8'>
        <h2 className='text-light mb-3 fw-normal'>OTP</h2>
      </div>
      {formik.status ? (
        <div className='mb-lg-5 alert alert-danger'>
           <span className='btn btn-icon btn-active-light-primary btn-close fs-8 position-absolute top-0 end-0' 
            style={{height:'1.7rem',width:'1.7rem'}} aria-label="Close" onClick={()=>{ formik.setStatus(undefined)}} ></span>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <></>
      )}
      <div className='mb-5 fs-5'>
        <div className='text-light'>
        Please enter the OTP
        </div>
      </div>
      <div className='fv-row mb-5'>
        <div className="mb-1 d-flex justify-content-between">
          { formik.values.otp.map((row, index)=>(
              <div key={index} style={{width : 80/Constants.LOGIN_OTP_LENGTH +'%' }}>
                {
                  resendingOtp === false && 
                  <input className='form-control fs-3 fw-bolder text-center py-2' autoComplete='off'
                  onKeyDown={(evt) => {return onKeyDown(evt,index);}}
                  onKeyUp={(evt) => onKeyUp(index)}
                  ref={inputRef}
                  maxLength={1} {...formik.getFieldProps('otp['+index+']')}/>
                }
                {
                  resendingOtp && 
                  <input className='form-control fs-3 fw-bolder text-center py-2' readOnly={true}
                    maxLength={1}/>
                }
              </div>
              
          ))}
        </div>
        <KTInputError active={formik.touched.otp} error={customError} textClass='text-light'/>
      </div>
      <div className='d-flex justify-content-between mb-5'>
      {resendOtpFlag && 
        <span className='btn btn-sm border border-light text-light text-hover-secondary bg-hover-light mt-5 mb-2 hoverable' onClick={ResendOtp}>RESEND OTP</span>}
      {!resendOtpFlag &&   
        <span className='btn btn-sm border border-gray-400 text-gray-400 mt-5 mb-2'>RESEND OTP</span>}
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-sm btn-secondary mt-5 mb-2 border-secondary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>SUBMIT</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {
        resendOtpFlag === false && PasswordPolicy.otpResendTimeOut && 
        <OTPResendCounter seconds={PasswordPolicy.otpResendTimeOut} 
            handleOnChange = {(val :number) =>{if (val < 1) SetResendOtpFlag(true);} } />
      }
    </form> }
    {resStatus && resStatus.code === 1 && isForResetPassword &&  (
          <div className='fs-5'>
            <div className='text-light py-5'>
              {resStatus.message} <br/> 
              
              click
              <Link className='text-warning text-hover-primary m-1' to='/'><u>here</u></Link> 
              to Login.
            </div>
          </div>
      )}
    </>
  )
}


type PropsKTHTMLEditorBox = {
  seconds?: number
  handleOnChange?: (val : number) => void
}
const OTPResendCounter: React.FC<PropsKTHTMLEditorBox> = ({seconds=60,handleOnChange}) => {
  
  const [countDown, setCountDown ] =  useState(seconds);

  const onChange = (val : any) => {
    if(handleOnChange)
      handleOnChange(val)
  }

  
  useEffect(()=>{
    let alertInterval = setInterval(() => {
        setCountDown(countDown - 1);
        onChange(countDown - 1);
        }, 1000);
        return ()=> {
            clearInterval(alertInterval);
          };
    });
  
  return (
    <>
    <div className='text-light fs-6'>
        {countDown} second(s) to RESEND OTP.
    </div>
    </>
  )
}
