import axios from 'axios'
import { env } from '../../../App.env';
import { DatatableResponse } from '../../../models/global/DataTable';
import { Status, StatusResponse } from '../../../models/global/StatusResponce';
import { ClinicRatingWeightage } from './_models';


const API_URL = env.base_api_url
const API_CP = `${API_URL}/Api/ClinicRatingWeightageSettings`



export function searchClinicRatingWeightage(req:any) {
  return axios.post<DatatableResponse<ClinicRatingWeightage>>(`${API_CP}/search`,req)
}

export function getById(id: String) {
  return axios.get<ClinicRatingWeightage>(`${API_CP}/get/${id}`)
}

export function save(data: ClinicRatingWeightage) {
  return axios.post<StatusResponse>(`${API_CP}/saveOrUpdate`,data)
}

export function deleteById(id: number) {
  return axios.get<Status>(`${API_CP}/delete/${id}`)
}

