import React, { FC } from 'react';

import { PageLink, PageTitle } from '../../../../_theme/layout/core';
import { Navigate, Route, Routes } from 'react-router-dom';
import { SurveyQuestionsList } from './SurveyQuestionsList';
import { SurveyQuestionsAddEdit } from './SurveyQuestionsAddEdit';
import { SurveyQuestionPublish } from './SurveyQuestionPublish';
import { SurveyQuestionViewLog } from './SurveyQuestionViewLog';


type Props = {
  breadcrumbs: Array<PageLink>
}

const SurveyQuestionsModule: FC<Props> = ({breadcrumbs}) => {

  const usersBreadcrumbs = breadcrumbs.concat([
    {
      title: 'Survey Questions',
      path: '/RatingSettings/SurveyQuestions',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    }
  ])

  return (
    <>
    <Routes>
        <Route path='Search'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Search Survey Questions</PageTitle>
              <SurveyQuestionsList />
            </>
          }
        />
        <Route path='Add'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Add Survey Questions</PageTitle>
              <SurveyQuestionsAddEdit />
            </>
          }
        />
      <Route path='Edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Edit Survey Questions</PageTitle>
              <SurveyQuestionsAddEdit />
            </>
          }
        />

        <Route path='Publish/:id'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Edit Survey Questions</PageTitle>
              <SurveyQuestionPublish />
            </>
          }
        />


      <Route path='View/Log/:id'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Edit Survey Questions</PageTitle>
              <SurveyQuestionViewLog />
            </>
          }
        />

      <Route index element={<Navigate to='/RatingSettings/SurveyQuestions/Search' />} />
    </Routes>
    </>
  )
}

export {SurveyQuestionsModule}
