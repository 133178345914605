import {FC} from 'react'
import { SetPageMainTitle } from '../../../services';

const Error401: FC = () => {
  SetPageMainTitle('401');
  return (
    <>
      <h1 className='fw-bolder fs-4x text-gray-300 mb-10'>Unauthorized</h1>

      <div className='fw-bold fs-3 text-gray-300  mb-15'>
      You are not authorized to access this page! <br />
      </div>
    </>
  )
}

export {Error401}
