import axios from 'axios'
import { env } from '../../../App.env';
import { Status, StatusResponse } from '../../../models/global/StatusResponce';
import { InpatientAttendanceFeeForm, InpatientAttendanceFeeResponse, InpatientAttendanceFeeResponseO, SearchFormAttendancefee } from './_models';


const API_URL = env.base_api_url
const API_CP = `${API_URL}/Api/ClinicalSettings/Inpatientattendancefee`

export function getInpatientAttendanceFees() {
  return axios.get<InpatientAttendanceFeeResponse>(`${API_URL}/Api/Settings/InpatientAttendanceFee/list`)
}

export function searchInpatientAttendanceFee(req:SearchFormAttendancefee,isSearch:boolean) {
  if(isSearch){
    return axios.get<InpatientAttendanceFeeResponse>(`${API_CP}/searchInpatientattendancefeeDetails?scheduleId=${req.scheduleId}&makercheckerstatus=${req.makercheckerstatus}&fromDate=${req.fromDate}&ToDate=${req.ToDate}`)
  }else{
    return axios.get<InpatientAttendanceFeeResponse>(`${API_CP}/getApprovedInpatientattendancefeeList`)
  }
  
}

export function getById(id: String) {
  return axios.get<InpatientAttendanceFeeResponseO>(`${API_CP}/getInpatientattendancefeeBYId/${id}`)
}

export function save(req: InpatientAttendanceFeeForm) {
  return axios.post<StatusResponse>(`${API_CP}/saveUpdateInpatientattendancefee`,req)
}

export function deleteById(id: number) {
  return axios.get<Status>(`${API_CP}/deleteInpatientattendancefee?id=${id}`)
}

