import * as Yup from 'yup';
import { string } from 'yup';

export interface SystemPolicyResponse {
  systempasswordpolicyForm: SystemPasswordPolicy[];
}

export interface SystemPolicyResponseO {
  systempasswordpolicyForm: SystemPasswordPolicy;
}


export class SystemPasswordPolicy{
  dateRangeforMCReports?: number
  disableFromDate: any
  disableToDate: any
  forgotpwdlinkexpiryperiodmin?: number
  fromDateFlag?: boolean
  fromdate: string=''
  id?: number
  isdelete: any
  minimumpasswordnumericchars?: number
  minpasswordcharlength?: number
  minpasswordlowercasechars?: number
  minpassworduppercasechars?: number
  minpwdnonalphanumericchars?: number
  minusernamecharlength?: number
  otpResendTimeOut?: number
  passwordvalidityperiodindays?: number
  reqProviderUserOTP?: number
  reusepreviouspassword?: number
  toDateFlag?: boolean
  todate?: string
}

export const SystemPasswordPolicySchema = Yup.object({
  reusepreviouspassword: string().max(256, 'Maximum 256').required('Please enter SystemPolicy Name')
})

