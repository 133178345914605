import * as Yup from 'yup';


export class ChangePasswordForm {
  oldPassword:string = ''
  newPassword:string = ''
  retypeNewPassword: string =''
}



export class ChangePasswordRequest {
  encryptedhashedpassword?: string
  hashedConfirmPassword?: string
  hashedpassword?: string
}

export const ChangePasswordValidationSchema = Yup.object({
  oldPassword: Yup.string().required('Please Enter Current Password'),
  newPassword: Yup.string().required('Please Enter New Password'),
  retypeNewPassword: Yup.string().required('Please Retype New Password').when("newPassword", (newPassword: string, schema: any) => {
    return schema.test({
      test: (retypeNewPassword: string) => {
        if(newPassword === '' || retypeNewPassword === '') return true;
        return newPassword === retypeNewPassword
      },
      message: "Password does not match with New Password."})
    })
})