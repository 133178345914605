import { Link } from "react-router-dom";
import { ActionIcon, Constants } from "../../../services";
import { useAuth } from "../../auth";
import { InpatientSurgeonFee } from "./_models";



export function InpatientSurgeonFeeTableColumns (pageNo:number = 0,confirm?:any) {

const {permissions} = useAuth();

return  (
    [
        {
            name: 'NO.',
            cell: (row:InpatientSurgeonFee,index:any) => (1 + index + (pageNo - 1 ) * Constants.DATATABLE_PAGE_SIZE),
            width:'65px'
        },

        {
            name: 'SCHEDULE NAME',
            cell: (row:InpatientSurgeonFee) => <>
            <div className='lh-md py-2 align-self-baseline'> 
                {row.feeTableName}
            </div>
            </>
           
        },

        {
            name: 'EFFECTIVE DATE',
            selector: (row:InpatientSurgeonFee) => row.fromDate,
        },

        {
            name: 'TERMINATION DATE',
            selector: (row:InpatientSurgeonFee) => row.toDate,
        },

        {
            name: 'CREATED BY',
            cell: (row:InpatientSurgeonFee) => <>
            <div className='lh-md py-2 align-self-baseline'> 
                {row.makercheckertasksForm.creator}
            </div>
            </>
           
        },

        {
            name: 'ASSIGNED TO',
            cell: (row:InpatientSurgeonFee) => <>
            <div className='lh-md py-2 align-self-baseline'> 
                {row.makercheckertasksForm.assignTo}
            </div>
            </>
        },

        {
            name: 'APPROVED BY',
            cell: (row:InpatientSurgeonFee) => <>
            <div className='lh-md py-2 align-self-baseline'> 
                {row.makercheckertasksForm.approvedBy}
            </div>
            </>
        },

        {
            name: 'STATUS',
            cell: (row:InpatientSurgeonFee) => <>
            <div className='lh-md py-2 align-self-baseline'> 
                {row.makercheckertasksForm.status}
            </div>
            </>
        },
        
        
        {
            name: 'ACTIONS',
            maxWidth:'160px',
            cell: (row:InpatientSurgeonFee) => <>

            { row.status === 'Submitted' && permissions?.canEditSugeonFee!==null && 
                <>
                <Link to={'/ClinicalSettings/InpatientSurgeonFee/Edit/'+row.id}>
                    <ActionIcon label='EDIT' />
                </Link>
                </>
            }

            { row.status !== 'Submitted' && 
                <>
                <Link to={'/ClinicalSettings/InpatientSurgeonFee/View/'+row.id}>
                    <ActionIcon label='VIEW' />
                </Link>
                </>
            }
                
              { row.status !== 'Approved' && permissions?.canEditSugeonFee!==null && 
                <>
                <span onClick={() => confirm(row.id)}>
                  <ActionIcon label='DELETE'/>
              </span>
              </>
              }
              
              </>
        }
      ]
  )
}
