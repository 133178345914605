import axios from 'axios'
import { env } from '../../../App.env';
import { CodeExist } from '../../../models';
import { Status, StatusResponse } from '../../../models/global/StatusResponce';
import { HealthScreeningDoctor } from '../../clinic-module/clinic/_models';
import { HealthScreeningType } from './_models';


const API_URL = env.base_api_url
const API_CP = `${API_URL}/Api/HealthScreeningType`

export function getHealthScreeningTypes() {
  return axios.get<HealthScreeningType[]>(`${API_URL}/Api/HealthScreeningType/findAll`)
}

export function searchHealthScreeningType() {
  return axios.get<HealthScreeningType[]>(`${API_CP}/findAll`)
}

export function getById(id: String) {
  return axios.get<HealthScreeningType>(`${API_CP}/findById/${id}`)
}

export function save(healthScreeningType: HealthScreeningType) {
  return axios.post<StatusResponse>(`${API_CP}/saveOrUpdate`,healthScreeningType)
}

export function deleteById(id: number) {
  return axios.get<Status>(`${API_CP}/delete/${id}`)
}

export function IsCodeExist(name? : string,id : number = 0) {
  return axios.get<CodeExist>(`${API_CP}/fetchExistsHealthScreeningType?code=${name}&id=${id}`)
}

export function searchHealthScreeningDoctor(clinicId : number ) {
  return axios.get<HealthScreeningDoctor[]>(`${API_URL}/Api/HealthScreeningDoctor/findAll/${clinicId}`)
}

