import React from 'react'
import {Modal} from 'react-bootstrap'

type Props = {
  data:string
  handleConfirm?: (param: string) => void
}

const ActiveLoginModal: React.FC<Props> = ({data,handleConfirm}) => {

  const [show, setShow] = React.useState(true);
  const confirm = () => {
    if(handleConfirm)
    handleConfirm(data);
  } 
  const close = () => {
  setShow(false);
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal_isUserActive'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='modal-lg'
      aria-hidden='true'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>NOTIFICATION</h5>
          <button
            className='btn btn-icon btn-sm btn-active-light-primary ms-2 btn-close' onClick={close}
            aria-label="Close"
          >
          </button>
        </div>
        <div className='modal-body fs-4'>
          Our system has detected an active session on another window or you have closed your browser without logging out. For security reasons, we will terminate the previous session. Click "Proceed" to continue.
        </div>
        <div className='modal-footer py-4'>
          <button type='button' className='btn btn-secondary' onClick={confirm}>
          PROCEED
          </button>
        </div>
      </div>
    </Modal>
  )
}

export {ActiveLoginModal}
