import { Auditable } from "../../../models/global/Auditable"
import * as Yup from 'yup';
import { DatatableInput } from "../../../models/global/DataTable";
import { Speciality, SubSpeciality } from "../../settings/speciality/_models";
import { Gender } from "../../settings/gender/_models";
import { InpatientAttendanceTable } from "../../settings/inpatient-attendance-table/_models";
import { InpatientSurgeonFeeTable } from "../../settings/inpatient-surgeon-fee-table/_models";
import { decode, encode } from "html-entities";
import { clone } from "../../../services";


export class DoctorSearchForm {
  dataTablesInput : DatatableInput;
  type : string = ""
  value : string = ""
  searchSubmitted: boolean = false
  doctorType?: string
  constructor(dataTablesInput : DatatableInput, type:string, value:string)
  {
      this.dataTablesInput = dataTablesInput
      this.type = type
      this.value = value
  }
}

export function DoctorEscapeHtml(_data:Doctor,type:string){

  let data = clone (_data);
  data.name = (type=="decode") ?decode(data.name):encode(data.name);
  data.mcr = (type=="decode") ?decode(data.mcr):encode(data.mcr);
  data.events = (type=="decode") ?decode(data.events):encode(data.events);
  data.internalRemarks = (type=="decode") ?decode(data.internalRemarks):encode(data.internalRemarks);
  data.displayname = (type=="decode") ?decode(data.displayname):encode(data.displayname);

  data.languagespoken = (type=="decode") ?decode(data.languagespoken):encode(data.languagespoken); 
  data.qualifications = (type=="decode") ?decode(data.qualifications):encode(data.qualifications); 
  data.clinicalinterests = (type=="decode") ?decode(data.clinicalinterests):encode(data.clinicalinterests); 
  data.biography = (type=="decode") ?decode(decode(data.biography)):encode(data.biography); 
  return data;
 
}



export class DoctorSearchResult{
  clinics: string = ""
  displayName: string = ""
  effectiveDate: string = ""
  id: number = 0;
  internalRatingExist: string = ""
  isPublished: string = ""
  mcr: string = ""
  name: string = ""
  overallrating: string = ""
  publishRatingExist: string = ""
  recordsFiltered: number = 0;
  recordsTotal: number = 0;
  reviews: string = ""
  rowId: number = 0;
  specialities: string = ""
  subspecialities?: string
  suspended: number = 0;
  termination: number = 0;
  tosp: string = ""
  watchlist: number = 0;
}

export class DoctorFormHelper {
  inpatientAttendancefeetableForm? : InpatientAttendanceTable[]
  inpatientSurgeonFeeTableForm? : InpatientSurgeonFeeTable[]
  specialityBean? : Speciality[]
}

export class Doctor extends Auditable  {
    hcinumber(hcinumber: any) {
      throw new Error('Method not implemented.');
    }
    articlelink:  any[] = []
    articletitle:  any[] = []
    attendanceeffecteddate:  string[] = []
    attendanceid:  string[] = []
    attendancemainid:  (string|null)[] = []
    attendanceterminateddate:  string[] = []
    biography?:  string
    clinicalinterests?:  string
    did?:  string
    displayname?:  string
    doctorSpecialityMapBean?: DoctorSpecialtyMap[]
    doctorTOSP?:  string 
    doctorTospBean: DoctorTospBean[] =[]
    doctorclinicmaps?:  string
    doctorclinicmapsdetails?: any[]
    doctordocumentsBean?: any[]
    doctorhealtharticles?: DoctorHealthArticle[]
    doctorinpatientattendancefeeBean: doctorinpatientattendancefeeBean[] =[]
    documentDesc?:  string
    effectivedate?:  string
    email?: string
    events?:  string
    fromDateFlag:  boolean = true
    gender?:  string
    genderBean?: Gender[]
    id: number = 0
    inpatientAttendenceFee?: string
    internalRemarks?:  string
    isprofileimageupdated?:  number
    languagespoken:  string = ""
    largeprofileimage:  string = ""
    mcr: string = ""
    mobile?:  string
    name:  string = ""
    practisingDate?:  string 
    qualifications:  string = ""
    rankingScore: number = 50.0
    smallprofleimage?:  string
    specialityDeleteList?:  string[]
    specialityList:  string[] = []
    specialty:  string = ""
    specialtyListForMap?: any[]
    specialtyName?:  string
    subSpecialityList:  string[] = []
    subspecialty:  string =""
    subspecialtyName?:  string
    suspendedDoctor?:  string
    suspendedDoctorBean?: any[]
    suspendedFromDate?:  string
    suspendedToDate?:  string
    terminationdate?:  string
    toDateFlag?:  boolean
    tospBean?:  string
    tospid:  string[] = []
    tospeffecteddate:  string[] = []
    tospidList:  string[]=[]
    tospmainid?:  (string|null)[]
    tospterminateddate:  string[]=[]
    watchFromDate?:  string
    watchToDate?:  string
    watchlistDoctor?:  string
    watchlistDoctorBean?: any[]
}


export const DoctorValidationSchema = Yup.object({

  mcr: Yup.string()
    .min(6,'MCR Must be 6 characters or more')
    .max(8, 'MCR Must be 8 characters or less')
    .required('Please enter MCR Number'),

  name: Yup.string()
    .max(128, 'Name Must be 128 characters or less') 
    .required('Please enter Doctor Name'),
    displayname:Yup.string()
    .max(128, 'Name Must be 128 characters or less') 
    .required('Please enter Display Name'),

  rankingScore: Yup.number().max(100,'Invalid Ranking Score, Max value : 100'),

    gender:Yup.string().nullable()
    .required('Please select Gender'),

    email: Yup.string()
    .nullable()
    .email('Email is invalid'),
    mobile: Yup.string()
    .nullable().max(12),

    qualifications : Yup.string()
    .required('Please enter Qualification'),

    languagespoken : Yup.string()
    .required('Please enter Languages Spoken'),

})


export class AttendanceFee {
   attendacneFeeID?:string = '0'
   effectedDate ?: string = ''
   terminationDate ?: string = ''
   newRecord:boolean=false;
   id?: number

   static ConvertFromModel(r : doctorinpatientattendancefeeBean) : AttendanceFee {
    var k = new AttendanceFee();
    k.attendacneFeeID = r.parentinpatientattendancefeeid.toString();
    k.effectedDate = r.fromdate;
    k.terminationDate = r.todate;
    k.id = r.id;
    return k;
   }

}

export const AttendanceFeeValidationSchema = Yup.object({
  attendacneFeeID: Yup.number()
    .required('Please select Attendance Fee').min(1,'Please select Attendance Fee'),
  effectedDate: Yup.string()
    .required('Please select Effective Date'),
})

export class doctorinpatientattendancefeeBean extends Auditable  {
  fromdate: string = ''
  id: number = 0
  parentinpatientattendancefeeid:number = 0
  todate: string = ''
}

export class FeeSchedule {
  tospID?:string = '0'
  effectedDate ?: string =''
  terminationDate ?: string =''
  id?: number
  newRecord:boolean=false;
  static ConvertFromModel(r : DoctorTospBean) : FeeSchedule {
    var k = new FeeSchedule();
    k.tospID = r.parentTOSPID.toString();
    k.effectedDate = r.fromDate;
    k.terminationDate = r.toDate;
    k.id = r.id;
    return k;
   }
}

export const FeeScheduleValidationSchema = Yup.object({
  tospID: Yup.number()
    .required('Please select Schedule Fee').min(1,'Please select Schedule Fee'),
  effectedDate: Yup.string()
    .required('Please enter Effective Date'),
})

export class DoctorTospBean extends Auditable  {
  fromDate: string = ''
  id: number = 0
  parentTOSPID:number = 0
  toDate: string = ''
}

export class DoctorSpecialtyMap  extends Auditable {

  dependencyOnRecord: boolean = false
  dependencyOnRecordID: number = 0
  doctorSpecialitySubSpecialityMapId: number = 0
  id: number = 0
  specialtybean : Speciality = new Speciality()
  subspecialtybean?:SubSpeciality
}

export class DoctorSpecialty{
  specialty?:string = '0'
  subspecialtyname?:string = ''
  subspecialty?:string = '0'
  id?: number
  dependencyOnRecord?: boolean=false;

  static ConvertFromModel(r : DoctorSpecialtyMap) : DoctorSpecialty {
   var k = new DoctorSpecialty();
   k.id = r.id;
   k.specialty = r.specialtybean.id.toString();
   if(r.subspecialtybean !== null)
   {
    k.subspecialty = r.subspecialtybean?.id.toString();
    k.subspecialtyname = r.subspecialtybean?.name;
   }
    k.dependencyOnRecord = r.dependencyOnRecord;
   return k;
  }
}

export const DoctorSpecialtyValidationSchema = Yup.object({
  specialty: Yup.number()
   .required('Please select Specialty').min(1,'Please select Specialty')
})


export class HealthArticle{
  articleTitle:string = ''
  articleLink:string = ''
  id?: number
  static ConvertFromModel(r : DoctorHealthArticle) : HealthArticle {
    var k = new HealthArticle();
    k.id = r.id;
    k.articleTitle = r.title;
    k.articleLink = r.reference;
    return k;
   }
}

export class DoctorHealthArticle extends Auditable 
{
  id: number = 0
  reference:string = ''
  title:string = ''
  
}


export const HealthArticleValidationSchema = Yup.object({
  articleTitle: Yup.string()
   .required('Please enter Article Title'),
   articleLink: Yup.string().url('Please enter valid Link')
   .required('Please enter Article Link')
})