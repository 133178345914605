import React, { useEffect } from 'react'
import {Modal} from 'react-bootstrap'

type Props = {
  message?: string,
  children?: React.ReactNode
  data?: any,
  showModal?:boolean,
  width?:String,
  handleClose?: (param?: string) => void,
  title?: 'CONFIRMATION'|'INFO'|'ERROR' | string
}

const AlertModal: React.FC<Props> = ({data,message,children,showModal=true,width='md',handleClose,title='INFO',}) => {

  const [show, setShow] = React.useState(showModal);

  useEffect(() => {
    //console.log("from modal", showModal);
    showModal && setShow(true);
  }, [showModal]);

  const close = () => {
  setShow(false);
    if(handleClose)
    handleClose(data);
  } 

  return (
    <Modal
      className='modal fade'
      id='kt_modal_alert'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName={'modal-'+width}
      aria-hidden='true'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>{title}</h5>
          <button
            className='btn btn-icon btn-sm btn-active-light-primary ms-2 btn-close' onClick={close}
            aria-label="Close"
          >
          </button>
        </div>
        <div className='modal-body fs-4'>
          {message}
          {children}
        </div>
        <div className='modal-footer py-4'>
          <button type='button' className='btn btn-sm btn-secondary' onClick={close}>
          OK
          </button>
        </div>
      </div>
    </Modal>
  )
}

export {AlertModal}
