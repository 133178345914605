import axios from 'axios'
import { env } from '../../../App.env';
import { SearchFormDate } from '../../../models';
import { Status, StatusResponse } from '../../../models/global/StatusResponce';
import { SurveyConfig } from './_models';


const API_URL = env.base_api_url
const API_CP = `${API_URL}/Api/SurveyConfig`



export function searchSurveyConfig(searchForm:SearchFormDate,isSearch:boolean) {
  if(isSearch){
    return axios.post<SurveyConfig[]>(`${API_CP}/searchConfig`,searchForm)
  }else{
    return axios.get<SurveyConfig[]>(`${API_CP}/fetchAllConfigs`)
  }
  
}

export function getById(id: String) {
  return axios.get<SurveyConfig>(`${API_CP}/id/${id}`)
}

export function save(req: SurveyConfig) {
  return axios.post<StatusResponse>(`${API_CP}/saveConfig`,req)
}

export function deleteById(id: number) {
  return axios.get<Status>(`${API_CP}/delete/${id}`)
}

