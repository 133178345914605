import axios from 'axios'
import { env } from '../../../App.env';
import { DatatableResponse } from '../../../models/global/DataTable';
import { Status, StatusResponse } from '../../../models/global/StatusResponce';
import { DoctorRatingWeightage } from './_models';


const API_URL = env.base_api_url
const API_CP = `${API_URL}/Api/DoctorRatingWeightageSettings`



export function searchDoctorRatingWeightage(req:any) {
    return axios.post<DatatableResponse<DoctorRatingWeightage>>(`${API_CP}/search`,req)
  }

export function getById(id: String) {
  return axios.get<DoctorRatingWeightage>(`${API_CP}/get/${id}`)
}

export function save(country: DoctorRatingWeightage) {
  return axios.post<StatusResponse>(`${API_CP}/saveOrUpdate`,country)
}

export function deleteById(id: number) {
  return axios.get<Status>(`${API_CP}/delete/${id}`)
}

