import { Auditable } from "../../../models/global/Auditable"
import { DatatableInput, DatatableResponse } from "../../../models/global/DataTable";
import { DoctorClinicMapForm } from "../../doctor-clinic-map/_models";
import { InpatientAttendanceFee  } from "../../clinical-settings/inpatient-attendance-fee/_models";
import { InpatientSurgeonFee } from "../../clinical-settings/inpatient-surgeon-fee/_models";


export class MakerCheckerStatus
{
  id: number = 0
  status: string =''
}
export class MakerCheckerTaskType
{
  id: number = 0
  taskname: string =''
}
export class MakerCheckerTask
{
  approvedBy: string =''
  assignedTo: string =''
  createdBy: string =''
  createdTime: string =''
  creatorRemarks?: string
  id: number = 0
  lastUpdatedBy: string =''
  lastUpdatedTime: string =''
  modifiedTime: string =''
  recordsFiltered: number = 0
  recordsTotal: number = 0
  rowId: number = 0
  status: string =''
  taskName: string =''
}

export class MakerCheckerTaskSearchForm {

  dataTablesInput : DatatableInput;
  createdBy:string = "0"
  status: string = "3"
  task: string ="0"
  createdFromDate?: string
  createdToDate?: string

  constructor(dataTablesInput : DatatableInput,type:string,value:string)
  {
      this.dataTablesInput = dataTablesInput
  }
}

export class MakerCheckerTaskResult {
  adminUserData : { user : string , userId : number }[] = []
  makercheckerstatusList : MakerCheckerStatus[] =[]
  makercheckertaskList : MakerCheckerTaskType[] = []
  dataTablesOutput? : DatatableResponse<MakerCheckerTask>
}

export class MakerCheckerTasksForm extends Auditable{
  id?: Number
  makercheckerstatus?: number
  status?: String
  makercheckertask?: number
  taskname?: string
  parentapproverid: string = '0'
  approverName?: string
  parentcreatorid?: number
  parenttaskreferenceid?: string
  creatorremarks?: string
  maker?: string
  effectiveDate?: string
  terminationDate?: string
  newMakerCheckerFlag?: boolean
  assignTo?: string
  creator?: string
  approvedBy?: string
  actualStatus?: string
  latestStatus?: string
  todateStatus?: string
}

export class MakerCheckerTasksResponse
{
    authorizationCareForm: any
    authorizationamountform: any
    clinicgroupregistrationForm: any
    doctorclinicmapForm?: DoctorClinicMapForm
    inpatientSurgeonFeeBean?: InpatientSurgeonFee
    inpatientattendancefeeForm?: InpatientAttendanceFee 
    logauthorizationCareForm: any
    makercheckertasksForm: MakerCheckerTasksForm = new MakerCheckerTasksForm()
    surgicalProcedureForm: any
    surgicalproceduregroupForm: any
}
