import React from 'react'
import { Constants } from '../lib/Constants';
type Props = {
  columns?: Array<any>
  show?:boolean
}

const EmptyDatatable: React.FC<Props> = ({columns,show = true}) => {

  if(!show) return (<></>)
  return (
    <>
      <table className={Constants.DATATABLE_STYLE}>
        <thead>
            <tr>
            {
                columns?.map((col : any,index) => (
                   <th key={'th_empty_'+index} dangerouslySetInnerHTML={{__html : col.name}}></th>
                ))
            }
            </tr>
        </thead>
        <tbody>
            <tr>
                <td colSpan={columns?.length} style={{textAlign:'center'}}>
                    No Records Found.
                </td>
            </tr>
        </tbody>
      </table>
    </>
  )
}

export {EmptyDatatable}
