import React, { FC } from 'react';

import { PageLink, PageTitle } from '../../../../_theme/layout/core';
import { Navigate, Route, Routes } from 'react-router-dom';
import { InpatientAttendanceFeeList } from './InpatientAttendanceFeeList';
import { InpatientAttendanceFeeAddEdit } from './InpatientAttendanceFeeAddEdit';
import { InpatientAttendanceFeeView } from './InpatientAttendanceFeeView';

type Props = {
  breadcrumbs: Array<PageLink>
}

const InpatientAttendanceFeeModule: FC<Props> = ({breadcrumbs}) => {

  const usersBreadcrumbs = breadcrumbs.concat([
    {
      title: 'Inpatient Attendance Fee',
      path: '/ClinicalSettings/InpatientAttendanceFee',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    }
  ])

  return (
    <>
    <Routes>
        <Route path='Search'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Search</PageTitle>
              <InpatientAttendanceFeeList />
            </>
          }
        />
        <Route path='Add'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Add Inpatient Attendance Fee</PageTitle>
              <InpatientAttendanceFeeAddEdit />
            </>
          }
        />
      <Route path='Edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Edit Inpatient Attendance Fee</PageTitle>
              <InpatientAttendanceFeeAddEdit />
            </>
          }
        />

      <Route path='View/:id'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>View Inpatient Attendance Fee</PageTitle>
              <InpatientAttendanceFeeView />
            </>
          }
        />

      <Route index element={<Navigate to='/ClinicalSettings/InpatientAttendanceFee/Search' />} />
    </Routes>
    </>
  )
}

export {InpatientAttendanceFeeModule}
