import React, { useEffect, useState } from 'react';
import { HealthScreeningType, HealthScreeningTypeSchema} from './_models';
import { save,getById, IsCodeExist } from './_requests';
import { KTCard, KTCardBody,KTCardFooter,KTCardHead } from '../../../../_theme/helpers';
import { useFormik } from 'formik';
import { ConfirmModal, Constants, FormActionButtons, KTInputBox, KTInputLabel, StatusResponseModal } from '../../../services';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { StatusResponse } from '../../../models/global/StatusResponce';
import { CodeExist } from '../../../models';

export function HealthScreeningTypeAddEdit () {

  const [isLoading, setLoading] = useState(true);
  const [statusResp, setStatusResp] = useState(new StatusResponse());
  const [isAlertModal, setAlertModal] = useState(false);
  const [healthScreeningTypeData, setHealthScreeningType] = useState(new HealthScreeningType());
  const [isConfirmModal, setConfirmModal] = useState(false);
  const [isExist, setExist] = useState(new CodeExist());
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(()=>{
    fetchHealthScreeningTypeList();
  return ()=>{ 
  }
  // eslint-disable-next-line
 },[])



 const fetchHealthScreeningTypeList = () => {
  if(id !== undefined && id && isLoading){
    getById(id).then(function (response) {
      setHealthScreeningType(response.data);
      formik.resetForm({
        values:response.data
      })
      setLoading(false);
    })
  }else{
    setLoading(false);
  }
}

 const formik = useFormik({
    initialValues: healthScreeningTypeData,
    validationSchema: HealthScreeningTypeSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {

      if(id !== undefined && id && healthScreeningTypeData.name !== values.name){
        setConfirmModal(true);
        return ;
      }

      setLoading(true)
      setSubmitting(true)
      try {
        var saveResp = await save(values);
        setStatusResp(saveResp.data);
        setAlertModal(true);
        setLoading(false)
        setSubmitting(false)
      } catch (error) {
        try{
        }
        catch(_error){
            setStatus(Constants.MESSAGE_ERROR);
        }
        console.error(error)
      }
    },
  })

  const confirmModal = async () => {
    setConfirmModal(false);
    var saveResp = await save(formik.values);
    setStatusResp(saveResp.data);
    setAlertModal(true);
    setLoading(false)
  }

  
  const closeModal = () => {
    setAlertModal(false);
    if(statusResp.code===1)
    navigate("/ClinicalSettings/HealthScreeningType");
  }
  


      return (
         <>
         {isAlertModal ? (<StatusResponseModal message={''} data={statusResp}  handleClose = {closeModal}/>):<></>}
         {isConfirmModal ? (<ConfirmModal message={'Proceed to change Health Screening Fee '+healthScreeningTypeData.name+' to '+formik.values.name+' ?'}  handleConfirm = {confirmModal}/>):<></>}
  
          
          <KTCard>
            <KTCardHead title={((id == undefined || parseInt(id) === 0)?'Add':'Edit')+' HEALTH SCREENING TYPE'}>
            </KTCardHead>
            <KTCardBody>
                <form className='form w-100 my-5' onSubmit={formik.handleSubmit} noValidate id='kt_country_add_edit_form' >
                    <div className="row">
                      

                      <div className='col-sm-6 d-md-flex my-2'>
                        <KTInputLabel required={true} title="Name" className='col-md-3' />
                        <div className="col-md-8">
                          <KTInputBox placeholder='Name' formikProps={formik.getFieldProps('name')} maxLength={512}
                            active={formik.touched.name}   error={formik.errors.name}
                            
                          />
                        </div>
                      </div>

                    
                      
                    </div>

                    <div className="row">

                    <div className='col-sm-6 d-md-flex my-2'>
                        <KTInputLabel required={true} title="Screening ID" className='col-md-3' />
                        <div className="col-md-8">
                          <KTInputBox placeholder='Screening ID From ODS' formikProps={formik.getFieldProps('screeningId')} maxLength={512}
                            active={formik.touched.screeningId}   error={formik.errors.screeningId}
                            
                          />
                        </div>
                      </div>
                      <div className='col-sm-6 d-md-flex my-2'>
                        <KTInputLabel required={true} title="Screening Name" className='col-md-3' />
                        <div className="col-md-8">
                          <KTInputBox placeholder='Screening NAME From ODS' formikProps={formik.getFieldProps('screeningName')} maxLength={512}
                            active={formik.touched.screeningName}   error={formik.errors.screeningName}
                            
                          />
                        </div>
                      </div>
                      </div>


                    

                    <KTCardFooter className='d-flex justify-content-end mt-5'>
                      {/* begin::Action */}                           
                        <FormActionButtons isLoading={isLoading} id={id} backUrl='/ClinicalSettings/HealthScreeningType' disabled={formik.isSubmitting || !formik.isValid } />
                        {/* end::Action */}
                    </KTCardFooter>


                </form>
            </KTCardBody>

          </KTCard>
          </>
      )
    
}


