import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink} from '../../../_theme/layout/core'
import { ClinicRatingWeightageModule } from './clinic-rating-weightage/ClinicRatingWeightageModule';
import { DoctorRatingWeightageModule } from './doctor-rating-weightage/DoctorRatingWeightageModule';
import { PublishRatingsModule } from './publish-ratings/PublishRatingsModule';
import { SurveyConfigModule } from "./survey-config/SurveyConfigModule";
import { SurveyQuestionsModule } from './survey-questions/SurveyQuestionsModule';
import { UploadInternalRatingsModule } from './upload-internal-ratings/UploadInternalRatingsModule';

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Rating Settings',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const RatingSettingsModule = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>

      <Route path='ClinicRatingWeightage/*'
          element={
            <>
              <ClinicRatingWeightageModule breadcrumbs={usersBreadcrumbs}/>
            </>
          }
        />

      <Route path='DoctorRatingWeightage/*'
          element={
            <>
              <DoctorRatingWeightageModule breadcrumbs={usersBreadcrumbs}/>
            </>
          }
        />


      <Route path='SurveyConfig/*'
          element={
            <>
              <SurveyConfigModule breadcrumbs={usersBreadcrumbs}/>
            </>
          }
        />

      <Route path='PublishRatings/*'
          element={
            <>
              <PublishRatingsModule breadcrumbs={usersBreadcrumbs}/>
            </>
          }
        />

      <Route path='SurveyQuestions/*'
          element={
            <>
              <SurveyQuestionsModule breadcrumbs={usersBreadcrumbs}/>
            </>
          }
        />

      <Route path='UploadInternalRatings/*'
          element={
            <>
              <UploadInternalRatingsModule breadcrumbs={usersBreadcrumbs}/>
            </>
          }
        />

      </Route>
      <Route index element={<Navigate to='/RatingSettings/SurveyConfig' />} />
    </Routes>
  )
}

export default RatingSettingsModule