import { Link } from "react-router-dom";
import { ActionIcon, Constants } from "../../../services";
import { HealthScreeningFee } from "./_models";


export function HealthScreeningFeeTableColumns (pageNo:number = 0,confirm?:any) {

return  (
    [
        {
            name: 'NO.',
            cell: (row:HealthScreeningFee,index:any) => (1 + index + (pageNo - 1 ) * Constants.DATATABLE_PAGE_SIZE),
            width:'65px'
        },
        {
            name: 'TYPE OF CHARGE',
            selector: (row:HealthScreeningFee) => row.typeOfCharge,
        },

        {
            name: 'Description',
            selector: (row:HealthScreeningFee) => row.description,
        },
        {
            name: 'Type',
            selector: (row:HealthScreeningFee) => row.healthScreeningTypeForm?.name,
        },
        
        {
            name: 'ACTIONS',
            maxWidth:'160px',
            cell: (row:HealthScreeningFee) => <>
                <Link to={'/ClinicalSettings/HealthScreeningFee/Edit/'+row.id}>
                <ActionIcon label='EDIT' />
              </Link>
              <span onClick={() => confirm(row.id)}>
                  <ActionIcon label='DELETE'/>
              </span>
              </>
        }
      ]
  )
}
