import { Link } from "react-router-dom";
import { ActionIcon, Constants } from "../../../services";
import { HealthScreeningType } from "./_models";


export function HealthScreeningTypeTableColumns (pageNo:number = 0,confirm?:any) {

return  (
    [
        {
            name: 'NO.',
            cell: (row:HealthScreeningType,index:any) => (1 + index + (pageNo - 1 ) * Constants.DATATABLE_PAGE_SIZE),
            width:'65px'
        },
        {
            name: 'NAME',
            selector: (row:HealthScreeningType) => row.name,
        },
        {
            name: 'Screening ID (ODS)',
            selector: (row:HealthScreeningType) => row.screeningId,
        },
        {
            name: 'Screening Name (ODS)',
            selector: (row:HealthScreeningType) => row.screeningName,
        },

       
        {
            name: 'ACTIONS',
            maxWidth:'160px',
            cell: (row:HealthScreeningType) => <>
                <Link to={'/ClinicalSettings/HealthScreeningType/Edit/'+row.id}>
                <ActionIcon label='EDIT' />
              </Link>
              <span onClick={() => confirm(row.id)}>
                  <ActionIcon label='DELETE'/>
              </span>
              </>
        }
      ]
  )
}
