import React from 'react'
type Props = {
  rating?: string
}

const RatingStar: React.FC<Props> = ({rating}) => {

  const [ratingNum] = React.useState(parseInt(rating ? rating : '0'));

  return (
    <> 
    <i className={ 'fa-star text-warning ' + (ratingNum > 0 ? 'fas' : 'far') } style={{marginRight:'1px'}}></i>
    <i className={ 'fa-star text-warning ' + (ratingNum > 1 ? 'fas' : 'far') } style={{marginRight:'1px'}}></i>
    <i className={ 'fa-star text-warning ' + (ratingNum > 2 ? 'fas' : 'far') } style={{marginRight:'1px'}}></i>
    <i className={ 'fa-star text-warning ' + (ratingNum > 3 ? 'fas' : 'far') } style={{marginRight:'1px'}}></i>
    <i className={ 'fa-star text-warning me-2 ' + (ratingNum > 4 ? 'fas' : 'far') }></i> 
    {rating}</>
  )
}

export {RatingStar}
