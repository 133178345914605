import React from 'react'
import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink} from '../../../_theme/layout/core'
import { InpatientAttendanceFeeModule } from './inpatient-attendance-fee/InpatientAttendanceFeeModule'
import { InpatientSurgeonFeeModule } from './inpatient-surgeon-fee/InpatientSurgeonFeeModule'
import { HealthScreeningFeeModule } from './health-screening-fee/HealthScreeningFeeModule'
import { HealthScreeningTypeModule } from './health-screening-type/HealthScreeninTypeModule'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Clinical Settings',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ClinicalSettingsModule = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>

      <Route path='InpatientAttendanceFee/*'
          element={
            <>
              <InpatientAttendanceFeeModule breadcrumbs={usersBreadcrumbs}/>
            </>
          }
        />

      <Route path='InpatientSurgeonFee/*'
          element={
            <>
              <InpatientSurgeonFeeModule breadcrumbs={usersBreadcrumbs}/>
            </>
          }
        />

      <Route path='HealthScreeningFee/*'
          element={
            <>
              <HealthScreeningFeeModule breadcrumbs={usersBreadcrumbs}/>
            </>
          }
        />

      <Route path='HealthScreeningType/*'
          element={
            <>
              <HealthScreeningTypeModule breadcrumbs={usersBreadcrumbs}/>
            </>
          }
        />

      </Route>

      
      <Route index element={<Navigate to='/ClinicalSettings/InpatientAttendanceFee' />} />
    </Routes>
  )
}

export default ClinicalSettingsModule