import { Link } from "react-router-dom";
import { ActionIcon, Constants } from "../../../services";
import { DoctorRatingWeightage } from "./_models";


export function DoctorRatingWeightageTableColumns (pageNo:number = 0,confirm?:any) {

return  (
    [
        {
            name: 'NO.',
            cell: (row:DoctorRatingWeightage,index:any) => (1 + index + (pageNo - 1 ) * Constants.DATATABLE_PAGE_SIZE),
            width:'65px'
        },
        {
            name: 'INTERNAL WEIGHTAGE',
            selector: (row:DoctorRatingWeightage) => row.internalWeightage,
        },

        {
            name: 'EXTERNAL WEIGHTAGE',
            selector: (row:DoctorRatingWeightage) => row.externalWeightage,
        },

        {
            name: 'MONTH',
            selector: (row:DoctorRatingWeightage) => row.monthYear,
        },
        
        {
            name: 'ACTIONS',
            maxWidth:'160px',
            cell: (row:DoctorRatingWeightage) => <>
                <Link to={'/RatingSettings/DoctorRatingWeightage/Edit/'+row.id}>
                <ActionIcon label='EDIT' />
              </Link>
              <span onClick={() => confirm(row.id)}>
                  <ActionIcon label='DELETE'/>
              </span>
              </>
        }
      ]
  )
}
