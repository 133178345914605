import { Link } from "react-router-dom";
import { ActionIcon, Constants } from "../../../services";
import { SuspendUser } from "./_models";


export function SuspendUserTableColumns (pageNo:number = 0,confirm?:any) {
return  (
    [
        {
            name: 'NO.',
            cell: (row:SuspendUser,index:any) => (1 + index + (pageNo - 1 ) * Constants.DATATABLE_PAGE_SIZE),
            width:'65px'
        },
        {
            name: 'EFFECTIVE DATE',
            selector: (row:SuspendUser) => row.fromdate,
        },
        {
          name: 'TERMINATION DATE',
          cell: (row:SuspendUser) => <>{row.todate}</>,
      },
      {
        name: 'REMARKS',
        cell: (row:SuspendUser) => <>{row.remark}</>,
    },
        {
            name: 'ACTIONS',
            maxWidth:'160px',
            cell: (row:SuspendUser) => <> 
            {row.canEdit && 
                <Link to={'/User/SuspendUser/Edit/userId/'+row.applicationuserId+'/id/'+row.id}>
                <ActionIcon label='EDIT' />
              </Link>
              }
              {row.canDelete && 
                <span onClick={() => confirm(row.id)}>
                    <ActionIcon label='DELETE'/>
                </span>
              }
              </>
        }
      ]
  )
}
