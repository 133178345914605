import { Constants } from "../../../services";
import { KTMinimizedText } from "../../../services/components/MinimizedText";
import { PublishRatingsForm } from "./_models";

export function PublishRatingsLogsTableColumns (pageNo:number = 0) {

    return  (
        [
            {
                name: 'NO.',
                cell: (row:PublishRatingsForm,index:any) => (1 + index + (pageNo - 1 ) * Constants.DATATABLE_PAGE_SIZE),
                width:'65px'
            },
            {
                name: 'RATING NAME',
                selector: (row:PublishRatingsForm) => row.ratingType,
            },
    
            {
                name: 'START DATE',
                selector: (row:PublishRatingsForm) => row.fromdate,
            },
    
            {
                name: 'END DATE',
                selector: (row:PublishRatingsForm) => row.todate,
            },
            {
                name: 'CREATED BY',
                selector: (row:PublishRatingsForm) => row.todate,
            },
            {
                name: 'REMARKS',
                cell: (row:PublishRatingsForm) => <>
                <KTMinimizedText data={row.remarks} />
                </> ,
            },
          ]
      )
    }