import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_theme/layout/core'
import { env } from '../../../App.env'
import { PermissionModel } from '../../../models'
import {UserModel} from '../../../models/UserModel'
import * as authHelper from './AuthHelpers'
import {getCurrentUser,getRolePermission,logout as logout_request} from './_requests'

type Props = {
  children?: React.ReactNode
};

type AuthContextProps = {
  auth: string | undefined
  saveAuth: (auth: string | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  permissions:PermissionModel |undefined
  setPermission: Dispatch<SetStateAction<PermissionModel | undefined>>
  logout: () => void
  login: (auth: string) => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  permissions: undefined,
  setPermission:()=>{},
  logout: () => {},
  login:() =>{}
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<Props> = ({children}) => {
  const [auth, setAuth] = useState<string | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [permissions, setPermission] = useState<PermissionModel | undefined>()
  const saveAuth = (auth: string | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    if(currentUser?.username)
      logout_request(currentUser?.username)
    saveAuth(undefined)
    setCurrentUser(undefined)
    setPermission(undefined)
  }

  const login = async (auth: string) => {
    saveAuth(auth)
    const {data: user} = await getCurrentUser(auth)
    setCurrentUser(user)
    const {data: permissions} = await getRolePermission()
    setPermission(permissions.fieldContent);
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser,permissions,setPermission, logout,login}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<Props> = ({children}) => {
  const {auth, logout, setCurrentUser,setPermission} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const {data} = await getCurrentUser(apiToken)
          if (data) {
            setCurrentUser(data);
            if(!env.release){
              console.log(' ** Left Menu Urls for Current User **');
              for(var i = 0 ; i< data.menuList.length ; i++){
                console.log(data.menuList[i].url);
                if(data.menuList[i].subMenu !== null )
                data.menuList[i].subMenu.map(s=>(console.log(s.subMenuUrl)))
                }
            }
          }
          const {data: permissions} = await getRolePermission()
          setPermission(permissions.fieldContent);
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth) {
      requestUser(auth)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
