
import React, { useEffect, useState } from 'react';
//Datatable Modules

import { KTCard, KTCardBody, KTCardFooter, KTCardHead } from '../../../../_theme/helpers';
import { AlertModal, FormActionButtons, KTInputBox, KTInputLabel, Loading } from '../../../services';
import { PageLink, PageTitle } from '../../../../_theme/layout/core';
import { changePassword } from '../_requests';
import { useAuth } from '../../auth';
import { KTDisplayBox, KTInputError } from '../../../services/components/InputTemplates';
import { getSystemPasswordPolicy } from '../../settings/system-policy/_requests';
import { SystemPasswordPolicy } from '../../settings/system-policy/_models';
import { useFormik } from 'formik';
import { ChangePasswordForm, ChangePasswordRequest, ChangePasswordValidationSchema } from './_models';
import { PasswordStrengthMeter, PasswordStrengthValidation } from '../../../services/components/PasswordStrength';
import { Status } from '../../../models/global/StatusResponce';
import * as CryptoJSx from 'crypto-js';
export function ProfilePage () {

const [isLoading, setLoading] = useState(false);
const [isAlertModal, setAlertModal] = useState(false);
const [resStatus, setResStatus] = useState(new Status(0,''));
const [pageTitle, setPageTitle] = useState('My Profile');
const [PasswordPolicy,SetSystemPasswordPolicy] = useState(new SystemPasswordPolicy())
const {currentUser} = useAuth();
const [isPwdVisibility1, setPwdVisibility1] = useState<boolean>(false) ;
const [isPwdVisibility2, setPwdVisibility2] = useState<boolean>(false) ;
const [isPwdVisibilityOld, setPwdVisibilityOld] = useState<boolean>(false) ;

useEffect(()=>{
  getSystemPasswordPolicy().then(function (response) {
    SetSystemPasswordPolicy(response.data);
  });
return ()=>{ 
}
},[])

const usersBreadcrumbs: Array<PageLink> = [
  {
     title: '',
     path: '',
     isSeparator: false,
     isActive: false,
   },
 ]

 const formik = useFormik({
  initialValues:new ChangePasswordForm(),
  validationSchema: ChangePasswordValidationSchema,
  enableReinitialize:true,
  onSubmit: async (values, {setStatus, setSubmitting}) => {
    try {
      var f = PasswordStrengthValidation(PasswordPolicy,values.newPassword);
      if(!f){
        formik.setFieldError('newPassword','errorr-----------');
        formik.setFieldTouched('newPassword',true);
        setSubmitting(false);
        return;
      }
      if(f){  
        setLoading(true)
        var param = new ChangePasswordRequest();
        param.hashedpassword = CryptoJSx.SHA256(values.oldPassword).toString();
        param.encryptedhashedpassword = CryptoJSx.SHA256(values.retypeNewPassword).toString();

        var EncryptionKey = "u/Gu5posvwDsXUnV5Zaq4g==";
        var EncryptionIv='5D9r9ZVzEYYgha93/aUK2w==';
        var rkEncryptionKey = CryptoJSx.enc.Base64.parse(EncryptionKey);
				var rkEncryptionIv = CryptoJSx.enc.Base64.parse(EncryptionIv);
				var utf8Stringified = CryptoJSx.enc.Utf8.parse(values.retypeNewPassword);
				var encrypted = CryptoJSx.AES.encrypt(utf8Stringified.toString(), rkEncryptionKey,{mode: CryptoJSx.mode.CBC, padding: CryptoJSx.pad.Pkcs7, iv: rkEncryptionIv});
        param.hashedConfirmPassword = encrypted.ciphertext.toString(CryptoJSx.enc.Base64);
        changePassword(param).then(res => {
          setResStatus(res.data);
          if(res.data.code === 1)
          {
            formik.resetForm({values:new ChangePasswordForm()});
          }
          setLoading(false)
          setAlertModal(true);
        })
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  },
})

const closeModal = () => {
  setAlertModal(false);
}
    //Datatable HTML
  return (
    <>
    {isLoading && (<Loading/>)}
    {isAlertModal && <AlertModal message={resStatus.message}  handleClose = {closeModal} />}
    <PageTitle breadcrumbs={usersBreadcrumbs}>{pageTitle}</PageTitle>
    <KTCard>
      <KTCardHead title={pageTitle}>
      </KTCardHead>
      <KTCardBody className='py-4'>
        <div className="row">
        <div className="col-md-3">
            <div className="nav nav-pills mb-6 d-flex flex-row flex-md-column" role="tablist" >
              <div className="nav-item col-md-12 mw-200px">
                <span className={'m-1 nav-link border border-secondary ' + (pageTitle === 'My Profile' ? 'active bg-secondary' : 'text-secondary bg-hover-light-primary border-hover-primary') } 
                onClick={()=>setPageTitle('My Profile')} >
                  <i className={'fa fa-user me-2 ' + (pageTitle === 'My Profile' ? ' text-light':'')}></i>
                  Personal Information
                  </span>
                </div>
              <div className="nav-item col-md-12 mw-200px">                  
                <span  className={'m-1 nav-link border border-secondary ' + (pageTitle === 'Change Password' ? 'active bg-secondary' : 'text-secondary bg-hover-light-primary border-hover-primary') } 
                onClick={()=>{setPageTitle('Change Password'); formik.resetForm({values:new ChangePasswordForm()});}}>
                  <i className={'fa fa-lock me-2 ' + (pageTitle === 'Change Password' ? ' text-light' :'')}></i> 
                   Change Password
                   </span>
              </div>
            </div>
          </div>
        <div className="col-md-6">
            <div className={"fade tab-pane "+ (pageTitle !== 'My Profile' ? 'd-none':'show')}>
                <div className='row'>
                  <div className='col-sm-6'>
                    <KTInputLabel title="Name"/>
                  </div>
                  <div className='col-md-12 mb-2'>
                    <KTDisplayBox text={currentUser?.name} />
                   </div>
                </div>
                <div className='row'>
                  <div className='col-sm-6 mt-2'>
                    <KTInputLabel title="Date of Birth"/>
                  </div>
                  <div className='col-md-12 mb-2'>
                  <KTDisplayBox text={currentUser?.dob} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-6 mt-2'>
                    <KTInputLabel title="Mobile Number"/>
                  </div>
                  <div className='col-md-12 mb-2'>
                    <KTDisplayBox text={currentUser?.mobile} />
                   </div>
                </div>
                <div className='row'>
                  <div className='col-sm-12 mt-2'>
                    <KTInputLabel title="Email"/>
                  </div>
                  <div className='col-md-12 mb-2'>
                    <KTDisplayBox text={currentUser?.email} />
                   </div>
                </div>
            </div>
            <div className={"fade tab-pane "+ (pageTitle !== 'Change Password' ? 'd-none' :'show')}>
            <form className='form w-100 pb-2' onSubmit={formik.handleSubmit} noValidate id='kt_password_reset_form' >
                <div className='row'>                  
                  <div className='col-md-6'>
                    <KTInputLabel  title="User Name" />
                    <div className="col-md-12 mb-2"><KTDisplayBox text={currentUser?.username} /> </div>
                  </div>
                  <div className='col-md-6'>
                    <KTInputLabel  title="Current Password" />
                    <div className="col-md-12 mb-2">
                    <div className="input-group mb-1">
                      <KTInputBox type={isPwdVisibilityOld?'Default':'Password'}
                        formikProps={formik.getFieldProps('oldPassword')}
                        maxLength={32} placeholder='Enter Current Password'/>
                         <span className="input-group-text" onClick={()=>{ setPwdVisibilityOld(!isPwdVisibilityOld)}} ><i className={isPwdVisibilityOld?'fa fa-eye fs-4 text-gray-400':'fa fa-eye-slash fs-4 text-gray-400' }></i></span>
                      </div>
                      <KTInputError active={formik.touched.oldPassword} error={formik.errors.oldPassword} textClass='text-danger' />
                      </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-12 mt-2'>
                    <KTInputLabel  title="New Password" />
                    </div>
                      <div className="col-md-12 mb-2">
                      <div className="input-group mb-1">
                        <KTInputBox  type={isPwdVisibility1?'Default':'Password'}
                        formikProps={formik.getFieldProps('newPassword')}
                        maxLength={32} placeholder='Enter New Password'/>
                         <span className="input-group-text" onClick={()=>{ setPwdVisibility1(!isPwdVisibility1)}} ><i className={isPwdVisibility1?'fa fa-eye fs-4 text-gray-400':'fa fa-eye-slash fs-4 text-gray-400' }></i></span>
                        </div>
                        <KTInputError active={formik.touched.newPassword} error={formik.errors.newPassword} textClass='text-danger' />
                        <PasswordStrengthMeter displayMode={"Text"} inlinePopup={false} password={formik.values.newPassword} passwordpolicy ={PasswordPolicy} />
                    </div>
                </div>
                <div className='row'>
                  <div className='col-md-12 mt-2'>
                    <KTInputLabel  title="Retype New Password" />
                    </div>
                      <div className="col-md-12">
                      <div className="input-group mb-1">
                        <KTInputBox type={isPwdVisibility2?'Default':'Password'}
                        formikProps={formik.getFieldProps('retypeNewPassword')}
                        maxLength={32} placeholder='Retype New Password'/>
                         <span className="input-group-text" onClick={()=>{ setPwdVisibility2(!isPwdVisibility2)}} ><i className={isPwdVisibility2?'fa fa-eye fs-4 text-gray-400':'fa fa-eye-slash fs-4 text-gray-400' }></i></span>
                         
                    </div>
                    <KTInputError active={formik.touched.retypeNewPassword} error={formik.errors.retypeNewPassword} textClass='text-danger' />
                    </div>
                </div>
                <KTCardFooter className='d-flex justify-content-start my-7'>
                      {/* begin::Action */}                           
                        <FormActionButtons isLoading={isLoading} id={currentUser?.applicationUserId.toString()} disabled={formik.isSubmitting || !formik.isValid} />
                        {/* end::Action */}
                </KTCardFooter>
                </form>
            </div>
          </div>
      </div>
      </KTCardBody>
    </KTCard>
    </>
  );
}
