import axios, {} from 'axios'
import { env } from '../../../App.env';
import { SearchForm } from '../../../models';
import { DatatableResponse } from '../../../models/global/DataTable';
import { Status, StatusResponse } from '../../../models/global/StatusResponce'; 
import { ClinicUser } from './_models';

const API_URL = env.base_api_url

 export function getAllClinicUserByClinicId(clinicId : number) {
  return axios.post<{clinicUserForms : Array<ClinicUser>}>(`${API_URL}/Api/Clinic/ClinicUser/getAllClinicUserByClinicId`,{id : clinicId})
 } 

 export function getClinicUserByid(clinicUserId : number) {
  return axios.post<{clinicuserForms : ClinicUser}>(`${API_URL}/Api/Clinic/ClinicUser/getClinicuserByid`,{id : clinicUserId})
 } 

 export function SearchClinicUsers(req: SearchForm) {
    return axios.post<DatatableResponse<ClinicUser>>(`${API_URL}/Api/Clinic/ClinicUser/searchAllClinicUser`, req)
  }
  
  export function SaveClinicUser(req: ClinicUser) {
    return axios.post<StatusResponse>(`${API_URL}/Api/Clinic/ClinicUser/saveClinicUser`, req)
  }
  
  export function getNextClinicUserId() {
    return axios.get<{requestSuccess:boolean,nextClinicUserId:number}>(`${API_URL}/Api/Clinic/ClinicUser/getNextClinicUserId/`)
  }
  export function ResetPasswordToClinicUser(req : any) {
    return axios.post<Status>(`${API_URL}/Api/Clinic/ClinicUser/ResetPasswordToClinicUser`,req)
  }
  export function SendFirstTimeLoginDetailsToClinicUser(req:any) {
    return axios.post<Status>(`${API_URL}/Api/Clinic/ClinicUser/SendFirstTimeLoginDetailsToClinicUser`,req)
  }
  