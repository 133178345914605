import { Auditable } from "../../../models/global/Auditable"
import * as Yup from 'yup';
import { string } from 'yup';
import { HealthScreeningType } from "../health-screening-type/_models"
import { ReactDateRange, ReactDateRangeString } from "../../../models/global/ReactDate";



export class HealthScreeningFee extends Auditable{
  id: number = 0;
  typeOfCharge: string ='';
  description: string ='';
  healthScreeningTypeForm:HealthScreeningType = new HealthScreeningType();
  scheduleDates : ReactDateRangeString[] = [];
}

export const HealthScreeningFeeSchema = Yup.object({
  typeOfCharge: string().max(256, 'Maximum 256').required('Please enter Type Of Charge'),
  description: string().max(256, 'Maximum 256').required('Please enter Description'),
  healthScreeningTypeForm: Yup.object().shape({
    id: Yup.number().moreThan(0, 'Please select screening type'),
  })
})
