import SVG from 'react-inlinesvg'
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

import React from 'react';
import { toAbsoluteUrl } from '../../../_theme/helpers';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Popover } from 'react-bootstrap';
import { decode } from 'html-entities';


type PropsMinimizedText = {
    minLength?:number
    className?:string
    data?:string
    type?:'POPUP' | 'HOVER'
    title?:string 
  }
  const KTMinimizedText: React.FC<PropsMinimizedText> = ({minLength = 128,className,data = '',type='POPUP',title=''}) => {
    const [show, setShow] = React.useState(false);

    const close = () => {
    setShow(false);
    } 

    const _data = (data === undefined || data === null || data === '') ? '' :data ;

    const Show = () => {
        if(type==='POPUP')
            setShow(true);
        } 
  
    return (
      <>     
          <div className={className?className:''}>
          <>
              {_data.length <= minLength  && <>{decode(_data)}</>}
              {_data.length > minLength && type === 'POPUP' &&
                  <>
                      <span>{_data.substring(0,minLength)}</span>
                      <OverlayTrigger
                            placement={'bottom'}
                            overlay={
                            <Tooltip id={`tooltip-${'bottom'}-${Math.floor(Math.random()*1000)}`}>Show More</Tooltip>
                            }
                        >
                            <span className={'border-hover-primary border bg-hover-light border-light pb-1 text-hover-primary svg-icon ms-2 svg-icon-gray-600'} style={{'position':'sticky'}} onClick={Show} > 
                                <SVG src={toAbsoluteUrl('/media/icons/duotune/general/gen052.svg')} style={{height:'1.8rem',width:'1.8rem'}}/>
                            </span>
                        </OverlayTrigger>

                  </>
            }
            {_data.length > minLength && type === 'HOVER' &&
                <OverlayTrigger
                    placement={'bottom'}
                    overlay={
                    <Popover id={`tooltip-${'bottom'}-${Math.floor(Math.random()*1000)}`} style={{minWidth:'400px'}}>
                      <div className='p-3'>
                         {decode(_data)}
                      </div>
                    </Popover>
                    }
                >        
                <div className={className?className:''}> 
                    <span>{_data.substring(0,minLength)}</span>
                    <span className={'pb-1 svg-icon ms-2 svg-icon-gray-600'} style={{'position':'sticky'}}> 
                        <SVG src={toAbsoluteUrl('/media/icons/duotune/general/gen052.svg')} style={{height:'1.8rem',width:'1.8rem'}}/>
                    </span>
                </div>
                </OverlayTrigger>
                }
          </>
      </div>

        {type=== 'POPUP' && (
            <Modal
                    className='modal fade'
                    id={'kt_modal_'+Math.floor(Math.random()*1000)}
                    tabIndex={-1}
                    role='dialog'
                    show={show}
                    dialogClassName='modal-lg'
                    aria-hidden='true'
                    onHide={close}
                    >
                    <div className='modal-content'>
                    <div className={'modal-header' + ( title === '' ? ' border-0 pb-0' :'') } >
                        <h5 className='modal-title'>{title}</h5>
                        <button
                            className='btn btn-icon btn-sm btn-active-light-primary ms-2 btn-close' onClick={close}
                            aria-label="Close"
                        >
                        </button>
                        </div>
                        <div className='modal-body fs-4 pt-3'>
                        {decode(_data)}
                        </div>
                    </div>
            </Modal>
            )}
      </>
    )
  }

  export {KTMinimizedText}
  
  
  