import { Auditable } from "../../../models/global/Auditable"
import * as Yup from 'yup';
import { string } from 'yup';


export interface InpatientSurgeonFeeResponse {
  InPatientSurgeonFee: InpatientSurgeonFee[];
  inpatientSurgeonFeesForm: InpatientSurgeonFee[];
}

export interface InpatientSurgeonFeeResponseO {
  viewInpatientSurgeonFee: InpatientSurgeonFee[];
  requestSuccess: boolean;
  viewInpatientSurgeonFeeCeiling: SurgicalTableform[];
}

export class InpatientSurgeonFeeReq{
  "inPatientSurgeonFeeForm" : InpatientSurgeonFee
  "makercheckertasksForm" : MakercheckertasksFormReq
  "surgicalfeeceling" : SurgicalTableform[] = []

}

export class MakercheckertasksFormReq  {
  "parentcreatorid"?: string
  "creatorremarks"?: string
  "taskname": "Inpatient Surgeon Fee"
  "parentapproverid"?: string
}


export class InpatientSurgeonFee extends Auditable{
  id?: number;
  parentFeeTableId: number=0;
  feeTableName: string='';
  fromDate: string='';
  toDate: string='';
  mainDoctorFirstOperationPercentage: number=0;
  mainDoctorSecondOperationSameIncisionPercentage: number=0;
  mainDoctorThirdOperationSameIncisionPercentage: number=0;
  mainDoctorFourthOperationSameIncisionPercentage: number=0;
  assistingDoctorPercentage: number=0.00;
  assistingDoctorMaximumNumber: number=0;
  mainDoctorFifthOperationSameIncisionPercentage: number=0;
  mainDoctorSixOperationSameIncisionPercentage: number=0;
  mainDoctorSevenOperationSameIncisionPercentage: number=0;
  mainDoctorFirstSpecialOperationSameIncisionPercentage: number=0;
  mainDoctorSecondSpecialOperationSameIncisionPercentage: number=0;
  mainDoctorThirdSpecialOperationSameIncisionPercentage: number=0;
  mainDoctorFourthSpecialOperationSameIncisionPercentage: number=0;
  mainDoctorFifthSpecialOperationSameIncisionPercentage: number=0;
  mainDoctorSixSpecialOperationSameIncisionPercentage: number=0;
  mainDoctorSevenSpecialOperationSameIncisionPercentage: number=0;
  surgicalTablesBean?: any;
  surgicalTables: number=0;
  anesthetistPercentage: number=0.00;
  anesthetistMinimumFee: number=0.00;
  parentapproverid?: any;
  creatorremarks: string='';
  name?: any;
  makercheckerstatus?: any;
  makercheckertasks?: any;
  applicationuserForm?: any;
  applicationUser?: any;
  approvedByName?: any;
  approvedUser?: any;
  feeCeiling?: any;
  surgicalid?: any;
  approvedBy?: any;
  remarks?: any;
  approvedUserName?: any;
  fromDateFlag?: any;
  emergencyoppercent: string='';
  parentCreatorID?: any;
  emergencySpecialoppercent: string='';
  makercheckertasksForm: MakercheckertasksForm= new MakercheckertasksForm();
  status: string='';
  surgicalFeeCellingList:SurgicalTableform[]=[];
}

export class MakercheckertasksForm extends Auditable{
  id?: number;
  makercheckerstatus: number=0;
  status: string='';
  makercheckertask: number=0;
  taskname: string='';
  parentapproverid: number=0;
  approverName: string='';
  parentcreatorid: number=0;
  parenttaskreferenceid: number=0;
  creatorremarks: string='';
  maker?: any;
  effectiveDate?: any;
  terminationDate?: any;
  newMakerCheckerFlag: boolean=false;
  assignTo: string='';
  creator: string='';
  approvedBy: string='';
  actualStatus?: any;
  latestStatus?: any;
  todateStatus?: any;
}

export class SearchFormSurgeonfee {
  inPatientSurgeonFeeTableId:number=0
  makercheckerstatus:string=''
  fromDate:string=""
  ToDate:string=''
}

export class SurgicalTableform {
  id: number=0;
  feeCeiling: number=0.00;
  name: string="";
  inpatientSurgeonFeeBean?: any;
  surgicalTableBean?: any;
}

export interface SurgicalTableformResponse {
  surgicalTableform: SurgicalTableform[];
  requestSuccess: boolean;
}




export const InpatientSurgeonFeeSchema = Yup.object({

  parentFeeTableId: Yup.number().required('Please select Fee Table Name').min(1,'Please select Fee Table Name'),
  fromDate: string().required('Please enter Effective Date.'),

  assistingDoctorPercentage: Yup.number().required('Please Enter Percentage of fees').min(1,'Please Enter Percentage of fees'),
  surgicalTables: Yup.number().required('Please select Surgical table').min(1,'Please  select Surgical table'),
  anesthetistPercentage: Yup.number().required('Please Enter Percentage of fees').min(1,'Please Enter Percentage of fees'),
  anesthetistMinimumFee: Yup.number().required('Please Enter Minimum Fees').min(1,'Please Enter Minimum Fees'),
    
  makercheckertasksForm :Yup.object({
    parentapproverid: Yup.number().required('Please select Approver').min(1,'Please select Approver')
    })
 
})
