import React from 'react'
import Tooltip from 'react-bootstrap/Tooltip';
import { OverlayTrigger } from 'react-bootstrap';
import { KTSVG, toAbsoluteUrl } from '../../../_theme/helpers';
import SVG from 'react-inlinesvg'
type Props = {
  label?: 'EDIT' | 'DELETE' | 'ADD' | 'VIEW' | string
  children?: React.ReactNode
  icon?:string
  style?:any
  svg?:string
  className?:string
}

const ActionIcon: React.FC<Props> = ({label = '', icon, style,svg,children,className=''}) => {
    var _tooltip = label;
    var _stylesvg = style? style :{'padding' : '0.3rem 0.3rem'};
    var _styleicon = style? style : {'padding' : '0.4rem 0.6rem'};
    var _svgpath = svg;
    var _size = 'fs-5';
    switch(label)
    {
        case 'EDIT' :
          _svgpath='/media/icons/duotune/art/art005.svg'
          _tooltip = 'Edit';
          break;
        case 'DELETE' :
          icon = 'fa fa-trash';
          _tooltip = 'Delete'; 
           break;
        case 'ADD' :
          _svgpath='/media/icons/duotune/general/gen041.svg'
          _tooltip = 'Add'; 
            break;
        case 'VIEW' :
          _svgpath='/media/icons/duotune/general/gen005.svg'
         _tooltip = 'View';
          break;
    }
    if(_svgpath !== undefined)
    {
      _styleicon = style? {height:'1.5rem',width:'1.5rem'} : {...style,...{height:'1.5rem',width:'1.5rem'}};
    }

    return (
      <>
          <OverlayTrigger
            placement={'bottom'}
            overlay={
              <Tooltip id={`tooltip-${'bottom'}-${Math.floor(Math.random()*1000)}`}>{_tooltip}</Tooltip>
            }
          >
            <span>
          {_svgpath !== undefined && (<div className={'border border-gray-500 bg-light bg-hover-white text-hover-primary border-hover-primary svg-icon m-1 svg-icon-gray-600 '+className} style={_stylesvg}> 
                  <SVG src={toAbsoluteUrl(_svgpath)}  style={_styleicon} /></div>
          )}
          {icon !== undefined && (
            <div className={'border border-gray-500 bg-light bg-hover-white text-hover-primary border-hover-primary m-1 '+className} style={_styleicon}> 
            <i className={icon +' '+ _size +' text-gray-600'}></i>
            </div>)}
            {children !== undefined && (
            <div className={'border border-gray-500 bg-light bg-hover-white text-hover-primary border-hover-primary m-1 '+className}  style={_styleicon}> 
            <span className={'text-gray-600'}>{children}</span>
            </div>)}

        </span>
          </OverlayTrigger>
      </>
    );
}

const LeftMenuSvgIcon : React.FC<{icon : string}> = ({icon}) =>{
var _icon = icon
_icon = _icon.replace(/fa /gi, "");
_icon = _icon.replace(/fas /gi, "");
_icon = _icon.replace(/far /gi, "");
_icon = _icon.replace(/fal /gi, "");

 var _svg = '/media/icons/duotune/general/gen017.svg';
 switch(_icon)
 {
  case 'fa-home': _svg = '/media/icons/duotune/general/gen017.svg' ;break; 
  case 'fa-plus-circle': _svg='/media/icons/duotune/general/gen041.svg';break; 
  case 'fa-user-md': _svg='/media/icons/duotune/communication/com006.svg';break; 
  case 'fa-hospital': _svg='/media/icons/duotune/medicine/med001.svg';break; 
  case 'fa-gem': _svg='/media/icons/duotune/abstract/abs025.svg';break; 
  case 'fa-tty': _svg='/media/icons/duotune/general/gen014.svg';break; 
  case 'fa-calendar': _svg='/media/icons/duotune/abstract/abs042.svg';break; 
  case 'fa-star': _svg='/media/icons/duotune/general/gen022.svg';break; 
  case 'fa-cog': _svg='/media/icons/duotune/abstract/abs037.svg';break; 
  case 'fa-users': _svg='/media/icons/duotune/general/gen047.svg';break; 
  case 'fa-cogs': _svg='/media/icons/duotune/general/gen019.svg';break; 
  case 'fa-file-alt': _svg='/media/icons/duotune/general/gen005.svg';break;
 }
 return (
  <span className='menu-icon me-0'>
    <KTSVG path={_svg} className='svg-icon-2' />
  </span>
)
}

export {ActionIcon,LeftMenuSvgIcon}
