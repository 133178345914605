import axios, {} from 'axios'
import { env } from '../../App.env'
import { BroadcastMessage } from '../operations/broadcast-messages/_models'
import { appointmentCount } from './_models'
const API_URL = env.base_api_url

 export function fetchMessageForClinicLogin() {
  return axios.get<{broadcastMessagesse : Array<BroadcastMessage>}>(`${API_URL}/Api/ClinicalSettings/Broadcast/fetchMessageForClinicLogin`)
 } 

 
 export function fetchDashboardCount() {
    return axios.get<{appointmentCount : appointmentCount}>(`${API_URL}/Api/ClinicManager/Preadmission/fetchDashboardCount?id=0`)
   } 