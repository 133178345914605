import { Auditable } from "../../models/global/Auditable"
import * as Yup from 'yup';
import { DatatableInput } from "../../models/global/DataTable";
import { decode, encode } from "html-entities";
import { clone } from "../../services";

export const SalutationList = [
  {"id":"Dr","name":"Dr"},
  {"id":"Mdm","name":"Mdm"},
  {"id":"Miss","name":"Miss"},
  {"id":"Mr","name":"Mr"},
  {"id":"Mrs","name":"Mrs"},
  {"id":"Ms","name":"Ms"},
  {"id":"Mstr","name":"Mstr"},
  {"id":"Prof","name":"Prof"},
];

export const TimePeriod = [
  {"id":"AM","name":"AM"},
  {"id":"PM","name":"PM"}
];

  export class Appointmentsourceofrequest extends Auditable{
      id: number=0;
      name?: string;
      orders: number=0;
      
  }



  export class AppointmentReq extends Auditable{
      id?: number | null;
      appointmentrequestno?: string;
      requestedclinicid?: number|null;
      requestedClinicCode?: string;
      requesteddoctorclinicmapid?: number;
      patientpolicynumber?: string;
      insuredsalutation?: string;
      insuredfirstname: string="";
      insuredlastname?: string;
      insurednric: string = "";
      nric: string = "";
      insureddateofbirth?: string;
      policyholderfirstname?: string;
      policyholderlastname?: string;
      policyholdernric: string="";
      sourceofrequestid: number=0;
      appointmentsourceofrequest?: Appointmentsourceofrequest;
      sourceofrequestother?: string;
      dateofsubmission?: string;
      submissiontimeHour?: string="12";
      submissiontimemins?: string="00";
      submissiontimeperiod?: string="AM";
      relationshipofrequestorid?: string;
      relationshipofrequestor?: string;
      nameofrequestor?: string;
      requestornric: string="";
      otherrelationshipofrequestor?: string;
      isinsuredanaiaagent?: number = 0;
      nameofagent?: string;
      nameofagency?: string;
      preferedphonenumber?: string;
      preferedphoneCountryCode?: string;
      contactmatchwithdatabase?: number;
      requestedspecialistname?: string|null;
      requestedspecialistmcrno?: string|null;
      requestedspecialty?: string;
      requestedclinicname?: string|null;
      requestedclinicaddress?: string|null;
      requestedhospitallocation?: string;
      requestedhospitalId?: number|null;
      requestedcliniccontactno?: string|null;
      requestoption1date?: string;
      requestoption1slot?: string;
      requestoption2date?: string;
      requestoption2slot?: string;
      visitdoctorinotherclinic: number| boolean = 0;
      additionalinformation?: string;
      confirmedclinicid?: number|null;
      confirmeddoctorclinicmapid?: number|null;
      confirmedspecialistname?: string|null;
      confirmedspecialistmcrno?: string|null;
      confirmedspecialty?: string|null;
      confirmedclinicname?: string|null;
      confirmedcliniccode?: string|null;
      confirmedclinicaddress?: string|null;
      confirmedhospitallocation?: string;
      confirmedhospitalId: number=0;
      confirmedcliniccontactno?: string|null;
      confirmedappointmentdate: string='';
      confirmedappointmenttimehour?: string;
      confirmedappointmenttimemins?: string;
      confirmedappointmenttimeperiod?: string;
      amendedpreferredcontactno?: string;
      amendedpreferredCountryCode?: string;
      patienttype?: string;
      patienttypeName?: string;
      casemanagerid?: number;
      caseManagerName?: string;
      internalremarks?: string;
      tosendsms?: boolean;
      status?: string;
      statusName: string="";
      statusId: number=0;
      statusRecordId?: number;
      canCancel?: boolean;
      canChangeAppt?: boolean;
      canProcess?: boolean;
      rowId?: number;
      modifiedbyname?: string;
      dateTaskCompleted?: string;
      timeTaskCompleted?: string;
      surveyStatus?: string;
      notificatitionStatus?: string;
      notificationStatusName?: string;
      surveyStatusname?: string;
      surveyExist?: boolean;
      statusupdatesms?: string;
      clinicMainEmail?: string;
      clinicConDetailEmail?: string;
      clinicConPersonemail?: string;
      doctroEmail?: string;
      isSmsChecked:boolean=false;
      selectedSpeciality?: number ;
      
  }

  export function AppointmentReqEscapeHtml(_data:AppointmentReq,type:string){

    let data = clone(_data);
    data.insuredfirstname = (type=="decode") ?decode(data.insuredfirstname):encode(data.insuredfirstname); 
    data.insuredlastname = (type=="decode") ?decode(data.insuredlastname):encode(data.insuredlastname); 
    data.policyholderfirstname = (type=="decode") ?decode(data.policyholderfirstname):encode(data.policyholderfirstname); 
    data.policyholderlastname = (type=="decode") ?decode(data.policyholderlastname):encode(data.policyholderlastname); 
    data.nameofrequestor = (type=="decode") ?decode(data.nameofrequestor):encode(data.nameofrequestor); 
    data.requestedspecialty = (type=="decode") ?decode(data.requestedspecialty):encode(data.requestedspecialty); 
    data.requestedspecialistname = (type=="decode") ?decode(data.requestedspecialistname):encode(data.requestedspecialistname); 
    data.requestedclinicname = (type=="decode") ?decode(data.requestedclinicname):encode(data.requestedclinicname); 
    data.requestedclinicaddress = (type=="decode") ?decode(data.requestedclinicaddress):encode(data.requestedclinicaddress); 
    data.additionalinformation = (type=="decode") ?decode(data.additionalinformation):encode(data.additionalinformation);
    data.confirmedspecialty = (type=="decode") ?decode(data.confirmedspecialty):encode(data.confirmedspecialty); 
    data.confirmedspecialistname = (type=="decode") ?decode(data.confirmedspecialistname):encode(data.confirmedspecialistname); 
    data.confirmedclinicname = (type=="decode") ?decode(data.confirmedclinicname):encode(data.confirmedclinicname);  
    data.confirmedclinicaddress = (type=="decode") ?decode(data.confirmedclinicaddress):encode(data.confirmedclinicaddress); 
    data.internalremarks = (type=="decode") ?decode(data.internalremarks):encode(data.internalremarks); 


    return data;
  }


  export class Specialist {
    id:number=0;
    name?: string|null="";
    mcr?: string|null="";
    

    constructor(id:number,name?:string|null,mcr?:string|null)
    {
        this.id = id
        this.name = name
        this.mcr = mcr
    }
}

export class ApptCancellation{
  appointmentrequestForm:AppointmentReq= new AppointmentReq();
  parentapptrequestid?:number|null;
  sourceofrequestid:number=0;
  cancellationremark?:string
}

export class ApptSmsData{
  content?:string;
  mobile?:string
}



export class ApptSMSTemplate {
  defaultMessage?:string;
  appointmentrequestForm:AppointmentReq = new AppointmentReq();
}

export class ApptSmsReq{
  parentappointmentrequestid?:number;
  parentsmstypeid?:string;
  countryCode:string="";
  smsphonenumber:string="";
  smstemplatemessage:string=""
  smsadditionalmessage:string=""
  parentappointmentsmsstatusid?: string;
}

export class AppointmentSearchForm {

    dataTablesInput : DatatableInput;
    type : string = ""
    value : string = ""
    fromDate: string = ""
    toDate: string = ""
    status: string = ""
    caseManager: string = ""
    caseManagerId:number=0;
    dateType:number=0
  
    constructor(dataTablesInput : DatatableInput,type:string,value:string)
    {
        this.dataTablesInput = dataTablesInput
        this.type = type
        this.value = value
       
    }

   
  }

  export class SearchForm {
    type : string = ""
    value : string = ""
    status : string = ""
    caseManager: string = ""
    caseManagerId?: number|null
    dateType:number=0

  
    constructor(type:string,value:string,status:string)
    {
        this.type = type
        this.value = value
        this.status = status
    }

   
  }


export class RequestStatusList extends Auditable{
    id: number=0;
    name: string="";
}

export class RelationshipOfReqList extends Auditable{
  id: number=0;
  name: string="";
    
}

export class PatientTypelist extends Auditable{
  id: number=0;
  name: string="";
   
}

export class SourceOfRequestList extends Auditable{
  id: number=0;
  name: string="";
  orders: number=0;
   
}

export class RequestAllStatus extends Auditable{
  id: number=0;
  name: string="";
   
}

export class ApptSmsType extends Auditable{
  id: number=0;
  name: string="";
}

export class ApptSource {
    requestStatusList: RequestStatusList[]=[];
    relationshipOfReqList: RelationshipOfReqList[]=[];
    patientTypelist: PatientTypelist[]=[];
    sourceOfRequestList: SourceOfRequestList[]=[];
    requestAllStatus: RequestAllStatus[]=[];
    caseManagerList: CaseManager[]=[];
}

export class DoctorclinicmapForm extends Auditable{
  id?: number;
  clinic: number=0;
  clinicName?: string;
  clinicCode?: string;
  doctor: number=0;
  doctorName?: string;
  effectivedate?: string;
  terminationdate?: string;
  doctorspecialityMapID?: number;
  internalRemarks?: string;
  mcr?: string;
  email?: string;
  publishdate?: string;
  selectedSpeciality?: number;
  specialtyList?: string;
  taskReferenceID?: number;
  specialtyID: number=0;
  displayRemarks?: string;
  terminationDateStatus?: string;
  specialtyName?: string;
  fromDateFlag?: string;
  toDateFlag?: string;
  publishDateFlag?: string;
  selectedSpecialityMapId?: string;
  hospitalName?: string;
  disableFromDate?: string;
  disableToDate?: string;
}

export class DoctorclinicmapAppt {
  clinicAddress?: string;
  hospitalId: number=0;
  requestSuccess?: boolean;
  hospitalLocation?: string;
  clinicContactNumber?: string;
  doctorclinicmapForm: DoctorclinicmapForm = new DoctorclinicmapForm();
}

export interface CaseManager{
  id:number;
  username : string;
  name:string;
}


export interface AppointmentSms extends Auditable{
  id: number;
  appointmentRequestId?: string;
  smsdate: string;
  preferedPhoneNumber: string;
  smsmessage: string;
  smsstatus?: string;
  createdUserName: string;
  appointmentrequestForm: AppointmentReq;

  
}

export interface AppointmentSmsGrid{
  rowId:                    number;
  id:                       number;
  preferedPhoneNumber:      string;
  smsdate:                  string;
  smsmessage:               string;
  apptId:                   number;
  appointmentrequestno:     string;
  confirmedspecialistname:  string;
  confirmedclinicname:      string;
  insurednric:              string;
  insuredfirstname:         string;
  caseManagerName:          string;
  confirmedappointmentdate: string;
  sentby:                   string;
}

export interface AppointmentSmsNotSentGrid{
  rowId: number;
  apptId: number;
  dateofsubmission: string;
  appointmentrequestno: string;
  confirmedspecialistname: string;
  confirmedclinicname: string;
  insurednric: string;
  insuredfirstname: string;
  caseManagerName: string;
  status: string;
}



export class AssignCaseManager {
  id?: number|null;
  casemanagerid?:number;
  
}


export interface CancelledAppointment extends Auditable{
  id: number;
  parentapptrequestid?: string;
  sourceofrequestid?: string;
  sourceofrequestother?: string;
  cancellationrequestno: string;
  submitteddate: string;
  cancellationremark?: string;
  appointmentrequestForm: AppointmentReq;
}

export class LiveSearchReq{
  doctorId?: number|null;
  hospitalId?: number|null;
  specialtyId?: number|null;
  spid?: number|null;
  clinicId?: number|null;
  specialityName?: string|null;
  confirmedApptDate?:string|null;
  specialityMcr?:string|null;
  requesteddoctorclinicmapid?: number|null;
} 


  
  export const ApptValidationSchema = Yup.object({

    nric: Yup.string()
      .max(16, 'Nric Must be 16 characters or less')
      .required('Please Enter Insurer Nric'),

    sourceofrequestid: Yup.number()
      .moreThan(0, 'Please Select Source of request'),

    dateofsubmission: Yup.string()
      .required('Please Enter Submission Date'),

    submissiontimeHour: Yup.number().required('Please Select Submission Hour.').moreThan(0, 'Please Select Submission Hour.'),
    submissiontimemins: Yup.string().required('Please Select Submission Mins.').length(2, 'Please Select Submission Mins.'),
    submissiontimeperiod: Yup.string().required('Please Select Submission Period.').matches(/[AM|PM]/, 'Please Select Submission Period.'),
    

    preferedphonenumber: Yup.string()
      .min(8, 'Min Phone number 8 digit')
      .max(16, 'Max Phone number 16 digit')
      .required('Please Enter Prefered Phone Number'),

      requestoption1date: Yup.string()
      .required('Please Enter Appointment Option 1 Date'),

      requestoption1slot: Yup.string()
      .required('Please Select Appointment Option 1 Slot').matches(/[AM|PM]/, 'Please Select Appointment Option 1 Slot.'),

      requestoption2slot: Yup.string().nullable()
      .when("requestoption2date", {
        is: undefined,
        then: Yup.string().nullable(),
        otherwise: Yup.string().required('Please Enter Appointment Option 2 Slot').matches(/[AM|PM]/, 'Please Select Appointment Option 2 Slot.'),
      })

      

  })

  export const ApptConfirmValidationSchema = Yup.object({

  statusId: Yup.number().moreThan(0, 'Please Select Appointment Status.'),
  

    confirmedappointmenttimehour: Yup.number()
    .when('confirmedappointmentdate', {
      is: undefined,
      then: Yup.number().nullable(),
      otherwise: Yup.number().required('Please Select Confirm Hour.').moreThan(0, 'Please Select Confirm Hour.')           
    }),

    confirmedappointmenttimemins: Yup.string()
    .when('confirmedappointmentdate', {
      is: undefined,
      then: Yup.string().nullable(),
      otherwise: Yup.string().required('Please Select Confirm Mins.').length(2, 'Please Select Confirm Mins.'),         
    }),

    confirmedappointmenttimeperiod: Yup.string()
    .when('confirmedappointmentdate', {
      is: undefined,
      then: Yup.string().nullable(),
      otherwise: Yup.string().required('Please Select Confirm Period.').matches(/[AM|PM]/, 'Please Select Confirm Period.')          
    }),

    confirmedappointmentdate: Yup.string()
    .when('statusId', {
      is: (statusId:number) => statusId===4,
      then: Yup.string().required('Confirmed Appointment Date is required').nullable(),   
      otherwise: Yup.string().nullable()
    }),


    confirmedhospitalId: Yup.number()
    .when('statusId', {
      is: (statusId:number) => statusId===4,
      then: Yup.number().moreThan(0, 'Please Select Confirmed Hospital.'), 
      otherwise: Yup.number()
    }),

    confirmedspecialistname: Yup.string()
    .when('statusId', {
      is: (statusId:number) => statusId===4,
      then: Yup.string().required('Confirmed Specialist Name is required'),   
      otherwise: Yup.string().nullable()
    }),

    confirmedspecialistmcrno: Yup.string()
    .when('statusId', {
      is: (statusId:number) => statusId===4,
      then: Yup.string().required('Confirmed Specialist MCR is required'),   
      otherwise: Yup.string().nullable()
    }),

    confirmedspecialty: Yup.string()
    .when('statusId', {
      is: (statusId:number) => statusId===4,
      then: Yup.string().required('Confirmed Specialty is required'),   
      otherwise: Yup.string().nullable()
    }),

    confirmedclinicid: Yup.number()
    .when('statusId', {
      is: (statusId:number) => statusId===4,
      then: Yup.number().moreThan(0, 'Please Select Confirmed Clinic.'), 
      otherwise: Yup.number()
    }),


    /*
    amendedpreferredCountryCode: Yup.string().nullable()
      .when("amendedpreferredcontactno", {
        is: (amendedpreferredcontactno:string) => amendedpreferredcontactno === undefined || amendedpreferredcontactno ===null || amendedpreferredcontactno ==='' ,
        then: Yup.string().nullable(),
        otherwise: Yup.string().required('Please Select Country Code'),
      }),

    */

   
 });


  export const ApptSmsValidationSchema = Yup.object({

    parentsmstypeid: Yup.string()
      .required('Please Select SMS Type.'),

      smstemplatemessage: Yup.string()
      .required('Please Enter Template Message.'),

      smsphonenumber: Yup.string()
      .min(8, 'Min Phone number 8 digit')
      .max(15, 'Max Phone number 15 digit')
      .required('Please Enter Phone number.'),

      countryCode: Yup.number().moreThan(0, 'Please Select Country Code'),

  })

  export const ApptCancellationValidation = Yup.object({

 
    sourceofrequestid: Yup.number()
      .moreThan(0, 'Please Select Source of request'),

  })
  