import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { KTCard, KTCardBody, KTSVG, toAbsoluteUrl } from "../../../../_theme/helpers";
import { KTInputLabel, Loading } from "../../../services";
import { BroadcastMessage } from "../../operations/broadcast-messages/_models";
import { fetchMessageForClinicLogin } from "../_requests";
import { encode as base64_encode} from 'base-64';
import { useNavigate } from "react-router-dom";
export function ClinicUserDashboard () {
    const [isLoading,SetLoading] = useState(true);
    const [Message,SetBroadcastMessage] = useState<BroadcastMessage[]>([])
    const [vMessage,SetViewMessage] = useState<BroadcastMessage>(new BroadcastMessage())    
    const [isAlert,SetAlert] = useState(false)
    const navigate = useNavigate();
  useEffect(()=>{

    var _pre = sessionStorage.getItem(base64_encode('_PRE_AUTHNUMBER'));
    if(_pre !== null){
        navigate("/ClinicAssistant/PreAuthDecisionAndLetter");
    }

    fetchMessageForClinicLogin().then(res=>{
        SetBroadcastMessage(res.data.broadcastMessagesse);
        SetLoading(false);
    })
  return ()=>{ 
  }
  // eslint-disable-next-line
 },[])

 const viewMessage = (message : BroadcastMessage) =>{
    SetAlert(true);
    SetViewMessage(message);
 }
 const closeMessage = () =>{
    SetAlert(false);
 }

 return (
    <>
        {isLoading && (<Loading/>)}
        <div className="d-flex flex-column flex-md-row">
            <div className={Message.length >0 ? "col-12 col-md-8 col-xl-9 mb-4 mb-md-0 me-md-4" : "col-md-12"} >         
                    <KTCard className="d-flex flex-center flex-column flex-column-fluid h-100 fs-1" >                 
                        <img src={toAbsoluteUrl('/media/image/AIAHomePage.png')} alt='' className="w-100 d-none" style={{'maxWidth' : '900px'}}/>
                        
                        <p style={{ fontSize: 50 }}>Welcome to</p>
                        <h1 style={{ fontSize: 70 }}>AIA Provider Portal</h1>

                    </KTCard>
            </div>
            {Message.length > 0 && (
                <div className="col-12 col-md-4 col-xl-3">
                    <KTCard className="p-4 h-100">
                        <div className="card-title py-2 pb-5 m-2 border-bottom">
                            <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-2 me-2 text-brand' />
                            <span className="card-label fw-bolder mb-1 text-brand fs-6">NOTIFICATIONS</span>
                        </div>
                        <KTCardBody className="mh-md-500px mh-lg-550px mh-xl-600px mh-xxl-650px overflow-auto">
                            <div>
                                {
                                    Message.map(x=>(
                                        <div key={x.id} className={'pb-2 my-2'}>
                                            <div className="fw-bolder">{x.messagetitle}</div>
                                            <div className="py-1 my-1">{x.shortMessage}</div>
                                            <div className="d-flex flex-row-reverse">
                                                <button type='button' className="btn btn-sm btn-secondary py-1 fs-8" onClick={()=>viewMessage(x)}>VIEW</button>
                                            </div>
                                            <div className="separator my-3"></div>
                                        </div>
                                    ))
                                }
                            </div>
                        </KTCardBody>
                    </KTCard>
                </div>
            )}
            {isAlert && <>
                <Modal className='modal fade' id='kt_modal_message' data-backdrop='static' tabIndex={-1} role='dialog' show={isAlert}
                    dialogClassName='modal-xl'
                    aria-hidden='true'
                    >
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title ms-2'>VIEW NOTIFICATION</h5>
                            <button
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2 btn-close' onClick={closeMessage}
                                aria-label="Close"
                            >
                            </button>
                        </div>
                        <div className='modal-body'>
                            <div className="mb-3">
                                <KTInputLabel title="Message Title" className="fw-bolder"/>
                                <div className="col-md-12 form-control display-box ">{vMessage.messagetitle}</div>
                            </div>
                            <div className="mb-3">
                                <KTInputLabel title="Short Message" className="fw-bolder"/>
                                <div className="col-md-12 form-control display-box ">{vMessage.shortMessage}</div>
                            </div>
                            <div>
                                <KTInputLabel title="Message" className="fw-bolder"/>
                                <div className="form-control display-box ">
                                    <div dangerouslySetInnerHTML={{__html:vMessage.message?vMessage.message:''}}></div>
                                </div>
                            </div>

                            </div>
                        <div className='modal-footer py-4'>
                        <button type='button' className='btn btn-sm btn-secondary' onClick={closeMessage}>
                            CLOSE
                        </button>
                        </div>
                    </div>
                    </Modal>
            
            </>}
        </div>

    </>
    )
}
