import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { KTCard, KTCardBody, KTCardHead } from "../../../../_theme/helpers";
import { Loading } from "../../../services";
import { appointmentCount } from "../_models";
import { fetchDashboardCount } from "../_requests";

export function AppointmentDashboard () {
    const [isLoading,SetLoading] = useState(true);
    const [vAppointmentCount,SetappointmentCount] = useState<appointmentCount>(new appointmentCount())    
    const navigate = useNavigate();
  useEffect(()=>{
    fetchDashboardCount().then(res=>{
        SetappointmentCount(res.data.appointmentCount);
        SetLoading(false);
    })
  return ()=>{ 
  }
 },[])

 const navigateTo = (url:string) =>{
    navigate(url);
 }


 return (
    <>
        {isLoading && (<Loading/>)}
            <KTCard>
                <KTCardHead title="Appointment">
                </KTCardHead>
                <KTCardBody className="mh-md-500px h-100">
                    <div className="row">
                        <div className="col-sm-5 col-md-4 col-lg-3 col-xxl-2 bg-light-primary p-8 m-sm-5 my-3 hover-shadow-sm hoverable border border-hover-primary border-light"
                            onClick={()=>navigateTo('/Appointments/New')}>
                            <div className="d-block fs-1 fw-bold text-primary ">
                                {vAppointmentCount.newc}
                            </div>
                            <div className="fw-bold fs-5 text-primary text-uppercase">NEW</div>
                        </div>
                        <div className="col-sm-5 col-md-4 col-lg-3 col-xxl-2 bg-light-warning p-8 m-sm-5 my-3 hover-shadow-sm hoverable border border-hover-warning border-light"
                            onClick={()=>navigateTo('/Appointments/Pending')}>
                            <div className="d-block fs-1 fw-bold text-warning ">
                                {vAppointmentCount.pending}
                            </div>
                            <div className="fw-bold fs-5 text-warning text-uppercase">PENDING</div>
                        </div>
                        <div className="col-sm-5 col-md-4 col-lg-3 col-xxl-2 bg-light-success p-8 m-sm-5 my-3 hover-shadow-sm hoverable border border-hover-success border-light"
                            onClick={()=>navigateTo('/Appointments/MyOwn')}>
                            <div className="d-block fs-1 fw-bold text-success ">
                                {vAppointmentCount.myown}
                            </div>
                            <div className="fw-bold fs-5 text-success text-uppercase">MY OWN</div>
                        </div>
                        <div className="col-sm-5 col-md-4 col-lg-3 col-xxl-2 bg-light-danger p-8 m-sm-5 my-3 hover-shadow-sm hoverable border border-hover-danger border-light"
                            onClick={()=>navigateTo('/Appointments/Cancellation')}>
                            <div className="d-block fs-1 fw-bold text-danger ">
                                {vAppointmentCount.cancellationreq}
                            </div>
                            <div className="fw-bold fs-5 text-danger text-uppercase">Cancellation</div>
                        </div>
                    </div>
                </KTCardBody>
            </KTCard>
    </>
    )
}
