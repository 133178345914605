import { env } from "../../App.env";
import { UserModel } from "../../models";
import { RoutePathAccess, RoutePathFilter, RoutePaths } from "./RoutePath";
import { UserType } from "./UserType";

export function RouteAccess(currentUser : UserModel,pathname : string)
  {
    var _flag = false;
    var url= pathname.split(/[?#]/)[0];
    if(!env.release)
        console.log('Current path : '+url);
    if(currentUser?.profilemasterName)
    {
       var _URA = UserRouteAccess.filter(p=>p.PROFILE.toUpperCase() === currentUser?.profilemasterName.toUpperCase());
       if(_URA.length > 0)
       {
        var URA = _URA[0];
        URA.PATHS.map(r=>(
            _flag = url.toUpperCase() === r.toUpperCase() || url.toUpperCase().startsWith(r.toUpperCase()) ? true : _flag
        ))
       }
    }
    if(!_flag && currentUser?.menuList)
    {
        // include url to allow before redirection (exact match)
        // route access validate after redirection
        var _ulrs = ['/Clinic/','/Doctor/','/Hospital/','/AdminUser/']
        _ulrs.map(r=>(
            _flag = r.toUpperCase() === url.toUpperCase() ? true : _flag
        ))
    }
    if(!_flag)
        console.log('Unauthorized Access : ' + url);
    return _flag;
  }

const UserRouteAccess : {PROFILE : string , PATHS : string[]}[] = [
    { 
        PROFILE : UserType.ADMINUSER.PROFILE.SUPER,
        PATHS   : RoutePathAccess([
            new RoutePathFilter('ALL',RoutePaths.USER),
            new RoutePathFilter('ALL',RoutePaths.CLINIC),
            new RoutePathFilter('ALL',RoutePaths.DOCTOR),
            new RoutePathFilter('ALL',RoutePaths.HOSPITAL),
            new RoutePathFilter('ALL',RoutePaths.MEMBER),
            new RoutePathFilter('ALL',RoutePaths.APPOINTMENT),
            new RoutePathFilter('ALL',RoutePaths.OPERATION),
            new RoutePathFilter('ALL',RoutePaths.CLINICAL_SETTINGS),
            new RoutePathFilter('ALL',RoutePaths.RATING_SETTINGS),
            new RoutePathFilter('ALL',RoutePaths.ADMINUSER),
            new RoutePathFilter('ALL',RoutePaths.SETTINGS),
            new RoutePathFilter('ALL',RoutePaths.REPORT),
            new RoutePathFilter('VR',RoutePaths.VOUCHER),
        ])
    },
    { 
        PROFILE : UserType.ADMINUSER.PROFILE.MASTER,
        PATHS   : RoutePathAccess([
            new RoutePathFilter('ALL',RoutePaths.USER),
            new RoutePathFilter('ALL',RoutePaths.CLINIC),
            new RoutePathFilter('ALL',RoutePaths.DOCTOR),
            new RoutePathFilter('ALL',RoutePaths.HOSPITAL),
            new RoutePathFilter('ALL',RoutePaths.MEMBER),
            new RoutePathFilter('ALL',RoutePaths.APPOINTMENT),
            new RoutePathFilter('ALL',RoutePaths.OPERATION),
            new RoutePathFilter('ALL',RoutePaths.CLINICAL_SETTINGS),
            new RoutePathFilter('ALL',RoutePaths.RATING_SETTINGS),
            new RoutePathFilter('ALL',RoutePaths.ADMINUSER),
            new RoutePathFilter('ALL',RoutePaths.SETTINGS),
            new RoutePathFilter('ALL',RoutePaths.REPORT),
            new RoutePathFilter('VR',RoutePaths.VOUCHER)
        ])
    },
    { 
        PROFILE : UserType.ADMINUSER.PROFILE.PORTAL,
        PATHS   : RoutePathAccess([
            new RoutePathFilter('ALL',RoutePaths.USER),
            new RoutePathFilter('ALL',RoutePaths.CLINIC),
            new RoutePathFilter('ALL',RoutePaths.DOCTOR),
            new RoutePathFilter('ALL',RoutePaths.HOSPITAL),
            new RoutePathFilter('ALL',RoutePaths.OPERATION),
            new RoutePathFilter('ALL',RoutePaths.CLINICAL_SETTINGS),
            new RoutePathFilter('ALL',RoutePaths.RATING_SETTINGS),
            new RoutePathFilter('ALL',RoutePaths.ADMINUSER),
            new RoutePathFilter('ALL',RoutePaths.SETTINGS),
            new RoutePathFilter('RS-SQ-RP-SP',RoutePaths.REPORT),
            new RoutePathFilter('VR',RoutePaths.VOUCHER)
        ])
    },
    { 
        PROFILE : UserType.CLINICUSER.PROFILE.MANAGER,
        PATHS   : RoutePathAccess([
            new RoutePathFilter('HO-PO',RoutePaths.USER),
            new RoutePathFilter('ALL',RoutePaths.CLINIC_LOGIN)
        ])
    },
    { 
        PROFILE : UserType.CLINICUSER.PROFILE.ASSISTANT_READONLY,
        PATHS   : RoutePathAccess([
            new RoutePathFilter('HO-PO',RoutePaths.USER),
            new RoutePathFilter('CP-CS-SM-PL-LC',RoutePaths.CLINIC_LOGIN)
        ])
    },
    { 
        PROFILE : UserType.CLINICUSER.PROFILE.ASSISTANT_WRITE,
        PATHS   : RoutePathAccess([
            new RoutePathFilter('HO-PO',RoutePaths.USER),
            new RoutePathFilter('CP-CS-SM-PL-LC',RoutePaths.CLINIC_LOGIN)
        ])
    },
    { 
        PROFILE : UserType.CLINICUSER.PROFILE.HEALTH_SCREENING_ASSISTANT,
        PATHS   : RoutePathAccess([
            new RoutePathFilter('HO-PO',RoutePaths.USER),
            new RoutePathFilter('CP-CS-SM-PL-LC',RoutePaths.CLINIC_LOGIN),
            new RoutePathFilter('VE',RoutePaths.VOUCHER)
        ])
    },
    { 
        PROFILE : UserType.CLINICUSER.PROFILE.HEALTH_SCREENING_MANAGER,
        PATHS   : RoutePathAccess([
            new RoutePathFilter('HO-PO',RoutePaths.USER),
            new RoutePathFilter('CP-CS-SM-PL-LC',RoutePaths.CLINIC_LOGIN),
            new RoutePathFilter('VE',RoutePaths.VOUCHER),
            new RoutePathFilter('SV',RoutePaths.REPORT),
        ])
    },
    { 
        PROFILE : UserType.CLINICUSER.PROFILE.CLINIC_ASSISTANT_WITH_HEALTH_SCREENING,
        PATHS   : RoutePathAccess([
            new RoutePathFilter('HO-PO',RoutePaths.USER),
            new RoutePathFilter('CP-CS-SM-PL-LC',RoutePaths.CLINIC_LOGIN),
            new RoutePathFilter('VE',RoutePaths.VOUCHER),
            
        ])
    },
    { 
        PROFILE : UserType.CLINICUSER.PROFILE.CLINIC_MANAGER_WITH_HEALTH_SCREENING,
        PATHS   : RoutePathAccess([
            new RoutePathFilter('HO-PO',RoutePaths.USER),
            new RoutePathFilter('CP-CS-SM-PL-LC',RoutePaths.CLINIC_LOGIN),
            new RoutePathFilter('VE',RoutePaths.VOUCHER),
            new RoutePathFilter('SV',RoutePaths.REPORT),
        ])
    },
    { 
        PROFILE : UserType.CARETEAM.PROFILE.MANAGER_SUPERVISOR,
        PATHS   : RoutePathAccess([
            new RoutePathFilter('HO-PO',RoutePaths.USER),
            new RoutePathFilter('ALL',RoutePaths.MEMBER),
            new RoutePathFilter('LI-VW',RoutePaths.CLINIC),
            new RoutePathFilter('LI-VW',RoutePaths.DOCTOR),
            new RoutePathFilter('AL-A5-AS-SY',RoutePaths.REPORT)
        ])
    },
    { 
        PROFILE : UserType.CARETEAM.PROFILE.MANAGER_SUPERVISOR_APPT,
        PATHS   : RoutePathAccess([
            new RoutePathFilter('HO-PO',RoutePaths.USER),
            new RoutePathFilter('ALL',RoutePaths.MEMBER),
            new RoutePathFilter('LI-VW',RoutePaths.CLINIC),
            new RoutePathFilter('LI-VW',RoutePaths.DOCTOR),
            new RoutePathFilter('ALL',RoutePaths.APPOINTMENT),
            new RoutePathFilter('AL-A5-AS-SY',RoutePaths.REPORT)
        ])
    },
    { 
        PROFILE : UserType.CARETEAM.PROFILE.MEMBER,
        PATHS   : RoutePathAccess([
            new RoutePathFilter('HO-PO',RoutePaths.USER),
            new RoutePathFilter('ALL',RoutePaths.MEMBER),
            new RoutePathFilter('LI-VW',RoutePaths.CLINIC),
            new RoutePathFilter('LI-VW',RoutePaths.DOCTOR)
        ])
    },
    { 
        PROFILE : UserType.CARETEAM.PROFILE.MEMBER_APPT,
        PATHS   : RoutePathAccess([
            new RoutePathFilter('HO-PO',RoutePaths.USER),
            new RoutePathFilter('ALL',RoutePaths.MEMBER),
            new RoutePathFilter('LI-VW',RoutePaths.CLINIC),
            new RoutePathFilter('LI-VW',RoutePaths.DOCTOR),
            new RoutePathFilter('ALL',RoutePaths.APPOINTMENT)
        ])
    },
    { 
        PROFILE : UserType.CLAIMSTEAM.PROFILE.TEAM,
        PATHS   : RoutePathAccess([ 
            new RoutePathFilter('HO-PO',RoutePaths.USER),
            new RoutePathFilter('ALL',RoutePaths.MEMBER)
        ])
    },
    { 
        PROFILE : UserType.CALLCENTER.PROFILE.TEAM,
        PATHS   : RoutePathAccess([ 
            new RoutePathFilter('HO-PO',RoutePaths.USER),
            new RoutePathFilter('CC-VW',RoutePaths.APPOINTMENT)
        ])
    },
    { 
        PROFILE : UserType.APPTTEAM.PROFILE.SUPERVISOR_APPT,
        PATHS   : RoutePathAccess([
            new RoutePathFilter('HO-PO',RoutePaths.USER),
            new RoutePathFilter('LI-VW-UL-UV',RoutePaths.CLINIC),
            new RoutePathFilter('LI-VW',RoutePaths.DOCTOR),
            new RoutePathFilter('LI-VW',RoutePaths.HOSPITAL),            
            new RoutePathFilter('ALL',RoutePaths.MEMBER),
            new RoutePathFilter('ALL',RoutePaths.APPOINTMENT), 
            new RoutePathFilter('WL-DC-CD-AL-A5-AS-SY',RoutePaths.REPORT)
        ])
    },
    
    { 
        PROFILE : UserType.APPTTEAM.PROFILE.USERS_APPT,
        PATHS   : RoutePathAccess([
            new RoutePathFilter('HO-PO',RoutePaths.USER),
            new RoutePathFilter('LI-VW-UL-UV',RoutePaths.CLINIC),
            new RoutePathFilter('LI-VW',RoutePaths.DOCTOR),
            new RoutePathFilter('LI-VW',RoutePaths.HOSPITAL),
            new RoutePathFilter('ALL',RoutePaths.MEMBER),
            new RoutePathFilter('ALL',RoutePaths.APPOINTMENT),
            new RoutePathFilter('DC-CD-AL-A5-AS-SY',RoutePaths.REPORT)
        ])
    },
    
]
    
    