import axios from 'axios'
import { env } from '../../../App.env';
import { Status, StatusResponse } from '../../../models/global/StatusResponce';
import { clean } from '../../../services';
import { InpatientSurgeonFeeReq, InpatientSurgeonFeeResponse, InpatientSurgeonFeeResponseO, SearchFormSurgeonfee, SurgicalTableformResponse } from './_models';


const API_URL = env.base_api_url
const API_CP = `${API_URL}/Api/ClinicalSettings/InSurgeonFee`


export function searchInpatientSurgeonFee(req:SearchFormSurgeonfee,isSearch:boolean) {

  if(isSearch){
    return axios.get<InpatientSurgeonFeeResponse>(`${API_CP}/searchInpatientSurgeonFeesDetails?inPatientSurgeonFeeTableId=${req.inPatientSurgeonFeeTableId}&makercheckerstatus=${req.makercheckerstatus}&fromDate=${req.fromDate}&ToDate=${req.ToDate}`)
  }else{
    return axios.get<InpatientSurgeonFeeResponse>(`${API_CP}/getInPatientSurgeonFee`)
  }

}

export function getSurgicalTable() {
  return axios.get<SurgicalTableformResponse>(`${API_CP}/getSurgicalTable`)
}

export function getById(id: String) {
  return axios.get<InpatientSurgeonFeeResponseO>(`${API_CP}/setInpatientSurgeonFee?id=${id}`)
}

export function save(req: InpatientSurgeonFeeReq) {
  req.inPatientSurgeonFeeForm = clean(req.inPatientSurgeonFeeForm);
  req.makercheckertasksForm = clean(req.makercheckertasksForm);

  if(req.inPatientSurgeonFeeForm.id){
    return axios.post<StatusResponse>(`${API_CP}/updateInPatientSurgeonFees`,clean(req))
  }else{
    return axios.post<StatusResponse>(`${API_CP}/saveInPatientSurgeonFees`,clean(req))
  }
  
}

export function deleteById(id: number) {
  return axios.get<any>(`${API_CP}/deleteInPatientSurgeonFee?id=${id}`)
}

