import React, { useEffect, useState } from 'react';
import { SurveyConfig, SurveyConfigSchema} from './_models';
import { save,getById } from './_requests';
import { KTCard, KTCardBody,KTCardFooter,KTCardHead } from '../../../../_theme/helpers';
import { useFormik } from 'formik';
import { Constants, DateToString, FormActionButtons, KTInputBox, KTInputLabel, StatusResponseModal } from '../../../services';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { StatusResponse } from '../../../models/global/StatusResponce';
import { KTDatePickerBox, KTInputRadioBox, KTSelectBox } from '../../../services/components/InputTemplates';
import { KeyValueOption } from '../../../models';

export function SurveyConfigAddEdit () {

  const [isLoading, setLoading] = useState(true);
  const [statusResp, setStatusResp] = useState(new StatusResponse());
  const [isAlertModal, setAlertModal] = useState(false);
  const [SurveyConfigData, setSurveyConfig] = useState(new SurveyConfig());
  const [TermiantionDateStart, setTermiantionDateStart] = useState(new Date());
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(()=>{
    fetchSurveyConfigList();
  return ()=>{ 
  }
  // eslint-disable-next-line
 },[])



 const fetchSurveyConfigList = () => {
  if(id !== undefined && id && isLoading){
    getById(id).then(function (response) {
      setSurveyConfig(response.data);
      formik.resetForm({
        values:response.data
      })
      setLoading(false);
    })
  }else{
    setLoading(false);
  }
}

 const formik = useFormik({
    initialValues: SurveyConfigData,
    validationSchema: SurveyConfigSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setSubmitting(true)
      try {
        var saveResp = await save(values);
        setStatusResp(saveResp.data);
        setAlertModal(true);
        setLoading(false)
        setSubmitting(false)
      } catch (error) {
        try{
        }
        catch(_error){
            setStatus(Constants.MESSAGE_ERROR);
        }
        console.error(error)
      }
    },
  })

  
  const closeModal = () => {
    setAlertModal(false);
    if(statusResp.code===1)
    navigate("/RatingSettings/SurveyConfig");
  }
  


      return (
         <>
         {isAlertModal ? (<StatusResponseModal message={''} data={statusResp}  handleClose = {closeModal}/>):<></>}
          
          <KTCard>
            <KTCardHead title={((id == undefined || parseInt(id) === 0)?'Add':'Edit')+' Survey Config'}>
            </KTCardHead>
            <KTCardBody>
                <form className='form w-100 my-5' onSubmit={formik.handleSubmit} noValidate id='kt_country_add_edit_form' >
                    


                <div className="row">

                  <div className='col-sm-6 d-lg-flex my-2'>
                    <KTInputLabel required={true} title="Effective Date" className='col-lg-4' />
                    <div className="col-lg-7">
                      <KTDatePickerBox placeholder='Enter Effective Date'  formikProps={formik.getFieldProps('fromdate')}
                        active={formik.touched.fromdate}   error={formik.errors.fromdate}
                        DatePickerType='Today&Future'                                  
                        readOnly={SurveyConfigData.id !== undefined && SurveyConfigData.id > 0}
                        handleOnChange ={ 
                          (date : Date) => {
                            (date !==null) && setTermiantionDateStart(date);
                            (date === null) && setTermiantionDateStart(new Date());
                            formik.setFieldValue('fromdate',DateToString(date))
                            formik.setFieldValue('todate','');
                          }
                        }
                        />
                    </div>
                  </div>

                  <div className='col-sm-6 d-lg-flex my-2'>
                    <KTInputLabel required={false} title="Termination Date" className='col-lg-4' />
                    <div className="col-lg-7">
                      <KTDatePickerBox placeholder='Enter Termination Date' 
                        formikProps={formik.getFieldProps('todate')}
                        active={formik.touched.todate}  
                        error={formik.errors.todate} 
                        handleOnChange ={ 
                          (date : Date) => {
                            formik.setFieldValue('todate',DateToString(date));
                          }
                        }
                        DatePickerType='Future'
                        DateRange={{start:TermiantionDateStart}}
                        />
                    </div>
                  </div>
                  </div>

                  <div className="row">
                      <div className='col-sm-6 d-lg-flex my-2'>
                        <KTInputLabel required={true} title="Max No Of Published Questions" className='col-lg-4' />
                        <div className="col-lg-7">
                          <KTInputBox placeholder='Enter Max No Of Published Questions' formikProps={formik.getFieldProps('maxpublishquestions')} maxLength={50}
                            active={formik.touched.maxpublishquestions}  
                            error={formik.errors.maxpublishquestions}
                          />
                        </div>
                      </div>

                      <div className='col-sm-6 d-lg-flex my-2'>
                        <KTInputLabel required={true} title="Minimum Alert Rating" className='col-lg-4' />
                        <div className="col-lg-7">
                          <KTSelectBox placeholder='Select Minimum Alert Rating'  formikProps={formik.getFieldProps('minimumalertrating')}  
                            optionsList={[1,2,3,4,5]?.map((x) => {return new KeyValueOption(x,x.toString());})}
                                  active={formik.touched.minimumalertrating} error={formik.errors.minimumalertrating}
                                  onChangeHandler={(val) => {
                                    formik.setFieldValue('minimumalertrating',parseInt(val));
                                  }} 
                                />
                        </div>
                      </div>
                      
                    </div>


                    <div className="row">
                      <div className='col-sm-6 d-lg-flex my-2'>
                        <KTInputLabel required={true} title="Email Address to send Upload Ratings Log File" className='col-lg-4' />
                        <div className="col-lg-7">
                          <KTInputBox placeholder='Enter Email Address to send Upload Ratings Log File' formikProps={formik.getFieldProps('uploademail')} maxLength={50}
                            active={formik.touched.uploademail}  
                            error={formik.errors.uploademail}
                          />
                        </div>
                      </div>

                      <div className='col-sm-6 d-lg-flex my-2'>
                        <KTInputLabel required={true} title="Alert Email address " className='col-lg-4' />
                        <div className="col-lg-7">
                        <KTInputBox placeholder='Enter Email Address to send Upload Ratings Log File' formikProps={formik.getFieldProps('alertemailaddress')} maxLength={50}
                            active={formik.touched.alertemailaddress}  
                            error={formik.errors.alertemailaddress}
                          />
                        </div>
                      </div>
                      
                    </div>

                    

                    <div className="row">
                      <div className='col-sm-6 d-lg-flex my-2'>
                        <KTInputLabel required={true} title="Require Email Address For Feeback" className='col-lg-4' />
                        <div className="col-lg-7">
                        <KTInputRadioBox  formikProps={formik.getFieldProps( 'alertemailforfeedback')} 
                              optionsList={[new KeyValueOption(false, 'No'), new KeyValueOption(true, 'Yes')]} 
                              active={formik.touched.alertemailforfeedback}  
                              error={formik.errors.alertemailforfeedback}                              
                              handleOnChange={(val) => formik.setFieldValue('alertemailforfeedback',val)}
                            /> 
                        </div>
                      </div>

                      <div className='col-sm-6 d-lg-flex my-2'>
                        <KTInputLabel required={true} title="App Notifications" className='col-lg-4' />
                        <div className="col-lg-7">
                        <KTInputRadioBox  formikProps={formik.getFieldProps( 'appnotification')} 
                              optionsList={[new KeyValueOption(false, 'No'), new KeyValueOption(true, 'Yes')]} 
                              active={formik.touched.appnotification}  
                              error={formik.errors.appnotification}
                              handleOnChange={(val) => formik.setFieldValue('appnotification',val)}
                            /> 
                        </div>
                      </div>
                      
                    </div>

                   
                    
                    <KTCardFooter className='d-flex justify-content-end mt-5'>
                      {/* begin::Action */}                           
                        <FormActionButtons isLoading={isLoading} id={id} backUrl='/RatingSettings/SurveyConfig' disabled={formik.isSubmitting || !formik.isValid } />
                        {/* end::Action */}
                    </KTCardFooter>



                </form>
            </KTCardBody>

          </KTCard>
          </>
      )
    
}


