
import React, { useEffect, useState } from 'react';
//Datatable Modules

import { KTCard, KTCardBody, KTCardHead, KTCardHeadToolBar } from '../../../../_theme/helpers';
import { Link, useParams } from 'react-router-dom';
import { ConfirmModal, Constants, decodeAes, EmptyDatatable, Loading, Pagination, StorageHelper, UserType } from '../../../services';
import { deleteSuspendedApplicationById, fetchSuspendUserListById, getUserDetialByApplicationUserId } from './_requests';
import DataTable from 'react-data-table-component';
import { UserDetialsHeader } from '../_headerUser';
import { AdminUserForm } from '../admin/_models';
import { SuspendUser } from './_models';
import { SuspendUserTableColumns } from './_dataRowColumns';
import { ClinicUser } from '../../clinic-module/clinic-user/_models';
import { Status } from '../../../models/global/StatusResponce';

export function SuspendUserList () {

  const [isLoading, setLoading] = useState(true);
  const [ClinicUserData,SetClinicUserData] = useState<ClinicUser>();
  const [AdminUserData,SetAdminUserData] = useState<AdminUserForm>();
  const [SuspensionList,SetSuspensionList] = useState<SuspendUser[]>([]);
  const [status, setStatus] = useState(new Status(0,''));
  const [isAlertModal, setAlertModal] = useState(false);  
  const [PageNo, setPageNo] = React.useState(1);
  const [deleteRow,SetDeleteRow] = useState(0);
  const { userId } = useParams();

    useEffect(()=>{
      fetchFormData();
    return ()=>{ 
    }
    // eslint-disable-next-line
   },[])
   
   async function fetchFormData() {
    var _userId = Number(userId);
    getUserDetialByApplicationUserId(_userId).then(response => {
      if(response.data.userForms.applicationuserForm.usertypeName === UserType.CLINICUSER.CODE) {
        SetClinicUserData(response.data.userForms as ClinicUser);
      }
      else {
        SetAdminUserData(response.data.userForms as AdminUserForm);
      }
    });
    fetchSuspendUserListById(_userId).then(response => {
      SetSuspensionList(response.data);
      setLoading(false);
    });
  }

  const AlertConfirm = (_id:number,) =>{
    SetDeleteRow(_id);
    setStatus(new Status(0,'Are you sure you want to Delete ?'))
    setAlertModal(true);
  }

  const handleConfirm = () =>{
    setLoading(true);
    deleteSuspendedApplicationById(deleteRow).then(response => {
      setStatus(response.data);
      fetchSuspendUserListById(Number(userId)).then(response => {
        SetSuspensionList(response.data)
        setLoading(false);
      });
    });
    setAlertModal(false);
}

  const handleClose = () =>{
    setAlertModal(false);
  }

  return (
    <>
    {isLoading && (<Loading/>)}
    {isAlertModal && <ConfirmModal message={status.message}  handleClose = {handleClose} handleConfirm={handleConfirm} />}
    <KTCard>
      <KTCardHead title='SUSPEND USER'>
            <KTCardHeadToolBar>
              <Link className='btn btn-sm btn-secondary me-3' to={StorageHelper.getBackURL()}>BACK</Link>
              <Link className='btn btn-sm btn-secondary' to={'/User/SuspendUser/Add/userId/' + userId }>ADD NEW SUSPENSION</Link>
          </KTCardHeadToolBar>
      </KTCardHead>
      <KTCardBody className='py-4'>
        {ClinicUserData && <UserDetialsHeader name={ClinicUserData.name} userType={ClinicUserData.applicationuserForm.usertypeName} profileType={ClinicUserData.applicationuserForm.profilemasterName} clinic={ClinicUserData.clinicName} />}
        {AdminUserData && <UserDetialsHeader name={AdminUserData.adminUserForm.name} userType={AdminUserData.applicationuserForm.usertypeName} profileType={AdminUserData.applicationuserForm.profilemasterName} LANID ={decodeAes(AdminUserData.adminUserForm.nric)} />}
        <div className="row">
            <div className='my-3'>
            <div className={Constants.DATATABLE_STYLE_REACT}>
                  <DataTable
                    columns={SuspendUserTableColumns(PageNo,AlertConfirm)}
                    onChangePage={(page: number) => {setPageNo(page);}}
                    data={SuspensionList}
                    pagination
                    paginationComponent={Pagination} 
                    noDataComponent={<EmptyDatatable columns={SuspendUserTableColumns()}/>}                   
                  /> 
                  </div>
            </div>
        </div>
      </KTCardBody>
    </KTCard>
    </>
  );
}


