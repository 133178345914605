import { Auditable } from "../../../models/global/Auditable"
import * as Yup from 'yup';
import { string } from 'yup';
import { number } from "yup/lib/locale";



export class HealthScreeningType extends Auditable{
  id: number = 0;
  name: string ='';
  screeningId:number=0;
  screeningName: string ='';
}

export const HealthScreeningTypeSchema = Yup.object({
  name: string().max(256, 'Maximum 256').required('Please enter name'),
  //screeningId : number().
})
