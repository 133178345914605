import { useEffect, useState } from 'react';
import { KTAccordion, KTAccordionItem, KTCard, KTCardBody,KTCardFooter,KTCardHead, KTCardHeadToolBar } from '../../../../_theme/helpers';
import { useFormik } from 'formik';
import { Constants, DateToString, decodeAes, encodeAes, FormActionButtons, KTInputLabel, Loading ,StatusResponseModal, StorageHelper, StringToDate, UserType} from '../../../services';
import { useNavigate, useParams } from 'react-router-dom';
import { StatusResponse } from '../../../models/global/StatusResponce';
import { KTDatePickerBox, KTInputBox, KTInputCheckBox, KTInputRadioBox, KTSelectBox, KTTextAreaBox} from '../../../services/components/InputTemplates';
import { ClinicHeader } from '../clinic/_headerClinic';
import { KeyValueOption } from '../../../models';
import { useAuth } from '../../auth';
import { ClinicUser, ClinicUserEscapeHtml, ClinicUserValidationSchema } from './_models';
import { getClinicUserByid, getNextClinicUserId, SaveClinicUser } from './_requests';
import { GetClinicById } from '../clinic/_requests';
import { Clinic } from '../clinic/_models';
import { fetchProfileType } from '../../users/_requests';
import { ProfileMaster } from '../../users/_models';
import { decode, encode } from 'html-entities';

export function ClinicUserAddEdit () {
  const [isLoading, setLoading] = useState(true);
  const [ClinicData, setClinic] = useState(new Clinic());
  const [isOwnProfile, SetOwnProfile] = useState(false);
  const [canEditClinicManager, SetClinicManager] = useState(false);
  const [effectiveDateDisable, SeteffectiveDateDisable] = useState(false);
  const [isEditable, setEditable] = useState(true);
  
  const [ClinicUserData, setClinicUserData] = useState(new ClinicUser());
  const [ClinicUserProfiles, setClinicUserProfiles] = useState<Array<ProfileMaster>>([]);
  const [statusResp, setStatusResp] = useState(new StatusResponse());
  const [isAlertModal, setAlertModal] = useState(false);
  const [TermiantionDateStart, setTermiantionDateStart] = useState(new Date());
  const [terminationDateDisable, SetTerminationDateDisable] = useState(false);
  const navigate = useNavigate();
  const { id , clinicId} = useParams();
  const {currentUser} = useAuth();
  
  useEffect(()=>{
    fetchFormData();
    if(currentUser?.profilemasterName === UserType.CLINICUSER.PROFILE.MANAGER)
      SetClinicManager(true);
  return ()=>{ 
  }
  // eslint-disable-next-line
 },[])

  async function fetchFormData() {
    if(id!==undefined && id!==null){
      getClinicUserByid(parseInt(id)).then(response => {
        var ClinicUser = response.data.clinicuserForms;
        ClinicUser.nric = decodeAes(ClinicUser.nric); 

        ClinicUser = ClinicUserEscapeHtml(ClinicUser,"decode");
        

          SetOwnProfile(ClinicUser.applicationuserForm.id === currentUser?.applicationUserId)
        var efd = new Date(ClinicUser.applicationuserForm.effectivedate);
          SeteffectiveDateDisable(efd < new Date());
        setClinicUserData(ClinicUser);

        if(ClinicUser.applicationuserForm.terminationdate){
          var tfd = new Date(ClinicUser.applicationuserForm.terminationdate);
          SetTerminationDateDisable(tfd < new Date());
        }

        let isEditable = (ClinicUser.applicationuserForm.terminationdate==null 
          ||  (StringToDate(ClinicUser.applicationuserForm.terminationdate).getTime() > new Date().getTime() 
          && (StringToDate(ClinicUser.applicationuserForm.effectivedate).getTime() < new Date().getTime()) ) )
      setEditable(isEditable);
      

      });
    }
    if(clinicId!==undefined && clinicId!==null ){
      GetClinicById(parseInt(clinicId)).then(responseClinic => {
        let _clinic  = responseClinic.data.clinicForms;
        setClinic(_clinic);
        if(id === undefined)
          {
            getNextClinicUserId().then(responseId => {
              formik.setFieldValue('applicationuserForm.username',_clinic.hcinumber.replace(" ", "") + "_" + responseId.data.nextClinicUserId)
            });
          }

          fetchProfileType(UserType.CLINICUSER.CODE).then(response => {
            let profileList = response.data.profilemasterForms;

            //For Panel-only clinic, please disable/hide health screening clinic user roles and, hide Panel with health screening user role.
            //For Health screning only clinic, please disable/hide Panel clinic user roles and, hide Panel with health screening user role.

            if(_clinic.panel && !_clinic.healthscreening){
              profileList = profileList.filter(p=> !p.name.includes("Health Screening"))
            }else if(!_clinic.panel && _clinic.healthscreening){
              profileList = profileList.filter(p=> p.name.includes("Health Screening") && !p.name.includes("with Health Screening"))
            }
      
           //console.log(profileList);
            setClinicUserProfiles(profileList);
          });

          setLoading(false);
      });
    }
    
  }

 const formik = useFormik({
    initialValues:ClinicUserData,
    validationSchema: ClinicUserValidationSchema,
    enableReinitialize:true,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setSubmitting(true)
      try {

        if(clinicId && values.clinic === 0)
        values.clinic = parseInt(clinicId);
        
        let _clinicuser = JSON.parse(JSON.stringify(values));
        if( _clinicuser.nric){
          _clinicuser.nric = encodeAes(_clinicuser.nric);
        }

        _clinicuser.internalRemarks = encode(_clinicuser.internalRemarks);
        
          SaveClinicUser(_clinicuser).then(respose =>{
            setStatusResp(respose.data);
            setAlertModal(true);
            setLoading(false)
            setSubmitting(false)
          });
      } catch (error) {
        try{
        }
        catch(_error){
            setStatus(Constants.MESSAGE_ERROR);
        }
        console.error(error)
        setLoading(false)
        setSubmitting(false)
      }
    },
  })
  
  const closeModal = () => {
    setAlertModal(false);
    if(statusResp.code===1)
      navigate(StorageHelper.getBackURL());
  }

      return (
         <>
         {isAlertModal && <StatusResponseModal message={''} data={statusResp}  handleClose = {closeModal} />}
         {isLoading && (<Loading/>)}
          <KTCard>
            <KTCardHead title={((id == undefined || parseInt(id) === 0)?'Add':'Edit')+' Clinic User'}>
                <KTCardHeadToolBar>
                </KTCardHeadToolBar>
            </KTCardHead>
            <KTCardBody>
            <ClinicHeader formView={true} name={ClinicData.name} code={ClinicData.hcinumber} uen={ClinicData.uen} type={ClinicData.clinictypeName} />
                <form className='form w-100 pt-6 pb-2' onSubmit={formik.handleSubmit} noValidate id='kt_clinic_user_add_edit_form' >
                <KTAccordion keys={['kt_1','kt_2']}>
                      <KTAccordionItem id={'kt_1'} title='Clinic User Details'>
                        <div className="row">
                          <div className='col-sm-6 d-md-flex my-2'>
                            <KTInputLabel title="NRIC" className='col-md-3' required={true} />
                              <div className="col-md-8">
                              <KTInputBox placeholder='NRIC' className='text-uppercase' formikProps={formik.getFieldProps('nric')} type={'AlphaNum'} maxLength = {16}
                                      active={formik.touched.nric}  readOnly={isOwnProfile || canEditClinicManager || ClinicUserData.id !== undefined}
                                      error={formik.errors.nric}      
                                />
                              </div>
                          </div>
                          <div className='col-sm-6 d-md-flex my-2'>
                            <KTInputLabel title="Name" className='col-md-3' required={true} />
                              <div className="col-md-8">
                              <KTInputBox placeholder='Enter Name' formikProps={formik.getFieldProps('name')} maxLength = {128}
                                      active={formik.touched.name}  readOnly={isOwnProfile || canEditClinicManager || ClinicUserData.id!== undefined}
                                      error={formik.errors.name}      
                                />
                              </div>
                          </div>
                        </div>  
                        <div className="row">
                          <div className='col-sm-6 d-md-flex my-2'>
                            <KTInputLabel title="DOB" className='col-md-3'/>
                              <div className="col-md-8">
                              <KTDatePickerBox placeholder='Enter Date Of Birth'  formikProps={formik.getFieldProps('dob')} dateFormat={'dd/MM/yyyy'}
                                      active={formik.touched.dob}  readOnly={isOwnProfile || canEditClinicManager || ClinicUserData.id !== undefined}
                                      error={formik.errors.dob} DatePickerType={'Past'}
                                      handleOnChange ={ (date : Date) => {formik.setFieldValue('dob',DateToString(date,'dd/MM/yyyy'))}}
                                />
                              </div>
                          </div>
                          <div className='col-sm-6 d-md-flex my-2'>
                            <KTInputLabel title="DID" className='col-md-3' />
                              <div className="col-md-8">
                              <KTInputBox placeholder='Enter DID' formikProps={formik.getFieldProps('did')} maxLength = {16} type={'Digit'}
                                      active={formik.touched.did}  
                                      error={formik.errors.did}      
                                />
                              </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className='col-sm-6 d-md-flex my-2'>
                            <KTInputLabel title="Mobile" className='col-md-3' required={true} />
                              <div className="col-md-8">
                              <KTInputBox placeholder='Enter Mobile Number' formikProps={formik.getFieldProps('mobile')} maxLength = {10}
                                      active={formik.touched.mobile}  readOnly={isOwnProfile} type={'Digit'}
                                      error={formik.errors.mobile}      
                                />
                              </div>
                          </div>
                          <div className='col-sm-6 d-md-flex my-2'>
                            <KTInputLabel title="Email" className='col-md-3' required={true} />
                              <div className="col-md-8">
                              <KTInputBox placeholder='Enter Email Address' formikProps={formik.getFieldProps('email')} maxLength = {128}
                                      active={formik.touched.email}  readOnly={isOwnProfile || canEditClinicManager  }
                                      error={formik.errors.email}      
                                />
                              </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className='col-sm-6 d-md-flex my-2'>
                            <KTInputLabel title="Gender" className='col-md-3' />
                              <div className="col-md-8">
                              <KTInputRadioBox formikProps={formik.getFieldProps('gender')} 
                                  optionsList={[new KeyValueOption('Male','Male'), new KeyValueOption('Female','Female')]}
                                  handleOnChange ={ (val:any) => formik.setFieldValue('gender',val)}
                                />
                              </div>
                          </div>
                          <div className='col-sm-6 d-md-flex my-2'>
                            <KTInputLabel title="Internal Remarks" className='col-md-3'/>
                              <div className="col-md-8">
                              <KTTextAreaBox formikProps={formik.getFieldProps('internalRemarks')} maxLength={512} />
                              </div>
                          </div>
                        </div>
                      </KTAccordionItem>
                      <KTAccordionItem id={'kt_2'} title='Clinic User Login Details'>
                      <div className="row">
                          <div className='col-sm-6 d-md-flex my-2'>
                            <KTInputLabel title="User Name" className='col-md-3' required={true} />
                              <div className="col-md-8">
                                <KTInputBox  formikProps={formik.getFieldProps('applicationuserForm.username')} maxLength = {32} readOnly={true}/>
                              </div>
                          </div>
                          <div className='col-sm-6 d-md-flex my-2'>
                            <KTInputLabel title="User Profile" className='col-md-3' required={true} />
                              <div className="col-md-8">
                              <KTSelectBox placeholder='-- Select --' formikProps={formik.getFieldProps('applicationuserForm.profilemaster')}
                                      active={formik.touched.applicationuserForm?.profilemaster}  readOnly={isOwnProfile}
                                      error={formik.errors.applicationuserForm?.profilemaster}     
                                      optionsList={ClinicUserProfiles.map((x) => {return new KeyValueOption(x.id,x.name);})}
                                      onChangeHandler={(val) => {formik.setFieldValue('applicationuserForm.profilemaster',parseInt(val));}} 
                                />
                              </div>
                          </div>
                        </div>
                        <div className="row">
                            <div className='col-sm-6 d-md-flex my-2'>
                              <KTInputLabel required={true} title="Effective Date" className='col-md-3' />
                              <div className="col-md-8">
                                <KTDatePickerBox placeholder='Effective Date'  
                                formikProps={formik.getFieldProps('applicationuserForm.effectivedate')}
                                  active={formik.touched.applicationuserForm?.effectivedate}   
                                  error={formik.errors.applicationuserForm?.effectivedate} 
                                  DatePickerType='Today&Future'                                  
                                  readOnly={isOwnProfile || canEditClinicManager || effectiveDateDisable}
                                  handleOnChange ={ 
                                    (date : Date) => {
                                      (date !==null) && setTermiantionDateStart(date);
                                      (date === null) && setTermiantionDateStart(new Date());
                                      formik.setFieldValue('applicationuserForm.effectivedate',DateToString(date))
                                      formik.setFieldValue('terminationdate','');
                                    }
                                  }
                                  />
                              </div>
                            </div>
                            <div className='col-sm-6 d-md-flex my-2'>
                              <KTInputLabel title="Termination Date" className='col-md-3' />
                              <div className="col-md-8">
                                <KTDatePickerBox placeholder='Enter Termination Date' 
                                  readOnly={isOwnProfile || terminationDateDisable}
                                  formikProps={formik.getFieldProps('applicationuserForm.terminationdate')}
                                  active={formik.touched.applicationuserForm?.terminationdate}  
                                  error={formik.errors.applicationuserForm?.terminationdate} 
                                  handleOnChange ={ 
                                    (date : Date) => {
                                      formik.setFieldValue('applicationuserForm.terminationdate',DateToString(date));
                                    }
                                  }
                                  DatePickerType='Future'
                                  DateRange={{start:TermiantionDateStart}}
                                  />
                              </div>
                            </div>
                          </div>

                          {!id && <>
                        <div className="row">
                          <div className='col-sm-6 d-md-flex my-2'>
                            <KTInputLabel title="Required To Send Email" className='col-md-3'/>
                              <div className="col-md-8">
                              <KTInputCheckBox formikProps={formik.getFieldProps('applicationuserForm.isMailSent')} 
                                handleOnChange ={ (val : boolean) => formik.setFieldValue('applicationuserForm.isMailSent', val ? 1 : 0)}
                               />
                              </div>
                          </div>
                        </div>
                        </>}

                      </KTAccordionItem>                     
                    </KTAccordion>
                  <KTCardFooter className='d-flex justify-content-end mt-5'>
                      {/* begin::Action */}                           
                        <FormActionButtons isLoading={isLoading} id={id} backUrl={StorageHelper.getBackURL()} disabled={formik.isSubmitting  || !isEditable } />
                        {/* end::Action */}
                  </KTCardFooter>
                </form>
            </KTCardBody>
          </KTCard>
          </>
      )
}
