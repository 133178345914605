export interface RoutePath  {
    code : string,
    path : string
};

export class RoutePathFilter  {
  constructor(_codes : string,_paths : Array<RoutePath>)
  {
      this.codes = _codes
      this.paths = _paths
  }
  codes : string = ''
  paths : Array<RoutePath> = []
};

export function RoutePathAccess(access? :Array<RoutePathFilter>)
  {
    var _paths : string[] = [];
    _paths = _paths.concat(
      '/auth/',
      '/logout',
      '/PRE_AUTHNUMBER/',
      '/error/',  
    );
    access?.map(r=>(
      r.paths.filter(f=> r.codes === 'ALL' || r.codes.split('-').includes(f.code)).map(m=>(
      _paths.push(m.path)
      ))
    ))
    return _paths;
  }

  export const RoutePaths = {
    USER : [
      {code : 'HO' , path :'/Dashboard'},
      {code : 'PO' , path :'/User/Profile'},
      {code : 'US' , path :'/User/SuspendUser/'},
      ],
    CLINIC : [
      {code : 'LI' , path :'/Clinic/Search'},
      {code : 'AD' , path :'/Clinic/Add'},
      {code : 'ED' , path :'/Clinic/Edit/id/'},
      {code : 'VW' , path :'/Clinic/View/id/'},
      {code : 'MD' , path :'/Clinic/MapDoctor/'},
      {code : 'CU' , path :'/Clinic/ClinicUser/'},
      {code : 'UL' , path :'/Clinic/ClinicUser/clinicId/'},
      {code : 'UV' , path :'/Clinic/ClinicUser/View/'},            
      {code : 'US' , path :'/ClinicUser'},
      {code : 'PS' , path :'/Clinic/PanelSettings'},
      {code : 'IR' , path :'/Clinic/InternalRating'},
      {code : 'PR' , path :'/Clinic/PublishRating'},
      {code : 'LC' , path :'/Clinic/LeaveCalendar/'}
      ],
    DOCTOR : [
      {code : 'LI' , path :'/Doctor/Search'},
      {code : 'AD' , path :'/Doctor/Add'},
      {code : 'ED' , path :'/Doctor/Edit/id/'},
      {code : 'VW' , path :'/Doctor/View/id/'},
      {code : 'MC' , path :'/Doctor/MapClinic/'},
      {code : 'PS' , path :'/Doctor/PanelSettings'},
      {code : 'SD' , path :'/Doctor/Suspended/'},
      {code : 'WL' , path :'/Doctor/Watched/'},
      {code : 'IR' , path :'/Doctor/InternalRating'},
      {code : 'PR' , path :'/Doctor/PublishRating'},
      {code : 'LC' , path :'/Doctor/LeaveCalendar/'}
      ],
    HOSPITAL : [
      {code : 'LI' , path :'/Hospital/Search'},
      {code : 'AD' , path :'/Hospital/Add'},
      {code : 'ED' , path :'/Hospital/Edit/id/'},
      {code : 'VW' , path :'/Hospital/View/id/'},
      ],    
    MEMBER : [
      {code : 'LI' , path :'/CareTeam/Member/Search'}
      ],    
    APPOINTMENT : [
      {code : 'CL' , path :'/Appointments/Cancellation'},
      {code : 'CR' , path :'/Appointments/Cancellation/Create/id/'},
      {code : 'DS' , path :'/Appointments/Specialist/search'},
      {code : 'AD' , path :'/Appointments/Add/id'},
      {code : 'VW' , path :'/Appointments/View/id/'},
      {code : 'CH' , path :'/Appointments/Change/id/'},
      {code : 'NW' , path :'/Appointments/New'},
      {code : 'PD' , path :'/Appointments/Pending'},
      {code : 'SL' , path :'/Appointments/SMSLog'},
      {code : 'SN' , path :'/Appointments/NotSent'},
      {code : 'SS' , path :'/Appointments/SendSms/apptId/'},
      {code : 'AS' , path :'/Appointments/Search'},
      {code : 'AC' , path :'/Appointments/Confirm/id/'},
      {code : 'MO' , path :'/Appointments/MyOwn'},
      {code : 'CC' , path :'/Appointments/Callcenter/'}
      ],
    OPERATION : [
      {code : 'BM' , path :'/Operations/BroadcastMessages'},
      {code : 'TA' , path :'/Operations/ApprovalAlerts'}
      ],
    VOUCHER : [
        {code : 'VE' , path :'/Voucher/Enquiry'},
        {code : 'VR' , path :'/Voucher/Redeemed'}
      ],
    ADMINUSER : [
      {code : 'LI' , path :'/AdminUser/Search'},
      {code : 'AD' , path :'/AdminUser/Add'},
      {code : 'ED' , path :'/AdminUser/Edit'},
      {code : 'VW' , path :'/AdminUser/View'},
      ],
    CLINICAL_SETTINGS : [
      {code : 'AF' , path :'/ClinicalSettings/InpatientAttendanceFee'},
      {code : 'SF' , path :'/ClinicalSettings/InpatientSurgeonFee'},
      {code : 'HS' , path :'/ClinicalSettings/HealthScreeningFee'},
      {code : 'HT' , path :'/ClinicalSettings/HealthScreeningType'},
      ],         
    RATING_SETTINGS : [
      {code : 'CW' , path :'/RatingSettings/ClinicRatingWeightage'},
      {code : 'DW' , path :'/RatingSettings/DoctorRatingWeightage'},
      {code : 'PR' , path :'/RatingSettings/PublishRatings'},
      {code : 'SC' , path :'/RatingSettings/SurveyConfig'},
      {code : 'SQ' , path :'/RatingSettings/SurveyQuestions'},
      {code : 'UR' , path :'/RatingSettings/UploadInternalRatings'},
      ], 
    SETTINGS : [
      {code : 'AR' , path :'/Settings/AppointmentRequest'},
      {code : 'BP' , path :'/Settings/BasePlanCode'},
      {code : 'CU' , path :'/Settings/Country'},
      {code : 'ES' , path :'/Settings/Estate'},
      {code : 'GT' , path :'/Settings/Gst'},
      {code : 'HO' , path :'/Settings/Holiday'},
      {code : 'AF' , path :'/Settings/InpatientAttendanceTable'},
      {code : 'SF' , path :'/Settings/InpatientSurgeonFeeTable'},
      {code : 'PS' , path :'/Settings/PlanTypeRemarks'},
      {code : 'PC' , path :'/Settings/RiderPlanCode'},
      {code : 'ST' , path :'/Settings/Smtp'},
      {code : 'SY' , path :'/Settings/Specialty'},
      {code : 'SS' , path :'/Settings/SubSpecialty'},
      {code : 'SP' , path :'/Settings/SystemPasswordPolicy'},
      {code : 'WT' , path :'/Settings/WardType'},
      {code : 'ZO' , path :'/Settings/Zone'}
      ], 
    REPORT : [
      {code : 'WL' , path :'/Reports/WorkLoadSummary'},
      {code : 'AL' , path :'/Reports/Appointment/List'},
      {code : 'A5' , path :'/Reports/Appointment/Top5'},
      {code : 'AS' , path :'/Reports/Appointment/Specialist'},
      {code : 'AC' , path :'/Reports/Appointment/Clinic'},
      {code : 'SY' , path :'/Reports/Appointment/Specialty'},
      {code : 'UA' , path :'/Reports/UserAccount'},
      {code : 'EL' , path :'/Reports/EmailList'},
      {code : 'PM' , path :'/Reports/ProviderMasterlist'},
      {code : 'DC' , path :'/Reports/DoctorClinicMap'},
      {code : 'CD' , path :'/Reports/ClinicDoctorMap'},
      {code : 'LC' , path :'/Reports/LeaveAndClosing'},
      {code : 'RC' , path :'/Reports/Rating/Clinic'},
      {code : 'RS' , path :'/Reports/Rating/Doctor'},
      {code : 'SQ' , path :'/Reports/Rating/SurveyQuestion'},
      {code : 'RD' , path :'/Reports/Rating/Detailed'},
      {code : 'RP' , path :'/Reports/Rating/Publish'},
      {code : 'CL' , path :'/Reports/Clinic/Leave'},
      {code : 'DL' , path :'/Reports/Doctor/Leave'},
      {code : 'SV' , path :'/Reports/Screening/Voucher'},
      {code : 'SP' , path :'/Reports/Screening/Partner'}
      ], 
    CLINIC_LOGIN : [
      {code : 'CP' , path :'/ClinicProfile/Profile'},
      {code : 'CU' , path :'/ClinicProfile/ClinicUser'},
      {code : 'CS' , path :'/ClinicProfile/Doctor'},
      {code : 'SM' , path :'/ClinicAssistant/SearchMembers'},
      {code : 'PL' , path :'/ClinicAssistant/PreAuthDecisionAndLetter'},
      {code : 'LC' , path :'/Doctor/LeaveCalendar/'}
      ],
  };
  