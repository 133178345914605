import { Auditable } from "../../../models/global/Auditable"
import * as Yup from 'yup';
import { decode, encode } from "html-entities";
import { clone } from "../../../services";
import { HealthScreeningFee } from "../../clinical-settings/health-screening-fee/_models";
import { Status } from "../../../models/global/StatusResponce";
import { HealthScreeningType } from "../../clinical-settings/health-screening-type/_models";

export class ClinicSearchResult{
  clinicCode: string = ""
  clinicName: string = ""
  clinicType: string = ""
  effectiveStartDate: string = ""
  filterrecord: number=0;
  hospital: string = ""
  id: number=0;
  internalRatingExist: string = ""
  isPublished: string = ''
  mappedDoctors: string = ""
  medixclinic: number=0
  overallrating: string = ""
  publishRatingExist: string = ""
  review: number=0;
  rownum: number=0;
  terminationDate: string = ""
  totalrecord: number=0;
  uenNumber: string = ""
  panel: boolean = false
  healthscreening: boolean = false
  hsProviderCode: string = ""
  hsProviderName: string = ""

}

export class ClinicForm{
  clinicsForm: Clinic = new Clinic();
  cliniccontactnumberForms: Array<ClinicContactNumber> = []
  gstregistrationForm : Array<any> = []
  pnregistrationForm : Array<any> = []
}

export class Clinic extends Auditable{
  acceptscreditcard?: number;
  accountmanager: string = ""
  accountname: string = ""
  accountno: string = ""
  bankcode: string = ""
  bankname: string = ""
  bankswiftcode: string = ""
  billingblocknumber: string = ""
  billingbuildingname: string = ""
  billingcity: string = ""
  billingparentcountryName: string = ""
  billingpostalcode: string = ""
  billingroadname: string = ""
  billingunitnumber: string = ""
  blocknumber: string = ""
  branchcode: string = ""
  buildingname: string = ""
  ccRemarks: string = ""
  city: string = ""
  clientId: string = ""
  clinicNameCode: string = ""
  clinicUser?: string
  clinicUserId: number=0;
  cliniccontactnumberList: Array<ClinicContactNumber> = []
  clinicremarks: string = ""
  clinictypeId: number=0;
  clinictypeName: string = ""
  contactpersondesignation: string = ""
  contactpersonemail: string = ""
  contactpersonname: string = ""
  contactpersonnumber: string = ""
  countryByBillingparentcountryid: string = ""
  countryByParentcountryid: number=185;
  creditcardremarks: string = ""
  displayClinicUENNameHospitalname: string = ""
  displayRemarks: string = ""
  doctorId: string = ""
  doctorName: string = ""
  effectivedate: string = ""
  email: string = ""
  estate: string = ""
  estateName: string = ""
  fax: string = ""
  fromDateFlag: string = ""
  gstregistrationsList: Array<any> = []
  hasdiagnosticfacilities?: number;
  hcinumber: string = ""
  healthScreeningTypeList: Array<HealthScreeningProfile> = []
  healthScreeningDoctorList: Array<HealthScreeningDoctor> = []
  healthScreeningFeeScheduleList: Array<HealthScreeningFeeSchdule> = []
  healthScreeningAddBottleList: Array<HealthScreeningAddBottle> = []
  holidayRemarks: string = ""
  hospitalName: string = ""
  hospitallocation: string = ""
  id: number=0;
  internalRemarks: string = ""
  is24hrclinic: number=0;
  isClinicMedisaveorMediShield?: number;
  ismedishield: string = ""
  latitude: string = ""
  longdescription: string = ""
  longitude: string = ""
  maincontactnumber: string = ""
  medixclinic: number=0;
  name: string = ""
  ohholidaysession1: string = ""
  ohholidaysession2: string = ""
  ohholidaysession3: string = ""
  ohmonfrisession1: string = ""
  ohmonfrisession2: string = ""
  ohmonfrisession3: string = ""
  ohsatsession1: string = ""
  ohsatsession2: string = ""
  ohsatsession3: string = ""
  ohsunsession1: string = ""
  ohsunsession2: string = ""
  ohsunsession3: string = ""
  parentHospitalId: number=0;
  parentcountryName: string = ""
  paymentUENnumber: string = ""
  paymententityname: string = ""
  pnregistrationList: Array<any> = []
  postalcode: string = ""
  roadname: string = ""
  shortdescription: string = ""
  terminationdate: string = ""
  uen: string = ""
  unitnumber: string = ""
  zone: number = 0
  zoneName?: string
  hsProviderCode:string=""
  hsProviderName:string=""
  healthscreening:boolean=false
  panel:boolean=false

  hsContactNumber:string=""
  hsEmail:string=""
  hsFax:string=""

  errorConoloscopyDoc:string='';

}

export function ClinicEscapeHtml(_data:Clinic,type:string){

  let data = clone(_data);

  data.name = (type=="decode") ?decode(data.name):encode(data.name);
  data.hcinumber = (type=="decode") ?decode(data.hcinumber):encode(data.hcinumber);
  data.uen = (type=="decode") ?decode(data.uen):encode(data.uen);
  data.email = (type=="decode") ?decode(data.email):encode(data.email);
  data.fax = (type=="decode") ?decode(data.fax):encode(data.fax);
  data.accountname = (type=="decode") ?decode(data.accountname):encode(data.accountname);
  data.contactpersondesignation = (type=="decode") ?decode(data.contactpersondesignation):encode(data.contactpersondesignation);

  data.unitnumber = (type=="decode") ?decode(data.unitnumber):encode(data.unitnumber);
  data.blocknumber = (type=="decode") ?decode(data.blocknumber):encode(data.blocknumber);
  data.roadname = (type=="decode") ?decode(data.roadname):encode(data.roadname);
  data.buildingname = (type=="decode") ?decode(data.buildingname):encode(data.buildingname);
  data.city = (type=="decode") ?decode(data.city):encode(data.city);
  data.billingblocknumber = (type=="decode") ?decode(data.billingblocknumber):encode(data.billingblocknumber);
  data.billingunitnumber = (type=="decode") ?decode(data.billingunitnumber):encode(data.billingunitnumber);
  data.billingroadname = (type=="decode") ?decode(data.billingroadname):encode(data.billingroadname);
  data.billingbuildingname = (type=="decode") ?decode(data.billingbuildingname):encode(data.billingbuildingname);
  data.billingcity = (type=="decode") ?decode(data.billingcity):encode(data.billingcity);
  data.displayClinicUENNameHospitalname = (type=="decode") ?decode(data.displayClinicUENNameHospitalname):encode(data.displayClinicUENNameHospitalname);

  data.bankname = (type=="decode") ?decode(data.bankname):encode(data.bankname);
  data.bankcode = (type=="decode") ?decode(data.bankcode):encode(data.bankcode);
  data.branchcode = (type=="decode") ?decode(data.branchcode):encode(data.branchcode);
  data.bankswiftcode = (type=="decode") ?decode(data.bankswiftcode):encode(data.bankswiftcode);

  data.clinicremarks = (type=="decode") ?decode(data.clinicremarks):encode(data.clinicremarks);
  data.internalRemarks = (type=="decode") ?decode(data.internalRemarks):encode(data.internalRemarks);
  data.displayRemarks = (type=="decode") ?decode(data.displayRemarks):encode(data.displayRemarks);
  data.longdescription = (type=="decode") ?decode(data.longdescription):encode(data.longdescription);
  data.shortdescription = (type=="decode") ?decode(data.shortdescription):encode(data.shortdescription);

  data.ohmonfrisession1 = (type=="decode") ?decode(data.ohmonfrisession1):encode(data.ohmonfrisession1);
  data.ohmonfrisession2 = (type=="decode") ?decode(data.ohmonfrisession2):encode(data.ohmonfrisession2);
  data.ohmonfrisession3 = (type=="decode") ?decode(data.ohmonfrisession3):encode(data.ohmonfrisession3);

  return clone(data);
 
}


export class ClinicLite {
  code: string = "" 
  id: number=0
  name:  string = ""
}

export class HsClinic {
  code: string = "" 
  id: number=0
  name:  string = ""
  hsProviderCode:  string = ""
  hsProviderName:  string = ""
  healthScreeningProfile:HealthScreeningProfile = new HealthScreeningProfile()
}


export class ClinicContactNumber extends Auditable  {
  clinic: number=0
  contactdetails:  string = "" 
  contacttype:  string = "" 
  id: number=0
  isDeletd: number=0
  isEmailset: number=0
  isemergencycontactnumber: number=0;
  oClinic?: string
}

export class ClinicType extends Auditable  {
  code: string = "" 
  id: number=0
  name:  string = ""
  remarks:  string = ""
}

export class ClinicTypeForms  {
  clinictypeForms : Array<ClinicType> = []
  requestSuccess  : boolean = false
}

export interface LocationResponse{
  found: number;
  totalNumPages: number;
  pageNum: number;
  results: Address[];

}

export class Address  {
  SEARCHVAL?: string;
  BLK_NO?: string;
  ROAD_NAME?: string;
  BUILDING?: string;
  ADDRESS?: string;
  POSTAL?: string;
  LATITUDE?: string;
  LONGITUDE?: string;
  X?: string;
  Y?: string;
}

export const ClinicValidationSchema = Yup.object({

  panel: Yup.bool().nullable()
  .when("healthscreening", {
    is: false,
    then: Yup.bool().oneOf([true], 'Panel or Health screen required'),
  }),
  //panel: Yup.bool().oneOf([true], 'Panel or Health screen required'),

  hsProviderName: Yup.string().nullable()
  .when("healthscreening", {
    is: true,
    then: Yup.string().required('Please enter Provider Name as Per Agreement').nullable()
  }),

  hsProviderCode: Yup.string().nullable()
  .when("healthscreening", {
    is: true,
    then: Yup.string().required('Please enter Provider Code as Per IL').nullable()
  }),

  hcinumber: Yup.string()
    .max(32, 'Code Must be 32 characters or less')
    .required('Please enter Clinic Code'),

  name: Yup.string()
    .max(128, 'Name Must be 128 characters or less') 
    .required('Please enter Clinic Name'),

  uen : Yup.string()
    .max(32, 'UEN Must be 32 characters or less') 
    .required('Please enter UEN'),

  clinictypeId: Yup.number().moreThan(0, 'Please Select Clinic type'),

  roadname: Yup.string()
  .required('Please Enter Road Name'),


  countryByParentcountryid: Yup.number()
  .moreThan(0, 'Please Select Country'),

  parentHospitalId:Yup.number()
  .moreThan(0, 'Please Select Hospital'),

  postalcode: Yup.string()
  .min(6, 'Postal code must be at least 6 digits')
  .max(6, 'Postal code must be at least 6 digits')
  .required('Please enter Postal code'),

  email: Yup.string()
  .nullable()
  .email('Email is invalid'),

  fax: Yup.string().min(8).max(12).nullable(),

  maincontactnumber: Yup.string().min(8).max(12).nullable(),

  contactpersonname: Yup.string().nullable()
  .when("panel", {
    is: true,
    then: Yup.string().max(128, 'Name Must be 128 characters or less') 
    .required('Please enter Name').nullable()
  }),

  contactpersonemail: Yup.string().nullable()
  .when("panel", {
    is: true,
    then: Yup.string().email('Email is invalid').nullable(),
  }),

 
  contactpersondesignation: Yup.string().nullable(),

  ohmonfrisession1: Yup.string().nullable()
  .when("is24hrclinic", {
    is: 0,
    then: Yup.string().required('Please enter required details')
  }),

  //healthScreeningDoctorList: Yup.array().min(1, 'Doctor is mandatory for colonoscopy.')

})


export const ClinicContactValidationSchema = Yup.object({

  contacttype: Yup.string().min(3,'Please select Contact Type')
    .required('Please select Contact Type'),


  contactdetails: Yup.string().required('Please Enter Contact Details')
      .when("contacttype", {
        is: 'email',
        then: Yup.string().email('Email is invalid')
      })
      .when("contacttype", {
        is: 'fax',
        then: Yup.string().required().min(8).max(12)
      })
      .when("contacttype", {
        is: 'contactnumber',
        then: Yup.string().required().min(8).max(12)
      })
})




export class HealthScreeningDoctor{
  doctorName:string = ''
  id: number = 0;
 
}

export const HealthScreeningDoctorValidationSchema = Yup.object({
  doctorName: Yup.string().required('Please enter doctor name')
  
})


export class HealthScreeningTypeOption {

}


export class HealthScreeningProfile {
  //healthScreeningType?:string = ''
  healthScreeningTypeForm : HealthScreeningType= new HealthScreeningType();
  effectedDate ?: string = ''
  terminationDate ?: string = ''
  id?: number
  error:string=''

}

export const HealthScreeningTypeValidationSchema = Yup.object({
  //healthScreeningType: Yup.string().required('Please select health screen type'),
  effectedDate: Yup.string().required('Please select effective date'),
  healthScreeningTypeForm: Yup.object().shape({
    id: Yup.number().moreThan(0, 'Please select screening type'),
  })
})



export class HealthScreeningFeeSchdule {
  healthScreeningFeeId:number=0;
  healthScreeningFee:HealthScreeningFee= new HealthScreeningFee();
  agreedFees: string = ''
  effectedDate ?: string = ''
  terminationDate ?: string = ''
  id: number = 0;
  error:string=''

}

export const HealthScreeningFeeSchduleValidationSchema = Yup.object({
  healthScreeningFeeId: Yup.number().moreThan(0, 'Please select type of charge'),
  agreedFees: Yup.number().required('Please enter agreed fees')
        .typeError("Please enter valid agreed fees")
        .nullable(true),
    // checking self-equality works for NaN, transforming it to null
    //.transform((_, val) => val === Number(val) ? val : null) ,
  effectedDate: Yup.string().required('Please select effective date'),
})

export class HealthScreeningAddBottle {
  agreedFees: string = ''
  effectedDate ?: string = ''
  terminationDate ?: string = ''
  id?: number
  error:string = '';

}

export const HealthScreeningAddBottleValidationSchema = Yup.object({
  agreedFees: Yup.number().required('Please enter agreed fees')
  .typeError("Please enter valid agreed fees") 
  .nullable(true),
  // checking self-equality works for NaN, transforming it to null
  //.transform((_, val) => val === Number(val) ? val : null) ,
  effectedDate: Yup.string().required('Please select effective date'),
})


