import { decode } from "html-entities"
import { Constants } from "../../../services"

//Error
type PropsInputError = {
    name: string
    code: string
    uen? : string
    type?: string
    formView?:boolean

  }
  const ClinicHeader: React.FC<PropsInputError> = ({name,code,uen,type,formView = false}) => {
    return (

        <div className="row">
            <div className={"mt-3 " + (formView ? 'form-col-full-width':'')}>
                <div className="d-none d-md-block">
                    <table className={Constants.DATATABLE_STYLE}>
                        <tbody>
                            <tr>
                                <td style={{width:'20%'}}><strong className='fs-7'>Clinic Name</strong></td><td style={{width:'30%'}}>{decode(name)}</td>
                                <td style={{width:'20%'}}><strong className='fs-7'>Clinic Code</strong></td><td style={{width:'30%'}}>{code}</td>
                            </tr>
                            {
                                uen !== undefined && type !== undefined && (
                                <tr>
                                    <td ><strong className='fs-7'>Clinic Type</strong></td><td >{type}</td>
                                    <td ><strong className='fs-7'>UEN Number</strong></td><td >{uen}</td>
                                </tr>
                            )}

                        </tbody>
                    </table>
                </div>
                <div className="d-md-none">
                    <table className={Constants.DATATABLE_STYLE}>
                        <tbody>
                            <tr><td style={{width:'35%'}}><strong className='fs-7'>Clinic Name</strong></td><td style={{width:'65%'}}>{decode(name)}</td></tr>
                            <tr><td ><strong className='fs-7'>Clinic Code</strong></td><td>{code}</td></tr>
                            {
                                uen !== undefined && type !== undefined && ( 
                                <>
                                    <tr><td ><strong className='fs-7'>Clinic Type</strong></td><td >{type}</td></tr>
                                    <tr><td ><strong className='fs-7'>UEN Number</strong></td><td >{uen}</td></tr>
                                </>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
  }

  export {ClinicHeader}