import React, { FC } from 'react';

import { PageLink, PageTitle } from '../../../../_theme/layout/core';
import { Navigate, Route, Routes } from 'react-router-dom';
import { SurveyConfigList } from './SurveyConfigList';
import { SurveyConfigAddEdit } from './SurveyConfigAddEdit';

type Props = {
  breadcrumbs: Array<PageLink>
}

const SurveyConfigModule: FC<Props> = ({breadcrumbs}) => {

  const usersBreadcrumbs = breadcrumbs.concat([
    {
      title: 'Survey Config',
      path: '/RatingSettings/SurveyConfig',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    }
  ])

  return (
    <>
    <Routes>
        <Route path='Search'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Search Survey Config</PageTitle>
              <SurveyConfigList />
            </>
          }
        />
        <Route path='Add'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Add Survey Config</PageTitle>
              <SurveyConfigAddEdit />
            </>
          }
        />
      <Route path='Edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Edit Survey Config</PageTitle>
              <SurveyConfigAddEdit />
            </>
          }
        />
      <Route index element={<Navigate to='/RatingSettings/SurveyConfig/Search' />} />
    </Routes>
    </>
  )
}

export {SurveyConfigModule}
