export interface DatatableResponse<T> {
    data : Array<T>,
    recordsTotal:number,
    recordsFiltered:number
}

export class DatatableInput {
    constructor(draw : number,length:number,start:number)
    {
        this.draw = draw
        this.length = length
        this.start = start
    }
    draw :number
    length:number
    start:number
}

