import { decode } from "html-entities"
import { Constants, UserType } from "../../services"

//Error
type PropsInputError = {
    name: string
    clinic?: string
    LANID? : string
    userType: string
    profileType:string
    formView?:boolean
  }
  const UserDetialsHeader: React.FC<PropsInputError> = ({name,clinic,LANID,userType,profileType,formView = false}) => {
    return (

        <div className="row">
            <div className={"mt-3 " + (formView ? 'form-col-full-width':'')}>
                <div className="d-none d-md-block">
                    <table className={Constants.DATATABLE_STYLE}>
                        <tbody>
                            <tr>
                                <td style={{width:'20%'}}><strong className='fs-7'>Name</strong></td><td style={{width:'30%'}}>{name}</td>
                                <td style={{width:'20%'}}><strong className='fs-7'>{userType === UserType.CLINICUSER.CODE ? 'Clinic':'LAN ID'}</strong></td><td style={{width:'30%'}}>{ userType === UserType.CLINICUSER.CODE ? decode(clinic) : LANID}</td>
                            </tr>
                                <tr>
                                    <td ><strong className='fs-7'>User Type</strong></td><td >{userType}</td>
                                    <td ><strong className='fs-7'>Profile Type</strong></td><td >{profileType}</td>
                                </tr>
                        </tbody>
                    </table>
                </div>
                <div className="d-md-none">
                    <table className={Constants.DATATABLE_STYLE}>
                        <tbody>
                            <tr><td style={{width:'35%'}}><strong className='fs-7'>Name</strong></td><td style={{width:'65%'}}>{name}</td></tr>
                            <tr><td ><strong className='fs-7'>{userType === UserType.CLINICUSER.CODE ? 'Clinic':'LAN ID'}</strong></td><td style={{width:'30%'}}>{ userType === UserType.CLINICUSER.CODE ? decode(clinic) : LANID}</td></tr>
                            <tr><td ><strong className='fs-7'>User Type</strong></td><td >{userType}</td></tr>
                            <tr><td ><strong className='fs-7'>Profile Type</strong></td><td >{profileType}</td></tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
  }

  export {UserDetialsHeader}