
import React, { FC } from 'react';

import { PageLink, PageTitle } from '../../../../_theme/layout/core';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ClinicUserList } from './ClinicUserList';
import { ClinicUserSearch } from './ClinicUserSearch';
import { ClinicUserAddEdit } from './ClinicUserAddEdit';
import { ClinicUserView } from './ClinicUserView';

type Props = {
  breadcrumbs: Array<PageLink>
}
const ClinicUserModule : FC<Props> = ({breadcrumbs}) => {

  return (
    <>
    <Routes>
        <Route path='clinicId/:clinicId'
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Clinic User</PageTitle>
              <ClinicUserList/>
            </>
          }
        />
        <Route path='Add/clinicId/:clinicId'
        element={
          <>
            <PageTitle breadcrumbs={breadcrumbs}>Add Clinic User</PageTitle>
            <ClinicUserAddEdit/>
          </>
        }
      />
      <Route path='Edit/:id/clinicId/:clinicId'
      element={
        <>
          <PageTitle breadcrumbs={breadcrumbs}>Edit Clinic User</PageTitle>
          <ClinicUserAddEdit/>
        </>
      }
    />
    <Route path='View/:id'
      element={
        <>
          <PageTitle breadcrumbs={breadcrumbs}>View Clinic User</PageTitle>
          <ClinicUserView/>
        </>
      }
    />
    </Routes>
    </>
  )
}



const ClinicUserSearchModule : FC = () => {

    const breadcrumbs: Array<PageLink> = [
      {
        title: 'Clinic User',
        path: '/ClinicUser',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

  return (
    <>
    <Routes>
        <Route path='Search'
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Clinic User</PageTitle>
              <ClinicUserSearch/>
            </>
          }
        />
          <Route index element={<Navigate to='/ClinicUser/Search' />} />
    </Routes>
    </>
  )
}

 export {ClinicUserModule ,ClinicUserSearchModule}
