import React, { useEffect, useState } from 'react';
import { SearchFormQuestion, SurveyQuestions} from './_models';
import { deleteById, searchSurveyQuestions } from './_requests';
import { KTCard, KTCardBody,KTCardHead } from '../../../../_theme/helpers';

import { AlertModal, ConfirmModal, Constants, Pagination, Loading, UserType, EmptyDatatable, DateToString, StringToDate } from '../../../services';
import { Link } from 'react-router-dom';
import { Status } from '../../../models/global/StatusResponce';
import { useAuth } from '../../auth';
import DataTable from 'react-data-table-component';
import {SurveyQuestionsTableColumns } from './_dataRowColumns';
import { DatatableInput } from '../../../models/global/DataTable';
import { FormikProvider, useFormik } from 'formik';
import { KTDatePickerBox, KTInputBox, KTInputCheckBox, KTSelectBox } from '../../../services/components/InputTemplates';
import { KeyValueOption } from '../../../models';

export function SurveyQuestionsList () {

  const {currentUser} = useAuth();
  const [isLoading, setLoading] = React.useState(true);
  const [dataList, setData] = React.useState(Array<SurveyQuestions>());
  const [statusResp, setStatusResp] = React.useState(new Status(0,""));
  const [isConfirmModal, setConfirmModal] = React.useState(false);
  const [isAlertModal, setAlertModal] = React.useState(false);
  const [countryId, setSurveyQuestionsId] = React.useState(0);
  const [PageNo, setPageNo] = React.useState(1);
  const [searchForm] = useState(new SearchFormQuestion(new DatatableInput(1,Constants.DATATABLE_PAGE_SIZE,0)));

  useEffect(()=>{
    fetchSurveyQuestions(searchForm,1);
  return ()=>{ 
  }
  // eslint-disable-next-line
 },[])

 const formik = useFormik({
  initialValues:searchForm,
  enableReinitialize:true,
  onSubmit: async (values) => {
    setLoading(true)
    fetchSurveyQuestions(values,0);
  },
})


const fetchSurveyQuestions = (searchForm:SearchFormQuestion,landingFlag:number)=>{
  searchForm.landingFlag=landingFlag;
  searchSurveyQuestions(searchForm).then(function (response) {
    setData(response.data.data);
    setLoading(false);
  })
}

  const confirmModal = (val?: number) => {
    setSurveyQuestionsId(val?val:0);
    setConfirmModal(true);
  }
  const closeModal = () => {
    setConfirmModal(false);
  }

  const closeDeleteModal = () => {
    setAlertModal(false);
    setLoading(true);
    fetchSurveyQuestions(searchForm,0);
  }
  
  const DeleteSurveyQuestions = async (val: any) => {
    try{
      setConfirmModal(false);
      await deleteById(parseInt(val)).then(function (response) {
        setStatusResp(response.data);
        setAlertModal(true);
      });
    }catch(e){
      console.log(e);
    }
  }

  

      return (
         <>
         {isLoading && (<Loading/>)}
         {isAlertModal ? (<AlertModal message={statusResp.message.toString()} data={statusResp}  handleClose = {closeDeleteModal}/>):<></>}
         {isConfirmModal ? (<ConfirmModal message={Constants.MESSAGE_DELETE_REQUEST} data={countryId} handleConfirm={DeleteSurveyQuestions} handleClose = {closeModal}/>):<></>}
          <KTCard>
            <KTCardHead title='SEARCH SURVEY QUESTIONS'>
              {
              currentUser?.usertypeName === UserType.ADMINUSER.CODE && (
              <div className="card-toolbar my-0">
                <Link className='btn btn-sm btn-secondary' to='/RatingSettings/SurveyQuestions/Add'>
                  ADD SURVEY QUESTIONS
                </Link>
              </div>)
              }
            </KTCardHead>
            <KTCardBody>
                
            <FormikProvider value={formik}>
                <form className='form w-100 my-5' onSubmit={formik.handleSubmit}>
                
                <div className="row">
                <div className="col-md-3 col-sm-6 my-2">
                  <KTInputBox placeholder='Question'  formikProps={formik.getFieldProps('question')}  />
                </div>
                <div className="col-xl-2 col-md-2 col-sm-6 my-2">
                      <KTSelectBox placeholder='Select Status'  formikProps={formik.getFieldProps('status')}  
                        optionsList={[new KeyValueOption('All','All'),new KeyValueOption('Both','Publish'),new KeyValueOption('unpublish','Unpublish')]}
                            onChangeHandler={(val) => {
                              formik.setFieldValue('status',val);
                              }
                            }
                        />
                      </div>
                  <div className="col-md-1 col-sm-12 mt-md-4 mt-1">For period</div>
                      <div className="col-xl-2 col-md-3 col-6 my-2">
                      <KTDatePickerBox placeholder='Enter From Date'  formikProps={formik.getFieldProps('fromdate')} 
                                            handleOnChange ={ 
                                              (date : Date) => {  formik.setFieldValue('fromdate',DateToString(date));formik.setFieldValue('todate','');
                                              }
                                            }
                                            />
                      </div>
                      <div className="col-xl-2 col-md-3 col-6 my-2">
                      <KTDatePickerBox placeholder='Enter To Date' 
                                            formikProps={formik.getFieldProps('todate')}
                                            handleOnChange ={ 
                                              (date : Date) => {
                                                formik.setFieldValue('todate',DateToString(date));
                                              }
                                            }
                                            DateRange={{start: StringToDate(formik.values.fromdate)}}
                                            />
                      </div>
                    </div>
                    <div className='row'>
                      <div className="col-xxl-1 col-xl-2 col-md-2 col-sm-12 mt-md-4 mt-1"> Question Type</div>
                      <div className="col-xxl-3 col-xl-3 col-md-6 col-10 my-2 d-flex">
                          <KTInputCheckBox formikProps={formik.getFieldProps('clinicqustype')} 
                                  handleOnChange ={ (val : boolean) => formik.setFieldValue('clinicqustype', val ? 1 : 0)}
                                  label='Clinic'
                                />                
                          <KTInputCheckBox formikProps={formik.getFieldProps('doctorqustype')} label='Doctor' className='ms-3'
                                  handleOnChange ={ (val : boolean) => formik.setFieldValue('doctorqustype', val ? 1 : 0)}
                                />                       
                      </div>                      
                      <div className="col-xxl-8 col-xl-7 col-md-4 col-2 my-2">
                        <button type="submit" id="search_submit" className="btn btn-sm btn-brand text-light float-end">
                          <span className="indicator-label">SEARCH</span>
                        </button>
                      </div>
                    </div>

                   
                <div className={Constants.DATATABLE_STYLE_REACT}>
                  <DataTable
                    columns={SurveyQuestionsTableColumns(PageNo,confirmModal)}
                    data={dataList}
                    pagination
                    onChangePage={(page: number) => {setPageNo(page);}}
                    paginationComponent={Pagination} 
                    noDataComponent={<EmptyDatatable columns={SurveyQuestionsTableColumns()}/>}                   
                  />
                </div>
                    </form>
                    </FormikProvider>

            </KTCardBody>
          </KTCard>
          </>
      )
}
