import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserType } from "../../services";
import { useAuth } from "../auth";
import { AppointmentDashboard } from "./components/_appointmentDashboard";
import { ClinicUserDashboard } from "./components/_clinicUserDashboard";

export function DashboardPage () {
    const {currentUser,permissions} = useAuth();
    const navigate = useNavigate();
  useEffect(()=>{
    if(currentUser?.usertypeName === UserType.CLAIMSTEAM.CODE)
        navigate('/CareTeam/Member/Search');
    if(currentUser?.usertypeName === UserType.CALLCENTER.CODE)
        navigate('/Appointments/Callcenter/Search');
  return ()=>{ 
  }
  // eslint-disable-next-line
 },[])



 return (
    <>
        { currentUser?.usertypeName === UserType.CLINICUSER.CODE && permissions?.canDashboardMenu === null && <ClinicUserDashboard/> }
        { 
            [UserType.CARETEAM.CODE,
                UserType.APPTTEAM.CODE,
                UserType.APPOINTMENTCENTER.CODE
            ].includes(currentUser?.usertypeName?currentUser?.usertypeName:'') 
            && 
            [UserType.CARETEAM.PROFILE.MANAGER_SUPERVISOR_APPT,
                UserType.CARETEAM.PROFILE.MEMBER_APPT,
                UserType.APPTTEAM.PROFILE.SUPERVISOR_APPT,
                UserType.APPTTEAM.PROFILE.USERS_APPT,
                UserType.APPOINTMENTCENTER.PROFILE.STAFF
            ].includes(currentUser?.profilemasterName?currentUser?.profilemasterName:'')
            && <AppointmentDashboard/> 
        }
    </>
    )
}
