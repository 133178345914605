import React, { FC } from 'react';

import { PageLink, PageTitle } from '../../../../_theme/layout/core';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PublishRatingsList } from './PublishRatingsList';
import { PublishRatingsView } from './PublishRatingsView';

type Props = {
  breadcrumbs: Array<PageLink>
}

const PublishRatingsModule: FC<Props> = ({breadcrumbs}) => {

  const usersBreadcrumbs = breadcrumbs.concat([
    {
      title: 'Publish Ratings',
      path: '/RatingSettings/PublishRatings',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    }
  ])

  return (
    <>
    <Routes>
        <Route path='Search'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Search Publish Ratings</PageTitle>
              <PublishRatingsList />
            </>
          }
        />
   
      <Route path='View/:id'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Publish Ratings Logs</PageTitle>
              <PublishRatingsView />
            </>
          }
        />
      <Route index element={<Navigate to='/RatingSettings/PublishRatings/Search' />} />
    </Routes>
    </>
  )
}

export {PublishRatingsModule}
