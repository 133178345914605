import {useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import { useAuth } from '../../auth';
import {encode as base64_encode} from 'base-64';
import { PreAuthSearch } from './_models';
import {UserType } from '../../../services';
import { LayoutSplashScreen } from '../../../../_theme/layout/core/SplashScreen';

export function PreAuthHandler() {

  const { refno } = useParams();
  const {currentUser} = useAuth()
  const navigate = useNavigate();

  useEffect(() => {
    if(refno === undefined || refno === '')
    {
      navigate("/error/404");
    }
    else{
      var _preAuth = new PreAuthSearch();
      _preAuth.paRefNo = refno;
      var jsonString = JSON.stringify(_preAuth);
      sessionStorage.setItem(base64_encode('_PRE_AUTHNUMBER'),base64_encode(jsonString)); 
      if(currentUser && currentUser.usertypeName === UserType.CLINICUSER.CODE)
        navigate("/ClinicAssistant/PreAuthDecisionAndLetter");
      else
        navigate("/");
    }
    // eslint-disable-next-line
  }, [refno])

  return (
  <><LayoutSplashScreen />
  </>
  )
}
