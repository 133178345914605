import React, { useEffect, useState } from 'react';
import { DoctorRatingWeightage, DoctorRatingWeightageSchema} from './_models';
import { save,getById } from './_requests';
import { KTCard, KTCardBody,KTCardFooter,KTCardHead } from '../../../../_theme/helpers';
import { useFormik } from 'formik';
import { Constants, DateToString, FormActionButtons, KTInputLabel, StatusResponseModal } from '../../../services';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { StatusResponse } from '../../../models/global/StatusResponce';
import { KTDatePickerBox, KTSelectBox } from '../../../services/components/InputTemplates';
import { KeyValueOption } from '../../../models';

export function DoctorRatingWeightageAddEdit () {

  const [isLoading, setLoading] = useState(true);
  const [statusResp, setStatusResp] = useState(new StatusResponse());
  const [isAlertModal, setAlertModal] = useState(false);
  const [DoctorRatingWeightageData, setDoctorRatingWeightage] = useState(new DoctorRatingWeightage());
  const [weightageRange] = useState(["0.00","0.25","0.50","0.75","1.00"]); //  //0.00,0.25,0.50,0.75,1.00
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(()=>{
    fetchDoctorRatingWeightageList();
  return ()=>{ 
  }
  // eslint-disable-next-line
 },[])



 const fetchDoctorRatingWeightageList = () => {
  if(id !== undefined && id && isLoading){
    getById(id).then(function (response) {
      setDoctorRatingWeightage(response.data);
      formik.resetForm({
        values:response.data
      })
      setLoading(false);
    })
  }else{
    setLoading(false);
  }
}

 const formik = useFormik({
    initialValues: DoctorRatingWeightageData,
    validationSchema: DoctorRatingWeightageSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setSubmitting(true)
      try {
        var saveResp = await save(values);
        setStatusResp(saveResp.data);
        setAlertModal(true);
        setLoading(false)
        setSubmitting(false)
      } catch (error) {
        try{
        }
        catch(_error){
            setStatus(Constants.MESSAGE_ERROR);
        }
        console.error(error)
      }
    },
  })

  
  const closeModal = () => {
    setAlertModal(false);
    if(statusResp.code===1)
    navigate("/RatingSettings/DoctorRatingWeightage");
  }
  


      return (
         <>
         {isAlertModal ? (<StatusResponseModal message={''} data={statusResp}  handleClose = {closeModal}/>):<></>}
          
          <KTCard>
            <KTCardHead title={((id == undefined || parseInt(id) === 0)?'Add':'Edit')+' Doctor Rating Weightage'}>
            </KTCardHead>
            <KTCardBody>
                <form className='form w-100 my-5' onSubmit={formik.handleSubmit} noValidate id='kt_country_add_edit_form' >
                   

                   
                <div className="row">
                      <div className='col-sm-6 d-md-flex my-2'>
                        <KTInputLabel required={true} title="Select Month" className='col-md-3' />
                        <div className="col-md-8">
                          <KTDatePickerBox placeholder='Enter Select Month'  formikProps={formik.getFieldProps('monthYear')} DatePickerMode='Month'
                              dateFormat='MM/yyyy'
                              active={formik.touched.monthYear}   error={formik.errors.monthYear} 
                              handleOnChange ={ 
                                (date : Date) => {
                                  formik.setFieldValue('monthYear',DateToString(date,'MM/yyyy'))
                                }
                              }
                          />

                        </div>
                      </div>

                      
                    </div>

                    <div className="row">
                      
                      <div className='col-sm-6 d-md-flex my-2'>
                        <KTInputLabel required={true} title="Internal Weightage" className='col-md-3' />
                        <div className="col-md-8">
                         
                          <KTSelectBox placeholder=' Select Internal Weightage' formikProps={formik.getFieldProps('internalWeightage')}
                                      active={formik.touched.internalWeightage}  
                                      error={formik.errors.internalWeightage}     
                                      optionsList={weightageRange.map((x) => {return new KeyValueOption(x,x.toString());})}
                                      onChangeHandler={(val) => {
                                        formik.setFieldValue('internalWeightage',val);
                                        let exW = 1-parseFloat(val);
                                        let exW2 = parseFloat(exW.toString()).toFixed(2);
                                        formik.setFieldValue('externalWeightage',exW2);
                                      }} 
                                />

                        </div>
                      </div>

                      <div className='col-sm-6 d-md-flex my-2'>
                        <KTInputLabel required={true} title="External Weightage" className='col-md-3' />
                        <div className="col-md-8">
                         
                          <KTSelectBox placeholder=' Select External Weightage' formikProps={formik.getFieldProps('externalWeightage')}
                                      active={formik.touched.externalWeightage}  
                                      error={formik.errors.externalWeightage}     
                                      optionsList={weightageRange.map((x) => {return new KeyValueOption(x,x.toString());})}
                                      onChangeHandler={(val) => {
                                        formik.setFieldValue('externalWeightage',val);
                                        let inW = 1-parseFloat(val);
                                        let inW2 = parseFloat(inW.toString()).toFixed(2);
                                        formik.setFieldValue('internalWeightage',inW2);
                                      }} 
                                />

                        </div>
                      </div>
                      
                    </div>


                    
                    <KTCardFooter className='d-flex justify-content-end mt-5'>
                      {/* begin::Action */}                           
                        <FormActionButtons isLoading={isLoading} id={id} backUrl='/RatingSettings/DoctorRatingWeightage' disabled={formik.isSubmitting || !formik.isValid } />
                        {/* end::Action */}
                    </KTCardFooter>


                </form>
            </KTCardBody>

          </KTCard>
          </>
      )
    
}


