import axios from 'axios'
import {AuthResponseModel} from '../../../models/AuthModel'
import {ResponseModel , RolePermissionModel, UserModel} from  '../../../models'
import { env } from '../../../App.env'
import { Status } from '../../../models/global/StatusResponce'

const API_URL = env.base_api_url

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/Api/Applicationuser/fetchCurrentUser`
export const GET_ROLE_PERMISSION_URL = `${API_URL}/Api/Clinic/getClinicField`
export const LOGIN_URL = `${API_URL}/Auth`
export const LOGOUT_URL = `${API_URL}/public/Api/logout/`
export const REQUEST_PASSWORD_URL = `${API_URL}/public/Api/forgotpassword`
export const REQUEST_USERID_URL = `${API_URL}/public/Api/forgotuserid`

// Server should return AuthModel
export function loginRequest(username: string, password: string) {
  return axios.post<AuthResponseModel>(LOGIN_URL, {
    afb5a33913eu : username,
    f00ef634f626p : password
  })
}

export function getCurrentUser(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}
export function getRolePermission() {
  return axios.get<RolePermissionModel>(GET_ROLE_PERMISSION_URL)
}
export function logout(username?: string) {
  return axios.post<ResponseModel>(LOGOUT_URL + username, {
  })
}
export function forgotPassword(param:any) {
  return axios.post<Status>(`${REQUEST_PASSWORD_URL}`,param)
}
export function forgotUserId(param:any) {
  return axios.post<Status>(`${REQUEST_USERID_URL}`,param)
}
export function resetPassword(param:any) {
  return axios.post<any>(`${API_URL}/public/GenerateOtpForResetPassword`,param)
}
export function verifyOtp(param:any) {
  return axios.post<Status>(`${API_URL}/public/verifyOtp`,param)
}
export function resendOtp(param:any) {
  return axios.post<AuthResponseModel>(`${API_URL}/public/resendOtp`,param)
}
export function verifyOtpForResetPassword(param:any) {
  return axios.post<Status>(`${API_URL}/public/verifyOtpForResetPassword`,param)
}
export function resendOtpForResetPassword(param:any) {
  return axios.post<AuthResponseModel>(`${API_URL}/public/resendOtpForResetPassword`,param)
}