import { Auditable } from "../../../models/global/Auditable"
import * as Yup from 'yup';
import { ClinicType } from "../../clinic-module/clinic/_models";
import { Speciality } from "../../settings/speciality/_models";


export class BroadcastMessage extends Auditable
{
  clinictypeForm?: ClinicType[]
  clinictypeids?: number[]
  fromDate: string = ''
  fromDateFlag: boolean = false
  id?: number
  message?: string
  messagetitle: string = ''
  shortMessage: string =''
  specialityids?: number[]
  specialtyBean?: Speciality[]
  toDate?: string
}

export class BroadcastMessageSearchForm {
  fromDate?: string
  messagetitle?: string
  toDate?: string
}


export const BroadCastMessageSchema = Yup.object({
  messagetitle: Yup.string()
    .max(128) 
    .required('Please Enter Message Title'),

  shortMessage: Yup.string()
    .max(512) 
    .required('Please Enter Short Message'),
  fromDate: Yup.string()
  .required('Please Enter From Date'),
  clinictypeids: Yup.array().min(1,'Please select Clinic Type')
  .required('Please select Clinic Type'),
  specialityids: Yup.array().min(1,'Please select Specialty')
  .required('Please select Specialty')
})