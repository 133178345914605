import {useEffect} from 'react'
import {Outlet, useNavigate} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
import {MenuComponent} from '../assets/ts/components'
import { SessionTimeout } from '../../app/services'
import { useAuth } from '../../app/modules/auth'
import { RouteAccess } from '../../app/services/lib/RouteAccess'

const MasterLayout = () => {
  const location = useLocation()
  const {currentUser} = useAuth()
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    if(currentUser && RouteAccess(currentUser,location.pathname) === false)
      navigate('/error/401');
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  // eslint-disable-next-line
  }, [location.key])

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />

          <div id='kt_content' className='content d-flex flex-column flex-column-fluid py-lg-5'>
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
          <Footer />
        </div>
      </div>

      {/* begin:: Modals */}
      {/* end:: Modals */}
      <ScrollTop />
      <SessionTimeout/>
    </PageDataProvider>
  )
}

export {MasterLayout}
