/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import { Route, Routes } from 'react-router-dom'
import { ProfilePage } from './profile/Profile'
import SuspendUserModule from './suspend-user/SuspendUserModule'

const UserModule: FC = () => {
  return (
    <>
    <Routes>
        <Route path='Profile'
          element={
            <>
              <ProfilePage/>
            </>
          }
        />
      <Route path='SuspendUser/*'
          element={
            <>
              <SuspendUserModule/>
            </>
          }
        />
    </Routes>
    </>
  )
}
export {UserModule}
