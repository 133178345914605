export const UserType = {
      ADMINUSER : { 
        CODE : 'ADMINUSER',
        PROFILE :{
          SUPER:	'Super Admin',
          MASTER:	'Master Admin',
          PORTAL:	'Portal Admin'
        }
      },
    CLINICUSER :{
      CODE : 'CLINICUSER',
        PROFILE :{
          MANAGER	:'Clinic Manager',
          ASSISTANT_WRITE	:'Clinic Assistant Write',
          ASSISTANT_READONLY	:'Clinic Assistant Readonly',
          HEALTH_SCREENING_ASSISTANT	:'Health Screening Assistant',
          HEALTH_SCREENING_MANAGER	:'Health Screening Manager',
          CLINIC_ASSISTANT_WITH_HEALTH_SCREENING	:'Clinic Assistant with Health Screening',
          CLINIC_MANAGER_WITH_HEALTH_SCREENING	:'Clinic Manager with Health Screening',

        }
    },
    CARETEAM  :{
      CODE : 'CARETEAM',
        PROFILE :{
          MANAGER_SUPERVISOR_APPT :	'Team manager or supervisor With Appt',
          MANAGER_SUPERVISOR:	'Team manager or supervisor',
          MEMBER_APPT:	'Team Member With Appt',
          MEMBER	:'Team Member'
        }
    },
    CLAIMSTEAM  :{
      CODE : 'CLAIMSTEAM',
        PROFILE :{
          TEAM :	'Claims Team'
        }
    },
    CALLCENTER  :{
      CODE : 'CALLCENTER',
        PROFILE :{
          TEAM: 'Call Center Team'
        }
    },
    APPTTEAM  :{
      CODE : 'APPTTEAM',
        PROFILE :{
          SUPERVISOR_APPT:	'Appt Team Supervisor',
          USERS_APPT	:'Appt Team Users',
        }
    },
    APPOINTMENTCENTER  :{
      CODE : 'APPOINTMENTCENTER',
        PROFILE :{
          STAFF :'Appointment Making Staff'
        }
      }
  };
  