import axios, {} from 'axios'
import { env } from '../../../App.env';
import { CodeExist, SearchForm } from '../../../models';
import { DatatableResponse } from '../../../models/global/DataTable';
import { Status, StatusResponse } from '../../../models/global/StatusResponce';
import { clean } from '../../../services';
import { Clinic,ClinicContactNumber,ClinicForm, ClinicSearchResult, ClinicTypeForms, LocationResponse} from './_models';

const API_URL = env.base_api_url


export function SearchClinicList(req: SearchForm) {
  return axios.post<DatatableResponse<ClinicSearchResult>>(`${API_URL}/Api/Clinic/searchClinicDetails`, req)
}

export function GetClinicById(id : number) {
  return axios.post<{clinicForms : Clinic}>(`${API_URL}/Api/Clinic/findById`, {id : id})
}

export function GetClinicTypeDetails() {
  return axios.get<ClinicTypeForms>(`${API_URL}/Api/Clinictype/getClinictypeDetails`)
}

export function fetchExistingClinicCode(code? : string,id : number = 0) {
 return axios.get<CodeExist>(`${API_URL}/Api/Clinic/fetchExistingClinicCode?clinicCode=${code}&id=${id}`)
}

export function saveOrUpdate(req: ClinicForm) {
  return axios.post<StatusResponse>(`${API_URL}/Api/Clinic/saveClinicDetails`, clean(req))
}

export function deleteClinicContact(id : number = 0) {
  return axios.get<{cliniccontactnumberForm:number,requestSuccess:boolean}>(`${API_URL}/Api/Cliniccontactnumber/deleteCliniccontactnumber?id=${id}`)
 } 
 export function getAllClinicContacts(id : number = 0) {
  return axios.get<{cliniccontactnumberForm: Array<ClinicContactNumber>,requestSuccess:boolean}>(`${API_URL}/Api/Cliniccontactnumber/getAllCliniccontactnumberByClinicId?id=${id}`)
 } 

 export function FetchLocationsByPincode(pincode : string) {
  return axios.get<LocationResponse>(`${API_URL}/Api/Clinic/fetchLocation/pincode/${pincode}`)
}
