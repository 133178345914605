import { decode } from "html-entities";
import { Link } from "react-router-dom";
import { ActionIcon, Constants, decodeAes } from "../../../services";
import { useAuth } from "../../auth";
import { StatusBadge } from "../../users/StatusBadge";
import { ClinicUser } from "./_models";


export function ClinicUserListTableColumns (handleConfirm?:any,pageNo?:number,clinicId?:string) {
const {permissions} = useAuth();

   var _col = [
        {
            name: 'NO.',
            cell: (row:ClinicUser,index:any) => <>
              {pageNo !==undefined && (1 + index + (pageNo - 1 ) * Constants.DATATABLE_PAGE_SIZE)}
              {pageNo ===undefined && (row.rowId)}
            </>,
            width:'60px'
        },
        {
        name: 'CLINIC USER',
        cell: (row:ClinicUser) =>
            <div className='lh-md py-2 align-self-baseline'> 
                <strong>
                    {clinicId !== undefined && <Link to={'/Clinic/ClinicUser/View/'+row.id}>{(row.applicationuserName)}</Link>}
                    {clinicId === undefined && <Link to={'/Clinic/ClinicUser/View/'+row.id}>{(row.applicationuserName)}</Link>}
                </strong><br/>
                <strong>Name: </strong>{row.name}<br/>
                <strong>NRIC: </strong>{decodeAes(row.nric)}<br/>

                {clinicId !== undefined && (
                  <>
                  <strong>DID Number: </strong>{row.did}<br/>
                  </>
                )}
                {clinicId === undefined && (
                  <>
                  <strong>Mobile Number: </strong>{row.mobile}<br/>
                  </>
                )}
                <strong>User Profile: </strong>{row.profileType}<br/>
                <strong>Last Login Date: </strong>{row.applicationuserForm.lastLoginRecord}
                {clinicId === undefined && (
                  <>
                  <br/><strong>Clinic Name: </strong>{decode(row.clinicName)}
                  <br/><strong>Clinic Code: </strong>{row.clinicCode}
                  </>
                )}
                <br/>
                <StatusBadge status={row.applicationuserForm.isActive} className='mt-2'/>
            </div>
        }
      ]
      if(permissions?.canAccessClinicUserAction !== null)
      {
        _col.push(
            {
            name: 'ACTIONS',
            width:'25%',
            cell: (row:ClinicUser) => <> 
            
            {permissions?.canEditClinicUser !== null &&
                <Link to={'/Clinic/ClinicUser/Edit/'+row.id+'/clinicId/'+row.clinic}>
                    <ActionIcon label="EDIT"/>
                </Link>
              }
            <Link to={'/User/SuspendUser/userId/'+row.applicationuserForm.id}>
                    <ActionIcon label={row.applicationuserForm.isActive === 'Suspended' ? 'UnSuspend Login' : 'Suspend Login'} icon="fa fa-ban" style={{'padding' : '0.4rem 0.5rem'}}/>
            </Link>
            {permissions?.canAccessClinicUserResetPassword !== null && row.applicationuserForm.lastLoginRecord !== null  &&
                <span onClick={() => handleConfirm('RESET',row.id,row.applicationuserForm.username)}>
                    <ActionIcon label='Reset Password' icon="fa fa-key"  style={{'padding' : '0.4rem 0.5rem'}}/>
                </span>
              }
              {row.applicationuserForm.lastLoginRecord === null &&
                <span onClick={() => handleConfirm('EMAIL',row.id,row.applicationuserForm.username)}>
                    <ActionIcon label='Send 1st Time Login Details' svg="/media/icons/duotune/communication/com002.svg"/>
                </span>
              }
            {row.applicationuserForm.isActive === 'Locked' &&
                <span onClick={() =>handleConfirm('UNLOCK',row.id,row.applicationuserForm.username)}>
                    <ActionIcon label='Unlock' icon="fa fa-unlock" style={{'padding' : '0.4rem 0.6rem'}}/>
                </span>
              }
              </>
        }
        )
      }
      return _col;
  
}
