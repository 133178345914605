import { Auditable } from "../../../models/global/Auditable"
import * as Yup from 'yup';
import { string } from 'yup';


export class InpatientAttendanceFeeForm{
  inpatientattendancefeeForm: InpatientAttendanceFee= new InpatientAttendanceFee();
  makercheckertasksForm: MakercheckertasksForm= new MakercheckertasksForm();;
}


export interface InpatientAttendanceFeeResponse {
  inpatientattendancefeeForm: InpatientAttendanceFeeForm[];
}

export interface InpatientAttendanceFeeResponseO {
  inpatientattendancefeeForm: InpatientAttendanceFeeForm;
}


export class InpatientAttendanceFee extends Auditable  {
  
  id?: number
  feescheduleId: number=0
  feeschedulename: string=""
  fromdate: string=""
  todate: string=""
  
  anesthetistafterofficehoursfee?: number=0.00
  anesthetisthdfee?: number=0.00
  anesthetisthdmaxvisits?: number=0
  anesthetisticufee: number=0.00
  anesthetisticumaxvisits: number=0
  anesthetistnormalhoursfee: number=0.00
  anesthetistnormalwardmaxvisits: number=0
  
  maindoctorafterofficehoursfee: number=0.00
  maindoctorhdfee: number=0.00
  maindoctorhdmaxvisits: number=0
  maindoctoricufee: number=0.00
  maindoctoricumaxvisits: number=0
  maindoctornormalhoursfee: number=0.00
  maindoctornormalwardmaxvisits: number=0
  
}


export class MakercheckertasksForm extends Auditable{
  id?: number;
  makercheckerstatus?: number;
  status: string='';
  makercheckertask?: number;
  taskname: string='';
  parentapproverid: number=0;
  approverName: string='';
  parentcreatorid: number=0;
  parenttaskreferenceid?: number;
  creatorremarks: string='';
  maker?: any;
  effectiveDate?: any;
  terminationDate?: any;
  newMakerCheckerFlag: boolean=false;
  assignTo: string='';
  creator: string='';
  approvedBy: string='';
  actualStatus?: any;
  latestStatus?: any;
  todateStatus?: any;
}

export class SearchFormAttendancefee {
  scheduleId:number=0
  makercheckerstatus:string=''
  fromDate:string=""
  ToDate:string=''
}



export const InpatientAttendanceFeeSchema = Yup.object({

  
  inpatientattendancefeeForm :Yup.object({
    fromdate: string().required('Please enter Effective Date.'),
    feescheduleId: Yup.number().required('Please select Schedule Name').min(1,'Please select Schedule Name'),

    anesthetistnormalhoursfee: Yup.number().required('Please Enter Normal hrs fee').min(1,'Please Enter Normal hrs fee'),
    anesthetistafterofficehoursfee: Yup.number().required('Please Enter After office hours Fees').min(1,'Please Enter After office hours Fees'),
    anesthetisticufee: Yup.number().required('Please Enter ICU charge').min(1,'Please Enter ICU charge'),
    anesthetisthdfee: Yup.number().required('Please Enter HDU charge').min(1,'Please Enter HDU charge'),
    
    anesthetisthdmaxvisits: Yup.number().required('Please Enter HDU charge').min(1,'Please Enter HDU charge'),
    anesthetistnormalwardmaxvisits: Yup.number().required('Please Enter Normal Ward').min(1,'Please Enter Normal Ward'),
    anesthetisticumaxvisits: Yup.number().required('Please Enter ICU charge').min(1,'Please Enter ICU charge'),

    maindoctornormalhoursfee: Yup.number().required('Please Enter Normal hrs fee').min(1,'Please Enter Normal hrs fee'),
    maindoctorafterofficehoursfee: Yup.number().required('Please Enter After office hours Fees').min(1,'Please Enter After office hours Fees'),
    maindoctoricufee: Yup.number().required('Please Enter ICU charge').min(1,'Please Enter ICU charge'),
    maindoctorhdfee: Yup.number().required('Please Enter HDU charge').min(1,'Please Enter HDU charge'),

    maindoctorhdmaxvisits: Yup.number().required('Please Enter HDU charge').min(1,'Please Enter HDU charge'),
    maindoctoricumaxvisits: Yup.number().required('Please Enter ICU charge').min(1,'Please Enter ICU charge'),
    maindoctornormalwardmaxvisits: Yup.number().required('Please Enter Normal Ward').min(1,'Please Enter Normal Ward'),
    
    }),

  makercheckertasksForm :Yup.object({
    parentapproverid: Yup.number().required('Please select Approver').min(1,'Please select Approver'),
    })
 
})
