import axios, {} from 'axios'
import { env } from '../../../App.env';
import { Status, StatusResponse } from '../../../models/global/StatusResponce';
import { SuspendUser } from './_models';

const API_URL = env.base_api_url

export function fetchSuspendUserListById(id : number = 0) {
  return axios.post<SuspendUser[]>(`${API_URL}/Api/Applicationuser/fetchSuspendApplicationUserListById?id=${id}`)
 } 
 export function SuspendUserSaveUpdate(req : SuspendUser) {
  return axios.post<StatusResponse>(`${API_URL}/Api/Applicationuser/suspendApplicationUser`,req)
 } 

 export function getUserDetialByApplicationUserId(id:any) {
  return axios.post<any>(`${API_URL}/Api/AdminUser/getAdminUserDetailsByApplicationUserId`,{id:id})
}
export function getSuspendApplicationUserById(id:any) {
  return axios.get<{Suspendedapplicationuser : SuspendUser}>(`${API_URL}/Api/Applicationuser/getSuspendApplicationUserByid?id=${id}`)
}
export function deleteSuspendedApplicationById(id:any) {
  return axios.get<Status>(`${API_URL}/Api/Applicationuser/deleteSuspendedApplicationById?id=${id}`)
}
