import React, { FC } from 'react';

import { PageLink, PageTitle } from '../../../../_theme/layout/core';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ClinicRatingWeightageList } from './ClinicRatingWeightageList';
import { ClinicRatingWeightageAddEdit } from './ClinicRatingWeightageAddEdit';

type Props = {
  breadcrumbs: Array<PageLink>
}

const ClinicRatingWeightageModule: FC<Props> = ({breadcrumbs}) => {

  const usersBreadcrumbs = breadcrumbs.concat([
    {
      title: 'Clinic Rating Weightage',
      path: '/RatingSettings/ClinicRatingWeightage',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    }
  ])

  return (
    <>
    <Routes>
        <Route path='Search'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Search Weightage</PageTitle>
              <ClinicRatingWeightageList />
            </>
          }
        />
        <Route path='Add'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Add Weightage</PageTitle>
              <ClinicRatingWeightageAddEdit />
            </>
          }
        />
      <Route path='Edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Edit Weightage</PageTitle>
              <ClinicRatingWeightageAddEdit />
            </>
          }
        />
      <Route index element={<Navigate to='/RatingSettings/ClinicRatingWeightage/Search' />} />
    </Routes>
    </>
  )
}

export {ClinicRatingWeightageModule}
