import axios from 'axios'
import { env } from '../../../App.env';
import { Status, StatusResponse } from '../../../models/global/StatusResponce';
import { SystemPasswordPolicy, SystemPolicyResponse, SystemPolicyResponseO } from './_models';

const API_URL = env.base_api_url
const API_CP = `${API_URL}/Api/Settings/Systempasswordpolicy`

export function getSystemPasswordPolicyList() {
  return axios.get<{systempasswordpolicyForm : SystemPasswordPolicy[]}>(`${API_URL}/Api/Settings/Systempasswordpolicy/getSystempasswordpolicyDetails`)
}
export function getSystemPasswordPolicy() {
  return axios.get<SystemPasswordPolicy>(`${API_URL}/public/Api/getActiveSystempasswordpolicy`)
}


export function searchSystemPolicy() {
  return axios.get<SystemPolicyResponse>(`${API_CP}/getSystempasswordpolicyDetails`)
}

export function getById(id: String) {
  return axios.get<SystemPolicyResponseO>(`${API_CP}/getSystempasswordpolicyById/${id}`)
}

export function save(req: SystemPasswordPolicy) {
  return axios.post<StatusResponse>(`${API_CP}/saveUpdateSystempasswordpolicy`,req)
}

export function deleteById(id: number) {
  return axios.post<Status>(`${API_CP}/deleteSystempasswordpolicy?id=${id}`)
}
