/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import { useAuth } from '../../../../app/modules/auth'

export function AsideMenuAIA() {
  const {currentUser} = useAuth();
  return (
    <>
    {
      currentUser?.menuList.map(menu => (
        menu.subMenu?.length > 0 ?
        (
          <AsideMenuItemWithSub key={menu.title.toLowerCase().toString()} to={menu.url} title={menu.title.toUpperCase()} icon={menu.icon} fontIcon={menu.icon} >
            {menu.subMenu.map(submenu => (
              <AsideMenuItem key={submenu.subMenuTitle.toLowerCase().toString()} to={submenu.subMenuUrl} title={submenu.subMenuTitle.toUpperCase()} hasBullet={true} />
            ))}
          </AsideMenuItemWithSub>
        )
      :
        (<AsideMenuItem key={menu.title.toLowerCase().toString()} to={menu.url} title={menu.title.toUpperCase()} icon={menu.icon} fontIcon={menu.icon} />)
        )
      ) 
    }  
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-2'></div>
        </div>
      </div>
    </>
  )
}
