import axios, {} from 'axios'
import { env } from '../../App.env';
import { UserModel } from '../../models';
import { Status } from '../../models/global/StatusResponce';
import { ChangePasswordRequest } from './profile/_models';
import { ProfileMaster, UserTypeMaster } from './_models';

const API_URL = env.base_api_url

export function fetchProfileType(usertype: String) {
  return axios.post<{profilemasterForms : Array<ProfileMaster>}>(`${API_URL}/Api/Profilemaster/fetchProfileType/${usertype}`)
}

export function fetchUsertypeList() {
  return axios.get<Array<UserTypeMaster>>(`${API_URL}/Api/Usertype/fetchUsertypeList`)
}

export function checkUserName(id:number,username:string) {
  return axios.post<boolean>(`${API_URL}/Api/Applicationuser/checkUserName`,{id:id,username:username})
}

export function checkLanId(id:number,lanid:string) {
  return axios.post<boolean>(`${API_URL}/Api/Applicationuser/checkLanId?lanid=`+lanid+`&id=`+id)
}
export function fetchCurrentUserDetails() {
  return axios.post<UserModel>(`${API_URL}/Api/Applicationuser/fetchCurrentUserDetails`)
}
export function unLockApplicationUser(_encdecid:any) {
  return axios.post<Status>(`${API_URL}/Api/Applicationuser/unLockApplicationUser`,{encdecid:_encdecid})
}
export function changePassword(param:ChangePasswordRequest) {
  return axios.post<Status>(`${API_URL}/Api/Applicationuser/changePassword`,param)
}

export function fetchUserNameByLanId(lanid:string) {
  return axios.get<any>(`${API_URL}/Api/AdminUser/fetchUserName/lanId/`+lanid)
}