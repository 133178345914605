import { decode } from "html-entities";
import { Link } from "react-router-dom";
import { ActionIcon, Constants } from "../../../services";
import { SurveyQuestions } from "./_models";


export function SurveyQuestionsTableColumns (pageNo:number = 0,confirm?:any) {

return  (
    [
        {
            name: 'NO.',
            cell: (row:SurveyQuestions,index:any) => (1 + index + (pageNo - 1 ) * Constants.DATATABLE_PAGE_SIZE),
            width:'65px'
        },
        {
            name: 'QUESTION',
            cell: (row:SurveyQuestions) => <>
            <div className='lh-md py-2 align-self-baseline'> 
                {decode(row.question)}
            </div>
            </>
        },

        {
            name: 'QUESTION TYPE',
            selector: (row:SurveyQuestions) => row.questiontypename,
        },

        {
            name: 'PUBLISH FROM DATE',
            selector: (row:SurveyQuestions) => row.publishFromdate,
        },

        {
            name: 'PUBLISH TO DATE',
            selector: (row:SurveyQuestions) => row.publishTodate,
        },

        {
            name: 'DISPLAY ORDER',
            selector: (row:SurveyQuestions) => row.displayOrder,
        },

        {
            name: 'CREATED BY',
            cell: (row:SurveyQuestions) => <>
            <div className='lh-md py-2 align-self-baseline'> 
                {row.applicationuserName}
            </div>
            </>
        },

       
        {
            name: 'QUESTION PUBLISHED?',
            cell: (row:SurveyQuestions) => <>
                <Link to={'/RatingSettings/SurveyQuestions/Publish/'+row.id}>
                    {row.publishFromdate===null && 'No'}
                    {row.publishFromdate!== null && row.status !== "Active" && "No"}
                    {row.publishFromdate!== null && row.status == "Active" && "Yes"}
                </Link>
            </>
        },
        {
            name: 'ACTIONS',
            maxWidth:'160px',
            cell: (row:SurveyQuestions) => <>

            {row.publishFromdate === null && <>
                <Link to={'/RatingSettings/SurveyQuestions/Edit/'+row.id}>
                <ActionIcon label='EDIT' />
              </Link>
            </>
            }                
              {row.publishFromdate !== null && row.status !== "Active" && <>
                <span onClick={() => confirm(row.id)}>
                    <ActionIcon label='DELETE'/>
                </span>
                </>
              }              
              </>
        }
      ]
  )
}
