import React, { useEffect } from 'react'
import {Modal} from 'react-bootstrap'
import { useIdleTimer } from 'react-idle-timer'
import {useAuth} from '../../modules/auth'
import {encode as base64_encode} from 'base-64';
import { Constants } from '../lib/Constants';
import { Loading } from './Common';
const SessionTimeout: React.FC = () => {

  const [show, setShow] = React.useState(false);
  const [seconds, setSeconds ] =  React.useState(60);
  const {logout} = useAuth()
  const onIdle = () => {
    setShow(true);
  }

  const continueSession = () =>{
    setShow(false);
    setSeconds(60);
  }

  useIdleTimer({ onIdle ,timeout:Constants.SESSION_TIMEOUT_IN_MINS * 60000}) 
  
  useEffect(()=>{
    let alertInterval = setInterval(() => {
            if (show && seconds > -1) {
                setSeconds(seconds - 1);
            }
            if(show && seconds < 1)
            {
                logout();                
                sessionStorage.setItem(base64_encode('_LOGOUT_REDIRECT'),base64_encode(Constants.LOGOUT_SESSION_TIMEOUT)); 
            }
            if(show && seconds < 0)
            {
              window.location.href = '/';
            }
        }, 1000);
        return ()=> {
            clearInterval(alertInterval);
          };
    });

  return (
    <>
    {seconds < 1 && (<Loading/>)}
    <Modal
      className='modal fade'
      id='kt_modal_session_timeout'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='modal-md'
      aria-hidden='true'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>Your session is about to Expire.</h5>
        </div>
        <div className='modal-body fs-4'>
            You'll be logged out in <span className='text-brand fw-bolder'>{seconds}</span> second(s).
            <p>Click <b>OK</b> to continue your session. </p>
        </div>
        <div className='modal-footer py-4'>
          <button type='button' className='btn btn-secondary btn-sm' onClick={continueSession}>
          OK
          </button>
        </div>
      </div>
    </Modal>
    </>
  )
}

export {SessionTimeout}
