import {decode as base64_decode, encode as base64_encode} from 'base-64';
import { LocalData, SearchForm } from '../../models';
import { AppointmentSearchForm } from '../../modules/appointment/_models';
import { DoctorSearchForm } from '../../modules/doctor-module/doctor/_models';
import { MakerCheckerTaskSearchForm } from '../../modules/operations/maker-checker-task/_models';
import { AdminSearchForm } from '../../modules/users/admin/_models';

const LOCAL_STORAGE_KEY_AIA = '_48HWNFEHCFE4YTX4'

const fetchLocalStorageData = (): LocalData => {
  if (sessionStorage) {
    try {
        const lsValue: string | null = sessionStorage.getItem(LOCAL_STORAGE_KEY_AIA)
        if(lsValue)
            {
            var jsontext = base64_decode(lsValue) as string;
            var form =  JSON.parse(jsontext) as LocalData
            return form;
            }
        } catch (error) {
                console.error('LOCAL STORAGE PARSE ERROR', error)
        }
    }
  return new LocalData();
}

const updateLocalStorageData = (val: LocalData) => {
  if (!sessionStorage) {
    return
  }
  try {
    var jsonString = JSON.stringify(val);
    var lsValue = base64_encode(jsonString)
    sessionStorage.setItem(LOCAL_STORAGE_KEY_AIA, lsValue)
  } catch (error) {
    console.error('LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeLocalStorageData = () => {
  if (!sessionStorage) {
    return
  }
  try {
    sessionStorage.removeItem(LOCAL_STORAGE_KEY_AIA)
  } catch (error) {
    console.error('LOCAL STORAGE REMOVE ERROR', error)
  }
}

export class StorageHelper {

   public static DeleteLocalStorageData() : void {
      removeLocalStorageData();
    }
   public static setClinicSearchForm(val :SearchForm) : void {
        var old = fetchLocalStorageData();
        old.ClinicSearch = val;
        updateLocalStorageData(old);        
    }
    public static getClinicSearchForm() : SearchForm {
        return fetchLocalStorageData().ClinicSearch
    }
    public static setDoctorSearchForm(val :DoctorSearchForm) : void {
      var old = fetchLocalStorageData();
      old.DoctorSearch = val;
      updateLocalStorageData(old);        
  }
  public static getDoctorSearchForm() : DoctorSearchForm {
      return fetchLocalStorageData().DoctorSearch
  }
  public static setClinicUsersSearchForm(val :SearchForm) : void {
    var old = fetchLocalStorageData();
    old.ClinicUserSearch = val;
    updateLocalStorageData(old);        
  }
  public static getClinicUsersSearchForm() : SearchForm {
      return fetchLocalStorageData().ClinicUserSearch
  }
  public static setAdminUsersSearchForm(val :AdminSearchForm) : void {
    var old = fetchLocalStorageData();
    old.AdminSearchForm = val;
    updateLocalStorageData(old);        
  }
  public static getAdminUsersSearchForm() : AdminSearchForm {
      return fetchLocalStorageData().AdminSearchForm
  }
  public static setTasksSearchForm(val :MakerCheckerTaskSearchForm) : void {
    var old = fetchLocalStorageData();
    old.TaskSearchForm = val;
    updateLocalStorageData(old);        
  }
  public static getTasksSearchForm() : MakerCheckerTaskSearchForm {
      return fetchLocalStorageData().TaskSearchForm
  }
  public static setAppointmentSearchForm(val :AppointmentSearchForm,_type : 'CARETEAM' | 'CALLCENTER' | 'MYOWN' | 'NEW' | 'PENDING' | 'SMSNOTSENT' | 'SMSLOG') : void {
    var old = fetchLocalStorageData();
    var i = old.AppointmentSearch.findIndex(x=>x.type === _type);
    if(i>0)
      old.AppointmentSearch[i].AppointmentSearchForm = val;
    else{
      old.AppointmentSearch.push({type:_type,AppointmentSearchForm:val})
    }
    updateLocalStorageData(old);        
  }
  public static getAppointmentSearchForm(_type : 'CARETEAM' | 'CALLCENTER' | 'MYOWN' | 'NEW' | 'PENDING' | 'SMSNOTSENT' | 'SMSLOG') : AppointmentSearchForm {
    var old = fetchLocalStorageData();
    var i = old.AppointmentSearch.findIndex(x=>x.type === _type);
    if(i>0)
      return old.AppointmentSearch[i].AppointmentSearchForm;
    else{
      return old.AppointmentSearch[0].AppointmentSearchForm;
    }
  }
  public static setBackURL() {
    var old = fetchLocalStorageData();
    old.backURL = window.location.pathname;
    updateLocalStorageData(old);
  }
  public static getBackURL() : string {
    var old = fetchLocalStorageData();
    return old.backURL;
  }
};

