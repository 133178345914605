import { Auditable } from "../../../models/global/Auditable"
import * as Yup from 'yup';
import { string } from 'yup';


export interface UploadInternalRatingsResponse {
  UploadInternalRatingsForm: UploadInternalRatings[];
}

export class SearchForm {
  formdate: string='';
  todate: string='';
  clinicinternalrating: number=0;
  doctorinternalrating: number=0;
}

export class RatingType{
  ratingtypeId: number=0;
  ratingtypeName: string="";
}

export class UploadInternalRatings extends Auditable{
  id?: number;
  ratingtypeId:  number=0;
  ratingtypeName: string='';
  filename: string='';
  noofuploadedrecord:  number=0;
  noofsuccessfulrecord:  number=0;
  nooffailedrecord:  number=0;
  email: string='';
  filePath?: any;
  uploadedData?: any;
  clinicinternalrating:  number=0;
  doctorinternalrating:  number=0;
  formdate?: any;
  todate?: any;
  createdName: string='';
  dateofupload: string='';
}

export const UploadInternalRatingsSchema = Yup.object().shape({
  filename: string().required('Please Upload File'),
  ratingtypeId: Yup.number().moreThan(0, 'Please Select Rating Type.'),
})

