import  { useEffect, useState } from 'react';
import { SurveyQuestions, SurveyQuestionsPublish} from './_models';
import { FetchAllSurveypublishedquestions, getById } from './_requests';
import { KTCard, KTCardBody,KTCardHead, KTCardHeadToolBar } from '../../../../_theme/helpers';
import { Constants} from '../../../services';
import { Link, useNavigate, useParams } from 'react-router-dom';

export function SurveyQuestionViewLog () {

  const [isLoading, setLoading] = useState(true);
  const [surveyQuestionsPublishList, setSurveyQuestionsPublishList] = useState(Array<SurveyQuestionsPublish>());
  
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(()=>{
    fetchSurveyQuestionsLog();
  return ()=>{ 
  }
  // eslint-disable-next-line
 },[])



 const fetchSurveyQuestionsLog = () => {
  if(id !== undefined && id && isLoading){
    FetchAllSurveypublishedquestions(id).then(function (response) {
      setSurveyQuestionsPublishList(response.data);
     
      setLoading(false);
    })
  }else{
    setLoading(false);
  }
}



      return (
         <>
         
          <KTCard>
            <KTCardHead title={'PUBLISH QUESTIONS VIEW LOG'}>
            <KTCardHeadToolBar>
              <Link className='btn btn-sm btn-secondary' to={'/RatingSettings/SurveyQuestions/Publish/'+id}>BACK</Link>
            </KTCardHeadToolBar>
            </KTCardHead>

            <KTCardBody>

          
               
            <table className={Constants.DATATABLE_STYLE}>
              <thead>
              <tr >
                
                <th className='col-2'>START DATE</th>
                <th className='col-2'>END DATE</th>
                <th className='col-2'>DISPLAY ORDER</th>
                <th className='col-2'>CREATED BY</th>
                <th className='col-2'>DEACTIVATED DATE</th>

              </tr>
              </thead>

              {surveyQuestionsPublishList.map((row, index) => (
               <tr key={index}>
                
                <td >   {row.fromdate}     </td>
                <td > {row.todate} </td>
                <td > {row.displayorder} </td>
                <td >  {row.createdName} </td>
                <td > {row.modifiedtime} </td> 
               </tr>
             ))}
              </table>

            </KTCardBody>

          </KTCard>
          </>
      )
    
}


