/* eslint-disable jsx-a11y/anchor-is-valid */
import {Route, Link, Routes, Outlet} from 'react-router-dom'
import {Error500} from './components/Error500'
import {Error404} from './components/Error404'
import {toAbsoluteUrl} from '../../../_theme/helpers'
import { Error401 } from './components/Error401'
import { useAuth } from '../auth'

const ErrorsLayout = () => {
  const {currentUser} = useAuth()
  return (
    <>
    <div className='d-flex flex-column flex-root bg-brand'>
      <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={{backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/progress-hd.png')}')`}}
      >
        <div className='d-flex flex-column flex-column-fluid text-center p-10 py-lg-20'>
          <a href='/Dashboard' className='mb-10 pt-lg-20'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/aia.png')}
              className='h-150px mb-5'
            />
          </a>
          <div className='pt-lg-10 mb-10'>
            <Outlet />
            <div className='text-center'>
              <Link to='/' className='btn btn-sm btn-secondary fw-normal fs-4'>
                 {currentUser ? <>Go to Dashboard</> : <>Go to Login</>} 
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

const ErrorsPage = () => (
  <Routes>
    <Route element={<ErrorsLayout />}>
      <Route path='404' element={<Error404 />} />
      <Route path='500' element={<Error500 />} />
      <Route path='401' element={<Error401 />} />
      <Route index element={<Error404 />} />
    </Route>
  </Routes>
)

export {ErrorsPage}
