export class Status{
    constructor(code:number,message:string)
    {
        this.code = code
        this.message = message
    }
    code:number = 0;
    message: string = '';
    status?:number |null;
}

export class StatusResponse {
    code:number = 0;
    id:number = 0;
    reason: string ='';
    statusList : Status[] = [];
}
