import clsx from 'clsx'
import { FieldInputProps} from 'formik'
import React, { useEffect, useState } from 'react'
import { KeyValueOption } from '../../models'
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { DateToString, StringToDate } from './Common';
import { decode } from 'html-entities';
import { HolidayLeave } from '../../modules/settings/holiday-master/_models';
import { ReactDateRange } from '../../models/global/ReactDate';
//Error
type PropsInputError = {
  active?:boolean
  error?: string
  textClass?:string
}
const KTInputError: React.FC<PropsInputError> = ({active,error,textClass ='text-danger'}) => {
  return (
    <>
    {active && error ? (
            <div className={'fv-plugins-message-container ' + textClass }>
            <div className='fv-help-block'>
            <span role='alert'>{error}</span>
            </div>
        </div>)
        :<></>
    }
  </>
  )
}

//Label
type PropsInputLabel = {
  required?:boolean
  readOnly?:boolean
  title?: string
  className?:string
}
const KTInputLabel: React.FC<PropsInputLabel> = ({required = false,title,className,readOnly = false}) => {
  return (
  <>
    <label  className={'form-label px-1 text-gray-900 ' +(required === false ?'py-1 ':'') + (className?className:'')}>
      {required && !readOnly ?(<span className="required">{title}</span>):<>{title}</>}
      </label>
  </>
  )
}

//InputBox
type PropsInputDisplay = {
  text?:string
  className?:string
}
const KTDisplayBox: React.FC<PropsInputDisplay> = ({text,className}) => {
  return (
    <div className={'form-control form-control-sm display-box '+ (className?className:'')}>{decode(text)}</div>
  )
}

//InputBox
type PropsInput = {
  active?:boolean
  trim?:boolean
  error?: string
  renderError?: boolean
  placeholder?:string
  maxLength?:number
  className?:string
  formikProps?: FieldInputProps<any>
  readOnly?:boolean
  type?: 'Default' | 'Digit' | 'AlphaNum' | 'Decimal' | 'Password'
  onKeyUpHandler?: () => any
  
}
const KTInputBox: React.FC<PropsInput> = ({active,trim,error,renderError=true,placeholder,maxLength,formikProps,className,type = 'Default',readOnly=false,onKeyUpHandler}) => {

  var inputProps = {
    placeholder: placeholder,
    type: type === 'Password' ? type :'text',
    autoComplete:'off',
    disabled : readOnly,
    ...(maxLength && { maxLength:maxLength })
  };

  const onKeyUp = (e:any) => {

    if(trim){
      e.target.value = e.target.value.trim();
    }

    if(onKeyUpHandler)
      onKeyUpHandler();
    } 

  const onKeyDown = (e:any,v:any) => {

    var ignoreKeys = ['Backspace','Shift','Tab','CapsLock','Control','Home','End','Delete','ArrowLeft','ArrowRight','ArrowUp','ArrowDown'];
    if(ignoreKeys.includes(e.key) || type === 'Default')
      return true;

    var RE_DIGIT_ONLY = /^\d+$/;
    var RE_ALPHANUM = /^[0-9a-zA-Z]+$/;
    var RE_DECIMAL_8_2 = /^(([0-9]{0,8})|(([0-9]{0,8})\.([0-9]{0,2})))$/;
    var f = true;
    switch (type) {
      case 'Digit' :  f = (RE_DIGIT_ONLY.test(e.key)) ; break;
      case 'AlphaNum' :  f = (RE_ALPHANUM.test(e.key)) ; break;
      case 'Decimal' :  f = (RE_DIGIT_ONLY.test(e.key) || e.key === '.' ) && RE_DECIMAL_8_2.test(v+e.key) ; break;
    }
    //if(!f)
      //e.preventDefault();
    return f;
  } 

  return (
    <>
    <input
     {...inputProps}
     {...formikProps}
     onKeyDown={(evt) => {return onKeyDown(evt,formikProps?.value);}}
     onKeyUp={onKeyUp}
     className={clsx('form-control form-control-sm '+ className,{ 'is-invalid': active && error})}
     style={{minWidth:'120px'}}
    />
    {renderError && (<KTInputError active={active} error={error} />)}
    </>
  )
}

//SelectBox
type PropsSelect = {
  active?:boolean
  error?: string
  renderError?: boolean
  placeholder?:string
  className?:string
  defaultPlaceholder?:boolean
  formikProps?: FieldInputProps<any>
  optionsList?:KeyValueOption[],
  only?:boolean,
  dafaultValue?:number|string;
  onChangeHandler?: (param: string) => any
  readOnly?:boolean
}
const KTSelectBox: React.FC<PropsSelect> = ({active,error,renderError=true,defaultPlaceholder=true,placeholder='-Select-',readOnly=false,dafaultValue=0,formikProps,optionsList,className,onChangeHandler}) => {

  var selectProps = {
    disabled : readOnly
  };

  const onChange = (e:any) => {
    if(onChangeHandler)
      onChangeHandler(e);
    } 

  return (
    <>
    <select
    {...selectProps}
     {...formikProps}
     onChange={(e) => { onChange(e.target.value)}}

     className={clsx('form-select form-select-sm '+className ,{ ' is-invalid': active && error})}
     style={{minWidth:'80px'}}
    >
      {defaultPlaceholder && (<option value={dafaultValue} key={dafaultValue} >{placeholder}</option>)}
        {optionsList?.map((el:KeyValueOption) => (
          <option key={el.key.toString()} value={el.key.toString()}>{el.value}</option>
        ))}
    </select>
    {renderError && (<KTInputError active={active} error={error} />)}
    </>
  )
}

//InputBox

type PropsDatePicker= {
  active?:boolean
  error?: string
  renderError?: boolean
  placeholder?:string
  dateFormat?: 'dd-MMM-yyyy'|'dd/MM/yyyy' |'MM/yyyy' | 'yyyy'
  formikProps?: FieldInputProps<any>
  readOnly?:boolean  
  className?:string
  showYearPicker?:true;
  handleOnChange?: (param: Date) => void
  DatePickerType?: 'All' | 'Future' | 'Today&Future' | 'Past' | 'Today&Past'
  DatePickerMode?: 'Date' | 'Month' | 'Year'
  DateRange?:{
    start?:Date
    end?:Date
  },
  _holidays?: HolidayLeave[]
  includeDates?:Date[]
  includeDateIntervals?:ReactDateRange[] 
}
const KTDatePickerBox: React.FC<PropsDatePicker> = ({active,error,renderError=true,placeholder='DD-MMM-YYYY',dateFormat='dd-MMM-yyyy',showYearPicker,readOnly=false,_holidays,includeDates,includeDateIntervals,formikProps,DatePickerType,DatePickerMode='Date',DateRange,className='',handleOnChange}) => {

  const [startdate, setStartDate] = useState(new Date());
  const [enddate, setEndDate] = useState(new Date());
  const [date, setDate] = useState<Date | null>(null);
  const [defaultDate, setDefaultDate] = useState(false);
  const [yearPicker, setYearPicker] = useState(false);
  const [monthPicker, setMonthPicker] = useState(false);

  //_holidays = [new HolidayLeave('09-Nov-2022','National Day'),new HolidayLeave('10-Nov-2022','National Day') ];
  

  const onInit = () => {
    if(DatePickerMode === 'Date'){
      setMonthPicker(false);
      setYearPicker(false);
    }
    if(DatePickerMode === 'Month'){
      setMonthPicker(true);
    }
    if(DatePickerMode === 'Year'){
      setYearPicker(true);
    }
  }

  const onChange = (date:Date) => {
    if(DatePickerMode === 'Year')
    {
      if(handleOnChange){
        handleOnChange(date)
       }
    }
    if(DatePickerMode === 'Month')
    {
      if(yearPicker)
      {
        setMonthPicker(true);
        setYearPicker(false);
      }
      else{
       if(handleOnChange){
        handleOnChange(date)
       }
      }
    }
    if(DatePickerMode === 'Date')
    {
      if(yearPicker)
      {
        setMonthPicker(true);
        setYearPicker(false);
      }
      else if(monthPicker)
      {
        setMonthPicker(false);
      }
      else{
       if(handleOnChange){
        handleOnChange(date)
       }
      }
    }
    setDate(date);
  }

  useEffect(()=>{

    var sd = new Date();
    var ed = new Date();
    switch(DatePickerType)
    {
      case 'Future':
        sd.setDate(sd.getDate() + 1);
        ed.setFullYear(ed.getFullYear() + 100);
      break;
      case 'Today&Future':
        ed.setFullYear(ed.getFullYear() + 100);
      break;
      case 'Past':
        sd.setFullYear(sd.getFullYear() - 100);
        ed.setDate(sd.getDate() - 1);
      break;
      case 'Today&Past':
        sd.setFullYear(sd.getFullYear() - 100);
      break;
      case 'All':
      default : 
        sd.setFullYear(sd.getFullYear() - 100);
        ed.setFullYear(ed.getFullYear() + 100);
      break;
    }
    
    if(DateRange?.start)
      setStartDate(DateRange?.start);
    else 
      setStartDate(sd);
    if(DateRange?.end)
      setEndDate(DateRange?.end);
    else 
      setEndDate(ed);
    
  return ()=>{ 
  }
 },[DateRange,DatePickerType,DatePickerMode])

 useEffect(()=>{
  if(formikProps && formikProps.value !== undefined && formikProps.value!==null && formikProps.value!== '' && defaultDate === false ){
    setDefaultDate(true);
    var d = StringToDate(formikProps.value,dateFormat);
    setDate(d);
  }
  return ()=>{ 
  }
  // eslint-disable-next-line
},[formikProps?.value])


 const MinDate = () => {
  if(monthPicker){
    return new Date(startdate.getFullYear(), startdate.getMonth(), 1);
  }  
  if(yearPicker){
   var k =  new Date(startdate.getFullYear(), 0, 1);
   return k;
  }
  return startdate;
  
}

const MaxDate = () => {
  if(monthPicker){
    return new Date(enddate.getFullYear(), enddate.getMonth()+1, 0);
  }  
  if(yearPicker){
    return new Date(enddate.getFullYear()+1,11, 0);
  }
  return enddate;
}

  return (
    <>
    <DatePicker 
      {...formikProps}
      autoComplete= {'off'}
      placeholderText ={placeholder}
      className={clsx('form-control form-control-sm ' + (className?className:''),{ 'is-invalid': active && error && date == null})}
      selected={date}
      isClearable={!readOnly}
      fixedHeight
      showMonthYearPicker = {monthPicker}
      showYearPicker = {yearPicker}
      disabled={readOnly}
      minDate={MinDate()}
      maxDate={MaxDate()}
      dateFormat={dateFormat}
      shouldCloseOnSelect ={(DatePickerMode === 'Date' && !monthPicker && !yearPicker) || (DatePickerMode === 'Month' && !yearPicker) || (DatePickerMode === 'Year')}
      onSelect={(date:Date) => {onChange(date)}}
      onChange={(date:Date) => {onChange(date)}}
      onCalendarOpen = {onInit}
      dayClassName={(date) =>  _holidays?.find(x=>x.date === DateToString(date))? "day-event" : "" }
      includeDates={includeDates}
      includeDateIntervals={includeDateIntervals}

      renderCustomHeader = {({ date, changeYear,monthDate,customHeaderCount,decreaseMonth, increaseMonth,prevMonthButtonDisabled,nextMonthButtonDisabled,decreaseYear,increaseYear, prevYearButtonDisabled,nextYearButtonDisabled}) => (
        <>
         {!prevYearButtonDisabled && yearPicker && (<button onClick={()=> changeYear(date.getFullYear()-12)} type="button" className="react-datepicker__navigation react-datepicker__navigation--previous" aria-label="Previous Month"><span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">Previous Month</span></button>)}
          {!prevYearButtonDisabled && monthPicker && (<button onClick={decreaseYear} type="button" className="react-datepicker__navigation react-datepicker__navigation--previous" aria-label="Previous Month"><span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">Previous Month</span></button>)}
          {!prevMonthButtonDisabled && !monthPicker && !yearPicker && (<button onClick={decreaseMonth} type="button" className="react-datepicker__navigation react-datepicker__navigation--previous" aria-label="Previous Month"><span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">Previous Month</span></button>)}
          <span onClick={() =>{
            if(!monthPicker && !yearPicker) { setMonthPicker(true);setYearPicker(false);}
            if (monthPicker && !yearPicker) { setMonthPicker(false); setYearPicker(true);}
          }} className="react-datepicker__current-month text-dark text-hover-primary">
            {!monthPicker && !yearPicker && (<>{ format(date, "MMMM")+' '+ date.getFullYear()}</>)}
            {monthPicker && (<>{date.getFullYear()}</>)}
            {yearPicker && (<>{
                  (date.getFullYear() + 1 - (date.getFullYear() % 12)) + ' - ' + (date.getFullYear() + 1 +  (date.getFullYear() % 12))
            }</>)}
          </span>
          {!nextMonthButtonDisabled && !monthPicker && !yearPicker && (<button onClick={increaseMonth} type="button" className="react-datepicker__navigation react-datepicker__navigation--next" aria-label="Next Month"><span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">Next Month</span></button>)}
          {!nextYearButtonDisabled && monthPicker && (<button onClick={increaseYear} type="button" className="react-datepicker__navigation react-datepicker__navigation--next" aria-label="Next Month"><span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">Next Month</span></button>)}
          {!nextYearButtonDisabled && yearPicker && (<button onClick={()=> changeYear(date.getFullYear()+12)} type="button" className="react-datepicker__navigation react-datepicker__navigation--next" aria-label="Next Month"><span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">Next Month</span></button>)}
        </>
      )}

      />
      {renderError && date == null && (<KTInputError active={active} error={error} />)}
    </>
  )
}


//RadioBox
type PropsInputRadio = {
  active?:boolean
  error?: string
  renderError?: boolean
  className?:string
  formikProps?: FieldInputProps<any>
  readOnly?:boolean
  optionsList?:KeyValueOption[]  
  disabledList?:KeyValueOption[]
  handleOnChange?: (val: any) => void
}
const KTInputRadioBox: React.FC<PropsInputRadio> = ({active,error,renderError=true,optionsList,disabledList,formikProps,className,handleOnChange,readOnly=false}) => {


  const [checkedValue, setCheckedValue] = useState<number|undefined>();

  useEffect(()=>{
    setCheckedValue(formikProps?.value); 
  return ()=>{ 
  }
 },[formikProps?.value])

  const onChange = (val : any) => {
    if(handleOnChange)
      handleOnChange(val);
  }

  return (
    <> 
    <div className={'form-check form-check-custom form-check-sm py-2 ' + (className?className:'') } >
      {
        optionsList?.map(o => (
          <div key={'rdo-input-'+formikProps?.name+'-'+o.value} className='mx-2 d-flex'>
            {checkedValue === o.key && (<input checked = {true} value={o.key.toString()} type={'radio'} className="form-check-input" id={'rdo-'+formikProps?.name+'-'+o.value} name={formikProps?.name} disabled ={readOnly || disabledList?.map(x=> {return x.key}).includes(o.key)} onChange ={()=>onChange(o.key)}/>)}
            {checkedValue !== o.key && (<input checked = {false} value={o.key.toString()} type={'radio'} className="form-check-input" id={'rdo-'+formikProps?.name+'-'+o.value} name={formikProps?.name} disabled ={readOnly || disabledList?.map(x=> {return x.key}).includes(o.key)} onChange ={()=>onChange(o.key)}/>)}
            <label  className="form-check-label" htmlFor={'rdo-'+formikProps?.name+'-'+o.value} >{o.value}</label>
          </div>
        ))
      }
    </div>
    {renderError && (<KTInputError active={active} error={error} />)}
    </>
  )
}

//CheckBox
type PropsInputCheck = {
  active?:boolean
  error?: string
  renderError?: boolean
  className?:string
  formikProps?: FieldInputProps<any>
  readOnly?:boolean
  label?:string
  handleOnChange?: (val : boolean) => void
}
const KTInputCheckBox: React.FC<PropsInputCheck> = ({active,error,renderError=true,formikProps,className,handleOnChange,label,readOnly=false}) => {

  const [ischecked, setIsChecked] = useState<boolean>(false);

  const onChange = (val : any) => {
    if(handleOnChange)
      handleOnChange(val.currentTarget.checked);
  }

  useEffect(()=>{
    setIsChecked(formikProps?.value === 1 || formikProps?.value==true); 
  return ()=>{ 
  }
 },[formikProps?.value])

  return (
    <> 
    <div className={'form-check form-check-custom form-check-sm py-2 ' + (className? className : '')}>
      {ischecked && (<input type={'checkbox'} className="form-check-input" id={'rdo-'+formikProps?.name} name={formikProps?.name} checked = {true} disabled ={readOnly} onChange ={onChange}/>)}
      {!ischecked && (<input type={'checkbox'} className="form-check-input" id={'rdo-'+formikProps?.name} name={formikProps?.name} checked = {false} disabled ={readOnly} onChange ={onChange}/>)}
    </div>    
    {label && 
    <label htmlFor={'rdo-'+formikProps?.name} className='align-self-center mt-2 ms-2 form-label px-1 text-dark py-1'>{label}</label> }
    {renderError && (<KTInputError active={active} error={error} />)}
    </>
  )
}

type PropsTextArea = {
  active?:boolean
  error?: string
  renderError?: boolean
  placeholder?:string
  showLengthCount?:boolean;
  maxLength?:number
  rows?:number
  className?:string
  formikProps?: FieldInputProps<any>
  readOnly?:boolean
  onKeyUpHandler?: () => any  
}
const KTTextAreaBox: React.FC<PropsTextArea> = ({active,error,renderError=true,maxLength = 1024,placeholder,formikProps,showLengthCount=true,rows=3,className,readOnly=false,onKeyUpHandler}) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isloaded, setloaded] = useState<boolean>(false);
  const onKeyUp = () => {
    if(onKeyUpHandler)
      onKeyUpHandler();
    }
    var inputProps = {
      autoComplete:'off',
      rows:rows,
      disabled : readOnly,
      placeholder : placeholder,
      ...(maxLength && { maxLength:maxLength })
    };

 useEffect(()=>{
  if(isloaded === false && formikProps && formikProps.value !== undefined && formikProps.value!==null && formikProps.value!== ''){
    setloaded(true);
    formikProps.value = decode(formikProps.value);
  }
  return ()=>{ 
  }
  // eslint-disable-next-line
},[formikProps?.value])


    return (
      <>
      <textarea 
       {...inputProps}
       {...formikProps} 
       onFocus = {()=>setIsActive(true)}
       onBlur = {()=>setIsActive(false)}
       onKeyUp={onKeyUp}
       className={clsx('form-control form-control-sm '+ className,{ 'is-invalid': active && error})}/>
      {isActive && showLengthCount && <span style={{position : 'sticky'}} 
         className={'float-end fs-7 me-5 pe-1 mt-n6 fw-bolder ' + ( formikProps?.value === undefined || formikProps?.value === null || formikProps?.value.length < maxLength ? 'text-primary' : 'text-danger') }>
          {formikProps?.value ? (maxLength - formikProps?.value.length) : maxLength}
          </span>}
      {renderError && (<KTInputError active={active} error={error} />)}
      </>
    )
  }



export {KTInputLabel,KTInputError,KTInputBox,KTSelectBox,KTDatePickerBox,KTInputRadioBox,KTInputCheckBox,KTDisplayBox,KTTextAreaBox}

