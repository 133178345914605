import { Link } from "react-router-dom";
import { ActionIcon, Constants } from "../../../services";
import { SurveyConfig } from "./_models";


export function SurveyConfigTableColumns (pageNo:number = 0,confirm?:any) {

return  (
    [
        {
            name: 'NO.',
            cell: (row:SurveyConfig,index:any) => (1 + index + (pageNo - 1 ) * Constants.DATATABLE_PAGE_SIZE),
            width:'65px'
        },
        {
            name: 'CONFIG',
            cell: (row:SurveyConfig) => <>
            <div className='lh-md py-2 align-self-baseline'> 
                <strong>Max Publish Questions : </strong>{row.maxpublishquestions !==null ? row.maxpublishquestions :''}<br/>
                <strong>Min Alert Rating : </strong>{row.minimumalertrating !==null ? row.minimumalertrating :''}<br/>
                <strong>Alerts for Feedback : </strong>{row.alertemailforfeedback !==null ? row.alertemailforfeedback :''}<br/>
                <strong>Alert Email : </strong>{row.alertemailaddress !==null ? row.alertemailaddress :''}<br/>
                <strong>App Notification: </strong>{row.appnotification ? 'Yes' : 'No'}<br/>
                <strong>Email Address for Upload Ratings Log File : </strong>{row.uploademail !==null ? row.uploademail :''}
                </div>
            </>
          },
          {
            name: 'DATES',
            cell: (row:SurveyConfig) => <>
            <div className='lh-md py-2 align-self-baseline'> 
                <strong>Effective Date: </strong>{row.fromdate !== null ? row.fromdate :  '' }<br/>
                <strong>Termination Date: </strong>{row.todate !== null ? row.todate : ''}
            </div>
            </>
          },
        
        {
            name: 'ACTIONS',
            maxWidth:'160px',
            cell: (row:SurveyConfig) => <>
                <Link to={'/RatingSettings/SurveyConfig/Edit/'+row.id}>
                <ActionIcon label='EDIT' />
              </Link>
              
              {row.isdelete && <>
                <span onClick={() => confirm(row.id)}>
                  <ActionIcon label='DELETE'/>
                </span>
              </>}
             
              </>
        }
      ]
  )
}
