import React, { useEffect, useState } from 'react';
import { QuestionType, SurveyQuestions, SurveyQuestionsSchema} from './_models';
import { save,getById, FetchquestiontypeList } from './_requests';
import { KTCard, KTCardBody,KTCardFooter,KTCardHead } from '../../../../_theme/helpers';
import { useFormik } from 'formik';
import { Constants, FormActionButtons, KTInputBox, KTInputLabel, StatusResponseModal } from '../../../services';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { StatusResponse } from '../../../models/global/StatusResponce';
import { KTSelectBox } from '../../../services/components/InputTemplates';
import { KeyValueOption } from '../../../models';

export function SurveyQuestionsAddEdit () {

  const [isLoading, setLoading] = useState(true);
  const [statusResp, setStatusResp] = useState(new StatusResponse());
  const [isAlertModal, setAlertModal] = useState(false);
  const [SurveyQuestionsData, setSurveyQuestions] = useState(new SurveyQuestions());
  const [questiontypeList, setQuestiontypeList] = useState(Array<QuestionType>());
  
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(()=>{
    fetchSurveyQuestionsList();
    fetchquestiontypeList();
  return ()=>{ 
  }
  // eslint-disable-next-line
 },[])

 const fetchquestiontypeList = () => {
    FetchquestiontypeList().then(function (response) {
      setQuestiontypeList(response.data);
      setLoading(false);
    })
  
}

 const fetchSurveyQuestionsList = () => {
  if(id !== undefined && id && isLoading){
    getById(id).then(function (response) {
      setSurveyQuestions(response.data);
      formik.resetForm({
        values:response.data
      })
      setLoading(false);
    })
  }else{
    setLoading(false);
  }
}

 const formik = useFormik({
    initialValues: SurveyQuestionsData,
    validationSchema: SurveyQuestionsSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setSubmitting(true)
      try {
        var saveResp = await save(values);
        setStatusResp(saveResp.data);
        setAlertModal(true);
        setLoading(false)
        setSubmitting(false)
      } catch (error) {
        try{
        }
        catch(_error){
            setStatus(Constants.MESSAGE_ERROR);
        }
        console.error(error)
      }
    },
  })

  
  const closeModal = () => {
    setAlertModal(false);
    if(statusResp.code===1)
    navigate("/RatingSettings/SurveyQuestions");
  }
  


      return (
         <>
         {isAlertModal ? (<StatusResponseModal message={''} data={statusResp}  handleClose = {closeModal}/>):<></>}
          
          <KTCard>
            <KTCardHead title={((id == undefined || parseInt(id) === 0)?'Add':'Edit')+' Survey Questions'}>
            </KTCardHead>
            <KTCardBody>
                <form className='form w-100 my-5' onSubmit={formik.handleSubmit} noValidate id='kt_country_add_edit_form' >
                
                <div className="row">
                      <div className='col-sm-6 d-md-flex my-2'>
                        <KTInputLabel required={true} title="Question" className='col-md-3' />
                        <div className="col-md-8">
                          <KTInputBox placeholder='Enter Question' formikProps={formik.getFieldProps('question')} maxLength={255}
                            active={formik.touched.question}  
                            error={formik.errors.question}
                          />
                        </div>
                      </div>
                      <div className='col-sm-6 d-md-flex my-2'>
                        <KTInputLabel required={true} title="Question Type" className='col-md-3' />
                        <div className="col-md-8">
                        <KTSelectBox placeholder='Select Question Type'  formikProps={formik.getFieldProps('questiontype')}  
                       
                        optionsList={questiontypeList?.map((x) => {return new KeyValueOption(x.id,x.name);})}
                                  active={formik.touched.questiontype} error={formik.errors.questiontype}
                                  onChangeHandler={(val) => {
                                    formik.setFieldValue('questiontype',parseInt(val));
                                  }} 
                                />
                        </div>
                      </div>
                    </div>


                    
                    
                    <KTCardFooter className='d-flex justify-content-end mt-5'>
                      {/* begin::Action */}                           
                        <FormActionButtons isLoading={isLoading} id={id} backUrl='/RatingSettings/SurveyQuestions' disabled={formik.isSubmitting || !formik.isValid } />
                        {/* end::Action */}
                    </KTCardFooter>


                </form>
            </KTCardBody>

          </KTCard>
          </>
      )
    
}


