/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Link, Outlet, Route, Routes} from 'react-router-dom'
import {ForgotPassword} from './components/ForgotPassword'
import {LoginPage} from './components/LoginPage'
import {toAbsoluteUrl} from '../../../_theme/helpers'
import { ForgotUserId } from './components/ForgotUserId'
import { ResetPassword } from './components/ResetPassword'
import { OtpPage } from './components/OtpPage'
import React from 'react'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed bg-brand'
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pt-lg-20 pb-lg-20'>
        {/* begin::Logo */}
        <Link to='/' className='mb-10 mt-n20'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/aia.png')} className='h-90px' />
        </Link>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-sm-375px shadow-sm p-10 mx-auto' style={{background: 'url(' + toAbsoluteUrl('/media/misc/bg-white.png') + ')'}} >
          <Outlet />
        </div>
        {/* end::Wrapper */}
      {/* end::Content */}
      {/* begin::Footer */}
        <div className='d-flex align-items-center fw-bold fs-6 pt-5'>
          <a href='https://www.aia.com.sg/en/index/terms-of-use.html' target="_blank" rel="noopener noreferrer" className='text-light text-hover-primary px-2'>
            Terms of Use
          </a>
          <a className='text-light text-hover-light px-2'> | </a>

          <a href='https://www.aia.com.sg/en/index/personal-data-policy.html' target="_blank" rel="noopener noreferrer" className='text-light text-hover-primary px-2'>
          AIA Personal Data Policy (Singapore)
          </a>

        </div>
      </div>
      {/* end::Footer */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='forgot-userId' element={<ForgotUserId />} />
      <Route path='ResetPassword/token/:token/valid/:valid' element={<ResetPassword />} />      
      <Route path='Otp' element={<OtpPage />} />
      <Route path='login/token/:token' element={<LoginPage />} />
      <Route index element={<LoginPage />} />
    </Route>
  </Routes>
)

export {AuthPage}
