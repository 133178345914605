import React, { useEffect, useState } from 'react';
import { PublishRatings, PublishRatingsForm} from './_models';
import { ActivatePublishRatings, DeActivatePublishRatings, searchPublishRatings } from './_requests';
import { KTCard, KTCardBody,KTCardHead } from '../../../../_theme/helpers';

import { AlertModal, ConfirmModal, Constants, Loading, DateToString, ActionIcon } from '../../../services';
import { Status } from '../../../models/global/StatusResponce';
import { KTDatePickerBox } from '../../../services/components/InputTemplates';
import { Link } from 'react-router-dom';

export function PublishRatingsList () {

  const [isLoading, setLoading] = React.useState(true);
  const [dataList, setData] = React.useState(Array<PublishRatings>());
  const [statusResp, setStatusResp] = React.useState(new Status(0,""));
  const [isConfirmModal, setConfirmModal] = React.useState(false);
  const [isAlertModal, setAlertModal] = React.useState(false);
  const [TermiantionDateStart, setTermiantionDateStart] = useState(new Date());
  const [publishRatings, setPublishRatings] = useState(new PublishRatings());
  const [remark, setRemark] =  useState('');
  const [isNew, setNew] = React.useState(false);

  useEffect(()=>{
    fetchPublishRatings();
  return ()=>{ 
  }
 },[])


const fetchPublishRatings = ()=>{
  searchPublishRatings().then(function (response) {
    setData(response.data);
    setLoading(false);
  })
}

  const closeModal = () => {
    setConfirmModal(false);
    setRemark('');
  }

  const closeDeleteModal = () => {
    setAlertModal(false);
    setRemark('');
    setLoading(true);
    fetchPublishRatings();
  }

  const publishRatingHandle = async (val: any) => {
    if(isNew){
      activate(val);
    }else{
      deactivate(val);
    }
  }
  
  const activatePopup = (_publishRatings:PublishRatings,isNew:boolean) => {
    setNew(isNew);
    setPublishRatings(_publishRatings);
    setConfirmModal(true);
  }

  const activate = async (val: any) => {
    try{
      setConfirmModal(false);
      let req = new PublishRatingsForm();
      req.ratingTypeID=publishRatings.id;
      req.remarks=remark;
      req.fromdate=publishRatings.publishRatingsForm.fromdate;
      req.todate=publishRatings.publishRatingsForm.todate;

      await ActivatePublishRatings(req).then(function (response) {
        setStatusResp(response.data);
        setAlertModal(true);
      });
    }catch(e){
      console.log(e);
    }
  }
  
  const deactivatePopup = (_publishRatings:PublishRatings,isNew:boolean) => {
    setNew(isNew);
    setPublishRatings(_publishRatings);
    setConfirmModal(true);
  }

  const deactivate = async (val: any) => {
    try{
      setConfirmModal(false);
      let req = new PublishRatingsForm();
      req.id=publishRatings.id;
      req.remarks=remark;

      await DeActivatePublishRatings(req).then(function (response) {
        setStatusResp(response.data);
        setAlertModal(true);
      });
    }catch(e){
      console.log(e);
    }
  }


      return (
         <>
         {isLoading && (<Loading/>)}

         {isAlertModal && (<AlertModal message={statusResp.message.toString()} data={statusResp}  handleClose = {closeDeleteModal}> </AlertModal>) }
         {isConfirmModal &&(<ConfirmModal  handleConfirm={publishRatingHandle} handleClose = {closeModal}>
          
          Proceed to {isNew?'Activate':'Deactivate'} {publishRatings.ratingname} ?
          <div className='my-10'>
          <textarea value={remark}   name="remark" placeholder="Please Enter Remarks" onChange={e => setRemark(e.target.value)} ></textarea>
          </div>
         </ConfirmModal>)}

          <KTCard>
            <KTCardHead title='PUBLISH RATING'>
            </KTCardHead>
            <KTCardBody>

              <table className={Constants.DATATABLE_STYLE}>
              <thead>
              <tr >
                <th className='w-50px'>No.</th>
                <th className='col-2'>RATING NAME</th>
                <th className='col-2'>START DATE</th>
                <th className='col-2'>END DATE</th>
                <th >REMARKS</th>
                <th className='col-2'>ACTIONS</th>
              </tr>
              </thead>
                 

              {dataList.map((row, index) => (
                    <tr key={index}>
                      <td>
                        {row.id}
                      </td>
                      <td>
                        {row.ratingname}
                      </td>
                      <td>
                          <div>
                        
                              {(row.publishRatingsForm.id!==null && row.publishRatingsForm.status==='Active') && <>
                                    {row.publishRatingsForm.fromdate}
                              </>}

                              {(row.publishRatingsForm.id===null || row.publishRatingsForm.status==='Inactive') && <div className='mw-200px'>
                              <KTDatePickerBox placeholder='Enter Start Date'  
                                  DatePickerType='Today&Future'                                      
                                  handleOnChange ={ 
                                  (date : Date) => {
                                      (date !==null) && setTermiantionDateStart(date);
                                      (date === null) && setTermiantionDateStart(new Date());
                                      row.publishRatingsForm.fromdate = DateToString(date);
                                      if(date){
                                        row.isActiveButton = true;
                                      }else{
                                        row.isActiveButton = false;
                                      }
                                      }
                                  }
                                />
                              </div>}

                          </div>
                      </td>
                        <td>
                          <div>
                              {(row.publishRatingsForm.id!==null && row.publishRatingsForm.status==='Active') && <>
                                    {row.publishRatingsForm.todate}
                              </>}
                              {(row.publishRatingsForm.id===null || row.publishRatingsForm.status==='Inactive') && <div className='mw-200px'>
                              <KTDatePickerBox placeholder='Enter End Date'  
                                DatePickerType='Today&Future'                                  
                                DateRange={{start:TermiantionDateStart}}
                                handleOnChange ={ 
                                    (date : Date) => {
                                        row.publishRatingsForm.todate = DateToString(date);
                                        row.isActiveButton = true;
                                    }
                                  }
                              />
                            </div>}
                          </div>
                        </td>

                      <td>
                        {row.publishRatingsForm.remarks}
                      </td>

                      <td >
                        <div className='d-flex flex-wrap'>
                        {row.isActiveButton && <>
                          
                          <span onClick={() => (activatePopup(row,true))}>
                            <ActionIcon label='Activate' svg='/media/icons/duotune/general/gen041.svg'/>
                          </span> 
                        </>}
                        
                        {row.publishRatingsForm.status==='Active' && <>
                        <span onClick={() => (deactivatePopup(row,false))}>
                            <ActionIcon label='Deactivate' svg='/media/icons/duotune/general/gen040.svg'/>
                          </span> 
                        </>}
                        
                          <Link to={'/RatingSettings/PublishRatings/View/'+row.id}>
                            <ActionIcon label='View Logs' svg='/media/icons/duotune/general/gen005.svg'/>
                          </Link> 
                      </div>
                      </td>
                  </tr>
                  ))}

              </table>
                
                
            </KTCardBody>
          </KTCard>
          </>
      )
}
