import {decode as base64_decode, encode as base64_encode} from 'base-64';
import { Constants } from '../../../services';

const LOCAL_STORAGE = '_ALT746XRPVUMD5NH'
const getAuth = (): string | undefined => {
  if (!sessionStorage) {
    return
  }

  const lsValue: string | null = sessionStorage.getItem(LOCAL_STORAGE)
  if (!lsValue) {
    return
  }

  try {
    const auth: string = base64_decode(lsValue) as string
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: string) => {
  if (!sessionStorage) {
    return
  }

  try {
    const lsValue = base64_encode(auth)
    sessionStorage.setItem(LOCAL_STORAGE, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!sessionStorage) {
    return
  }

  try {
    sessionStorage.removeItem(LOCAL_STORAGE)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth) {
        config.headers.Authorization = auth
      }
      return config
    },
    (err: any) => Promise.reject(err)
  )
  axios.interceptors.response.use((response: any) => {
    return response;
  }, (error: { response: { status: number; }; }) => {
    const auth = getAuth()  
    if (error.response.status === 401 && auth) {
      // current auth not valid OR expired.
      removeAuth();
      sessionStorage.setItem(base64_encode('_LOGOUT_REDIRECT'),base64_encode(Constants.LOGOUT_ACTIVE_SESSION));
      window.location.href = '/';
    }
    else{
      return Promise.reject(error);
    }
 });
}

export {getAuth, setAuth, removeAuth, LOCAL_STORAGE}
