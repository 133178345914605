import  React, { useEffect, useState } from 'react';
import {  PublishSurveyQuestionsSchema, SurveyQuestions, SurveyQuestionsPublish} from './_models';
import { deactivatequestionbyId, getById,  getUnpublishedId,  savePublishquestions } from './_requests';
import { KTCard, KTCardBody,KTCardHead, KTCardHeadToolBar } from '../../../../_theme/helpers';
import { useFormik } from 'formik';
import { ActionIcon, AlertModal, ConfirmModal, Constants,  DateToString,  KTInputBox } from '../../../services';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Status } from '../../../models/global/StatusResponce';
import { KTDatePickerBox } from '../../../services/components/InputTemplates';

export function SurveyQuestionPublish () {

  const [isLoading, setLoading] = useState(true);
  const [status, setStatus] = useState(new Status(0,""));
  const [actStatus, setActStatus] = useState("");
  const [isStatusModal, setStatusModal] = useState(false);
  const [SurveyQuestionsData, setSurveyQuestions] = useState(new SurveyQuestions());
  const [surveyQuestionsPublish, setSurveyQuestionsPublish] = useState(new SurveyQuestionsPublish());
  const [TermiantionDateStart, setTermiantionDateStart] = useState(new Date());
  const [isConfirmModal, setConfirmModal] = React.useState(false);
  
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(()=>{
    fetchSurveyQuestionsById();
  return ()=>{ 
  }
  // eslint-disable-next-line
 },[])


 const fetchSurveyQuestionsById = () => {
  if(id !== undefined && id && isLoading){
    getById(id).then(function (response) {
      setSurveyQuestions(response.data);
      setLoading(false);
    })

    getUnpublishedId(id).then(function (response) {
      if(response.data.length>0){
        setSurveyQuestionsPublish(response.data[0]);
      }
      setLoading(false);
    })
  }else{
    setLoading(false);
  }
}

 const formik = useFormik({
    initialValues: surveyQuestionsPublish,
    validationSchema: PublishSurveyQuestionsSchema,
    enableReinitialize:true,
    onSubmit: async (values, { setSubmitting}) => {
      setLoading(true)
      setSubmitting(true)
      try {
        values.surveyquestionId = Number(id);
        if(actStatus==='Activate'){
          var saveResp = await savePublishquestions(values);
          setStatus(saveResp.data);
          setStatusModal(true);
        }else{
           saveResp = await deactivatequestionbyId(values.id?values.id:0);
          setStatus(saveResp.data);
          setStatusModal(true);
        }
        
        setLoading(false)
        setSubmitting(false)
      } catch (error) {
        console.error(error)
      }
    },
  })

  const confirmPopup = (status:string) => {
    
   setActStatus(status);
   if(status==='Activate'){
    formik.handleSubmit();
   }else{
    setConfirmModal(true);
   }
  }
  

 



  const publishSurveyQuesHandle = async () => {
    setConfirmModal(false)
    formik.handleSubmit();
  }


  
  const closeModal = () => {
    setConfirmModal(false);
    setStatusModal(false);
    if(status.code===1){
      navigate("/RatingSettings/SurveyQuestions");
    }
   
  }
  


      return (
         <>
          {isStatusModal ? (<AlertModal message={status.message} data={status.message} showModal={isStatusModal} handleClose = {closeModal}/>):<></>}
         
         {isConfirmModal &&(<ConfirmModal  handleConfirm={publishSurveyQuesHandle} handleClose = {closeModal}>
          
          Proceed to {actStatus==='Deactivate'?'Un':''} Publish Question ?
          
         </ConfirmModal>)}
          
          <KTCard>
            <KTCardHead title={(surveyQuestionsPublish.status === 'Active' ? 'UNPUBLISH' : 'PUBLISH')  + ' QUESTION'}>
            <KTCardHeadToolBar>
              <Link className='btn btn-sm btn-secondary' to={'/RatingSettings/SurveyQuestions/Search'}>BACK</Link>
            </KTCardHeadToolBar>
            </KTCardHead>
            <KTCardBody>

            
            <table className={Constants.DATATABLE_STYLE}>
             
              <tr >
                <th className='col-3'><strong>Question</strong></th>
                <th className='col-9'>{SurveyQuestionsData.question}</th>
              </tr>

              <tr >
                <th className='col-3'><strong>Question Type</strong></th>
                <th className='col-9'>{SurveyQuestionsData.questiontypename}</th>
              </tr>
              
              </table>
              <form className='form w-100 my-5' onSubmit={formik.handleSubmit} noValidate id='kt_country_add_edit_form' >  
            <table className={Constants.DATATABLE_STYLE}>
              <thead>
              <tr >
                
                <th className='col-4'>START DATE</th>
                <th className='col-4'>END DATE</th>
                <th className='col-2'>DISPLAY ORDER</th>
                <th className='col-2'>ACTIONS</th>
              </tr>
              </thead>
              <tr >
                
                <td className='col-2'>

                <KTDatePickerBox placeholder='Enter Start Date'  formikProps={formik.getFieldProps('fromdate')} 
                                  DatePickerType='Today&Future'  
                                  readOnly={ surveyQuestionsPublish.candisplay==="true" && surveyQuestionsPublish.status==='Active'}   
                                  active={formik.touched.fromdate}   error={formik.errors.fromdate}                              
                                  handleOnChange ={ 
                                  (date : Date) => {
                                    (date !==null) && setTermiantionDateStart(date);
                                    (date === null) && setTermiantionDateStart(new Date());
                                    formik.setFieldValue('fromdate',DateToString(date))
                                    formik.setFieldValue('todate','');
                                      
                                      }
                                  }
                                />
                                
                </td>
                <td className='col-2'>

                <KTDatePickerBox placeholder='Enter End Date'   formikProps={formik.getFieldProps('todate')} 
                        readOnly={ surveyQuestionsPublish.candisplay==="true" && surveyQuestionsPublish.status==='Active'}
                              active={formik.touched.todate}   error={formik.errors.todate}
                                DatePickerType='Today&Future'                                  
                                DateRange={{start:TermiantionDateStart}}
                                handleOnChange ={ 
                                    (date : Date) => {
                                      formik.setFieldValue('todate',DateToString(date));
                                        
                                    }
                                  }
                              />
                              
                </td>
                <td >
                  <KTInputBox placeholder='Sort Order'
                  readOnly={ surveyQuestionsPublish.candisplay==="true" && surveyQuestionsPublish.status==='Active'}
                  active={formik.touched.displayorder}   error={formik.errors.displayorder}
                   formikProps={formik.getFieldProps('displayorder')} maxLength={2}  type='Digit' 
                   />
                </td>
                <td className='col-2'>
                <div className='d-flex flex-wrap'>

               

                  { surveyQuestionsPublish.status!=='Active' &&         
                  <span onClick={() => (confirmPopup('Activate'))}>
                    <ActionIcon label='Activate' svg='/media/icons/duotune/general/gen041.svg'/>
                  </span> 
                }       
               
                        
                { surveyQuestionsPublish.status==='Active' && 
                  <span onClick={() => (confirmPopup('Deactivate'))}>
                    <ActionIcon label='Deactivate' svg='/media/icons/duotune/general/gen040.svg'/>
                  </span> 
                }
                
              


                <Link to={'/RatingSettings/SurveyQuestions/View/Log/'+id}>
                  <ActionIcon label='View Logs' svg='/media/icons/duotune/general/gen005.svg'/>
                </Link> 

                    </div>
                </td>
              </tr>

              </table>
              </form>

            </KTCardBody>

          </KTCard>
          </>
      )
    
}


