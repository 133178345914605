import { Auditable } from "../../../models/global/Auditable"
import * as Yup from 'yup';


export class SuspendUser extends Auditable  {
  applicationuserId: number = 0
  canDelete?: boolean
  canEdit?: boolean
  fromdate: string = ""
  id?: number
  remark: string = ""
  status?: boolean
  todate: string = ""
}

export const SuspendUserValidationSchema = Yup.object({
  fromdate: Yup.string().required('Please Enter Effective Date'),
  remark: Yup.string().required('Please Enter Remarks'),
  })