import React from 'react'


type Props = {
  status?: string
  className?:string
}
const StatusBadge: React.FC<Props> = ({status,className}) => {

  var _color = 'badge-light-secondary'
  switch (status)
  {
    case 'Active':
      _color ='badge-light-success border border-success';break;
    case 'Suspended':
      _color ='badge-light-info border border-info';break;
    case 'Locked':
      _color ='badge-light-warning border border-warning';break;
    case 'Inactive':
      _color ='badge-light-primary border border-primary';break;
    case 'Terminated':
      _color ='badge-light-danger border border-danger';break;
  }
  return (
      <span className= {className +" badge py-1 " + _color} style={{minWidth:'80px'}}><span className='mx-auto fs-7'>{status}</span></span>
  )
}

export {StatusBadge}