import React, { useEffect, useState } from 'react';
import { DoctorRatingWeightage, SearchForm} from './_models';
import { deleteById, searchDoctorRatingWeightage } from './_requests';
import { KTCard, KTCardBody,KTCardHead } from '../../../../_theme/helpers';

import { AlertModal, ConfirmModal, Constants, Pagination, Loading, UserType, EmptyDatatable, DateToString } from '../../../services';
import { Link } from 'react-router-dom';
import { Status } from '../../../models/global/StatusResponce';
import { useAuth } from '../../auth';
import DataTable from 'react-data-table-component';
import {DoctorRatingWeightageTableColumns } from './_dataRowColumns';
import { FormikProvider, useFormik } from 'formik';
import { KTDatePickerBox } from '../../../services/components/InputTemplates';
import { DatatableInput } from '../../../models/global/DataTable';

export function DoctorRatingWeightageList () {

  const {currentUser} = useAuth();
  const [isLoading, setLoading] = React.useState(true);
  const [dataList, setData] = React.useState(Array<DoctorRatingWeightage>());
  const [statusResp, setStatusResp] = React.useState(new Status(0,""));
  const [isConfirmModal, setConfirmModal] = React.useState(false);
  const [isAlertModal, setAlertModal] = React.useState(false);
  const [countryId, setDoctorRatingWeightageId] = React.useState(0);
  const [PageNo, setPageNo] = React.useState(1);
  const [searchForm, setSearchForm] = useState(new SearchForm(new DatatableInput(0,10,1),""));

  useEffect(()=>{
    fetchDoctorRatingWeightage();
  return ()=>{ 
  }
  // eslint-disable-next-line
 },[])

 const formik = useFormik({
  initialValues:searchForm,
  enableReinitialize:true,
  onSubmit: async (values, { setSubmitting}) => {
    setLoading(true)
    setSubmitting(true)
    searchForm.searchValue=values.searchValue
    setSearchForm(searchForm);
    fetchDoctorRatingWeightage();
  }, 
})


const fetchDoctorRatingWeightage = ()=>{
  searchDoctorRatingWeightage(searchForm).then(function (response) {
    setData(response.data.data);
    setLoading(false);
  })
}

  const confirmModal = (val?: number) => {
    setDoctorRatingWeightageId(val?val:0);
    setConfirmModal(true);
  }
  const closeModal = () => {
    setConfirmModal(false);
  }

  const closeDeleteModal = () => {
    setAlertModal(false);
    setLoading(true);
    fetchDoctorRatingWeightage();
  }
  
  const DeleteDoctorRatingWeightage = async (val: any) => {
    try{
      setConfirmModal(false);
      await deleteById(parseInt(val)).then(function (response) {
        setStatusResp(response.data);
        setAlertModal(true);
      });
    }catch(e){
      console.log(e);
    }
  }


      return (
         <>
         {isLoading && (<Loading/>)}
         {isAlertModal ? (<AlertModal message={statusResp.message.toString()} data={statusResp}  handleClose = {closeDeleteModal}/>):<></>}
         {isConfirmModal ? (<ConfirmModal message={Constants.MESSAGE_DELETE_REQUEST} data={countryId} handleConfirm={DeleteDoctorRatingWeightage} handleClose = {closeModal}/>):<></>}
          <KTCard>
            <KTCardHead title='DOCTOR RATING WEIGHTAGE SETTINGS'>
              {
              currentUser?.usertypeName === UserType.ADMINUSER.CODE && (
              <div className="card-toolbar my-0">
                <Link className='btn btn-sm btn-secondary' to='/RatingSettings/DoctorRatingWeightage/Add'>
                  ADD DOCTOR RATING WEIGHTAGE
                </Link>
              </div>)
              }
            </KTCardHead>
            <KTCardBody>

            <FormikProvider value={formik}>
                <form className='form w-100 my-5' onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-xl-2 col-md-3 col-sm-4 my-2">
                  
                      <KTDatePickerBox placeholder='Enter Year'  formikProps={formik.getFieldProps('searchValue')} DatePickerMode='Year'
                            handleOnChange ={ 
                              (date : Date) => {
                                formik.setFieldValue('searchValue',DateToString(date,'yyyy'))
                              }
                            }
                        />

                    </div>
                    
                    <div className="col-xl-2 col-md-3 col-sm-4 my-2">
                      <button type="submit" id="search_submit" className="btn btn-sm btn-brand text-light">
                        <span className="indicator-label">SEARCH</span>
                      </button>
                    </div>
                  </div>
                </form>
                </FormikProvider>
                
                <div className={Constants.DATATABLE_STYLE_REACT}>
                  <DataTable
                    columns={DoctorRatingWeightageTableColumns(PageNo,confirmModal)}
                    data={dataList}
                    pagination
                    onChangePage={(page: number) => {setPageNo(page);}}
                    paginationComponent={Pagination} 
                    noDataComponent={<EmptyDatatable columns={DoctorRatingWeightageTableColumns()}/>}                   
                  />
                </div>
            </KTCardBody>
          </KTCard>
          </>
      )
}
