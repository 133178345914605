import { Constants } from "../lib/Constants";
import { format } from 'date-fns'
import * as CryptoJSx from 'crypto-js';
import { Link } from "react-router-dom";
import { FieldInputProps } from "formik";
import jwt_decode from "jwt-decode";
import * as authHelper from './../../modules/auth/core/AuthHelpers'
import { decode, encode } from "html-entities";

export function Loading() {
    return (
        <div className="overlay overlay-block h-100" style={{position:'fixed',zIndex:'990'}}>
            <div style={{top:'40%',position:'fixed',left:'50%',marginLeft:'-100px',zIndex:'999'}}>
                <div className="kt-loader bg-white">
                    <div className="kt-loader-icon"></div>
                    <span>Loading...</span>
                </div>
            </div>
        </div>)
  }

export function LoadingButton() {
    return (
        <span className='indicator-progress' style={{display: 'block'}}>
        Please wait...
        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
      </span>
      )
}

type PropsSaveUpdateButton = {
    isLoading?:boolean,
    id?:string,
    label?:'Save' | 'Update',
    type?: 'submit' | 'button' | 'reset',
    backUrl?:string
    editUrl?:string,
    disabled?:boolean

  }
  const FormActionButtons: React.FC<PropsSaveUpdateButton> = ({isLoading = false,id='0', label ='Save',type ='submit',editUrl, backUrl,disabled = false}) => {
    var _mode = label;
    if(id !== undefined && parseInt(id) > 0)
        _mode = 'Update'
    return (
    <div className="btn-group" role="group" > 
    {editUrl === undefined && (
        <button type={type} id={'save_update_'+type} className='btn btn-sm btn-secondary' disabled={disabled} >
            {!isLoading && <span className='indicator-label'>{_mode.toUpperCase()}</span>}
            {isLoading &&  <LoadingButton/> }
        </button>
    )}
    {editUrl !== undefined && editUrl!=='' && (disabled === undefined || disabled === false) && ( <Link className='btn btn-sm btn-secondary ms-3' to={editUrl}>EDIT</Link>)}
    {backUrl !== undefined && ( <Link className='btn btn-sm btn-secondary ms-3' to={backUrl}>CLOSE</Link>)}
    </div>
    )
  }

export {FormActionButtons};

export function DateToString (date : Date | undefined | null , dateformat?: string)
{
    if(date === null || date ===undefined)
    return '';
    if(!dateformat)
        dateformat = Constants.DATE_FORMAT
    return format(date, dateformat);
}

export function MemberDate (date : string, dateformat?: string)
{
    if(date === null || date ===undefined)
    return '';
    if(!dateformat)
        dateformat = Constants.DATE_FORMAT
       
    //Input format DOB e.g.19791004, Output format e.g. 04-Oct-1979
    var tmp_year = parseInt(date.toString().substring(0, 4), 10);
    var tmp_month = parseInt(date.toString().substring(4, 6), 10);
    var tmp_day = parseInt(date.toString().substring(6, 8), 10);
    var v_date = new Date(tmp_year, tmp_month - 1, tmp_day);

    return DateToString(v_date);

}

export function StringToDate (date?: string,format? : 'dd-MMM-yyyy'|'dd/MM/yyyy'|'MM/yyyy'|'yyyy') : Date
{
    var v_date = new Date();
    if(date === null || date ===undefined || date === '')
    return v_date;

    var tmp_year = parseInt(date.split('/')[2]);
    var tmp_month = parseInt(date.split('/')[1]);
    var tmp_day = parseInt(date.split('/')[0]);
    switch(format)
    {
        case 'dd-MMM-yyyy':
        default :
            v_date = new Date(date);break;
        case 'dd/MM/yyyy': 
            tmp_year = parseInt(date.split('/')[2]);
            tmp_month = parseInt(date.split('/')[1]);
            tmp_day = parseInt(date.split('/')[0]);
            v_date = new Date(tmp_year, tmp_month - 1, tmp_day);
            break;        
        case 'MM/yyyy':     
            tmp_year = parseInt(date.split('/')[1]);
            tmp_month = parseInt(date.split('/')[0]);
            tmp_day = 1;
            v_date = new Date(tmp_year, tmp_month - 1, tmp_day);
            break;        
        case 'yyyy':     
            tmp_year = parseInt(date);
            tmp_month = 0;
            tmp_day = 1;
            v_date = new Date(tmp_year, tmp_month - 1, tmp_day);
            break;
    }
    return v_date;
}

export function KTPaginationInfo(currentPage:number,rowCount:number)
{
    return <div className="fs-7">Showing 
        <span className="mx-1">{((currentPage) * Constants.DATATABLE_PAGE_SIZE) - (Constants.DATATABLE_PAGE_SIZE-1)} </span>
        to 
        <span className="mx-1">{Math.min(currentPage * Constants.DATATABLE_PAGE_SIZE,rowCount)} </span>
        of <span className="mx-1">{rowCount} </span> entries</div>
}


export function encodeAes (input:any){
    if(input==null)return "";

    var passphrase;
    let token  = authHelper.getAuth() as string;
    if(token){
        let _user = jwt_decode(token) as any; 
        passphrase=_user.sk;
    }else{
        passphrase=Constants.AESKEY;
    }
    
    var data = input;
    var iv = CryptoJSx.lib.WordArray.random(128/8).toString(CryptoJSx.enc.Hex);
    var salt = CryptoJSx.lib.WordArray.random(128/8).toString(CryptoJSx.enc.Hex);
   
    try{
        var ciphertext = encrypt(salt, iv, passphrase, data);

        var encryptedValue = (iv + "::" + salt + "::" + ciphertext);
        var encryptedBase64 = btoa(encryptedValue);
        return encryptedBase64;
    }catch(e){
        console.log(e);
    }
    return "";
    
}


export function decodeAes (input?:string){
    if(input===null || input === undefined)return "";
    var decodedBase64 =  window.atob(input);
    var aesData = decodedBase64.split("::");
    let token  = authHelper.getAuth() as string;
    let _user = jwt_decode(token) as any; 
    try{
        //var aesUtil = AesUtil(keySize, iterationCount);
        var decryptData = decrypt(aesData[0], aesData[1], _user.sk, aesData[2]);

        return decryptData;
    }catch(e){
        console.log(e);
    }
    return "";
}

export function encrypt (salt:string, iv:string, passPhrase:string, plainText:string) {
    var key = generateKey(salt, passPhrase);
    var encrypted = CryptoJSx.AES.encrypt(
        plainText,
        key,
        { iv: CryptoJSx.enc.Hex.parse(iv) });
    return encrypted.ciphertext.toString(CryptoJSx.enc.Base64);
  }

function decrypt (salt:string, iv:string, passPhrase:string, cipherText:string) {
    try{
        var key = generateKey(salt, passPhrase);
        var cipherParams = CryptoJSx.lib.CipherParams.create({
          ciphertext: CryptoJSx.enc.Base64.parse(cipherText)
        });
        var decrypted = CryptoJSx.AES.decrypt(
            cipherParams,
            key,
            { iv: CryptoJSx.enc.Hex.parse(iv) });
        var decryptedStrig = decrypted.toString(CryptoJSx.enc.Utf8);
        return decryptedStrig;
    }catch(e){
        console.log(e);
    }
    return "";
    
}

function generateKey (salt:string, passPhrase:string) {
       
    var key = CryptoJSx.PBKDF2(
        passPhrase, 
        CryptoJSx.enc.Hex.parse(salt),
        { keySize: 128 / 32, iterations: 5000 });
    return key;
}



export function validateNRIC(nric:string) {
    if (nric.length < 6 || nric.length > 16) 
        return false;
    
        nric = nric.toUpperCase();
    
    //NRIC validation modified with implementation of new rider booster member plan,only checking for format
    //19-Mar-2019
    var regTest = (/^([A-Z0-9]){5,16}$/).test(nric);
    return regTest

}


export function defaultFormikProp (value : any,name : string){
    return {value : value,name : name ,onBlur: ()=>{}, onChange : () => {}} as FieldInputProps<any>;
}
export function SetPageMainTitle (name : string){
    document.title = 'AIA '+ (name === '' ?'' :'| '+name.toString());
}

export function clone(obj:any) {
  
    return JSON.parse(JSON.stringify(obj));
}

export function addDays(date: Date, days: number): Date {
    //date.setDate(date.getDate() + days);
    date = new Date(date.getTime() + (1000 * 60 * 60 * 24 * days));
    return new Date(date.getTime());
}

export function cloneDate(dateObject:Date) {
    return new Date(dateObject.valueOf());
}

export function clean(obj:any) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === '' || obj[propName] === undefined ) {
        delete obj[propName];
      }
    }
    return obj
}

export function escapeHtml(obj:any) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === '' || obj[propName] === undefined ) {
        obj[propName] = encode(obj[propName]);
      }
    }
    return obj
}

export function unescapeHtml(obj:any) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === '' || obj[propName] === undefined ) {
        obj[propName] = decode(obj[propName]);
      }
    }
    return obj
}


  export function removeTime(date = new Date()) {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
  }

  export  function currencyFormat(num?:string) {

     let num2 = num ? parseFloat(num) : '0.00';
     num2 = Number(num2);
    return '$' + num2.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }

  export const reactStyles = {
    container: (base: any) => ({
      ...base,
      height: 40,
      minHeight: 40,
      flex: 1,
    
    })
  };

  export function areIntervalsOverlappings(left: { start: Date; end: Date; }, right: { start: Date; end: Date; }) {
    var leftStartTime = left.start.getTime();
    var leftEndTime = left.end.getTime();
    var rightStartTime = right.start.getTime();
    var rightEndTime = right.end.getTime();  
    var isOverLapped = leftStartTime <= rightEndTime && rightStartTime <= leftEndTime;
    console.log(isOverLapped);
    return isOverLapped;
  }

