import { Constants } from "../../../services";
import { UploadInternalRatings } from "./_models";


export function UploadInternalRatingsTableColumns (pageNo:number = 0) {

return  (
    [
        {
            name: 'NO.',
            cell: (row:UploadInternalRatings,index:any) => (1 + index + (pageNo - 1 ) * Constants.DATATABLE_PAGE_SIZE),
            width:'65px'
        },
        {
            name: 'DATE OF UPLOAD',
            selector: (row:UploadInternalRatings) => row.dateofupload,
        },

        {
            name: 'FILE NAME',
            cell: (row:UploadInternalRatings) => <>
            <div className='lh-md py-2 align-self-baseline'> 
                {row.filename}
            </div>
            </>
        },

        {
            name: 'TYPE OF UPLOAD',
            selector: (row:UploadInternalRatings) => row.ratingtypeName,
        },

        {
            name: 'UPLOAD SUMMARY',
            cell: (row:UploadInternalRatings) => <>
            <div className='lh-md py-2 align-self-baseline'> 
            
            <strong>Number of record Uploaded: </strong>{row.noofuploadedrecord !=null ? row.noofuploadedrecord :''}<br/>
            <strong>Successful records: </strong>{row.noofsuccessfulrecord != null ? row.noofsuccessfulrecord : ''}<br/>
            <strong>Failed records: </strong>{row.nooffailedrecord != null ? row.nooffailedrecord : ''}<br/>
           
            </div>
            </>
        },

        {
            name: 'UPLOAD BY',
            cell: (row:UploadInternalRatings) => <>
            <div className='lh-md py-2 align-self-baseline'> 
                {row.createdName}
            </div>
            </>
        },

        {
            name: 'LOG FILE EMAIL',
            cell: (row:UploadInternalRatings) => <>
            <div className='lh-md py-2 align-self-baseline'> 
                {row.email}
            </div>
            </>
        },
        
       
      ]
  )
}
