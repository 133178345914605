import { Auditable } from "../../../models/global/Auditable"
import * as Yup from 'yup';
import { string } from 'yup';


export interface PublishRatingsResponse {
  PublishRatingsForm: PublishRatings[];
}

export class PublishRatingsForm extends Auditable{
  id?: number;
  ratingTypeID?: number=0;
  ratingType: string="";
  fromdate: string="";
  todate?: any;
  status: string="";
  remarks: string="";
  modifiedByName: string="";
}

export class PublishRatings {
  id?: number;
  ratingname: string="";
  candisplay: boolean=false;
  publishRatingsForm: PublishRatingsForm = new PublishRatingsForm();
  isActiveButton: boolean=false;
}


export const PublishRatingsSchema = Yup.object({
  name: string().max(256, 'Maximum 256').required('Please enter PublishRatings Name')
})
