/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../_theme/layout/core'
import { DashboardPage } from './DashboardPage'

const usersBreadcrumbs: Array<PageLink> = [
 {
    title: '',
    path: '',
    isSeparator: false,
    isActive: false,
  },
]

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
