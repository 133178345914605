import React, { useEffect, useState } from 'react';
import { HealthScreeningFee, HealthScreeningFeeSchema} from './_models';
import { save,getById, IsCodeExist } from './_requests';
import { KTCard, KTCardBody,KTCardFooter,KTCardHead } from '../../../../_theme/helpers';
import { useFormik } from 'formik';
import { ConfirmModal, Constants, FormActionButtons, KTInputBox, KTInputLabel, StatusResponseModal } from '../../../services';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { StatusResponse } from '../../../models/global/StatusResponce';
import { CodeExist, KeyValueOption } from '../../../models';
import { KTSelectBox } from '../../../services/components/InputTemplates';
import { HealthScreeningType } from '../health-screening-type/_models';
import { getHealthScreeningTypes } from '../health-screening-type/_requests';

export function HealthScreeningFeeAddEdit () {

  const [isLoading, setLoading] = useState(true);
  const [statusResp, setStatusResp] = useState(new StatusResponse());
  const [isAlertModal, setAlertModal] = useState(false);
  const [healthScreeningFeeData, setHealthScreeningFee] = useState(new HealthScreeningFee());
  const [isConfirmModal, setConfirmModal] = useState(false);
  const [isExist, setExist] = useState(new CodeExist());
  const navigate = useNavigate();
  const [healthScreeningTypeList, setHealthScreeningTypeList] = useState(Array<HealthScreeningType>());


  const { id } = useParams();

  useEffect(()=>{
    fetchHealthScreeningFeeList();
    fetchScreeningType();
  return ()=>{ 
  }
 // eslint-disable-next-line
},[])


 const fetchScreeningType = () => {
  if(isLoading){
    getHealthScreeningTypes().then(function (response) {
     setHealthScreeningTypeList(response.data);
       setLoading(false);
     })
  }
 }

 const fetchHealthScreeningFeeList = () => {
  if(id !== undefined && id && isLoading){
    getById(id).then(function (response) {
      setHealthScreeningFee(response.data);
      formik.resetForm({
        values:response.data
      })
      setLoading(false);
    })
  }else{
    setLoading(false);
  }
}

 const formik = useFormik({
    initialValues: healthScreeningFeeData,
    validationSchema: HealthScreeningFeeSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {

      setConfirmModal(false);

      if(id !== undefined && id && healthScreeningFeeData.typeOfCharge !== values.typeOfCharge){
        setConfirmModal(true);
        return ;
      }

      setLoading(true)
      setSubmitting(true)
      try {
        formik.values.description= values.description;
        save(values).then(function (response) {
          setStatusResp(response.data);
          setAlertModal(true);
          setLoading(false)
          setSubmitting(false)
        });
       

       
      } catch (error) {
        try{
        }
        catch(_error){
            setStatus(Constants.MESSAGE_ERROR);
        }
        console.error(error)
      }
    },
  })

  const confirmModal = async () => {
    setConfirmModal(false);
    formik.submitForm();
    var saveResp = await save(formik.values);
    setStatusResp(saveResp.data);
    setAlertModal(true);
    setLoading(false)
  }

  
  const closeModal = () => {
    setAlertModal(false);
    if(statusResp.code===1)
    navigate("/ClinicalSettings/HealthScreeningFee");
  }
  


      return (
         <>
         {isAlertModal ? (<StatusResponseModal message={''} data={statusResp}  handleClose = {closeModal}/>):<></>}
         {isConfirmModal ? (<ConfirmModal message={'Proceed to change Health Screening Fee '+healthScreeningFeeData.typeOfCharge+' to '+formik.values.typeOfCharge+' ?'}  handleConfirm = {confirmModal}/>):<></>}
  
          
          <KTCard>
            <KTCardHead title={((id == undefined || parseInt(id) === 0)?'Add':'Edit')+' HEALTH SCREENING FEE'}>
            </KTCardHead>
            <KTCardBody>
                <form className='form w-100 my-5' onSubmit={formik.handleSubmit} noValidate id='kt_country_add_edit_form' >
                    <div className="row">
                      <div className='col-sm-6 d-md-flex my-2'>
                        <KTInputLabel required={true} title="Name" className='col-md-3' />
                        <div className="col-md-8">
                          <KTInputBox placeholder='Enter Type of charge' formikProps={formik.getFieldProps('typeOfCharge')} maxLength={128}
                            active={formik.touched.typeOfCharge}  
                            error={formik.errors.typeOfCharge || (isExist?.isExists ? 'Health Screening Fee already exist. Please enter a different one' : undefined)}
                            onKeyUpHandler={() => {
                              if(formik.values.typeOfCharge !== undefined && formik.values.typeOfCharge.length > 0)
                              IsCodeExist(formik.values.typeOfCharge,formik.values.id).then(response => {
                              setExist(response.data);
                              if(response.data.isExists)
                              formik.setFieldError("typeOfCharge","Health Screening Fee Code already exist. Please enter a different one");
                              });
                            }}  
                          />
                        </div>
                      </div>

                      <div className='col-sm-6 d-md-flex my-2'>
                        <KTInputLabel required={true} title="Description" className='col-md-3' />
                        <div className="col-md-8">
                          <KTInputBox placeholder='Description' formikProps={formik.getFieldProps('description')} maxLength={512}
                            active={formik.touched.description}   error={formik.errors.description}
                            
                          />
                        </div>
                      </div>
                      
                    </div>

                    <div className="row">
                      <div className='col-sm-6 d-md-flex my-2'>
                          <KTInputLabel required={true} title="Screening Type" className='col-md-3' />
                          <div className="col-md-8">
                          <KTSelectBox placeholder='Select Screening Type'  formikProps={formik.getFieldProps('healthScreeningTypeForm.id')}  
                          optionsList={healthScreeningTypeList?.map((x) => {return new KeyValueOption(x.id,x.name);})}
                                    active={formik.touched.healthScreeningTypeForm?.id} error={formik.errors.healthScreeningTypeForm?.id}
                                    onChangeHandler={(val) => {
                                      formik.setFieldValue('healthScreeningTypeForm.id',parseInt(val));
                                    }} 
                                  />
                          </div>
                        </div>
                    </div>


                    

                    <KTCardFooter className='d-flex justify-content-end mt-5'>
                      {/* begin::Action */}                           
                        <FormActionButtons isLoading={isLoading} id={id} backUrl='/ClinicalSettings/HealthScreeningFee' disabled={formik.isSubmitting || !formik.isValid || isExist?.isExists} />
                        {/* end::Action */}
                    </KTCardFooter>


                </form>
            </KTCardBody>

          </KTCard>
          </>
      )
    
}


