
import React, { FC } from 'react';

import { PageLink, PageTitle } from '../../../../_theme/layout/core';
import { Route, Routes } from 'react-router-dom';
import { SuspendUserList } from './SuspendUserList';
import { SuspendUserAddEdit } from './SuspendUserAddEdit';
import { StorageHelper } from '../../../services';

const SuspendUserModule : FC = () => {


  const breadcrumbs: Array<PageLink> = [
    {
      title: StorageHelper.getBackURL().toUpperCase().includes('CLINIC') ? 'Clinic User' : 'Admin User',
      path: StorageHelper.getBackURL(),
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
    <Routes>
        <Route path='userId/:userId'
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Suspend User</PageTitle>
              <SuspendUserList/>
            </>
          }
        />
        <Route path='Add/userId/:userId'
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Add Suspension</PageTitle>
              <SuspendUserAddEdit/>
            </>
          }
        />
        <Route path='Edit/userId/:userId/id/:id'
        element={
          <>
            <PageTitle breadcrumbs={breadcrumbs}>Edit Suspension</PageTitle>
            <SuspendUserAddEdit/>
          </>
        }
      />
    </Routes>
    </>
  )
}

 export default SuspendUserModule
