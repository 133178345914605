import { useEffect, useState } from 'react';
import { KTCard, KTCardBody,KTCardFooter,KTCardHead, KTCardHeadToolBar } from '../../../../_theme/helpers';
import { useFormik } from 'formik';
import { Constants, DateToString, decodeAes, FormActionButtons, KTInputLabel, Loading ,StatusResponseModal, StringToDate, UserType} from '../../../services';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { StatusResponse } from '../../../models/global/StatusResponce';
import { KTDatePickerBox, KTTextAreaBox} from '../../../services/components/InputTemplates';
import { getSuspendApplicationUserById, getUserDetialByApplicationUserId, SuspendUserSaveUpdate } from './_requests';
import { AdminUserForm } from '../admin/_models';
import { SuspendUser, SuspendUserValidationSchema } from './_models';
import { UserDetialsHeader } from '../_headerUser';
import { ClinicUser } from '../../clinic-module/clinic-user/_models';

export function SuspendUserAddEdit () {
  const [isLoading, setLoading] = useState(true);
  const [FormData,SetFormData] = useState<SuspendUser>(new SuspendUser());
  const [ClinicUserData,SetClinicUserData] = useState<ClinicUser>();
  const [AdminUserData,SetAdminUserData] = useState<AdminUserForm>();
  const [statusResp, setStatusResp] = useState(new StatusResponse());
  const [isAlertModal, setAlertModal] = useState(false);
  const [TermiantionDateStart, setTermiantionDateStart] = useState(new Date());
  const navigate = useNavigate();
  const { id , userId} = useParams();
  
  useEffect(()=>{
    fetchFormData();
  return ()=>{ 
  }
  // eslint-disable-next-line
 },[])

  async function fetchFormData() {
    var _userId = Number(userId);
    getUserDetialByApplicationUserId(_userId).then(response => {
      if(response.data.userForms.applicationuserForm.usertypeName === UserType.CLINICUSER.CODE) {
        SetClinicUserData(response.data.userForms as ClinicUser);
      }
      else {
        SetAdminUserData(response.data.userForms as AdminUserForm);
      }
      setLoading(false);
    });
    if(id!==undefined && id!==null ){
      getSuspendApplicationUserById(id).then(res => {
        SetFormData(res.data.Suspendedapplicationuser);
      });
    }
  }

 const formik = useFormik({
    initialValues:FormData,
    validationSchema: SuspendUserValidationSchema,
    enableReinitialize:true,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setSubmitting(true)
      try {
        if(values.id === undefined)
           values.applicationuserId = Number(userId);
        SuspendUserSaveUpdate(values).then(respose => 
          {
            setStatusResp(respose.data);
            setAlertModal(true);
            setLoading(false)
            setSubmitting(false)
          });
      } catch (error) {
        try{
        }
        catch(_error){
            setStatus(Constants.MESSAGE_ERROR);
        }
        console.error(error)
        setLoading(false)
        setSubmitting(false)
      }
    },
  })
  
  const closeModal = () => {
    setAlertModal(false);
    if(statusResp.code===1)
      navigate('/User/SuspendUser/userId/' + userId);
  }

      return (
         <>
         {isAlertModal ? (<StatusResponseModal message={''} data={statusResp}  handleClose = {closeModal} />):<></>}
         {isLoading && (<Loading/>)}
          <KTCard>
            <KTCardHead title={'SUSPEND USER'}>
                <KTCardHeadToolBar>
                    <Link className='btn btn-sm btn-secondary' to={'/User/SuspendUser/userId/' + userId}>
                        BACK
                    </Link>
                </KTCardHeadToolBar>
            </KTCardHead>
            <KTCardBody>
            {ClinicUserData && <UserDetialsHeader formView={true} name={ClinicUserData.name} userType={ClinicUserData.applicationuserForm.usertypeName} profileType={ClinicUserData.applicationuserForm.profilemasterName} clinic={ClinicUserData.clinicName} />}
            {AdminUserData && <UserDetialsHeader formView={true} name={AdminUserData.adminUserForm.name} userType={AdminUserData.applicationuserForm.usertypeName} profileType={AdminUserData.applicationuserForm.profilemasterName} LANID ={decodeAes(AdminUserData.adminUserForm.nric)} />}
        
                <form className='form w-100 pt-6 pb-2' onSubmit={formik.handleSubmit} noValidate id='kt_panel_add_edit_form' >
                  <div className="row">
                    <div className='col-sm-6 d-md-flex my-2'>
                      <KTInputLabel required={true} title="Effective Date" className='col-md-3' />
                          <div className="col-md-8">
                            <KTDatePickerBox placeholder='Effective Date'  formikProps={formik.getFieldProps('fromdate')}
                                  active={formik.touched.fromdate}   error={formik.errors.fromdate}
                                  DatePickerType='Today&Future'                                  
                                  readOnly={formik.values.id !== undefined && formik.values.id > 0 && (StringToDate(formik.values.fromdate) < new Date()) }
                                  handleOnChange ={ 
                                    (date : Date) => {
                                      (date !==null) && setTermiantionDateStart(date);
                                      (date === null) && setTermiantionDateStart(new Date());
                                      formik.setFieldValue('fromdate',DateToString(date))
                                      formik.setFieldValue('todate','');
                                    }
                                  }
                                  />
                              </div>
                            </div>
                            <div className='col-sm-6 d-md-flex my-2'>
                              <KTInputLabel required={false} title="Termination Date" className='col-md-3' />
                              <div className="col-md-8">
                                <KTDatePickerBox placeholder='Enter Termination Date' 
                                  formikProps={formik.getFieldProps('todate')}
                                  active={formik.touched.todate}  
                                  error={formik.errors.todate} 
                                  handleOnChange ={ 
                                    (date : Date) => {
                                      formik.setFieldValue('todate',DateToString(date));
                                    }
                                  }
                                  DatePickerType='Future'
                                  DateRange={{start:TermiantionDateStart}}
                                  />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                              <div className='col-sm-6 d-md-flex my-2'>
                                    <KTInputLabel title="Remarks" className='col-md-3' required={true} />
                                      <div className="col-md-8">
                                      <KTTextAreaBox formikProps={formik.getFieldProps('remark')} placeholder='Please Enter Remarks' maxLength={1024} 
                                      active={formik.touched.remark}  
                                      error={formik.errors.remark} 
                                      />
                                      </div>
                              </div>
                          </div>
                  <KTCardFooter className='d-flex justify-content-end mt-5'>
                      {/* begin::Action */}                           
                        <FormActionButtons isLoading={isLoading} id={id} backUrl={'/User/SuspendUser/userId/' + userId} disabled={formik.isSubmitting} />
                        {/* end::Action */}
                  </KTCardFooter>
                </form>
            </KTCardBody>

          </KTCard>
          </>
      )
}
