import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import { Status } from '../../../models/global/StatusResponce'
import { forgotUserId } from '../core/_requests'
import { KTInputError, SetPageMainTitle } from '../../../services'
import { env } from '../../../App.env'

const initialValues = {
  email: '',
  mobile : ''
}

const forgotUserIdSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .max(128, 'Maximum 128')
    .required('Please Enter Email Id'),  
  mobile: Yup.number()
    .min(8,'Minimum 8')
    .required('Please Enter Mobile Number'),
  }
)

export function ForgotUserId() {
  SetPageMainTitle('Forgot UserId');
  const [loading, setLoading] = useState(false)
  const [resStatus, setStatus] = useState<Status>()
  const formik = useFormik({
    initialValues,
    enableReinitialize:true,
    validationSchema: forgotUserIdSchema,
    onSubmit: (values) => {
      setLoading(true)
      var param = {
        apiUrl: env.base_api_url,
        clientUrl: window.location.href,
        emailId: values.email,
        mobile: values.mobile,
        usertype: "2"
      }
      forgotUserId(param)
          .then( res => {
            setStatus(res.data);
            setLoading(false)
        })
    },
  })

  const onKeyDown = (e:any,v:any) => {
    var ignoreKeys = ['Backspace','Shift','Tab','CapsLock','Control','Home','End','Delete','ArrowLeft','ArrowRight','ArrowUp','ArrowDown'];
    if(ignoreKeys.includes(e.key))
      return true;
    var RE_DIGIT_ONLY = /^\d+$/;
    var f = (RE_DIGIT_ONLY.test(e.key));
    if(!f)
      e.preventDefault();
    return f;
  } 

  return (
    <>
    {resStatus === undefined && (
      <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_login_forgotpassword_form'>
      <div className='mb-8'>
        <h2 className='text-light mb-3 fw-normal'>FORGOT USERID ?</h2>
      </div>
      <div className='mb-5 fs-5'>
        <div className='text-light'>
        Enter below details to retrive your userid.
        </div>
      </div>
      <div className='fv-row mb-5'>
        <div className="input-group mb-1">
        <span className="input-group-text"><i className="fa fa-phone fs-4 text-gray-400"></i></span>
        <input maxLength={12}
          onKeyDown={(evt) => {return onKeyDown(evt,formik.values.mobile);}}
          placeholder='Mobile'
          {...formik.getFieldProps('mobile')}
          className={clsx(
            'form-control form-control-solid form-control-sm',
            {'is-invalid': formik.touched.mobile && formik.errors.mobile}
          )}
          type='text'
          autoComplete='off'
        />
        </div>
        <KTInputError active={formik.touched.mobile} error={formik.errors.mobile} textClass='text-light'/>
      </div>
      <div className='fv-row mb-5'>
      <div className="input-group mb-1">
        <span className="input-group-text"><i className="fa fa-envelope fs-4 text-gray-400"></i></span>
        <input maxLength={128}
        placeholder='Email'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-solid form-control-sm',
            {'is-invalid': formik.touched.email && formik.errors.email}
          )}
        />
        </div>
        <KTInputError active={formik.touched.email} error={formik.errors.email} textClass='text-light' />
      </div>
      <div className='d-flex justify-content-between mb-5'>
        <Link className='btn btn-sm border border-light text-light text-hover-secondary bg-hover-light mt-5 mb-2'to={'/'}>BACK</Link>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-sm btn-secondary mt-5 mb-2 border-secondary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>SUBMIT</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      <div className='d-flex justify-content-start mt-6 p-1'>
      </div>
    </form>
    )}
    {
      resStatus && (
          <div className='fs-5'>
            <div className='text-light py-5'>
              {resStatus.message}
            </div>
            <div className='text-light py-5'>
              Please check your email to get UserId and click
              <Link className='text-warning text-hover-primary m-1' to='/'><u>here</u></Link> 
              to login.
            </div>
          </div>
      )}
    </>
  )
}
