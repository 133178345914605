import { Auditable } from "../../../models/global/Auditable"
import * as Yup from 'yup';
import { string } from 'yup';


export interface SurveyConfigResponse {
  SurveyConfigForm: SurveyConfig[];
}

export class SurveyConfig extends Auditable{
  id?: number;
  maxpublishquestions?: number;
  todate: string="";
  fromdate: string="";
  minimumalertrating: number=0;
  alertemailaddress: string="";
  alertemailforfeedback: boolean=false;
  appnotification: boolean=false;
  isdelete: boolean=false;
  isnoteditable: boolean=false;
  disableFromDate?: any;
  disableToDate?: any;
  toDateFlag?: any;
  fromDateFlag?: any;
  requestoption1date?: any;
  requestoption1slot?: any;
  requestoption2date?: any;
  requestoption2slot?: any;
  uploademail: string="";
}

export const SurveyConfigSchema = Yup.object({

  fromdate: string().required('Please enter Effective Date.'),
  maxpublishquestions: Yup.number().required('Please Select Max No Of Published Questions.').moreThan(0, 'Please Select Max No Of Published Questions.'),
  minimumalertrating: Yup.number().moreThan(0, 'Please Select Minimum Alert Rating.'),
  uploademail: string().required('Please enter Email Address to send Upload Ratings Log File.'),
  alertemailaddress: string().required('Please enter Alert Email address.'),
 
})
