import React from 'react'
import {Modal} from 'react-bootstrap'

type Props = {
  message?: string,
  children?: React.ReactNode
  data?: any,
  handleClose?: (param?: string) => void
  handleConfirm?: (param?: string) => any
}

const ConfirmModal: React.FC<Props> = ({data,message,children,handleClose,handleConfirm}) => {

  const [show, setShow] = React.useState(true);
  const close = () => {
  setShow(false);
    if(handleClose)
    handleClose(data);
  } 
  const confirm = () => {
    setShow(false);
      if(handleConfirm)
      handleConfirm(data);
    } 

  return (
    <Modal
      className='modal fade'
      id='kt_modal_confirm'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='modal-md'
      aria-hidden='true'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>CONFIRMATION</h5>
          <button
            className='btn btn-icon btn-sm btn-active-light-primary ms-2 btn-close' onClick={close}
            aria-label="Close"
          >
          </button>
        </div>
        <div className='modal-body fs-4'>
          {message}
          {children}
        </div>
        <div className='modal-footer py-4'>
        <button type='button' className='btn btn-sm btn-brand text-light' onClick={confirm}>
          YES
          </button>
          <button type='button' className='btn btn-sm btn-secondary' onClick={close}>
          NO
          </button>
        </div>
      </div>
    </Modal>
  )
}

export {ConfirmModal}
