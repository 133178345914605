import { Auditable } from "../../../models/global/Auditable"
import * as Yup from 'yup';
import { ApplicationUser } from "../../users/_models";
import { decode, encode } from "html-entities";
import { clone } from "../../../services";


export class ClinicUser extends Auditable  {
  applicationuser?: number
  applicationuserForm: ApplicationUser = new ApplicationUser()
  applicationuserName?: string
  clinic: number = 0
  clinicCode?: string
  clinicName?: string
  did?: string
  dob?: string
  email: string = ""
  encdecApplicationuserId?: string
  encdecClinicuserId?:string
  gender?: string
  id?: number
  internalRemarks?: string
  ismaincontactperson?: any
  //lastLogin?: string 
  mobile: string = ""
  name: string = ""
  nric?: string = ""
  profileType?: string 
  rowId?: number
}

export function ClinicUserEscapeHtml(_data:ClinicUser,type:string){

  let data = clone(_data);
  data.internalRemarks = (type=="decode") ?decode(data.internalRemarks):encode(data.internalRemarks);
  
  return data;
 
}

export const ClinicUserValidationSchema = Yup.object({
  nric: Yup.string().required('Please Enter NRIC').min(5).max(16),
  name: Yup.string().required('Please Enter Name'),
  mobile: Yup.string().required('Please Enter Mobile Number'),
  email: Yup.string().email().required('Please Enter vaild Email'),

  applicationuserForm: Yup.object({
    effectivedate: Yup.string()
      .required('Please Enter Effective Date'), 
      profilemaster: Yup.number().min(1,'Please Select User Profile')
      .required('Please Select User Profile'),
  })
})