import clsx from 'clsx'
import {FC} from 'react'
import { KTSVG } from './KTSVG'

type PropsHead = {
  className?: string
  title?:string
  children?: React.ReactNode
}

const KTCardHead: FC<PropsHead> = (props) => {
  const {className,title, children} = props
  return (
    <div
      className={clsx(
        'card-header p-5',
        className && className,
        {},
      )}
    >
      { title ?   <div className="card-title flex-row">
                <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-2 me-2 text-brand' />
                <span className="card-label fw-bolder mb-1 text-brand fs-6">{title.toUpperCase()}</span>
              </div> : <></>}

      {children}
    </div>
  )
}

type PropsHeadTool = {
  className?: string
  children?: React.ReactNode
}

const KTCardHeadToolBar: FC<PropsHeadTool> = (props) => {
  const {className, children} = props
  return (
    <div
      className={clsx(
        'card-toolbar my-0',
        className && className,
        {},
      )}
    >
      {children}
    </div>
  )
}


type PropsFooter = {
  className?: string
  children?: React.ReactNode
}

const KTCardFooter: FC<PropsFooter> = (props) => {
  const {className, children} = props
  return (
    <div
      className={clsx(
        'card-footer bg-light',
        className && className,
        {},
      )}
    >

      {children}
    </div>
  )
}

export {KTCardHead,KTCardFooter,KTCardHeadToolBar}
