import { useEffect, useState } from 'react';
import { InpatientAttendanceFeeForm, InpatientAttendanceFeeSchema} from './_models';
import { save,getById } from './_requests';
import { KTAccordion, KTAccordionItem, KTCard, KTCardBody,KTCardFooter,KTCardHead } from '../../../../_theme/helpers';
import { useFormik } from 'formik';
import { Constants, KTInputBox, KTInputLabel, MakerChecker, StatusResponseModal } from '../../../services';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { StatusResponse } from '../../../models/global/StatusResponce';
import { KTDatePickerBox, KTSelectBox, KTTextAreaBox } from '../../../services/components/InputTemplates';
import { InpatientAttendanceTable } from '../../settings/inpatient-attendance-table/_models';
import { searchInpatientAttendanceTable } from '../../settings/inpatient-attendance-table/_requests';
import { KeyValueOption } from '../../../models';
import { getAllActiveAdminUser } from '../../users/admin/_requests';
import { useAuth } from '../../auth';

export function InpatientAttendanceFeeView () {

  const [isLoading, setLoading] = useState(true);
  const [isLoadingAt, setLoadingAt] = useState(true);
  const [statusResp, setStatusResp] = useState(new StatusResponse());
  const [isAlertModal, setAlertModal] = useState(false);
  const [inpatientAttendanceFeeData, setInpatientAttendanceFee] = useState(new InpatientAttendanceFeeForm());
  const navigate = useNavigate();
  const [inpatientAttendanceTableList, setInpatientAttendanceTableList] = useState(Array<InpatientAttendanceTable>);
  const [AdminUserDDLList, setAdminUserDDLList] = useState<KeyValueOption[]>([]);
  const {currentUser} = useAuth();
  
  const { id } = useParams();

  useEffect(()=>{
    fetchFormData();
  return ()=>{ 
  }
 })

 async function fetchFormData() {
  fetchInpatientAttendanceFeeList();
  fetchInpatientAttendanceTable();
  //fetchAllActiveAdminUser();
 }



 const fetchInpatientAttendanceFeeList = () => {
  if(id !== undefined && id && isLoading){
    getById(id).then(function (response) {
      setInpatientAttendanceFee(response.data.inpatientattendancefeeForm);
      formik.resetForm({
        values:response.data.inpatientattendancefeeForm
      })
      setLoading(false);
    })
  }else{
    setLoading(false);
  }
}



const fetchInpatientAttendanceTable = ()=>{
  if(isLoadingAt){
    searchInpatientAttendanceTable().then(function (response) {
      setInpatientAttendanceTableList(response.data.inpatientattendancefeetableForms);
      setLoadingAt(false);
    })

    getAllActiveAdminUser(MakerChecker.TASK.ClinicDoctorMap).then(response => {
      setAdminUserDDLList(response.data.applicationUserForm.filter((x)=>x.adminUserForm.applicationUserId !== currentUser?.applicationUserId).map((x) => {return new KeyValueOption(x.applicationuserForm.id,x.adminUserForm.name)}));
      setLoading(false);
    });

  }
  
}



 const formik = useFormik({
    initialValues: inpatientAttendanceFeeData,
    validationSchema: InpatientAttendanceFeeSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setSubmitting(true)
      try {
        values.makercheckertasksForm.taskname = "Inpatient Attendance Fees";
        var saveResp = await save(values);
        setStatusResp(saveResp.data);
        setAlertModal(true);
        setLoading(false)
        setSubmitting(false)
      } catch (error) {
        try{
        }
        catch(_error){
            setStatus(Constants.MESSAGE_ERROR);
        }
        console.error(error)
      }
    },
  })

  
  const closeModal = () => {
    setAlertModal(false);
    if(statusResp.code===1)
    navigate("/ClinicalSettings/InpatientAttendanceFee");
  }
  


      return (
         <>
         {isAlertModal ? (<StatusResponseModal message={''} data={statusResp}  handleClose = {closeModal}/>):<></>}
          
          <KTCard>
            <KTCardHead title='View Inpatient Attendance Fee'>
            </KTCardHead>
            <KTCardBody>
                <form className='form w-100 my-5' onSubmit={formik.handleSubmit} noValidate id='kt_country_add_edit_form' >
                <KTAccordion keys={['kt_1','kt_2','kt_3','kt_4']}>
                    <KTAccordionItem id={'kt_1'} title='InPatient Attendance Fee Details'>

                <div className="row">
                      
                      <div className='col-sm-6 d-md-flex my-2'>
                        <KTInputLabel required={true} title="Schedule Name" className='col-md-3' />
                        <div className="col-md-8">
                        <KTSelectBox placeholder='Select Schedule Name' readOnly={true}  formikProps={formik.getFieldProps('inpatientattendancefeeForm.feescheduleId')}  
                        optionsList={inpatientAttendanceTableList?.map((x) => {return new KeyValueOption(x.id,x.name);})}
                                  active={formik.touched.inpatientattendancefeeForm?.anesthetisthdfee} error={formik.errors.inpatientattendancefeeForm?.feescheduleId}
                                  onChangeHandler={(val) => {
                                    formik.setFieldValue('inpatientattendancefeeForm.feescheduleId',parseInt(val));
                                  }} 
                                />
                        </div>
                      </div>
                    </div>

                <div className="row">
                      <div className='col-sm-6 d-md-flex my-2'>
                        <KTInputLabel required={true} title="Effective Date" className='col-md-3' />
                        <div className="col-md-8">
                          <KTDatePickerBox placeholder='Enter Effective Date'  formikProps={formik.getFieldProps('inpatientattendancefeeForm.fromdate')}
                            active={formik.touched.inpatientattendancefeeForm?.fromdate}   error={formik.errors.inpatientattendancefeeForm?.fromdate}
                            DatePickerType='Today&Future'                                  
                            readOnly={true}
                            />
                        </div>
                      </div>
                      <div className='col-sm-6 d-md-flex my-2'>
                        <KTInputLabel required={false} title="Termination Date" className='col-md-3' />
                        <div className="col-md-8">
                          <KTDatePickerBox placeholder='Enter Termination Date'  readOnly={true}
                            formikProps={formik.getFieldProps('inpatientattendancefeeForm.todate')}
                            active={formik.touched.inpatientattendancefeeForm?.todate}  
                            error={formik.errors.inpatientattendancefeeForm?.todate} 
                            
                            DatePickerType='Future'
                            />
                        </div>
                      </div>
                    </div>

                  </KTAccordionItem>


                  <KTAccordionItem id={'kt_2'} title='Attendance Fee'>

                  <table className={Constants.DATATABLE_STYLE}>
                    <thead>
                      <tr>
                        <th className='col-md-4'>Fee</th>
                        <th className='col-md-3'>	Normal Hours Fees</th>
                        <th className='col-md-3'>After office hours Fees</th>
                        <th className='col-md-2'>HDU</th>
                        <th className='col-md-2'>ICU</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Main Surgeon </td>
                        <td> 
                        <KTInputBox placeholder='' readOnly={true}  formikProps={formik.getFieldProps('inpatientattendancefeeForm.maindoctornormalhoursfee')} type="Decimal" maxLength={8} 
                                  active={formik.touched.inpatientattendancefeeForm?.maindoctornormalhoursfee}  
                                  error={formik.errors.inpatientattendancefeeForm?.maindoctornormalhoursfee} 
                                />
                        </td>

                        <td> 
                        <KTInputBox placeholder='' readOnly={true}  formikProps={formik.getFieldProps('inpatientattendancefeeForm.maindoctorafterofficehoursfee')} maxLength={32} 
                                  active={formik.touched.inpatientattendancefeeForm?.maindoctorafterofficehoursfee}  
                                  error={formik.errors.inpatientattendancefeeForm?.maindoctorafterofficehoursfee} 
                                />
                        </td>

                        <td> 
                        <KTInputBox placeholder='' readOnly={true}  formikProps={formik.getFieldProps('inpatientattendancefeeForm.maindoctorhdfee')} maxLength={32} 
                                  active={formik.touched.inpatientattendancefeeForm?.maindoctorhdfee}  
                                  error={formik.errors.inpatientattendancefeeForm?.maindoctorhdfee} 
                                />
                        </td>

                        <td> 
                        <KTInputBox placeholder='' readOnly={true}  formikProps={formik.getFieldProps('inpatientattendancefeeForm.maindoctoricufee')} maxLength={32} 
                                  active={formik.touched.inpatientattendancefeeForm?.maindoctoricufee}  
                                  error={formik.errors.inpatientattendancefeeForm?.maindoctoricufee} 
                                />
                        </td>
                        
                      </tr>

                      <tr>
                        <td>Anesthetist </td>
                        <td> 
                        <KTInputBox placeholder=''  readOnly={true} formikProps={formik.getFieldProps('inpatientattendancefeeForm.anesthetistnormalhoursfee')} maxLength={32} 
                                  active={formik.touched.inpatientattendancefeeForm?.anesthetistnormalhoursfee}  
                                  error={formik.errors.inpatientattendancefeeForm?.anesthetistnormalhoursfee} 
                                />
                        </td>

                        <td> 
                        <KTInputBox placeholder='' readOnly={true} formikProps={formik.getFieldProps('inpatientattendancefeeForm.anesthetistafterofficehoursfee')} maxLength={32} 
                                  active={formik.touched.inpatientattendancefeeForm?.anesthetistafterofficehoursfee}  
                                  error={formik.errors.inpatientattendancefeeForm?.anesthetistafterofficehoursfee} 
                                />
                        </td>

                        <td> 
                        <KTInputBox placeholder='' readOnly={true} formikProps={formik.getFieldProps('inpatientattendancefeeForm.anesthetisthdfee')} maxLength={32} 
                                  active={formik.touched.inpatientattendancefeeForm?.anesthetisthdfee}  
                                  error={formik.errors.inpatientattendancefeeForm?.anesthetisthdfee} 
                                />
                        </td>
                        
                        <td> 
                        <KTInputBox placeholder='' readOnly={true}  formikProps={formik.getFieldProps('inpatientattendancefeeForm.anesthetisticufee')} maxLength={32} 
                                  active={formik.touched.inpatientattendancefeeForm?.anesthetisticufee}  
                                  error={formik.errors.inpatientattendancefeeForm?.anesthetisticufee} 
                                />
                        </td>
                        
                      </tr>

                      
                    </tbody>
                  </table>
                    </KTAccordionItem>

                    <KTAccordionItem id={'kt_3'} title='Attendance Max Visits'>

                      
                  <table className={Constants.DATATABLE_STYLE}>
                    <thead>
                      <tr>
                        <th className='col-md-4'>Visit</th>
                        <th className='col-md-3'>	Normal Ward</th>
                        <th className='col-md-2'>HDU</th>
                        <th className='col-md-2'>ICU</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Main Surgeon </td>
                        <td> 
                        <KTInputBox placeholder='' readOnly={true} formikProps={formik.getFieldProps('inpatientattendancefeeForm.maindoctornormalwardmaxvisits')} type="Digit" maxLength={8} 
                                  active={formik.touched.inpatientattendancefeeForm?.maindoctornormalwardmaxvisits}  
                                  error={formik.errors.inpatientattendancefeeForm?.maindoctornormalwardmaxvisits} 
                                />
                        </td>

                        <td> 
                        <KTInputBox placeholder='' readOnly={true} formikProps={formik.getFieldProps('inpatientattendancefeeForm.maindoctorhdmaxvisits')} maxLength={32} 
                                  active={formik.touched.inpatientattendancefeeForm?.maindoctorhdmaxvisits}  
                                  error={formik.errors.inpatientattendancefeeForm?.maindoctorhdmaxvisits} 
                                />
                        </td>

                        <td> 
                        <KTInputBox placeholder='' readOnly={true} formikProps={formik.getFieldProps('inpatientattendancefeeForm.maindoctoricumaxvisits')} maxLength={32} 
                                  active={formik.touched.inpatientattendancefeeForm?.maindoctoricumaxvisits}  
                                  error={formik.errors.inpatientattendancefeeForm?.maindoctoricumaxvisits} 
                                />
                        </td>

                       
                        
                      </tr>

                      <tr>
                        <td>Anesthetist </td>
                        <td> 
                        <KTInputBox placeholder='' readOnly={true} formikProps={formik.getFieldProps('inpatientattendancefeeForm.anesthetistnormalwardmaxvisits')} maxLength={32} 
                                  active={formik.touched.inpatientattendancefeeForm?.anesthetistnormalwardmaxvisits}  
                                  error={formik.errors.inpatientattendancefeeForm?.anesthetistnormalwardmaxvisits} 
                                />
                        </td>

                        <td> 
                        <KTInputBox placeholder='' readOnly={true} formikProps={formik.getFieldProps('inpatientattendancefeeForm.anesthetisthdmaxvisits')} maxLength={32} 
                                  active={formik.touched.inpatientattendancefeeForm?.anesthetisthdmaxvisits}  
                                  error={formik.errors.inpatientattendancefeeForm?.anesthetisthdmaxvisits} 
                                />
                        </td>

                        <td> 
                        <KTInputBox placeholder='' readOnly={true} formikProps={formik.getFieldProps('inpatientattendancefeeForm.anesthetisticumaxvisits')} maxLength={32} 
                                  active={formik.touched.inpatientattendancefeeForm?.anesthetisticumaxvisits}  
                                  error={formik.errors.inpatientattendancefeeForm?.anesthetisticumaxvisits} 
                                />
                        </td>
                        
                      
                        
                      </tr>

                      
                    </tbody>
                  </table>

                    </KTAccordionItem>

                    <KTAccordionItem id={'kt_4'} title='Approval'>

                    <div className="row">
                      <div className='col-sm-6 d-md-flex my-2'>
                        <KTInputLabel title="Approver" required ={true} className='col-md-3' />
                          <div className="col-md-8">
                          <KTSelectBox placeholder='-- Select User --' readOnly={true} formikProps={formik.getFieldProps('makercheckertasksForm.parentapproverid')}  
                              optionsList={AdminUserDDLList}
                              active={formik.touched.makercheckertasksForm?.parentapproverid} 
                              error={formik.errors.makercheckertasksForm?.parentapproverid}
                              onChangeHandler={(val: any) => {
                                  formik.setFieldValue('makercheckertasksForm.parentapproverid',val);
                                }} 
                              />
                          </div>
                      </div>
                    </div>
                    <div className="row">
                        <div className='col-sm-6 d-md-flex my-2'>
                          <KTInputLabel title="Remarks" className='col-md-3' />
                            <div className="col-md-8">
                              <KTTextAreaBox readOnly={true} formikProps={formik.getFieldProps('makercheckertasksForm.creatorremarks')} maxLength={1024} />
                            </div>
                        </div>
                    </div>

                    </KTAccordionItem>

                    

                  </KTAccordion>

                    <KTCardFooter className='d-flex justify-content-end mt-5'>
                      {/* begin::Action */}
                        <div className="btn-group" role="group" >                                
                          
                            <Link className='btn btn-sm btn-secondary ms-3' to='/ClinicalSettings/InpatientAttendanceFee/Search'>CLOSE</Link>
                        </div>
                        {/* end::Action */}
                    </KTCardFooter>
                </form>
            </KTCardBody>

          </KTCard>
          </>
      )
    
}


