/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useLayout} from '../core'

const Footer: FC = () => {
  const {classes} = useLayout()
  return (
    <div className='footer py-4 d-flex flex-lg-column bg-dark' id='kt_footer'>
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Copyright */}
        <div className='d-flex align-items-center fs-7'>
          <a href='https://www.aia.com.sg/en/index/terms-of-use.html' target="_blank" rel="noopener noreferrer" className='text-light text-hover-primary px-2'>
            Terms of Use
          </a>
          <a className='text-light text-hover-light px-2'> | </a>
          <a href='https://www.aia.com.sg/en/index/personal-data-policy.html' target="_blank" rel="noopener noreferrer" className='text-light text-hover-primary px-2'>
          AIA Personal Data Policy (Singapore)
          </a>

        </div>
        {/* end::Copyright */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export {Footer}
