import React, { FC } from 'react';

import { PageLink, PageTitle } from '../../../../_theme/layout/core';
import { Navigate, Route, Routes } from 'react-router-dom';
import { UploadInternalRatingsList } from './UploadInternalRatingsList';
import { UploadInternalRatingsAddEdit } from './UploadInternalRatingsAddEdit';

type Props = {
  breadcrumbs: Array<PageLink>
}

const UploadInternalRatingsModule: FC<Props> = ({breadcrumbs}) => {

  const usersBreadcrumbs = breadcrumbs.concat([
    {
      title: 'Upload Internal Ratings',
      path: '/RatingSettings/UploadInternalRatings',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    }
  ])

  return (
    <>
    <Routes>
        <Route path='Search'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Search Uploads</PageTitle>
              <UploadInternalRatingsList />
            </>
          }
        />
        <Route path='Add'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Add Uploads</PageTitle>
              <UploadInternalRatingsAddEdit />
            </>
          }
        />
      <Route path='Edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Edit Uploads</PageTitle>
              <UploadInternalRatingsAddEdit />
            </>
          }
        />
      <Route index element={<Navigate to='/RatingSettings/UploadInternalRatings/Search' />} />
    </Routes>
    </>
  )
}

export {UploadInternalRatingsModule}
