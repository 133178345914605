import { Auditable } from "../../../models/global/Auditable"
import * as Yup from 'yup';
import { string } from 'yup';

export interface SpecialityResponse {
  specialty: Speciality[];
}

export interface SpecialityResponseO {
  speciality: Speciality;
}

export class Speciality extends Auditable  {
  id: number=0
  name: string = ''
  specialty:string = ''
}

export class SubSpeciality extends Auditable  {
  id: number=0
  name: string =''
  parentSpecialtyId:number = 0
}


export const SpecialitySchema = Yup.object({
  name: string().max(256, 'Maximum 256').required('Please enter Specialty Name')
})