import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import { Status } from '../../../models/global/StatusResponce'
import { forgotPassword } from '../core/_requests'
import { KTInputError, SetPageMainTitle } from '../../../services'

const initialValues = {
  email: '',
  username : ''
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .max(128, 'Maximum 128')
    .required('Please Enter Email Id'),  
  username: Yup.string()
    .max(32, 'Maximum 32')
    .required('Please Enter Username'),
  }
)

export function ForgotPassword() {
  SetPageMainTitle('Forgot Password');
  const [loading, setLoading] = useState(false)
  const [resStatus, setStatus] = useState<Status>()
  const formik = useFormik({
    initialValues,
    enableReinitialize:true,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values) => {
      setLoading(true)
      var param = {
        emailId: values.email,
        username: values.username,
      }
        forgotPassword(param)
          .then( res => {
            setStatus(res.data);
            setLoading(false)
        })
    },
  })

  return (
    <>
    {resStatus === undefined && (
      <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_login_forgotpassword_form'>
      <div className='mb-8'>
        <h2 className='text-light mb-3 fw-normal'>FORGOT PASSWORD ?</h2>
      </div>
      <div className='mb-5 fs-5'>
        <div className='text-light'>
        Enter below details to reset your password.
        </div>
      </div>
      <div className='fv-row mb-5'>
        <div className="input-group mb-1">
        <span className="input-group-text"><i className="fa fa-user fs-4 text-gray-400"></i></span>
        <input maxLength={32}
          placeholder='Username'
          {...formik.getFieldProps('username')}
          className={clsx(
            'form-control form-control-solid form-control-sm',
            {'is-invalid': formik.touched.username && formik.errors.username}
          )}
          type='text'
          autoComplete='off'
        />
        </div>
        <KTInputError active={formik.touched.username} error={formik.errors.username} textClass='text-light'/>
      </div>
      <div className='fv-row mb-5'>
      <div className="input-group mb-1">
        <span className="input-group-text"><i className="fa fa-envelope fs-4 text-gray-400"></i></span>
        <input maxLength={128}
        placeholder='Email'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-solid form-control-sm',
            {'is-invalid': formik.touched.email && formik.errors.email}
          )}
        />
        </div>
        <KTInputError active={formik.touched.email} error={formik.errors.email} textClass='text-light' />
      </div>
      <div className='d-flex justify-content-between mb-5'>
        <Link className='btn btn-sm border border-light text-light text-hover-secondary bg-hover-light mt-5 mb-2'to={'/'}>BACK</Link>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-sm btn-secondary mt-5 mb-2 border-secondary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>SUBMIT</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>

      <div className='d-flex justify-content-start mt-6'>
            <Link to='/auth/forgot-userId' className='link-light fs-6 fw-bold py-1 px-2 text-hover-secondary bg-hover-light'>
            FORGET USERID ?
            </Link>
      </div>
    </form>
    )}
    {
      resStatus && (
          <div className='fs-5'>
            <div className='text-light py-5'>
              {resStatus.message}
            </div>
            <div className='text-light py-5'>
               Please check your email to reset password and click 
               <Link className='text-warning text-hover-primary m-1' to='/'><u>here</u></Link> 
               to Login.
            </div>
          </div>
      )}
    </>
  )
}
