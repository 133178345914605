import React, { useEffect, useState } from 'react';
import {  InpatientSurgeonFee, InpatientSurgeonFeeReq, InpatientSurgeonFeeSchema, MakercheckertasksForm, MakercheckertasksFormReq, SurgicalTableform} from './_models';
import { save,getById, getSurgicalTable } from './_requests';
import { KTAccordion, KTAccordionItem, KTCard, KTCardBody,KTCardFooter,KTCardHead } from '../../../../_theme/helpers';
import { useFormik } from 'formik';
import { Constants, DateToString, FormActionButtons, KTInputBox, KTInputLabel, MakerChecker, StatusResponseModal } from '../../../services';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { StatusResponse } from '../../../models/global/StatusResponce';
import { KTDatePickerBox, KTSelectBox, KTTextAreaBox } from '../../../services/components/InputTemplates';
import { KeyValueOption } from '../../../models';
import { InpatientSurgeonFeeTable } from '../../settings/inpatient-surgeon-fee-table/_models';
import { searchInpatientSurgeonFeeTable } from '../../settings/inpatient-surgeon-fee-table/_requests';
import { getAllActiveAdminUser } from '../../users/admin/_requests';
import { useAuth } from '../../auth';

export function InpatientSurgeonFeeAddEdit () {

  const [isLoading, setLoading] = useState(true);
  const [isLoadingAt, setLoadingAt] = useState(true);
  const [isLoading3, setLoading3] = useState(true);
  const [statusResp, setStatusResp] = useState(new StatusResponse());
  const [isAlertModal, setAlertModal] = useState(false);
  const [InpatientSurgeonFeeData, setInpatientSurgeonFee] = useState(new InpatientSurgeonFee());
  const [inpatientSurgeonTableList, setInpatientSurgeonTableList] = React.useState(Array<InpatientSurgeonFeeTable>());
  const [TermiantionDateStart, setTermiantionDateStart] = useState(new Date());
  const [AdminUserDDLList, setAdminUserDDLList] = useState<KeyValueOption[]>([]);
  const [surgicalTableList, setSurgicalTableList] = useState<SurgicalTableform[]>([]);
  const [req] = useState<InpatientSurgeonFeeReq>(new InpatientSurgeonFeeReq());
  const navigate = useNavigate();
  const {currentUser} = useAuth();

  const { id } = useParams();

  useEffect(()=>{
    fetchInpatientSurgeonFeeList();
    fetchInpatientSurgeonTable();
    fetchFormData();
  return ()=>{ 
  }
  // eslint-disable-next-line
 },[])


 const fetchInpatientSurgeonTable = ()=>{
  if(isLoading3){
  searchInpatientSurgeonFeeTable().then(function (response) {
    setInpatientSurgeonTableList(response.data.inpatientSurgeonFeeTableForms);
    setLoading3(false);
  })
  }
}

 const fetchInpatientSurgeonFeeList = () => {
  if(id !== undefined && id && isLoading){
    getById(id).then(function (response) {
      
      let _InpatientSurgeonFeeData = response.data.viewInpatientSurgeonFee[0]
      _InpatientSurgeonFeeData.makercheckertasksForm=new MakercheckertasksForm();
      _InpatientSurgeonFeeData.makercheckertasksForm.parentapproverid=parseInt(_InpatientSurgeonFeeData.approvedBy);
      _InpatientSurgeonFeeData.surgicalTables = _InpatientSurgeonFeeData.surgicalTablesBean.id;
      _InpatientSurgeonFeeData.surgicalFeeCellingList = response.data.viewInpatientSurgeonFeeCeiling;
      setInpatientSurgeonFee(_InpatientSurgeonFeeData);

      setLoading(false);
    })
  }else{
    setLoading(false);
  }
}

const fetchFormData = ()=>{
  if(isLoadingAt){
    getSurgicalTable().then(function (response) {
      setSurgicalTableList(response.data.surgicalTableform);
      InpatientSurgeonFeeData.surgicalFeeCellingList = response.data.surgicalTableform;
      setInpatientSurgeonFee(InpatientSurgeonFeeData);
      setLoadingAt(false);
    })

    getAllActiveAdminUser(MakerChecker.TASK.ClinicDoctorMap).then(response => {
      setAdminUserDDLList(response.data.applicationUserForm.filter((x)=>x.adminUserForm.applicationUserId !== currentUser?.applicationUserId).map((x) => {return new KeyValueOption(x.applicationuserForm.id,x.adminUserForm.name)}));
      setLoading(false);
    });

  }
  
}

 const formik = useFormik({
    initialValues: InpatientSurgeonFeeData,
    validationSchema: InpatientSurgeonFeeSchema,
    enableReinitialize:true,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setSubmitting(true)
      try {
        
        let mkreq = new MakercheckertasksFormReq();
        mkreq.taskname = "Inpatient Surgeon Fee";
        mkreq.parentapproverid= values.approvedBy;
        mkreq.creatorremarks = values.creatorremarks;
        
        req.inPatientSurgeonFeeForm=values;
        req.makercheckertasksForm = mkreq;
    
        req.surgicalfeeceling=values.surgicalFeeCellingList;
      
        var saveResp = await save(req);
        setStatusResp(saveResp.data);
        setAlertModal(true);
        setLoading(false)
        setSubmitting(false)
      } catch (error) {
        try{
        }
        catch(_error){
            setStatus(Constants.MESSAGE_ERROR);
        }
        console.error(error)
      }
    },
  })

  
  const closeModal = () => {
    setAlertModal(false);
    if(statusResp.code===1)
    navigate("/ClinicalSettings/InpatientSurgeonFee");
  }
  


      return (
         <>
         {isAlertModal ? (<StatusResponseModal message={''} data={statusResp}  handleClose = {closeModal}/>):<></>}
          
          <KTCard>
            
            <KTCardHead title={((InpatientSurgeonFeeData.id == undefined || InpatientSurgeonFeeData.id === 0)?'Add':'Edit')+' Inpatient Surgeon Fee'}>
            </KTCardHead>
            <KTCardBody>
                <form className='form w-100 my-5' onSubmit={formik.handleSubmit} noValidate id='kt_country_add_edit_form' >
                    
                <KTAccordion keys={['kt_1','kt_2','kt_3','kt_4','kt_5','kt_6']}>
                    <KTAccordionItem id={'kt_1'} title='InPatient Surgeon Fee Details'>

                <div className="row">
                      
                      <div className='col-sm-6 d-md-flex my-2'>
                        <KTInputLabel required={true} title="Fee Table Name" className='col-md-3' />
                        <div className="col-md-8">
                        <KTSelectBox placeholder='Select Fee Table Name'  formikProps={formik.getFieldProps('parentFeeTableId')}  
                        optionsList={inpatientSurgeonTableList?.map((x) => {return new KeyValueOption(x.id,x.name);})}
                                  active={formik.touched.parentFeeTableId} error={formik.errors.parentFeeTableId}
                                  onChangeHandler={(val) => {
                                    formik.setFieldValue('parentFeeTableId',parseInt(val));
                                  }} 
                                />
                        </div>
                      </div>
                    </div>

                <div className="row">
                      <div className='col-sm-6 d-md-flex my-2'>
                        <KTInputLabel required={true} title="Effective Date" className='col-md-3' />
                        <div className="col-md-8">
                          <KTDatePickerBox placeholder='Enter Effective Date'  formikProps={formik.getFieldProps('fromDate')}
                            active={formik.touched.fromDate}   error={formik.errors.fromDate}
                            DatePickerType='Today&Future'                                  
                            readOnly={InpatientSurgeonFeeData.id !== undefined && InpatientSurgeonFeeData.id > 0}
                            handleOnChange ={ 
                              (date : Date) => {
                                (date !==null) && setTermiantionDateStart(date);
                                (date === null) && setTermiantionDateStart(new Date());
                                formik.setFieldValue('fromDate',DateToString(date))
                                formik.setFieldValue('todate','');
                              }
                            }
                            />
                        </div>
                      </div>
                      <div className='col-sm-6 d-md-flex my-2'>
                        <KTInputLabel required={false} title="Termination Date" className='col-md-3' />
                        <div className="col-md-8">
                          <KTDatePickerBox placeholder='Enter Termination Date' 
                            formikProps={formik.getFieldProps('toDate')}
                            active={formik.touched.toDate}  
                            error={formik.errors.toDate} 
                            handleOnChange ={ 
                              (date : Date) => {
                                formik.setFieldValue('toDate',DateToString(date));
                              }
                            }
                            DatePickerType='Future'
                            DateRange={{start:TermiantionDateStart}}
                            />
                        </div>
                      </div>
                    </div>

                  </KTAccordionItem>


                  <KTAccordionItem id={'kt_2'} title='Main Doctor'>

                               
                  <table className={Constants.DATATABLE_STYLE}>
                    <thead>
                      <tr>
                        <th className='col-md-4'> </th>
                        <th className='col-md-3'>	NORMAL PROCEDURE</th>
                        <th className='col-md-2'>SPECIAL SURGICAL PROCEDURE</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Main Operation % </td>
                        <td>
                          <KTInputBox placeholder='Enter Percentage of Fees %' formikProps={formik.getFieldProps( 'mainDoctorFirstOperationPercentage')}  maxLength={5} type='Decimal'
                              active={formik.touched.mainDoctorFirstOperationPercentage} error={formik.errors.mainDoctorFirstOperationPercentage} 
                            />  
                        </td>
                        <td>
                          <KTInputBox placeholder='Enter Percentage of Fees %' formikProps={formik.getFieldProps( 'mainDoctorFirstSpecialOperationSameIncisionPercentage')} maxLength={5} type='Decimal'
                              active={formik.touched.mainDoctorFirstSpecialOperationSameIncisionPercentage} error={formik.errors.mainDoctorFirstSpecialOperationSameIncisionPercentage} 
                            />  

                        </td>
                      </tr>

                      <tr>
                        <td>2nd Operation (same incision) % </td>
                        <td>
                          <KTInputBox placeholder='2nd Operation (same incision) %' formikProps={formik.getFieldProps( 'mainDoctorSecondOperationSameIncisionPercentage')} maxLength={5} type='Decimal' 
                              active={formik.touched.mainDoctorSecondOperationSameIncisionPercentage} error={formik.errors.mainDoctorSecondOperationSameIncisionPercentage} 
                            />  
                        </td>
                        <td>
                          <KTInputBox placeholder='2nd Operation (same incision) %' formikProps={formik.getFieldProps( 'mainDoctorSecondSpecialOperationSameIncisionPercentage')} maxLength={5} type='Decimal' 
                              active={formik.touched.mainDoctorSecondSpecialOperationSameIncisionPercentage} error={formik.errors.mainDoctorSecondSpecialOperationSameIncisionPercentage} 
                            />  

                        </td>
                      </tr>

                      
                      <tr>
                        <td>3rd Operation (same incision) % </td>
                        <td>
                          <KTInputBox placeholder='2nd Operation (same incision) %' formikProps={formik.getFieldProps( 'mainDoctorThirdOperationSameIncisionPercentage')} maxLength={5} type='Decimal' 
                              active={formik.touched.mainDoctorThirdOperationSameIncisionPercentage} error={formik.errors.mainDoctorThirdOperationSameIncisionPercentage} 
                            />  
                        </td>
                        <td>
                          <KTInputBox placeholder='2nd Operation (same incision) %' formikProps={formik.getFieldProps( 'mainDoctorThirdSpecialOperationSameIncisionPercentage')} maxLength={5} type='Decimal' 
                              active={formik.touched.mainDoctorThirdSpecialOperationSameIncisionPercentage} error={formik.errors.mainDoctorThirdSpecialOperationSameIncisionPercentage} 
                            />  

                        </td>
                      </tr>

                      
                      <tr>
                        <td>4th Operation (same incision) % </td>
                        <td>
                          <KTInputBox placeholder='2nd Operation (same incision) %' formikProps={formik.getFieldProps( 'mainDoctorFourthOperationSameIncisionPercentage')} maxLength={5} type='Decimal'
                              active={formik.touched.mainDoctorFourthOperationSameIncisionPercentage} error={formik.errors.mainDoctorFourthOperationSameIncisionPercentage} 
                            />  
                        </td>
                        <td>
                          <KTInputBox placeholder='2nd Operation (same incision) %' formikProps={formik.getFieldProps( 'mainDoctorFourthSpecialOperationSameIncisionPercentage')} maxLength={5} type='Decimal' 
                              active={formik.touched.mainDoctorFourthSpecialOperationSameIncisionPercentage} error={formik.errors.mainDoctorFourthSpecialOperationSameIncisionPercentage} 
                            />  

                        </td>
                      </tr>



                      
                      <tr>
                        <td>5th Operation (same incision) % </td>
                        <td>
                          <KTInputBox placeholder='2nd Operation (same incision) %' formikProps={formik.getFieldProps( 'mainDoctorFifthOperationSameIncisionPercentage')} maxLength={5} type='Decimal'
                              active={formik.touched.mainDoctorFifthOperationSameIncisionPercentage} error={formik.errors.mainDoctorFifthOperationSameIncisionPercentage} 
                            />  
                        </td>
                        <td>
                          <KTInputBox placeholder='2nd Operation (same incision) %' formikProps={formik.getFieldProps( 'mainDoctorFifthSpecialOperationSameIncisionPercentage')} maxLength={5} type='Decimal' 
                              active={formik.touched.mainDoctorFifthSpecialOperationSameIncisionPercentage} error={formik.errors.mainDoctorFifthSpecialOperationSameIncisionPercentage} 
                            />  

                        </td>
                      </tr>





                      
                      <tr>
                        <td>6th Operation (same incision) % </td>
                        <td>
                          <KTInputBox placeholder='2nd Operation (same incision) %' formikProps={formik.getFieldProps( 'mainDoctorSixOperationSameIncisionPercentage')} maxLength={5} type='Decimal'
                              active={formik.touched.mainDoctorSixOperationSameIncisionPercentage} error={formik.errors.mainDoctorSixOperationSameIncisionPercentage} 
                            />  
                        </td>
                        <td>
                          <KTInputBox placeholder='2nd Operation (same incision) %' formikProps={formik.getFieldProps( 'mainDoctorSixSpecialOperationSameIncisionPercentage')} maxLength={5} type='Decimal' 
                              active={formik.touched.mainDoctorSixSpecialOperationSameIncisionPercentage} error={formik.errors.mainDoctorSixSpecialOperationSameIncisionPercentage} 
                            />  

                        </td>
                      </tr>



                      
                      <tr>
                        <td>7th Operation (same incision) % </td>
                        <td>
                          <KTInputBox placeholder='2nd Operation (same incision) %' formikProps={formik.getFieldProps( 'mainDoctorSevenOperationSameIncisionPercentage')} maxLength={5} type='Decimal'
                              active={formik.touched.mainDoctorSevenOperationSameIncisionPercentage} error={formik.errors.mainDoctorSevenOperationSameIncisionPercentage} 
                            />  
                        </td>
                        <td>
                          <KTInputBox placeholder='2nd Operation (same incision) %' formikProps={formik.getFieldProps( 'mainDoctorSevenSpecialOperationSameIncisionPercentage')} maxLength={5} type='Decimal' 
                              active={formik.touched.mainDoctorSevenSpecialOperationSameIncisionPercentage} error={formik.errors.mainDoctorSevenSpecialOperationSameIncisionPercentage} 
                            />  

                        </td>
                      </tr>




                      
                      <tr>
                        <td>Emergency Fee %  </td>
                        <td>
                          <KTInputBox placeholder='2nd Operation (same incision) %' formikProps={formik.getFieldProps( 'emergencyoppercent')} maxLength={5} type='Decimal'
                              active={formik.touched.emergencyoppercent} error={formik.errors.emergencyoppercent} 
                            />  
                        </td>
                        <td>
                          <KTInputBox placeholder='2nd Operation (same incision) %' formikProps={formik.getFieldProps( 'emergencySpecialoppercent')} maxLength={5} type='Decimal' 
                              active={formik.touched.emergencySpecialoppercent} error={formik.errors.emergencySpecialoppercent} 
                            />  

                        </td>
                      </tr>



                      
                    </tbody>
                  </table>

                    </KTAccordionItem>

                    <KTAccordionItem id={'kt_3'} title='Assisting Doctor'>

                      
                    <div className="row">
                        <div className='col-sm-6 d-md-flex my-2'>
                          <KTInputLabel title="Percentage of Fees %" required={true} className='col-md-3' />
                          <div className="col-md-8">
                            <KTInputBox placeholder='Enter Percentage of Fees %' formikProps={formik.getFieldProps( 'assistingDoctorPercentage')} maxLength={128} 
                              active={formik.touched.assistingDoctorPercentage} error={formik.errors.assistingDoctorPercentage} 
                            /> 
                          </div>
                        </div>
                        <div className='col-sm-6 d-md-flex my-2'>
                          <KTInputLabel title="Minimum Surgical table for claims" required={true} className='col-md-3' />
                          <div className="col-md-8">
                          <KTSelectBox placeholder='Select Fee Table Name'  formikProps={formik.getFieldProps('surgicalTables')}  
                              optionsList={surgicalTableList?.map((x) => {return new KeyValueOption(x.id,x.name);})}
                                  active={formik.touched.surgicalTables} error={formik.errors.surgicalTables}
                                  onChangeHandler={(val) => {
                                    formik.setFieldValue('surgicalTables',parseInt(val));
                                    formik.setFieldValue('surgicalTablesBean.id',parseInt(val));
                                  }} 
                                />
                          </div>
                        </div>
                      </div>


                    </KTAccordionItem>

                    <KTAccordionItem id={'kt_4'} title='Anesthetist'>

                      
                    <div className="row">
                        <div className='col-sm-6 d-md-flex my-2'>
                          <KTInputLabel title="Percentage of Fees %" required={true} className='col-md-3' />
                          <div className="col-md-8">
                            <KTInputBox placeholder='Enter Percentage of Fees %'  formikProps={formik.getFieldProps( 'anesthetistPercentage')} maxLength={128} 
                              active={formik.touched.anesthetistPercentage} error={formik.errors.anesthetistPercentage} 
                            /> 
                          </div>
                        </div>
                        <div className='col-sm-6 d-md-flex my-2'>
                          <KTInputLabel title="Minimum Fees $" required={true} className='col-md-3' />
                          <div className="col-md-8">
                          <KTInputBox placeholder='Minimum Fees $' formikProps={formik.getFieldProps( 'anesthetistMinimumFee')} maxLength={128} 
                              active={formik.touched.anesthetistMinimumFee} error={formik.errors.anesthetistMinimumFee} 
                            /> 
                          </div>
                        </div>
                      </div>

                    </KTAccordionItem>

                    <KTAccordionItem id={'kt_5'} title='Surgical Fee Celling'>

                                  
                  <table className={Constants.DATATABLE_STYLE}>
                    <thead>
                      <tr>
                        <th className="w-50px">NO.</th>
                        <th  >Table of Surgical Procedure </th>
                        <th  >	Surgeon Fees Ceiling </th>
                      </tr>
                    </thead>
                    <tbody>
                     

                      {formik.values.surgicalFeeCellingList?.map((row, index) => (
                                          <tr key={index}>
                                          <td>{index+1}</td>
                                          <td>{row.name}</td>
                                          <td>
                                            <KTInputBox placeholder={row.name}
                                             formikProps={formik.getFieldProps('surgicalFeeCellingList['+index+'].feeCeiling')}
                                            maxLength={5} type='Digit'
                                          /></td>
                                        </tr>
                                        ))}
                
                    </tbody>
                  </table>

                    </KTAccordionItem>

                    

                    <KTAccordionItem id={'kt_6'} title='Approval'>

                    <div className="row">
                      <div className='col-sm-6 d-md-flex my-2'>
                        <KTInputLabel title="Approver" required ={true} className='col-md-3' />
                          <div className="col-md-8">
                          <KTSelectBox placeholder='-- Select User --'  formikProps={formik.getFieldProps('makercheckertasksForm.parentapproverid')}  
                              optionsList={AdminUserDDLList}
                              active={formik.touched.makercheckertasksForm?.parentapproverid} 
                              error={formik.errors.makercheckertasksForm?.parentapproverid}
                              onChangeHandler={(val: any) => {
                                  formik.setFieldValue('makercheckertasksForm.parentapproverid',val);
                                  formik.setFieldValue('approvedBy',val);
                                  
                                }} 
                              />
                          </div>
                      </div>
                    </div>
                    <div className="row">
                        <div className='col-sm-6 d-md-flex my-2'>
                          <KTInputLabel title="Remarks" className='col-md-3' />
                            <div className="col-md-8">
                              <KTTextAreaBox formikProps={formik.getFieldProps('makercheckertasksForm.creatorremarks')} maxLength={1024} />
                            </div>
                        </div>
                    </div>

                    </KTAccordionItem>

                    

                  </KTAccordion>


                  <KTCardFooter className='d-flex justify-content-end mt-5'>
                      {/* begin::Action */}                           
                        <FormActionButtons isLoading={isLoading} id={id} backUrl='/ClinicalSettings/InpatientSurgeonFee/Search' disabled={formik.isSubmitting || !formik.isValid } />
                        {/* end::Action */}
                    </KTCardFooter>

                </form>
            </KTCardBody>

          </KTCard>
          </>
      )
    
}


