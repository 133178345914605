import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import { Status } from '../../../models/global/StatusResponce'
import { resetPassword } from '../core/_requests'
import { AlertModal, encodeAes, KTInputError, SetPageMainTitle } from '../../../services'
import { PasswordStrengthMeter, PasswordStrengthValidation } from '../../../services/components/PasswordStrength'
import { SystemPasswordPolicy } from '../../settings/system-policy/_models'
import { getSystemPasswordPolicy } from '../../settings/system-policy/_requests'
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import { OTPVerify, ResetPasswordForm } from '../core/_models'
import { AuthResponseModel } from '../../../models'
import * as CryptoJSx from 'crypto-js';

const initialValues = {
  newPassword: '',
  retypeNewPassword : ''
}

export const RestPasswordSchema = Yup.object({
  newPassword: Yup.string().required('Please Enter New Password'),
  retypeNewPassword: Yup.string().required('Retype New Password').when("newPassword", (newPassword: string, schema: any) => {
    return schema.test({
      test: (retypeNewPassword: string) => {
        if(newPassword === '' || retypeNewPassword === '') return true;
        return newPassword === retypeNewPassword
      },
      message: "Password does not match with New Password."})
    })
})

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const [resStatus, setResStatus] = useState<Status>()  
  const [isAlertModal, setAlertModal] = useState(false);   
  const [showStrength, setShowStrength] = useState(false);  
  const [isTokenValid, setTokenValid] = useState(false);  
  const [PasswordPolicy,SetSystemPasswordPolicy] = useState(new SystemPasswordPolicy())  
  const [username, setUserName] = useState<string>()  
  const [show, setShow] = useState(false);
  const [isPwdVisibility1, setPwdVisibility1] = useState<boolean>(false) ;
  const [isPwdVisibility2, setPwdVisibility2] = useState<boolean>(false) ;

  const { token, valid } = useParams();
  const navigate = useNavigate();
  
  useEffect(()=>{
    SetPageMainTitle('Reset Password');
    if(token !== undefined && valid !== undefined)
      setTokenValid(valid.toUpperCase()==='TRUE')
    getSystemPasswordPolicy().then(function (response) {
        SetSystemPasswordPolicy(response.data);
    });
    var _u = sessionStorage.getItem(base64_encode('_USERNAME'));
    if(_u !== null)
    {
      var _uu = base64_decode(_u);
      setUserName(_uu);
      sessionStorage.removeItem(base64_encode('_USERNAME'));
    }

  return ()=>{ 
  }
  // eslint-disable-next-line
 },[])


  const formik = useFormik({
    initialValues,
    enableReinitialize:true,
    validationSchema: RestPasswordSchema,
    onSubmit: (values,{resetForm, setSubmitting}) => {
      
      setShowStrength(false);
      var f = PasswordStrengthValidation(PasswordPolicy,values.newPassword);
      if(!f){
        formik.setFieldError('newPassword','errorr-----------');
        formik.setFieldTouched('newPassword',true);
        setSubmitting(false);
        return;
      }
      if(f){
        setLoading(true)
        var param =  new ResetPasswordForm(); 
        var resetPassToken = sessionStorage.getItem(base64_encode('_token'));
        param.password = encodeAes(values.retypeNewPassword)
        param.sessionToken = token

       if(resetPassToken)
        param.resetPassToken =   base64_decode(resetPassToken);

        if(username)
          param.username = username
        resetPassword(param)
            .then( res => {
              setLoading(false);
              try{
                var result = res.data as Status;
                setResStatus(result);
                if(result.code === 0)
                {
                  setAlertModal(true);
                  resetForm({values:initialValues});
                }
              }catch{}
              try{
                var resultOtp = res.data as AuthResponseModel;
                if(resultOtp.isOTPRequired === true)
                  {
                    var _verifyOtp = new OTPVerify();
                    _verifyOtp.otpTokenUUID = resultOtp.tokenUUID;
                    _verifyOtp.sessionToken = resultOtp.session;
                    _verifyOtp.username = username;
                    _verifyOtp.password = CryptoJSx.SHA256(values.retypeNewPassword).toString();
                    _verifyOtp.resetPassToken = token;
                    var jsonString = JSON.stringify(_verifyOtp);
                    sessionStorage.setItem(base64_encode('_RESETPASSWORD'),base64_encode(jsonString)); 
                    navigate('/auth/Otp');
                  }
              }catch{}
          })
      }
    },
  })

  return (
    <>
    {isAlertModal  && <AlertModal  handleClose={()=>{setAlertModal(false)}} message={resStatus?.message ? resStatus?.message : ''} />}
    {isTokenValid && (resStatus === undefined || resStatus.code === 0) && (
      <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_login_forgotpassword_form'>
      <div className='mb-8'>
        <h2 className='text-light mb-3 fw-normal'>RESET PASSWORD ?</h2>
      </div>
      <div className='mb-5 fs-6'>
        <div className='text-light'>
        Enter your new password to reset.
        </div>
      </div>
      <div className='fv-row mb-5'>
        <div className="input-group mb-1">
        <span className="input-group-text"><i className="fa fa-lock fs-4 text-gray-400"></i></span>
        <input maxLength={32} onFocus={()=>{setShowStrength(true)}}
          placeholder='New Password'
          {...formik.getFieldProps('newPassword')}
          className={clsx(
            'form-control form-control-solid form-control-sm',
            {'is-invalid': formik.touched.newPassword && formik.errors.newPassword}
          )}
          type={isPwdVisibility1?'text':'password'}
          autoComplete='off'
        />
         <span className="input-group-text" onClick={()=>{ setPwdVisibility1(!isPwdVisibility1)}} ><i className={isPwdVisibility1?'fa fa-eye fs-4 text-gray-400':'fa fa-eye-slash fs-4 text-gray-400' }></i></span>
        </div>
        <KTInputError active={formik.touched.newPassword} error={formik.errors.newPassword} textClass='text-light'/>
        {  <PasswordStrengthMeter displayMode={"Text"} inlinePopup={false} passwordpolicy={PasswordPolicy} password={formik.values.newPassword} mode='Light' />}
      </div>
      <div className='fv-row mb-5'>
      <div className="input-group mb-1">
        <span className="input-group-text"><i className="fa fa-lock fs-4 text-gray-400"></i></span>
        <input maxLength={32}  onFocus={()=>{setShowStrength(false)}}
        placeholder='Retype New Password'
        type={isPwdVisibility2?'text':'password'}
          autoComplete='off'
          {...formik.getFieldProps('retypeNewPassword')}
          className={clsx(
            'form-control form-control-solid form-control-sm',
            {'is-invalid': formik.touched.retypeNewPassword && formik.errors.retypeNewPassword}
          )}
        />
        <span className="input-group-text" onClick={()=>{ setPwdVisibility2(!isPwdVisibility2)}} ><i className={isPwdVisibility2?'fa fa-eye fs-4 text-gray-400':'fa fa-eye-slash fs-4 text-gray-400' }></i></span>
        </div>
        <KTInputError active={formik.touched.retypeNewPassword} error={formik.errors.retypeNewPassword} textClass='text-light' />
      </div>
      <div className='d-flex justify-content-between mb-5'>
        <Link className='btn btn-sm border border-light text-light text-hover-secondary bg-hover-light mt-5 mb-2'to={'/'}>BACK</Link>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-sm btn-secondary mt-5 mb-2 border-secondary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>SUBMIT</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      <div className='d-flex justify-content-start mt-6 p-1'>


      { /*
                          <pre>
                            {JSON.stringify(formik.errors, null, 2)}
                          </pre> 
                          */
                      }

      </div>
    </form>
    )}
    {resStatus && resStatus.code === 1 && (
          <div className='fs-5'>
            <div className='text-light py-5'>
              {resStatus.message} <br/> 
              
              click
              <Link className='text-warning text-hover-primary m-1' to='/'><u>here</u></Link> 
              to Login.
            </div>
          </div>
      )}
    {!isTokenValid && (
          <div className='fs-5'>  
            <div className='mb-8'>
              <h2 className='text-light mb-3 fw-normal'>RESET PASSWORD ?</h2>
            </div>
            <div className='text-light py-5'>
                  This link has expired.<br/> Please submit a new request
            </div>
            <div className='d-flex justify-content-end mb-2'>
              <Link className='btn btn-sm btn-secondary mt-2 mb-2'to={'/'}>BACK</Link>
            </div>
          </div>
      )}
    </>
  )
}