
import React, { useEffect, useState } from 'react';
//Datatable Modules

import { KTCard, KTCardBody, KTCardHead, KTCardHeadToolBar } from '../../../../_theme/helpers';
import { Link, useParams } from 'react-router-dom';
import { AlertModal, ConfirmModal, Constants, EmptyDatatable, Loading, Pagination, StorageHelper } from '../../../services';
import { ClinicHeader } from '../clinic/_headerClinic';
import { ClinicUserListTableColumns } from './_dataRowColumns';
import DataTable from 'react-data-table-component';
import { GetClinicById } from '../clinic/_requests';
import { Clinic } from '../clinic/_models';
import { ClinicUser } from './_models';
import { getAllClinicUserByClinicId, ResetPasswordToClinicUser, SendFirstTimeLoginDetailsToClinicUser } from './_requests';
import { Status } from '../../../models/global/StatusResponce';
import { env } from '../../../App.env';
import { unLockApplicationUser } from '../../users/_requests';

export function ClinicUserList () {

  const [isLoading, setLoading] = useState(true);  
  const [status, setStatus] = useState(new Status(0,''));
  const [isAlertModal, setAlertModal] = useState(false);  
  const [isConfirmModal, setConfirmModal] = useState(false);
  const [clinic,SetClinic] = useState(new Clinic())
  const [action,SetAction] = useState({type : '',id : 0, username :''})
  const [ClinicUserList,SetClinicUserList] = useState<Array<ClinicUser>>([]);
  const [PageNo, setPageNo] = React.useState(1);
  const { clinicId } = useParams();
    //Datatable HTML

    useEffect(()=>{
      fetchFormData();
      StorageHelper.setBackURL();
    return ()=>{ 
    }
    // eslint-disable-next-line
   },[])
   
   async function fetchFormData() {
    var _clinicId = parseInt(clinicId?clinicId:'0');
    GetClinicById(_clinicId).then(response => {
      SetClinic(response.data.clinicForms);
    });

    getAllClinicUserByClinicId(_clinicId).then(response => {
      SetClinicUserList(response.data.clinicUserForms);
      setLoading(false);
    });
  }

  const AlertConfirm = (_type:'EMAIL'|'RESET'|'UNLOCK',_id:number,_username:string) =>{
    SetAction({type:_type,id:_id,username:_username});
    if(_type === 'EMAIL')
      setStatus(new Status(0,'Are you sure you want to Send First Time Login Details ?'))
    if(_type === 'RESET')  
      setStatus(new Status(0,'Are you sure you want to Reset Password ?'))
    if(_type==='UNLOCK')
    {
      setStatus(new Status(0,'Are your sure you want Proceed to unlock '+_username+' ?'))
    }
    setConfirmModal(true);
  }
  const handleClose = () =>{
    setAlertModal(false);
    setConfirmModal(false);
    if(status.code === 1)
      fetchFormData();
  }
  const handleConfirm = () =>{
    if(action.type === 'EMAIL')
    {
      setLoading(true);
      SendFirstTimeLoginDetailsToClinicUser({encdecid : ClinicUserList.find(x=>x.id=== action.id)?.encdecClinicuserId}).then(res => {
        setStatus(res.data);
        setAlertModal(true);
        setLoading(false);
      })
    }
    if(action.type === 'RESET')
    {
      setLoading(true);
      var p = {
        encdecid : ClinicUserList.find(x=>x.id=== action.id)?.encdecClinicuserId,
        //clientUrl : window.location.href,
        apiUrl : env.base_api_url
      };
      ResetPasswordToClinicUser(p).then(res => {
        setStatus(res.data);
        setAlertModal(true);
        setLoading(false)
      })
    }
    if(action.type === 'UNLOCK')
    {
      setLoading(true);
      unLockApplicationUser(ClinicUserList.find(x=>x.id=== action.id)?.encdecApplicationuserId).then(res => {
        setStatus(res.data);
        setAlertModal(true);
        setLoading(false)
      })
    }
  }

  return (
    <>
    {isConfirmModal && <ConfirmModal message={status.message}  handleClose = {handleClose} handleConfirm={handleConfirm} />}
    {isAlertModal && <AlertModal message={status.message}  handleClose = {handleClose}/>}
    {isLoading && (<Loading/>)}
    <KTCard>
      <KTCardHead title='CLINIC USER'>
            <KTCardHeadToolBar>
              <Link className='btn btn-sm btn-secondary me-3' to='/Clinic/Search'>BACK</Link>
              <Link className='btn btn-sm btn-secondary' to={'/Clinic/ClinicUser/Add/clinicId/' + clinicId }>ADD CLINIC USER</Link>
          </KTCardHeadToolBar>
      </KTCardHead>
      <KTCardBody className='py-4'>
        <ClinicHeader name={clinic.name} code={clinic.hcinumber} uen= {clinic.uen} type={clinic.clinictypeName} />
        <div className="row">
            <div className='my-3'>
            <div className={Constants.DATATABLE_STYLE_REACT}>
                  <DataTable
                    columns={ClinicUserListTableColumns(AlertConfirm,PageNo,clinicId)}
                    onChangePage={(page: number) => {setPageNo(page);}}
                    data={ClinicUserList}
                    pagination
                    paginationComponent={Pagination} 
                    noDataComponent={<EmptyDatatable columns={ClinicUserListTableColumns()}/>}                   
                  /> 
                  </div>
            </div>
        </div>
      </KTCardBody>
    </KTCard>
    </>
  );
}


