import axios from 'axios'
import { env } from '../../../App.env';
import { CodeExist } from '../../../models';
import { Status, StatusResponse } from '../../../models/global/StatusResponce';
import { InpatientSurgeonFeeTable, InpatientSurgeonFeeTableResponse } from './_models';


const API_URL = env.base_api_url
const API_CP = `${API_URL}/Api/Settings/InSurgeonMaster`


export function searchInpatientSurgeonFeeTable() {
  return axios.get<InpatientSurgeonFeeTableResponse>(`${API_CP}/getInpatientSurgeonFeeTableDetails?name=`)
}

export function getById(id: String) {
  return axios.get<InpatientSurgeonFeeTable>(`${API_CP}/id/${id}`)
}

export function save(req: InpatientSurgeonFeeTable) {
  return axios.post<StatusResponse>(`${API_CP}/save`,req)
}

export function deleteById(id: number) {
  return axios.get<Status>(`${API_CP}/delete/id/${id}`)
}

export function IsCodeExist(name? : string,id : number = 0) {
  return axios.get<CodeExist>(`${API_CP}/fetchExistsInpatientsurgeonMaster?Inpatientsurgeon=${name}&surgeonId=${id}`)
}

