import axios, {} from 'axios'
import { env } from '../../../App.env';
import { DatatableResponse } from '../../../models/global/DataTable';
import { Status, StatusResponse } from '../../../models/global/StatusResponce';
import { AdminSearchForm, AdminUser, AdminUserForm } from './_models';

const API_URL = env.base_api_url

export function getAllActiveAdminUser(taskName: String) {
  return axios.get<{applicationUserForm : Array<AdminUserForm>}>(`${API_URL}/Api/AdminUser/getAllActiveAdminUser?taskName=${taskName}`)
}

export function getAllAdminUsers(param: AdminSearchForm) {
  return axios.post<DatatableResponse<AdminUser>>(`${API_URL}/Api/AdminUser/getAllAdminUsers`,param)
}

export function getAdminUserDetailsById(id: string) {
  return axios.post<{userForms : AdminUserForm}>(`${API_URL}/Api/AdminUser/getAdminUserDetailsById`,{id:id})
}

export function saveOrUpdateAdminUser(data: AdminUserForm) {
  return axios.post<StatusResponse>(`${API_URL}/Api/AdminUser/saveOrUpdateAdminUser`,data)
}
export function ResetPasswordToAdminUser(req : any) {
  return axios.post<Status>(`${API_URL}/Api/AdminUser/ResetPasswordToAdminUser`,req)
}
export function SendFirstTimeLoginDetailsToAdminUser(req:any) {
  return axios.post<Status>(`${API_URL}/Api/AdminUser/SendFirstTimeLoginDetailsToAdminUser`,req)
}
