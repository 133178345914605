import React, { useEffect, useState } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { KTSVG } from '../../../_theme/helpers'
import { KeyValueOption } from '../../models'
import { SystemPasswordPolicy } from '../../modules/settings/system-policy/_models'
import { Constants } from '../lib/Constants'

type Props = {
  password?: string,
  passwordpolicy : SystemPasswordPolicy
  mode? : 'Default' | 'Light',
  displayMode? : string
  inlinePopup?:boolean
}

const PasswordStrengthMeter: React.FC<Props> = ({passwordpolicy, password = '',mode ='Default',displayMode,inlinePopup=false}) => {
    const [strength, setStrength] = useState(0);
    const [policyStatus, setPolicyStatus] = useState<KeyValueOption[]>([]);
    const [show, setShow] = useState(false);

    const _strengthText = ['Weak', 'Weak', 'Weak', 'Weak','Weak', 'Strong']
    const _strengthColor = ['#ccc', '#ef4836', '#f6b44d', '#2b90ef','#ef4836', '#25c281']

    useEffect(()=>{
        var _strength = 0;
        var minlen = passwordpolicy.minpasswordcharlength ? passwordpolicy.minpasswordcharlength : 9;
        var minlenPolicy = new KeyValueOption(0,'Minimum '+minlen+' Password length');
        if(password.length >= minlen){
            _strength = _strength + 1;
            minlenPolicy.key = 1;
        }
        UpdatPolicyStatus(minlenPolicy,'Password length');

        var ucLen = passwordpolicy.minpassworduppercasechars ? passwordpolicy.minpassworduppercasechars : 1;
        var ucLenPolicy = new KeyValueOption(0,'Minimum '+ucLen+' Uppercase Alphabet');
        if(RegExp('(.*[A-Z]{'+ucLen+',})').test(password)){
            _strength = _strength + 1;
            ucLenPolicy.key = 1;
        }
        UpdatPolicyStatus(ucLenPolicy,'Uppercase Alphabet');

        var lcLen = passwordpolicy.minpasswordlowercasechars ? passwordpolicy.minpasswordlowercasechars : 1;
        var lcLenPolicy = new KeyValueOption(0,'Minimum '+lcLen+' Lowercase Alphabet');
        if(RegExp('(.*[a-z]{'+lcLen+',})').test(password))
        {
            _strength = _strength + 1;
            lcLenPolicy.key = 1;
        }
        UpdatPolicyStatus(lcLenPolicy,'Lowercase Alphabet');

        var ncLen = passwordpolicy.minimumpasswordnumericchars ? passwordpolicy.minimumpasswordnumericchars : 1;        
        var ncLenPolicy = new KeyValueOption(0,'Minimum '+ncLen+' Number');
        if(RegExp('(.*[0-9]{'+ncLen+',})').test(password)){
            _strength = _strength + 1;
            ncLenPolicy.key = 1;
        }
        UpdatPolicyStatus(ncLenPolicy,'Number');

        var scLen = passwordpolicy.minpwdnonalphanumericchars ? passwordpolicy.minpwdnonalphanumericchars : 1;
        var scLenPolicy = new KeyValueOption(0,'Minimum '+scLen+' Special Character '+Constants.P_SPECIAL_CHAR+'');
        if(RegExp('(.*['+Constants.P_SPECIAL_CHAR+']{'+scLen+',})').test(password)){
            _strength = _strength + 1;
            scLenPolicy.key = 1;
        }
        UpdatPolicyStatus(scLenPolicy,'Special Character');

        
        var isWeakPolicy = new KeyValueOption(0,'Password should not contain three or more consecutive characters or number Like Abcd@1234');
        if(!isWeakPassword(password)){
            _strength = _strength + 1;
            isWeakPolicy.key = 1;
        }
        UpdatPolicyStatus(isWeakPolicy,'Weak Password');

        setStrength(_strength);
        setShow(false);
      return ()=>{ 
      }
      // eslint-disable-next-line
      },[password])

      const UpdatPolicyStatus = (val : KeyValueOption,type : string) =>{
        var _policy = policyStatus;
        if(policyStatus.length === 0)
            for(var i=0;i<=5;i++)
            _policy.push(new KeyValueOption(0,''));
        
        switch(type)
        {
            case 'Password length':_policy[0] = val; break;
            case 'Uppercase Alphabet':_policy[1] = val; break;
            case 'Lowercase Alphabet':_policy[2] = val; break;
            case 'Number':_policy[3] = val; break;
            case 'Special Character':_policy[4] = val; break;
            case 'Weak Password':_policy[5] = val; break;
            
        }
        setPolicyStatus(_policy);
      } 


      const popover = (
        <Popover id="popover-basic">
            <div className='p-4 border-bottom'>
                <h6 className='mb-0'>Password Policy</h6>
            </div>
          <Popover.Body>
             <table>
                <tbody>
                    {
                        policyStatus.map(x=>(
                        <tr key={`${'tr'}-${Math.floor(Math.random()*1000)}`}><td>{x.value}</td><td>
                            {x.key === 1 && <><KTSVG path="/media/icons/duotune/general/gen043.svg" className=" mt-1 svg-icon-success svg-icon-1" /></>}
                            {x.key === 0 && <><KTSVG path="/media/icons/duotune/general/gen040.svg" className=" mt-1 svg-icon-danger svg-icon-1" /></>}
                            </td></tr>
                        ))
                    }
                </tbody>
             </table>
          </Popover.Body>
        </Popover>
      );

  return ( 

    
  <> { password.length > 0 &&(

     <div className='d-flex flex-column mt-1'>

        <div className='d-flex flex-row border'>
            <span style={{'padding':'0.125rem', 'width' : '20%','backgroundColor' : strength > 0 ? _strengthColor[strength-1] : '#eee' }}></span>
            <span style={{'width' : '20%','backgroundColor' : strength > 1 ? _strengthColor[strength-1] : '#eee'}}></span>
            <span style={{'width' : '20%','backgroundColor' : strength > 2 ? _strengthColor[strength-1] : '#eee'}}></span>
            <span style={{'width' : '20%','backgroundColor' : strength > 3 ? _strengthColor[strength-1] : '#eee'}}></span>
            <span style={{'width' : '20%','backgroundColor' : strength > 4 ? _strengthColor[strength-1] : '#eee'}}></span>
            <span style={{'width' : '20%','backgroundColor' : strength > 5 ? _strengthColor[strength-1] : '#eee'}}></span>
        </div>

        <div className='d-flex justify-content-between'>
                <span className={'fs-7 fw-bolder mt-1 px-2 ' + (mode === 'Light' ? 'bg-gray-200' : '')} style={{'color' : _strengthColor[strength-1]}}>{_strengthText[strength-1]} </span>
                

        { password.length > 0 && displayMode==="Popup" &&(
            <OverlayTrigger show={show} placement='bottom-end' overlay={popover}>
                    <span onMouseEnter={() => setShow(true)} onTouchStart={() => setShow(true)}  onClick={() => setShow(true)} onMouseLeave={() => setShow(false)} onTouchEnd={() => setShow(false)}><KTSVG path="/media/icons/duotune/general/gen046.svg" className={'mt-1 svg-icon-1 hoverable ' + (mode === 'Light' ? 'svg-icon-gray-300' : 'svg-icon-primary')} /></span>
                </OverlayTrigger>
        )}
        </div>

        { password.length > 0 && displayMode==="Text" &&(
        <>
         
          <div className={"fv-plugins-message-container text-" + (mode === 'Light' ? 'light' : 'danger')}>
              <div className="fv-help-block"> 
          
        {
            policyStatus.filter(  y => y.key === 0).map(x=>(
                <span key={`${'tr'}-${Math.floor(Math.random()*1000)}`}> *  {x.value}  <br/></span>
                ))
        }

        </div>
        </div>
          

        </>
         )}


     </div>
     )}

        
  </>
  )
}



export {PasswordStrengthMeter}

export function PasswordStrengthValidation (passwordpolicy:SystemPasswordPolicy, password:string = '') : boolean{
    
    var minlen = passwordpolicy.minpasswordcharlength ? passwordpolicy.minpasswordcharlength : 9;
    var lcLen = passwordpolicy.minpasswordlowercasechars ? passwordpolicy.minpasswordlowercasechars : 1;
    var ucLen = passwordpolicy.minpassworduppercasechars ? passwordpolicy.minpassworduppercasechars : 1;    
    var ncLen = passwordpolicy.minimumpasswordnumericchars ? passwordpolicy.minimumpasswordnumericchars : 1;
    var scLen = passwordpolicy.minpwdnonalphanumericchars ? passwordpolicy.minpwdnonalphanumericchars : 1;

    var check = '^(?=(.*[a-z]){'+lcLen+',})(?=(.*[A-Z]){'+ucLen+',})(?=(.*[0-9]){'+ncLen+',})(?=(.*['+Constants.P_SPECIAL_CHAR+']){'+scLen+',}).{'+minlen+',}$';
    var _PASSWORD_STRENGTH = new RegExp(check);

    var isPwdPolicyPassed = _PASSWORD_STRENGTH.test(password)
    var isWeak = isWeakPassword(password);
    return isPwdPolicyPassed && !isWeak
}

export function isWeakPassword(password:string = ''){
    password = password.toUpperCase();
	let pwdArray = password.split("");
    let counter = 0 ;
    let isWeak = false;
		
		for(let i=0;i<pwdArray.length-1 ;i++) {
			var currentAscii = password.charCodeAt(i);
            var nextAscii = password.charCodeAt(i+1);
            //System.out.println(currentAscii);
            if(nextAscii-currentAscii==1) {
                counter++;
            }else {
				counter = 0 ;
			}

			if(counter==2) {
				isWeak = true;
				break;
			}
		}
		return isWeak;
}





